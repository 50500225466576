/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Injectable,
	ApplicationRef,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';

import { FileDropzoneComponent } from './file-dropzone.component';

@Injectable()
export class FileDropService {
	@Input() dropAccept = '*';

	private dropzones: FileDropzoneComponent[] = [];
	private eventTarget: Element = null;

	private events = {
		dragenter: [event => this.onDragEnter(event), false],
		dragleave: [event => this.onDragLeave(event), false],
		dragover: [event => event.preventDefault(), false],
		drop: [event => this.onDrop(event), true],
	};

	constructor(private appRef: ApplicationRef) {
		Object.entries(this.events).forEach(([type, [listener, useCapture]]) =>
			document.addEventListener(type, listener as EventListener, useCapture as boolean));

		document.addEventListener('mouseup', () => {
			if (this.eventTarget) this.end();
		});
	}

	onDragEnter(event) {
		if (!this.eventTarget) {
			this.start(event);
		}

		this.eventTarget = event.target as Element;
		event.preventDefault();
	}

	onDragLeave(event) {
		if (event.target === this.eventTarget) {
			this.end();
		}
	}

	onDrop(event) {
		event.preventDefault();
		this.end();
	}

	start(event) {
		this.dropzones.forEach(dropzone => {
			dropzone.start(event);
		});
	}

	end() {
		this.dropzones.forEach(dropzone => {
			dropzone.end();
		});
		this.eventTarget = null;
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		Object.entries(this.events).forEach(([type, [listener, useCapture]]) =>
			document.removeEventListener(type, listener as EventListener, useCapture as boolean));
	}

	add(fileDropzone: FileDropzoneComponent) {
		this.dropzones.push(fileDropzone);
	}

	remove(dropAccept: FileDropzoneComponent) {
		this.dropzones.splice(
			this.dropzones.indexOf(dropAccept),
			1,
		);
	}
}
