/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { RouterModule } from '@angular/router';

import { SettingsComponent } from './components/settings';
import { AdminComponent } from './components/admin';
import { NotFoundComponent } from './components/notfound';

export const routes = RouterModule.forChild([
	{
		path: '404',
		component: NotFoundComponent,
		data: {
			breadcrumb: [{ name: 'Not Found' }],
		},
	},
	// ### Settings
	{
		path: 'settings',
		component: SettingsComponent,
		data: {
			breadcrumb: [{ name: 'Settings', url: '/settings' }],
		},
	},
	// ### Admin
	{
		path: 'admin',
		component: AdminComponent,
		data: {
			breadcrumb: [{ name: 'Admin', url: '/admin' }],
		},
	},
]);
