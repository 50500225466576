/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import { analyticsTypes } from '../../../../models/analytics';

const graphTypes = [
	analyticsTypes.uniqueVisitorsOverTime,
	analyticsTypes.pageviewsOverTime,
	analyticsTypes.pageviewsPerUniqueVisitorOverTime,
];

const dateFormat = 'YYYY-MM-DD';
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

@Component({
	selector: 'analytics-control',
	template: `
		<div class="analytics">
			<h1 i18n>Analytics</h1>
			<div class="box-1-1">
				<div>
					<label i18n>Organisation</label>
					<div class="organisations">
						<div *ngIf="organisationOptions.length === 1">
							{{ organisationOptions[0].label }}
						</div>
						<ng-select
							*ngIf="organisationOptions.length > 1"
							(selected)="selectOrganisation($event)"
							[options]="organisationOptions"
							[placeholder]="'… select organisation'"
						></ng-select>
					</div>
				</div>
				<div>
					<div *ngIf="!analyticsForOrganisation && exhibitionOptions?.length">
						<label i18n>Exhibition</label>
						<div class="exhibitions">
							<div *ngIf="exhibitionOptions.length === 1">
								{{ exhibitionOptions[0].label }}
							</div>
						</div>
					</div>
					<div *ngIf="isRoot && siteId">
						<a [href]="visitUrl" target="_blank">Visit the Matomo analytics</a>
					</div>
					<input-date-range
						[beginDate]="dateFrom.toDate()"
						[endDate]="dateTo.toDate()"
						(dateRange)="dateRangeChanged($event)"
					></input-date-range>
					<div class="tabs">
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.uniqueVisitorsOverTime }"
							(click)="setAnalyticsType(analyticsTypes.uniqueVisitorsOverTime)"
							i18n
						>
							Unique visitors over time
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageviewsOverTime }"
							(click)="setAnalyticsType(analyticsTypes.pageviewsOverTime)"
							i18n
						>
							Pageviews over time
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageviewsPerUniqueVisitorOverTime }"
							(click)="setAnalyticsType(analyticsTypes.pageviewsPerUniqueVisitorOverTime)"
							i18n
						>
							Pageviews per visitor
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageTitles }"
							(click)="setAnalyticsType(analyticsTypes.pageTitles)"
							i18n
						>
							Pages
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.deviceModel }"
							(click)="setAnalyticsType(analyticsTypes.deviceModel)"
							i18n
						>
							Device models
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.visitsPerTime }"
							(click)="setAnalyticsType(analyticsTypes.visitsPerTime)"
							i18n
						>
							Visits per daytime
						</div>
					</div>
					<div *ngIf="datasourceOptions?.android?.length && isRoot">
						<label>Google Play Store</label>
						<div class="tabs">
							<div
								*ngFor="let app of datasourceOptions.android"
								class="button"
								[ngClass]="{ active: analyticsType == analyticsTypes.googlePlayStoreInstalls }"
								(click)="setAnalyticsType(analyticsTypes.googlePlayStoreInstalls, app.value)"
							>
								{{ app.label }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<button type="button" (click)="onExport()" i18n>Export TSV (Excel)</button>
			</div>
		</div>
	`,
	styleUrls: ['./analytics-control.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsControlComponent {
	@Input() organisationOptions: [any];
	@Input() exhibitionOptions: [any];
	@Input() datasourceOptions: any;
	@Input() siteId: number;
	@Input() analyticsForOrganisation = false;
	@Input() isRoot = false;
	@Output() change = new EventEmitter(false);

	public currentOrgId;
	public currentExhId;
	public currentDatId;
	public analyticsType = analyticsTypes.uniqueVisitorsOverTime;
	public analyticsTypes = analyticsTypes;
	public dateFrom = moment(oneMonthAgo);
	public dateTo = moment();

	selectOrganisation(event) {
		const id = event.value;
		this.currentOrgId = id;
		this.emitChange();
	}

	selectExhibition(event) {
		const id = event.value;
		this.currentExhId = id;
		this.emitChange();
	}

	setAnalyticsType(type, dataSourceID?) {
		this.analyticsType = type;
		if (dataSourceID) this.currentDatId = dataSourceID;
		this.emitChange();
	}

	isGraphType() {
		return graphTypes.includes(this.analyticsType);
	}

	emitChange() {
		if (!this.currentOrgId) return;
		this.change.emit({
			organisationID: this.currentOrgId,
			exhibitionID: this.currentExhId,
			dataSourceID: this.currentDatId,
			analyticsType: this.analyticsType,
			dateFrom: this.dateFrom.format(dateFormat),
			dateTo: this.dateTo.format(dateFormat),
		});
	}
	onExport() {
		if (!this.currentOrgId) return;
		this.change.emit({
			organisationID: this.currentOrgId,
			exhibitionID: this.currentExhId,
			dataSourceID: this.currentDatId,
			analyticsType: this.analyticsType,
			dateFrom: this.dateFrom.format(dateFormat),
			dateTo: this.dateTo.format(dateFormat),
			format: 'TSV',
		});
	}

	dateRangeChanged(eventValue) {
		this.dateFrom = moment(eventValue.beginDate);
		this.dateTo = moment(eventValue.endDate);
		this.emitChange();
	}

	ngOnChanges(changes) {
		if (this.organisationOptions && this.organisationOptions.length === 1) {
			this.currentOrgId = this.organisationOptions[0].value;
		}
		if (this.exhibitionOptions && this.exhibitionOptions.length === 1) {
			this.currentExhId = this.exhibitionOptions[0].value;
		}
		if (this.datasourceOptions && this.datasourceOptions.length === 1) {
			this.currentDatId = this.datasourceOptions[0].value;
		}
	}
}
