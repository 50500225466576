/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';

import { definition as schema, labels } from '../../../models/exhibit/exhibitSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { ExhibitFormComponent } from './components/exhibit-form.component';
import { selectors } from '../selectors';
import { info } from './info';
import { pluckDistinct, setItemFieldDefault } from '../utils';

export const exhibitConfig = new ModelConfig({
	collectionName: 'Exhibit',
	icon: 'icon-xlarge-icons',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'Article', inSidebar: true, inList: true },
		{ name: 'Location', inSidebar: true },
		{ name: 'MediaObject', inSidebar: true },
		{ name: 'Exhibition', inSidebar: true },
		{ name: 'Beacon', inSidebar: false },
	],

	schema,
	labels,

	initialState: {
		article2name: {},
		deps: {},
		sort: {
			objectPath: 'changeLog.editedOn',
			reverse: false,
		},
		sortedItems: [],
		alphabeticSortedItems: [],
		initialized: false,
		filter: '',
	},
	publishable: true,

	formComponent: ExhibitFormComponent,
	infoHtml: info,
});

Object.assign(exhibitConfig, {
	getFormData$(store, id, versionId?) {
		const models$ = pluckDistinct(store, 'models');

		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(models$, 'Article').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(models$, 'Exhibition').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'currentuser'),
			selectors.getSortedListForOrg$(pluckDistinct(models$, 'Exhibit'), store).pipe(
				map(({ list }) => list)),
			(model: any, articles, exhibitions: any, currentUser: any, exhibits: any[]) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;

				exhibitions = organisationID ? exhibitions.filter(ex => ex.organisationID === organisationID) : exhibitions;
				const modelExhibition = model._id && exhibitions.find(({_id}) => _id === model.exhibitionID);
				const isLandingExhibit = modelExhibition && (modelExhibition.landingExhibitID === model._id);

				exhibits = modelExhibition
					? exhibits.filter(exhibit => exhibit.exhibitionID === modelExhibition._id)
					: exhibits;

				const allCodes = exhibits
					.filter(exhibit => exhibit.id !== id)
					.reduce((acc, exhibit) => {
						for (const code of exhibit.codes || []) acc.add(code);
						return acc;
					}, new Set());

				if (!id) {
					setItemFieldDefault(model, 'exhibitionID', exhibitions);
				}

				return {
					model,
					articles,
					exhibitions,
					isLandingExhibit,
					modelExhibition,
					access: currentUser.access,
					organisationID,
					allCodes,
				};
			}
		);
	},
	getListData$(store) {
		return observableCombineLatest(
			selectors.getSortedListForOrg$(pluckDistinct(store, 'models', this.collectionName), store),
			pluckDistinct(store, 'models', 'Article').pipe(map((articles: any) => articles.entities)),
			(data: any, articles) => Object.assign(data, { articles }),
		);
	},

	getItemCoverId(item, data) {
		const article = data.articles[item.articleID];
		return article && article.coverID;
	},

	getPublishEnabled(item: any, data: any) {
		return !!item
			&& item.isLive
			&& !item.isUpToDate
			&& item.exhibitionID
			&& (data.exhibitions || []).some(
				({ _id, isLive }) => _id === item.exhibitionID && isLive
			);
	},
});
