/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Input,
} from '@angular/core';

import { definition as webClientConfigSchema } from '../../../../models/dataSource/webClientConfigSchema';

@Component({
	selector: 'web-client-config',
	templateUrl: './index.html',
})
export class WebClientConfigComponent {
	@Input() data: any;
	@Input() formGroup;

	schema = webClientConfigSchema;
}
