/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';

import { ArticleFormComponent } from './components/article-form.component';
import { ArContentFormComponent } from './components/ar-content-form';
import { InputLayerExhibitionComponent } from './components/input-layer-exhibition.component';
import { CustomMapContentFormComponent } from './components/custom-map-content-form';
import { InputMediaListComponent } from './components/input-media-list.component';
import { MemoryGameFormComponent } from './components/memory-game-component-form'

@NgModule({
	imports: [FormInputsModule],
	exports: [ArticleFormComponent, ArContentFormComponent, CustomMapContentFormComponent,
			InputLayerExhibitionComponent, MemoryGameFormComponent],
	declarations: [
		ArContentFormComponent,
		InputLayerExhibitionComponent,
		ArticleFormComponent,
		CustomMapContentFormComponent,
		InputMediaListComponent,
		MemoryGameFormComponent
	],
	entryComponents: [ArticleFormComponent],
})
export class ArticleModule {}
