/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {map} from 'rxjs/operators';
import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { messageActions } from '../../core/_messages';
import { statusActions } from '../../status/actions';
import { getFileAcceptAttr, mimeToMediaType, fileIsAccepted } from '../../utils';
import config from '../../config';


@Component({
	selector: 'input-file',
	template: `<div
		class="input-file"
		[ngClass]="{
			'input-file--iconized': iconized,
			'input-file--small': small,
			'input-file--disabled': disabled
		}">
		<ng-container *ngIf="!iconized">
			<media-viewer
				*ngIf="viewable()"
				[source]="getUrl()"
				[type]="type"
				[fullSize]="true"
				class="input-file__preview {{type}}">
			</media-viewer>
			<div *ngIf="!viewable()" class="input-file__preview {{type}}">
				<ng-content></ng-content>
			</div>
		</ng-container>

		<div class="input-file__body {{type}}">

			<div *ngIf="!iconized && canUpdate" class="input-file__hover-overlay">
				<span>Drop file</span>
			</div>

			<div class="input-file__menu" (mouseenter)="showMenu = true" (mouseleave)="showMenu = false">
				<span class="menu-btn icon-circled-chevron-down" [ngClass]="{'menu-btn--active': showMenu}"></span>

				<div class="menu" [ngClass]="{'menu--active': showMenu}">
					<div [hidden]="!viewable()" (click)="modal.open()">
						<span class="icon-zoom-to-extents"></span>
						<ng-container i18n>View</ng-container>
					</div>
					<label [hidden]="!canUpdate" [attr.for]="inputId">
						<span class="icon-upload-to-cloud"></span>
						<ng-container i18n>Upload</ng-container>
					</label>
					<a target="_blank" [hidden]="!value" [href]="getUrl()" [download]="downloadName || ''">
						<span class="icon-download-from-cloud"></span>
						<ng-container i18n>Download</ng-container>
					</a>
					<ng-content select="[slot=menu]"></ng-content>
				</div>
			</div>

			<file-dropzone
				*ngIf="canUpdate"
				[accept]="(accept && accept[0] === '.') ? '*' : (accept || getAcceptMime())"
				(fileDrop)="handleChange($event.event)">
				<span>Update file</span>
			</file-dropzone>
			<progress [attr.uploading]="uploading" [attr.value]="progress" max="100"></progress>
		</div>

		<modal-component #modal [fullSize]="true" [clean]="true">
			<media-viewer
				class="{{type}}"
				*ngIf="viewable() && modal?.isOpen()"
				[source]="getUrl()"
				[type]="type">
			</media-viewer>
		</modal-component>

		<input hidden type="file" [attr.accept]="accept || getAcceptMime()" (change)="handleChange($event)" [id]="inputId">
	</div>`,
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputFileComponent), multi: true }],
	styleUrls: ['./input-file.component.sass'],
})
export class InputFileComponent extends CustomValueAccessor {
	@Input() value;
	@Input() iconized = false;
	@Input() uploadPath;
	@Input() accept;
	@Input() type = null;
	@Input() file;
	@Input() canUpdate = true;
	@Input() downloadName = '';
	@Input() small = false;
	@Input() disabled = false;

	@Output() fileChange = new EventEmitter();
	@Output() error = new EventEmitter();

	private organisationId;
	private sub: Subscription;
	private destroyed = false;
	public progress;
	public uploading;
	public namespace;
	public showModal;
	public showMenu;
	public fileInfo = null;
	public xhr: XMLHttpRequest;

	constructor(private store: Store<any>, changeDetectorRef: ChangeDetectorRef, public i18n: I18n) {
		super(i18n);

		this.sub = store
			.select('currentuser').pipe(
			map((res: any) => res.organisation._id))
			.subscribe((orgId) => {
				this.organisationId = orgId;
			});
		this.progress = 0;
		this.uploading = false;
		this.namespace = this.uid();
		this.initOptions({ changeDetectorRef });
	}
	s4() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	uid() {
		return this.s4() + this.s4();
	}
	get inputId() {
		return `fileup${this.namespace}`;
	}

	getUrl() {
		return this.value ? `${config.baseURL}${this.uploadPath}/${this.value}` : '';
	}

	viewable() {
		return !!this.value && /^(image|video|audio)$/.test(this.type);
	}

	ngOnInit() {
		if (!((<any>window).File && (<any>window).FileList && (<any>window).FileReader)) {
			alert('html5 file upload not available!');
		}

		this.uploadPath = this.uploadPath || '/api/mediafiles';

		// use file that was given by a parent component
		if (this.file && typeof this.file === 'object') {
			this.setFile(this.file);
		}
	}


	handleChange(event) {
		event.preventDefault();
		event.stopPropagation();

		let files = (event.target.files || event.dataTransfer.files);
		const accept = this.accept || this.getAcceptMime();

		// check that file types match
		if (accept) {
			const accepts = accept.split(',');

			const { accepted, rejected } = Array.from(files).reduce((acc: any, file) => {
				if (accepts.some(type => fileIsAccepted(file, type))) {
					acc.accepted.push(file);
				} else {
					acc.rejected.push(file);
				}

				return acc;
			}, { accepted: [], rejected: [] }) as any;

			if (rejected.length) {
				const message = rejected.length === 1
					? `<em>${rejected[0].name}</em> ${this.i18n('has an unsupported file format and wasn\'t uploaded.')}`
					: `${this.i18n('The following files weren\'t uploaded as their formats aren\'t supported:')}
						<ul>${rejected.map(file => `<li>${file.name}</li>`).join('')}</ul>`;

				this.store.dispatch(messageActions.error(message));
			}

			files = accepted;
		}

		if (files.length) {
			this.setFile(files[0]);
		}

		// clear the input element otherwise a "change" event may not be fired if
		// the user picks the same file again
		if (event.target instanceof HTMLInputElement) {
			event.target.value = null;
		}

		this.propagateChange();
	}

	setFile(file) {
		const originalName = file.name;

		this.file = file;
		this.uploading = true;

		if (this.xhr) this.xhr.abort();

		this.xhr = uploadFile({
			file: file,
			url: `${config.baseURL}${this.uploadPath}`,
			onreadystatechange: (event) => {
				const xhr = event.target;

				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						this.fileInfo = JSON.parse(xhr.responseText);
						const fileInfo = this.fileInfo;

						fileInfo.originalName = originalName;
						fileInfo.type = mimeToMediaType(fileInfo.contentType);
						fileInfo.prevId = this.value;

						this.type = fileInfo.type;
						this.value = fileInfo._id;
						this.fileChange.emit(fileInfo);
						this.changeDetectorRef.detectChanges();
					} else {
						this.error.emit(xhr);
						this.store.dispatch(messageActions.error(`${this.i18n('File upload failed:')} ${xhr.responseText || xhr.status}`));
					}

					this.progress = 0;
					this.uploading = false;
					this.store.dispatch(statusActions.removeUpload());
				}
			},
			onprogress: (e: any) => {
				if (e.lengthComputable && !this.destroyed) {
					this.progress = Math.round((e.loaded * 100) / e.total);
					// Must trigger change detection manually since the event happens async
					// and angular does not know about the change.
					this.changeDetectorRef.detectChanges();
				}
			},
		});

		this.store.dispatch(statusActions.addUpload());
	}

	getAcceptMime() {
		return getFileAcceptAttr(this.type);
	}

	ngOnDestroy() {
		this.destroyed = true;
		this.sub.unsubscribe();

		if (this.uploading && this.xhr) {
			this.xhr.abort();
		}
	}
}

export function uploadFile(options) {
	const xhr = new XMLHttpRequest();
	const formData = new FormData();

	formData.append('file', options.file);

	Object.keys(options)
		.filter(key => key.indexOf('on') === 0 && (!xhr.upload || key !== 'onprogress'))
		.forEach(listenerKey => {
			xhr[listenerKey] = options[listenerKey];
		});

	if (xhr.upload) {
		xhr.upload.onprogress = options.onprogress;
	}

	xhr.open('POST', options.url); // identifier vom file ist `file`
	xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');
	xhr.withCredentials = true;
	xhr.send(formData);

	return xhr;
}
