/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema, labels } from '../../../models/user/userSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { UserFormComponent } from './components/user-form';
import { selectors } from '../selectors';
import { info } from './info';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const userConfig = new ModelConfig({
	collectionName: 'User',
	clientPathPrefix: '',
	icon: 'icon-group-background',

	dependencies: [
		{ name: 'Organisation', inSidebar: true },
		// { name: 'currentuser' },
	],

	schema,
	labels,

	formComponent: UserFormComponent,
	infoHtml: info,
});

Object.assign(userConfig, {
	getItemTitle(item) {
		return `${item.firstName} ${item.lastName}`;
	},


	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'Organisation').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'currentuser'),
			(model, organisations, currentuser) => ({
				model,
				organisations,
				currentuser,
			}),
		);
	},

	getListData$(store) {
		return observableCombineLatest(
			pluckDistinct(store, 'models', 'User').pipe(map(selectors.getListAndSort)),
			pluckDistinct(store, 'currentuser', 'organisation', '_id'),
			({list, sort}, organisationId) => ({
				list: organisationId ? list.filter(user => user.roles.some(role => role.organisationID === organisationId)) : list,
				sort,
			})
		);
	},
});
