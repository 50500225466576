/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { I18n } from '@ngx-translate/i18n-polyfill';
import { sample, switchMap, distinctUntilChanged, tap, map, filter, take, pluck } from 'rxjs/operators';

import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Effect } from '@ngrx/effects';

import { actions } from '../actions';
import { messageActions } from '../core/_messages/actions';
import { modelConfigs, nameToCollectionName } from '../modelConfigs';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { getValidators } from '../services/schemaTools';
import { RouteInfo } from '../services/route-info';
import { DynamicModelFormComponent } from '../components/dynamic-model-form.component';
import { ModalComponent } from '../modals/modal.component';
import { AppState } from '../reducers';
import { ModelEditFooterComponent } from './model-edit-footer.component';

@Component({
	template: `
		<div class="sl-form" [ngSwitch]="(model$ | async)?.name">
			<dynamic-model-form
				#dynamicModelForm
				[disabled]="(cloneId$ | async) ? false : !!getHumanReadableVersion(versionId$ | async)"
				[collectionName]="(model$ | async)?.collectionName"
				[id]="id$ | async"
				[versionId]="versionId$ | async"
				[data]="data$ | async"
				class="sl-form__content"
			>
				<!-- <span
				*ngIf="(model$ | async)?.infoHtml"
				(click)="infoModal.open()"
				i18n-title
				title="Click here to get additional information about this form."
				class="icon-Info-2 model-edit__info"></span> -->
				<h1 *ngIf="!dynamicModelForm.id">
					Create
					<span *ngIf="(model$ | async)?.name == 'unifiedarticle'" i18n>article</span>
					<span *ngIf="(model$ | async)?.name != 'unifiedarticle'">{{ (model$ | async)?.name }}</span>
				</h1>
				<h1 *ngIf="!(cloneId$ | async) && !!getHumanReadableVersion(versionId$ | async)" i18n>
					Version {{ getHumanReadableVersion(versionId$ | async) }}
				</h1>
			</dynamic-model-form>

			<model-edit-footer
				#footer
				(saveEvent)="onSave($event)"
				[formGroup]="dynamicModelForm.formGroup"
				[id]="dynamicModelForm.id"
				[versionId]="versionId$ | async"
				[versionIdHuman]="(cloneId$ | async) ? undefined : getHumanReadableVersion(versionId$ | async)"
				[data]="dynamicModelForm.data"
				[model]="model$ | async"
				[uploading]="uploading"
				[cloneId]="cloneId$ | async"
			>
			</model-edit-footer>
		</div>

		<modal-component #askSaveModal (closeEvent)="closeAskSave(false)">
			<div style="text-align: center">
				<span *ngIf="uploading" i18n
					>You're currently uploading a file for this {{ (model$ | async)?.name }} item.</span
				>
				<span *ngIf="!uploading" i18n
					>You have unsaved changes in this {{ (model$ | async)?.name }} item.</span
				>
			</div>
			<br />
			<button type="button" class="button--red" (click)="closeAskSave(true)">
				<ng-container *ngIf="uploading" i18n>Cancel and discard changes</ng-container>
				<ng-container *ngIf="!uploading" i18n>Discard changes</ng-container>
			</button>
			<button type="button" (click)="closeAskSave(false)">
				<ng-container *ngIf="uploading; else elseBlock" i18n> Wait for upload </ng-container>
				<ng-template #elseBlock i18n> Continue editing </ng-template>
			</button>
			<button
				type="button"
				*ngIf="!uploading"
				[disabled]="!dynamicModelForm.formGroup.valid"
				[attr.title]="dynamicModelForm.formGroup.valid ? '' : buttonTextInvalid"
				(click)="footer.save(); closeAskSave(true)"
				i18n
			>
				Save and leave
			</button>
		</modal-component>
		<modal-component #infoModal>
			<div [innerHTML]="(model$ | async)?.infoHtml"></div>
		</modal-component>

		<div *ngIf="!(appStatus$ | async).online" class="offline-overlay"></div>
	`,
	styleUrls: ['./model-edit.component.sass'],
	// styles: ['h1 { text-transform: capitalize }'],
	preserveWhitespaces: true,
})
export class ModelEditComponent {
	@ViewChild('dynamicModelForm', { static: true }) public dynamicModelForm: DynamicModelFormComponent;
	@ViewChild('askSaveModal', { static: false }) public askSaveModal: ModalComponent;
	@ViewChild('footer', { static: true }) public footer: ModelEditFooterComponent;

	id$: Observable<string>;
	versionId$: Observable<string>;
	cloneId$: Observable<string>;
	model$: Observable<ModelConfig>;
	data$: Observable<any>;
	appStatus$: Observable<any> = this.store.pipe(pluck('status'));
	_resolveCanDeactivate: any;
	uploading = false;
	private onCtrlS;
	public buttonTextInvalid = '';

	@Effect({ dispatch: false })
	blurOnOffline$ = this.appStatus$
		.pipe(
			pluck('online'),
			filter((online: boolean) => !online),
			map(() => this.blurActiveInput())
		)
		.subscribe();

	@Effect({ dispatch: false })
	updateUploading$ = this.appStatus$
		.pipe(
			pluck('uploading'),
			tap((uploading: boolean) => (this.uploading = uploading))
		)
		.subscribe();

	preventOfflineFocus = (event) => {
		// prevent focus on elements if offline
		this.appStatus$.pipe(take(1)).subscribe((status) => !status.online && this.blurActiveInput());
	};

	constructor(
		private routeInfo: RouteInfo,
		private store: Store<AppState>,
		private elRef: ElementRef,
		public i18n: I18n
	) {
		this.buttonTextInvalid = this.i18n('The form values are invalid');
		this.id$ = routeInfo.params$.pipe(pluck('id'), distinctUntilChanged()) as any;
		this.versionId$ = routeInfo.params$.pipe(pluck('versionId'), distinctUntilChanged()) as any;
		this.cloneId$ = routeInfo.params$.pipe(pluck('cloneId'), distinctUntilChanged()) as any;
		this.model$ = routeInfo.params$.pipe(pluck('modelConfig'), distinctUntilChanged()) as any;

		this.data$ = routeInfo.params$.pipe(
			switchMap(({ modelConfig, id, versionId, cloneId }) => {
				// If the item id provided in the URL is not valid redirect to the start page.
				// But we cannot redirect immediately since the collection is loaded
				// async so we wait until it was initialized.
				if (id) {
					store
						.pipe(
							pluck('models', modelConfig.collectionName),
							filter((state: any) => state.initialized),
							take(1),
							sample(routeInfo.navigationEnd$)
						)
						.subscribe((state: any) => {
							if (!(id in state.entities)) {
								routeInfo.router.navigate(['/']);
							}
						});
				}

				let data$ = modelConfig.getFormData$(store, id || cloneId, versionId);

				if (cloneId) {
					data$ = data$.pipe(
						map((data) => {
							const itemData = data.model || data;
							const nameKey = ['lastName', 'name'].find((key) => key in itemData);

							if (nameKey) {
								itemData[nameKey] += ' (copy)';
							}
							itemData.isClone = true;
							// clear the codes
							if ('codes' in itemData) itemData.codes = [];
							// Clear the old publishied data
							if ('publishedVersions' in itemData) itemData.publishedVersions = [];
							if (itemData.articleID && itemData.articleID.publishedInfos)
								itemData.articleID.publishedInfos = [];

							clearUniqueFields(itemData, modelConfig.schema);

							return data;
						})
					);
				}

				this.dynamicModelForm.vcr.element.nativeElement.scrollTop = 0;

				return data$;
			})
		);

		this.onCtrlS = (event: KeyboardEvent) => {
			if (!(event.ctrlKey && event.key === 's')) {
				return;
			}

			event.preventDefault();
			event.stopImmediatePropagation();

			this.footer.popupInvalidInpusText();
			this.footer.save();
		};
	}

	ngOnInit() {
		this.routeInfo.modelEditInstance = this;
	}

	ngAfterViewInit() {
		document.addEventListener('keydown', this.onCtrlS, true);
		document.addEventListener('focus', this.preventOfflineFocus, true);
	}

	ngOnDestroy() {
		if (this.routeInfo.modelEditInstance === this) {
			this.routeInfo.modelEditInstance = null;
		}

		document.removeEventListener('keydown', this.onCtrlS, true);
		document.removeEventListener('focus', this.preventOfflineFocus, true);
	}

	closeAskSave(canNavigate: boolean) {
		if (this._resolveCanDeactivate) {
			this._resolveCanDeactivate(canNavigate);
			this._resolveCanDeactivate = null;
			this.askSaveModal.close();
		}
	}

	async askToSave(): Promise<boolean> {
		// don't open modal if the item being edited was deleted
		if (/\/edit\//.test(this.routeInfo.router.url) && !this.dynamicModelForm.id) {
			return true;
		}

		this.askSaveModal.open();

		return new Promise<boolean>((resolve) => {
			this._resolveCanDeactivate = resolve;
		});
	}

	onSave(info) {
		this.store.dispatch(messageActions.info(`Your ${info.collectionName} item has been saved`));
	}

	blurActiveInput() {
		const focused = document.activeElement as HTMLElement;

		if (focused && this.elRef.nativeElement.contains(focused)) {
			focused.blur();
		}
	}
	getHumanReadableVersion(versionId) {
		return parseInt(versionId, 10);
	}
}

function clearUniqueFields(obj, schema) {
	for (const field in obj) {
		const value = obj[field];

		delete obj._id;

		if (
			/^(changeLog|isLive|isUptoDate|analyticsSiteId)$/.test(field) ||
			(schema[field] && (schema[field].softUnique || schema[field].unique))
		) {
			obj[field] = null;
		} else if (value && typeof value === 'object' && !Array.isArray(value) && schema[field]) {
			obj[field] = clearUniqueFields(value, schema[field]);
		}
	}

	return obj;
}
