
	<div class="messages">
		<ng-container *ngFor="let messageList of ['sticky', 'transient']">
			<ul *ngIf="messages[messageList].length">
				<li *ngFor="let item of messages[messageList]">
					<message-component [data]="item"></message-component>
					<div *ngIf="item.closeable" (click)="close(item.id)" class="message-close">
						<span class="icon-cancel"></span>
					</div>
				</li>
			</ul>
		</ng-container>
	</div>
