<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>
	<div class="first-row">
		<label i18n>Name</label>
		<input type="text" formControlName="name" i18n-placeholder placeholder="… event name" />
	</div>
	<div class="box-1-1">
		<div>
			<div>
				<label i18n>Date</label>
				<input-date-time formControlName="date"></input-date-time>
			</div>

			<div *ngIf="data.showShareOption || data.access.isAdmin">
				<label i18n>share on social media</label>
				<form-input [formGroup]="formGroup" type="boolean" formControlName="isShareable"></form-input>
			</div>
			<div *ngIf="data.showShareOption || data.access.isAdmin">
				<label i18n>Social media target url</label>
				<input
					type="text"
					name="shareTargetUrl"
					formControlName="shareTargetUrl"
					placeholder="… https://www.your-website.com/subpage"
				/>
			</div>
		</div>
		<div>
			<label i18n>Cover</label>
			<input-media-file
				[organisationID]="data.organisationID"
				type="image"
				formControlName="coverID"
				menuDir="left"
			></input-media-file>
		</div>
	</div>

	<input-language-content-calendar formControlName="languageContent"></input-language-content-calendar>
</ng-container>
