/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { currentuserActions } from '../actions';
import { AppHttp } from 'app/services/AppHttp';

@Component({
	selector: 'login-component',
	template: `
	<form
		class="login"
		[formGroup]="form"
		(ngSubmit)="onSubmit()">
	<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100">
	<h1>shoutr labs</h1>
	<div class="form">
		<h2 i18n>Log into your CMS account</h2>
		<div>
			<label i18n>email</label>
			<input
				required
				pattern="^.+@.+\\.\\w+$"
				type="email"
				formControlName="username">
		</div>
		<div>
			<label i18n>password</label>
			<input formControlName="password" type="password">
		</div>
		<div class="cookie-info">
			<ng-container i18n>By using this site, you agree to the use of cookies in your browser.</ng-container>
			<a href="https://www.shoutrlabs.com/datenschutz/?lang=en" i18n>Click here for more information</a>.
		</div>
		<button class="button" type="submit">Login</button>
		<div class="forgot-password">
			<a routerLink="/account/resetpassword" i18n>Forgot password?</a>
		</div>
	</div>
	<div class="footer">
		<a href="https://www.shoutrlabs.com/datenschutz/?lang=en" i18n>Privacy</a>
		| <a href="https://www.shoutrlabs.com/impressum/?lang=en" i18n>Imprint</a>
	</div>
</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./login.component.sass'],
})
export class LoginComponent {
	public form;

	constructor(private fb: FormBuilder, private store: Store<any>, private appHttp: AppHttp) {}

	ngOnInit() {
		this.form = this.fb.group({
			username: ['', Validators.required],
			password: ['', [Validators.required, Validators.minLength(6)]],
		});

		// check if already logged in
		this.appHttp.get({ url: '/loggedin', responseType: 'text' }).subscribe(response => {
			if (response.ok) {
				this.store.dispatch({ type: currentuserActions.LOGIN_SUCCESS })
			}
		})
	}

	onSubmit() {
		if (this.form.valid) {
			this.store.dispatch(currentuserActions.login(this.form.value));
		}
	}
}
