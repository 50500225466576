/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectors } from '../../selectors';
import * as CollectionActions from '../../socket.actions';
import { mediaConfig } from '../../_media/config';
import config from '../../config';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	NgZone,
	forwardRef,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as Leaflet from 'leaflet';
import 'leaflet-draw';
import 'leaflet-toolbar';
import 'leaflet-distortableimage';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';
import appConfig from '../../config';
const baseURL = `${appConfig.baseURL}/api/articles/`;

delete Leaflet.Icon.Default.prototype._getIconUrl; // Leaflet wepack workaround for working url images
Leaflet.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Leaflet.Control.Attribution.prototype.options.prefix = null;

function getCoordinates(crs, coordinates) {
	if (crs === 'EPSG3857') {
		return coordinates.map(point => {
			if (Array.isArray(point)) return [point[1], point[0]];
			return [point.lat, point.lng];
		});
	}
	return coordinates;
}

/**
 * ## GeoPickerModalComponent
 * using momentjs http://momentjs.com/docs/
 */
@Component({
	selector: 'input-map',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputMapComponent),
			multi: true,
		},
	],
})
export class InputMapComponent {
	@Input() articleID;
	@Input() mapMetaData;
	@Input() formGroup: FormGroup;
	@Input() marker;
	@Input() crs = 'EPSG3857'; // or 'Simple'
	@Input() disabled = false;
	@Input() hasMarker = false;
	@Input() hasPolygon = false;
	@Input() hasPolyline = false;
	@Input() isGeoOffline = false;
	@Input() hasDistortableImage = false;
	@Input() imageMediaObjectID = '';
	@Input() set otherMapPoints(locations: any[]) {
		// remove previous markers
		// for (const marker of this.otherLocationMarkers) {
		// 	marker.setMap(null)
		// }
		// loadGoogleApi().then(google => {
		// 	// draw a marker for each other marker type location
		// 	this.otherLocationMarkers = (locations || []).map(location => {
		// 		const { coordinates } = location.geoLocation;
		// 		return new (window as any).google.maps.Marker({
		// 			position: {
		// 				lng: coordinates[0][0],
		// 				lat: coordinates[0][1],
		// 			},
		// 			map: this.showOthers && this.map ? this.map : null
		// 		})
		// 	})
		// })
	}

	@Output() polygonChanged = new EventEmitter();

	@ViewChild('mapModal', { static: false })
	mapModal: any;
	@ViewChild('mapContainer', { static: true })
	mapContainer: any;

	layers: any[];

	get coordinates() {
		if (!this.formGroup.value) return [];
		// console.log("this.shape", this.shape);
		const coordinates = this.shape === 'polygon'
			? this.formGroup.value.coordinates[0]
			: this.formGroup.value.coordinates;
		// console.log("coordinates", coordinates);
		return (coordinates || []).map(point => {
			if (Array.isArray(point)) return { lat: point[1], lng: point[0] };
			return point;
		});
	}
	get shape() {
		if (!this.formGroup.value) return '';
		return this.formGroup.value.shape;
	}

	protected ngZone: any;
	protected leafletMap: any;
	protected editableLayers: any;
	protected locationLayer: any;
	protected distortableImage: any;
	public mediaObjectEntities = {};
	private subscription: Subscription;

	constructor(
		protected changeDetectorRef: ChangeDetectorRef,
		private elementRef: ElementRef,
		private store: Store<any>
	) {
		// super();
		store.dispatch(new CollectionActions.GetCollectionList({ collectionName: mediaConfig.collectionName }));
		this.subscription = store
			.select(s => s.models.MediaObject)
			.pipe(map(selectors.getEntities))
			.subscribe(entities => {
				this.mediaObjectEntities = entities;
				this.initDistortableImage();
			});
	}

	ngOnInit() {
		this.leafletMap = Leaflet.map(this.mapContainer.nativeElement, {
			zoomSnap: 0.2,
			zoomDelta: 0.2,
			crs: Leaflet.CRS[this.crs],
		});

		this.editableLayers = new Leaflet.FeatureGroup();
		this.leafletMap.addLayer(this.editableLayers);

		this.leafletMap.addControl(
			new Leaflet.Control.Draw({
				draw: {
					polyline: this.hasPolyline,
					polygon: this.hasPolygon,
					marker: this.hasMarker,
					rectangle: false,
					circle: false,
					circlemarker: false,
				},
				edit: {
					featureGroup: this.editableLayers,
					remove: false,
				},
			})
		);

		this.leafletMap.on(Leaflet.Draw.Event.CREATED, event => {
			this.clear(); // remove existing layers
			const { layer, layerType } = event;
			const locationJson = {};
			if (layerType === 'marker') {
				const { lat, lng } = layer.getLatLng();
				Object.assign(locationJson, { coordinates: [{ lat, lng }], shape: layerType });
			} else if (layerType === 'polygon') {
				Object.assign(locationJson, { coordinates: layer._latlngs, shape: layerType });
			} else if (layerType === 'polyline') {
				Object.assign(locationJson, { coordinates: layer._latlngs, shape: layerType });
			}
			this.patch(locationJson);
		});
		this.leafletMap.on('draw:edited', event => {
			event.layers.eachLayer(layer => {
				const locationJson = {};
				if (layer instanceof Leaflet.Marker) {
					const { lat, lng } = layer.getLatLng();
					Object.assign(locationJson, { coordinates: [{ lat, lng }], shape: 'marker' });
				} else if (layer instanceof Leaflet.Polygon && !(layer instanceof Leaflet.Rectangle)) {
					Object.assign(locationJson, { coordinates: layer._latlngs, shape: 'polygon' });
				} else if (layer instanceof Leaflet.Polyline && !(layer instanceof Leaflet.Polygon)) {
					Object.assign(locationJson, { coordinates: layer._latlngs, shape: 'polyline' });
				}
				this.patch(locationJson);
			});
		});

		this.formGroup.valueChanges.subscribe(value => {
			if (!value) return;
			this.formGroup.patchValue(value, { emitEvent: false });
			this.updateMap();
		});
		this.updateTiles();
		this.initDistortableImage();
	}

	initDistortableImage(notEditable?) {
			console.log('this.getDistortableImageUrl()', this.getDistortableImageUrl());
		if (this.hasDistortableImage && this.getDistortableImageUrl()) {
			this.distortableImage = Leaflet.distortableImageOverlay(this.getDistortableImageUrl(), {
				// 'corners' is the only required option for this class
				// and is in NW, NE, SW, SE order
				corners: this.mapMetaData.distortableImageCorners || this.formGroup.value || [
					Leaflet.latLng(52.520008, 13.404954),
					Leaflet.latLng(52.237049, 21.017532),
					Leaflet.latLng(50.520008, 13.404954),
					Leaflet.latLng(50.237049, 21.017532),
				],
				mode: 'rotateScale',
				selected: true,
				// actions: ['ToggleTransparency', 'ToggleRotateScale', 'Revert'],
			}).addTo(this.leafletMap);

			// enable editing
			if (!notEditable) {
				Leaflet.DomEvent.on(
					this.distortableImage._image,
					'load',
					this.distortableImage.editing.enable,
					this.distortableImage.editing
				);
			}
		}
	}

	updateMap() {
		this.clear();
		if (!this.formGroup.value || !this.coordinates) return;
		// must do all these checks so marker can be a default input
		// that is rendered once both lat and lng fields are filled
		if (
			this.shape === 'marker' &&
			this.coordinates &&
			this.coordinates.length &&
			this.coordinates[0].lat &&
			this.coordinates[0].lng
		) {
			this.locationLayer = Leaflet.marker(getCoordinates(this.crs, this.coordinates)[0]);
			try {
				if (this.locationLayer) this.leafletMap.flyTo(this.locationLayer.getLatLng(), 16);
			} catch (error) {
				// do nothing
			}
		} else if (this.shape === 'polygon') {
			this.locationLayer = Leaflet.polygon(getCoordinates(this.crs, this.coordinates));
			this.leafletMap.fitBounds(this.locationLayer.getBounds());
		} else if (this.shape === 'polyline') {
			this.locationLayer = Leaflet.polyline(getCoordinates(this.crs, this.coordinates));
			this.leafletMap.fitBounds(this.locationLayer.getBounds());
		}
		if (this.locationLayer) this.editableLayers.addLayer(this.locationLayer);
	}

	clear() {
		if (this.locationLayer) {
			this.editableLayers.removeLayer(this.locationLayer);
		}
	}
	patch(value) {
		this.formGroup.patchValue(value);
		this.formGroup.markAsDirty();
	}
	inputCoord(i, coordValue) {
		const coordinates = [Object.assign({}, (this.formGroup.value.coordinates || [])[0])];
		coordinates[0][i] = parseFloat(coordValue);
		this.patch({ coordinates });
	}
	inputCoordPolygon(index, latLang, coordValue) {
		const coordinates = [...this.formGroup.value.coordinates];
		coordinates[0][index][latLang] = parseFloat(coordValue);
		this.patch({ coordinates });
	}

	ngOnChanges(changes) {
		if (changes.mapMetaData) {
			if (this.mapMetaData) {
				if (this.mapMetaData.distortableImageCorners) {
					this.hasDistortableImage = true;
					if (!this.distortableImage) {
						this.initDistortableImage(true);
					} else {
						// Remove the existing distortableImage and render a new one
						this.leafletMap.removeLayer(this.distortableImage);
						this.initDistortableImage(true);
					}
				} else if (this.distortableImage) {
					// there are not distortableImageCorners, so remove the image from the map
					this.leafletMap.removeLayer(this.distortableImage);
				}
			}

			this.updateTiles();
		}
	}

	updateTiles() {
		if (!(this.leafletMap && this.mapMetaData && (this.mapMetaData.bounds.length || this.crs === 'EPSG3857'))) {
			return;
		}

		for (const layer of this.layers || []) {
			layer.leaflet.remove();
		}

		if (this.mapMetaData.bounds.length) {
			this.leafletMap.setMaxBounds(this.mapMetaData.bounds);
			this.leafletMap.fitBounds(this.mapMetaData.bounds);
		}
		if (!this.mapMetaData.center.length) this.mapMetaData.center = [52.220836, 13.16902];
		this.leafletMap.setView(this.mapMetaData.center, 1);
		const { layers, maxZoom, minZoom } = this.mapMetaData;

		if (this.crs === 'Simple') {
			for (const layer of layers) {
				layer.leaflet = Leaflet.tileLayer(`${baseURL}${this.articleID}/map${layer.templateUrl}`, {
					minZoom: minZoom || 1,
					maxZoom: maxZoom,
					noWrap: true,
				}).addTo(this.leafletMap);
			}
		} else if (this.crs === 'EPSG3857') {
			Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				minZoom: minZoom || 1,
				maxZoom: maxZoom,
				noWrap: false,
			}).addTo(this.leafletMap);
		}

		this.layers = layers;
	}

	openMap($event) {
		$event.preventDefault();
		this.mapModal.open();

		// we need to do this or the map will not render
		setTimeout(() => {
			(window as any).dispatchEvent(new Event('resize'));
			// if (this.mapObject) this.mapObject.setCenterd();
		}, 250);
	}

	get staticImage() {
		return '';
	}

	getDistortableImageUrl() {
		if (!(this.imageMediaObjectID && this.mediaObjectEntities)) return '';
		const mediaObject = this.mediaObjectEntities[this.imageMediaObjectID];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}`;
	}

	save() {
		if (this.hasDistortableImage) {
			const corners = this.distortableImage.getCorners();
			this.patch(corners);
		}
		if (this.isGeoOffline) {
			this.polygonChanged.emit(this.formGroup.value);
		}
	}
}
