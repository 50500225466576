/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { definition as schema, labels, articleTypes } from '../../../models/article/articleSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { ArticleFormComponent } from './components/article-form.component';
import { selectors } from '../selectors';
import { mediaConfig } from '../_media/config';
import { info } from './info';
import { isoLang2name, fixLanguageContent, pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const articleConfig = new ModelConfig({
	collectionName: 'Article',
	namePlural: 'webApps',
	icon: 'icon-folder_L',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'MediaObject', inSidebar: true },
		{ name: 'Exhibit', inSidebar: true },
		{ name: 'Exhibition', inSidebar: true },
	],

	schema,
	labels,

	formComponent: ArticleFormComponent,
	infoHtml: info,
});

Object.assign(articleConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(store, 'models', 'MediaObject', 'entities'),
			pluckDistinct(store, 'models', 'Organisation').pipe(map(selectors.getEntities)),
			pluckDistinct(store, 'models', 'Exhibition').pipe(map(selectors.getAlphabeticList)),
			(model: any, currentUser: any, mediaObjects: any, organisationEntities, exhibitions) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
				const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
				const languageOptions = (languages || []).map(value => ({
					value: `${value}`,
					label: `${isoLang2name[value]}`,
				}));
				const showShareOption = modelOrg
					? modelOrg.showShareOption
					: currentUser.organisation.showShareOption;
				const availableArticleTypes =
					modelOrg && modelOrg.articleTypes.length ? modelOrg.articleTypes : articleConfig.schema.type.enum;

				const articleTypeOptions = availableArticleTypes.map(value => ({
					value,
					label: articleTypes[value],
				}));

				// when creating a new item
				if (!id) {
					model.type = articleTypeOptions[0].value;
				}

				if (!model.incomplete) {
					model.languageContent = fixLanguageContent({
						languageOptions,
						languageContent: model.languageContent,
					});

					model.arContent.guidanceText = fixLanguageContent({
						languageOptions,
						languageContent: (model.arContent || {}).guidanceText,
					});
				}
				exhibitions = organisationID
					? exhibitions.filter(ex => ex.organisationID === organisationID)
					: exhibitions;

				return {
					model,
					exhibitions,
					mediaObjects,
					organisationID,
					languageOptions,
					articleTypeOptions,
					showShareOption,
					currentUser,
				};
			}
		);
	},
});
