/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';


@Component({
	selector: 'input-time',
	template: `
<div class="input-widget">
	<div class="time" [ngClass]="{ disabled: disabled }">
		<div class="hour">
			<div (click)="hour = hour + 1; setTime($event)">+</div>
			<input
				type="number"
				min="0"
				max="23"
				step="1"
				(input)="setTime($event)"
				[(ngModel)]="hour">
			<div (click)="hour = hour - 1; setTime($event)">-</div>
		</div>
		<div class="colon">
			<div>:</div>
		</div>
		<div class="minute">
			<div (click)="minute = minute + 15; setTime($event)">+</div>
			<input
				type="number"
				min="0"
				max="59"
				step="1"
				(input)="setTime($event)"
				[(ngModel)]="minute"
				>
			<div (click)="minute = minute - 15; setTime($event)">-</div>
		</div>
	</div>
	<div i18n>
		o'clock
	</div>
</div>
`,
	styleUrls: ['./input-time.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTimeComponent), multi: true }],
})
export class InputTimeComponent extends CustomValueAccessor {
	@Input() disabled = false;
	@Output() inputTime = new EventEmitter();

	public hour = 15;
	public minute = 45;
	protected _value = new Date();

	ngOnInit() {
		this._value = new Date(this.value);
		this.hour = this._value.getHours();
		this.minute = this._value.getMinutes();
	}

	get value() {
		return this._value.toString();
	}

	set value(value) {
		if (value == null) return;

		let parsedValue;

		if (typeof(value) === 'string') {
			parsedValue = new Date(value);
		}

		if (parsedValue !== this.value) {
			this.set_value(value);
			this.value = parsedValue;
			this.hour = this._value.getHours();
			this.minute = this._value.getMinutes();
		}
	}

	setTime($event) {
		if (!this._value) this._value = new Date();
		$event.preventDefault();
		if (this.hour < 0) this.hour = 0;
		if (this.hour > 23) this.hour = 23;
		if (this.minute < 0) this.minute = 0;
		if (this.minute > 59) this.minute = 59;
		this._value.setHours(this.hour, this.minute);
		this.onChange(this.value);
		this.onTouched();

		this.inputTime.emit(this.value);
	}
}
