<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<div class="first-row">
		<div>
			<label i18n>Name</label>
			<input type="text" formControlName="name" i18n-placeholder placeholder="… tour name" />
		</div>
		<div class="is-live">
			<label i18n>is live</label>
			<input
				[attr.disabled]="data.isLandingExhibit || null"
				class="sl-form__switch"
				id="isLiveUA"
				type="checkbox"
				[ngClass]="{ 'is-live--unpublished-changes': !data.model?.isUpToDate }"
				formControlName="isLive"
			/>
			<label isLive [item]="data.model" for="isLiveUA"></label>
		</div>
	</div>
	<div class="box-3-1 background-wrapper">
		<div>
			<!-- first child 2/3 -->
			<div>
				<label i18n>Exhibition</label>
				<form-input
					type="enum"
					formControlName="exhibitionID"
					i18n-placeholder
					placeholder="… select exhibition"
					[modelItems]="data.exhibitions"
				>
				</form-input>
				<div *ngIf="!data.exhibitions.length">
					<ng-container i18n>... missing exhibitions</ng-container>
					<button (click)="createExhibitionModal.open()" type="button">
						<div class="icon-add"></div>
						<ng-container i18n>add exhibition</ng-container>
					</button>
				</div>
				<div *ngIf="(data.hasLocationNav || data.access.isAdmin) && !data.isQuiz">
					<div>
						<label i18n>Tour Path</label>
						<form-input
							type="enum"
							[blankEntry]="true"
							formControlName="pathLocationID"
							i18n-placeholder
							placeholder="… select the tour path location"
							[modelItems]="data.polylineLocationEntities"
						>
						</form-input>
					</div>
					<div>
						<label i18n>Tour Start Marker</label>
						<form-input
							type="enum"
							[blankEntry]="true"
							formControlName="startLocationID"
							i18n-placeholder
							placeholder="… select the tour start location"
							[modelItems]="data.markerLocationEntities"
						>
						</form-input>
					</div>

					<div *ngIf="data.showShareOption || data.access.isAdmin">
						<label i18n>share on social media</label>
						<form-input [formGroup]="formGroup" type="boolean" formControlName="isShareable"></form-input>
					</div>
					<div *ngIf="data.showShareOption || data.access.isAdmin">
						<label i18n>Social media target url</label>
						<input
							type="text"
							name="shareTargetUrl"
							formControlName="shareTargetUrl"
							placeholder="… https://www.your-website.com/subpage"
						/>
					</div>
					<div>
						<label i18n>hasArArticles</label>
						<form-input [formGroup]="formGroup" type="boolean" formControlName="hasArArticles"></form-input>
					</div>
				</div>
			</div>
		</div>
		<div>
			<!-- last child 1/3 -->
			<div>
				<label i18n>Cover</label>
				<input-media-file
					[organisationID]="data.organisationID"
					type="image"
					formControlName="coverID"
				></input-media-file>
			</div>
		</div>
	</div>

	<div>
		<input-language-content
			formControlName="languageContent"
			[fields]="tourLanguageFields"
			[formGroup]="formGroup"
		>
		</input-language-content>
	</div>

	<label i18n>Options</label>
	<div>
		<input-switch *ngIf="!data.isQuiz" formControlName="addOverview" class="column tour-options" > </input-switch> <span i18n>Add article overview page to the tour</span>
		<input-switch formControlName="endTourOnlyOnCommand" class="column tour-options"> </input-switch><span i18n>End tour only on command</span>
		<input-switch formControlName="hideTourPathInExhibition" class="column tour-options"> </input-switch><span i18n>Hide tour path when not in tour</span>
	</div>
	<input-exhibit-list
		[quiz]="data.isQuiz"
		[unifiedArticles]="data.unifiedArticles"
		[exhibitionID]="formGroup.value.exhibitionID"
		formControlName="exhibitIDs"
	></input-exhibit-list>
</ng-container>
