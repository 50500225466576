/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { currentuserActions } from '../../actions';


function areEqual(group) {
	const valid = group.controls.newPassword.value === group.controls.newPasswordRepeated.value;
	if (valid) return null;
	return { areEqual: true };
}

@Component({
	selector: 'password-component',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.sass'],
})
export class PasswordComponent {
	public MIN_LENGTH = 8;

	@Output() save = new EventEmitter(false);

  public form;
  public id;

	constructor(private fb: FormBuilder, private store: Store<any>) {
		this.fb = fb;
		this.store = store;
	}

	ngOnInit() {
		this.form = this.fb.group({
			oldPassword: ['', Validators.required],
			newPassword: ['', [Validators.required, Validators.minLength(6)]],
			newPasswordRepeated: ['', [Validators.required, Validators.minLength(6)]],
		}, { validator: areEqual });
	}

	onSubmit() {
		if (this.form.valid) {
			this.store.dispatch(currentuserActions.changePassword(Object.assign({}, { id: this.id }, this.form.value)));
		}
		return false;
	}
}
