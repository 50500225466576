/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {first} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as actions from '../socket.actions';

type DepList = { name: string, full?: boolean, options?: any }[];
type DepsArg = ({ name: string, full?: boolean, options?: any } | string)[];

function depsArgToList(deps: DepsArg) {
	return deps.map(dep => typeof dep === 'string' ? { name: dep } : dep);
}

/*
 * Takes lists of collections and dispatches GetCollection(Full)List
 * and LeaveCollection(Full)List actions.
 */
export default class CollectionJoiner {
	private currentDeps: DepList = [];

	static getUntil(store: Store<any>, deps: DepsArg, destroyed$: Observable<any>) {
		return new CollectionJoiner(store, deps, destroyed$);
	}

	constructor(private store: Store<any>, initial?: DepsArg, destroyed$?: Observable<any>) {
		this.replace(initial || []);

		if (destroyed$) {
			destroyed$.pipe(first()).subscribe(() => this.leaveCurrent());
		}
	}

	/**
	 * Dispatch Leave actions for the previous dependencies.
	 */
	leaveCurrent() {
		this.currentDeps.forEach(({ name, full }) => {
			const actionClass = full ? actions.LeaveCollectionFullList : actions.LeaveCollectionList;
			this.store.dispatch(new actionClass({ collectionName: name }));
		});

		this.currentDeps = [];
	}

	/**
	 * Dispatch Leave actions for the previous dependencies and then dispatch Get
	 * actions for the new list of dependencies.
	 */
	replace(deps: DepsArg) {
		this.leaveCurrent();
		this.currentDeps = depsArgToList(deps);

		this.currentDeps
			.forEach(({ name, full, options }) => {
				const actionClass = full ? actions.GetCollectionFullList : actions.GetCollectionList;
				this.store.dispatch(new actionClass({ collectionName: name, options }));
			});
	}
}
