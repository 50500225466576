/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	arZip: { type: Schema.ObjectId },
	models: [
		{
			name: String,
			version: Number,
		},
	],
	indexJson: { type: Object },
	markers: [Schema.ObjectId],
	externalMediaMarkers: [
		{
			mediaObjectID: { type: Schema.ObjectId, ref: 'MediaObject' },
			prefabName: { type: String },
		},
	],
	externalMediaObjects: { type: [Schema.ObjectId], ref: 'MediaObject' },
	fileSize: { type: Number, default: 0 },
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
