import {
	Component,
	Input,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
} from '@angular/core';
import * as d3 from 'd3';
import * as moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const parseDate = d3.timeParse('%Y-%m-%d');
const bisectDate = d3.bisector((d) => d.date).left;

const transition = d3.transition().duration(500);

@Component({
	selector: 'analytics-visits-graph',
	template: `
		<div class="graph">
			<div
				class="button"
				[ngClass]="{ 'button--blue-ghost': visualizationType == 'graph' }"
				(click)="visualizationType = 'graph'"
				i18n
			>
				Graph
			</div>
			<div
				class="button"
				[ngClass]="{ 'button--blue-ghost': visualizationType == 'table' }"
				(click)="visualizationType = 'table'"
				i18n
			>
				Tabelle
			</div>
			<div #visitsGraph class="visits-graph" [hidden]="!(visualizationType == 'graph')"></div>
			<div class="wrapper" [hidden]="!(visualizationType == 'table')">
				<table>
					<thead>
						<tr>
							<th i18n>Date</th>
							<th i18n>{{ yAxisLabelText }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let entry of data">
							<td>{{ entry.date | date: 'longDate' }}</td>
							<td>{{ entry.value }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./analytics-visits-graph.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsVisitsGraphComponent {
	@Input() timeSeriesData: [any];
	@Input() yAxisLabelText: string;

	@ViewChild('visitsGraph', { static: true }) visitsGraph: ElementRef;

	public visualizationType = 'graph';

	private svg;
	private margin;
	private width;
	private height;
	private height2;

	private x;
	private x2;
	private y;
	private y2;

	private xAxis;
	private xAxis2;
	private yAxis;

	private brush;
	private Line_chart;
	private line;
	private line2;
	private zoom;
	private context;
	private focus;
	private focus2;
	private xAxisFocus;
	private yAxisFocus;
	private xAxisContext;
	private yAxisLabel;
	private lineFocus;
	private lineContext;
	private brushG;

	public data;

	ngAfterViewInit() {
		this.svg = d3.select(this.visitsGraph.nativeElement).append('svg');

		const { clientHeight } = this.visitsGraph.nativeElement;
		this.margin = { top: 20, right: 20, bottom: 200, left: 40 };
		const width = +this.visitsGraph.nativeElement.clientWidth - this.margin.left - this.margin.right;
		const height = +clientHeight - this.margin.top - this.margin.bottom;
		const margin2 = { top: clientHeight - 70, right: 20, bottom: 30, left: 40 };
		this.height2 = +clientHeight - margin2.top - margin2.bottom;
		this.svg = d3.select('svg');
		this.svg.attr('viewBox', `0 0 ${width} ${height}`);
		this.x = d3.scaleTime().range([0, width]);
		this.x2 = d3.scaleTime().range([0, width]);
		this.y = d3.scaleLinear().range([height, 0]);
		this.y2 = d3.scaleLinear().range([this.height2, 0]);

		this.xAxis = d3.axisBottom(this.x);
		this.xAxis2 = d3.axisBottom(this.x2);
		this.yAxis = d3.axisLeft(this.y);

		this.brush = d3
			.brushX()
			.extent([
				[0, 0],
				[width, this.height2],
			])
			.on('brush end', () => {
				if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom
				const s = d3.event.selection || this.x2.range();
				this.x.domain(s.map(this.x2.invert, this.x2));
				this.Line_chart.select('.line').attr('d', this.line(this.data));
				this.focus.select('.axis--x').call(this.xAxis);
				this.svg
					.select('.zoom')
					.call(this.zoom.transform, d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0));
			});

		this.zoom = d3
			.zoom()
			.scaleExtent([1, Infinity])
			.translateExtent([
				[0, 0],
				[width, height],
			])
			.extent([
				[0, 0],
				[width, height],
			])
			.on('zoom', () => {
				if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return; // ignore zoom-by-brush
				const t = d3.event.transform;
				this.x.domain(t.rescaleX(this.x2).domain());
				this.Line_chart.select('.line').attr('d', this.line(this.data));
				this.focus.select('.axis--x').call(this.xAxis);
				this.context.select('.brush').call(this.brush.move, this.x.range().map(t.invertX, t));
			});

		this.createLines();

		this.svg
			.append('defs')
			.append('svg:clipPath')
			.attr('id', 'clip')
			.append('svg:rect')
			.attr('width', width)
			.attr('height', height)
			.attr('x', 0)
			.attr('y', 0);

		// text label for the y axis
		this.yAxisLabel = this.svg
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('y', 0)
			.attr('x', 0 - height / 2)
			.attr('dy', '.7em')
			.style('text-anchor', 'middle');
		// .text('... loading label');

		this.Line_chart = this.svg
			.append('g')
			.attr('class', 'focus')
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
			.attr('clip-path', 'url(#clip)');

		this.focus = this.svg
			.append('g')
			.attr('class', 'focus')
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

		this.context = this.svg
			.append('g')
			.attr('class', 'context')
			.attr('transform', 'translate(' + margin2.left + ',' + margin2.top + ')');

		this.focus2 = this.focus.append('g').attr('class', 'focus2').style('display', 'none');

		const rect = this.svg
			.append('rect')
			.attr('class', 'zoom')
			.attr('width', width)
			.attr('height', height)
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
			.on('mouseover', () => {
				this.focus2.style('display', null);
			})
			.on('mouseout', () => {
				this.focus2.style('display', 'none');
			})
			.on('mousemove', () => {
				if (!this.data) return;
				const x0 = this.x.invert(d3.mouse(rect.node())[0]);
				const i = bisectDate(this.data, x0, 1);
				const d0 = this.data[i - 1];
				const d1 = this.data[i];
				const d = x0 - d0.year > d1.year - x0 ? d1 : d0;
				this.focus2.attr('transform', 'translate(' + this.x(d.date) + ',' + this.y(d.value) + ')');
				this.focus2.select('text').text(function () {
					return d.value;
				});
				this.focus2.select('.x-hover-line').attr('y2', height - this.y(d.value));
				this.focus2.select('.y-hover-line').attr('x2', width);
			})
			.call(this.zoom);

		this.focus2.append('line').attr('class', 'x-hover-line hover-line').attr('y1', 0).attr('y2', height);

		this.focus2.append('line').attr('class', 'y-hover-line hover-line').attr('x1', 0).attr('x2', width);

		this.focus2.append('circle').attr('r', 7.5);
		this.focus2.append('text').attr('x', 15).attr('dy', '.31em');

		this.xAxisFocus = this.focus
			.append('g')
			.attr('class', 'axis axis--x')
			.attr('transform', 'translate(0,' + height + ')');
		this.yAxisFocus = this.focus.append('g').attr('class', 'axis axis--y');
		this.lineFocus = this.Line_chart.append('path').datum(this.data).attr('class', 'line');
		this.lineContext = this.context.append('path').datum(this.data).attr('class', 'line');
		this.xAxisContext = this.context
			.append('g')
			.attr('class', 'axis axis--x')
			.attr('transform', 'translate(0,' + this.height2 + ')');
		this.brushG = this.context.append('g').attr('class', 'brush');
	}

	createLines() {
		this.line = d3
			.line()
			.curve(d3.curveCardinal)
			.x((d) => this.x(d.date))
			.y((d) => this.y(d.value));

		this.line2 = d3
			.line()
			.curve(d3.curveCardinal)
			.x((d) => this.x2(d.date))
			.y((d) => this.y2(d.value));
	}

	ngOnChanges() {
		if (!this.svg) return;
		this.yAxisLabel.text(this.yAxisLabelText);
		if (this.timeSeriesData) {
			const data = this.timeSeriesData.map((d) => {
				d.date = parseDate(moment(d.date).format(dateFormat));
				d.value = +d.value;
				return d;
			});
			this.data = data;

			this.x.domain(d3.extent(data, (d) => d.date));
			this.y.domain([0, d3.max(data, (d) => d.value)]);
			this.x2.domain(this.x.domain());
			this.y2.domain(this.y.domain());

			this.createLines();

			this.xAxisFocus.transition(transition).call(this.xAxis);

			this.yAxisFocus.transition(transition).call(this.yAxis);

			this.lineFocus.transition(transition).attr('d', this.line(data));

			this.lineContext.transition(transition).attr('d', this.line2(data));

			this.xAxisContext.transition(transition).call(this.xAxis2);

			this.brushG.call(this.brush).call(this.brush.move, this.x.range());
		}
	}
}
