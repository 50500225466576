/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable ,  Subject, merge } from 'rxjs';
import {distinctUntilChanged, switchMap, map} from 'rxjs/operators';
import { Component, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { AppState } from '../../reducers';
import { pluckDistinct } from '../../utils';
import * as socketActions from '../../socket.actions';
import { analyticsTypes } from '../../../../models/analytics';

const dependencies = ['Organisation', 'Exhibition'];
const _graphTypesLabels = {
	[analyticsTypes.uniqueVisitorsOverTime]: 'Unique Visitors',
	[analyticsTypes.pageviewsOverTime]: 'Pageviews',
	[analyticsTypes.pageviewsPerUniqueVisitorOverTime]: 'Average Pageviews per Unique Visitors',
}
const graphTypes = Object.keys(_graphTypesLabels);

@Component({
	selector: 'visitor-analytics',
	template: `
<div class="visitor-analytics">
	<analytics-control
		[organisationOptions]="organisationOptions$ | async"
		[exhibitionOptions]="exhibitionOptions$ | async"
		[datasourceOptions]="datasourceOptions$ | async"
		[siteId]="currentSiteId"
		[isRoot]="isRoot$ | async"
		(change)="onChange($event)"></analytics-control>
	<div class="visitor-analytics__body">
		<analytics-visits-graph
			*ngIf="isGraphType()"
			[timeSeriesData]="(analytics$ | async)[analyticsType]"
			[yAxisLabelText]="graphTypesLabels[analyticsType]"></analytics-visits-graph>
		<analytics-visits-graph
			*ngIf="analyticsType == analyticsTypes.googlePlayStoreInstalls"
			[timeSeriesData]="(analytics$ | async)[analyticsType]"
			yAxisLabelText="Active Device Installs"></analytics-visits-graph>
		<analytics-devices
			*ngIf="analyticsType == analyticsTypes.deviceModel"
			[data]="(analytics$ | async).deviceModel">deviceModel</analytics-devices>
		<analytics-times
			*ngIf="analyticsType == analyticsTypes.visitsPerTime"
			[data]="(analytics$ | async).visitsPerTime">visitsPerTime</analytics-times>
		<analytics-pages
			*ngIf="analyticsType == analyticsTypes.pageTitles"
			[data]="(analytics$ | async).pageTitles">pageTitles</analytics-pages>
	</div>
</div>
	`,
	styleUrls: ['./analytics.container.sass'],
})
export class AnalyticsContainer {
	public organisationOptions$: Observable<any>;
	public exhibitionOptions$: Observable<any>;
	public datasourceOptions$: Observable<any>;
	private lastExhibitionId;
	currentOrganisationID$ = new Subject<string>();
	currentExhibitionID$ = new Subject<string>();
	public currentSiteId = '';
	public isRoot$: Observable<boolean>;
	public analytics$: Observable<any>;
	public analyticsForOrganisation$ = new Subject();
	public analyticsType = analyticsTypes.visitsOverTime;
	public analyticsTypes = analyticsTypes;
	public graphTypesLabels = _graphTypesLabels;

	constructor(private store: Store<AppState>, private i18n: I18n) {
		this.graphTypesLabels = {
			[analyticsTypes.uniqueVisitorsOverTime]: this.i18n('Unique Visitors'),
			[analyticsTypes.pageviewsOverTime]: this.i18n('Pageviews'),
			[analyticsTypes.pageviewsPerUniqueVisitorOverTime]: this.i18n('Average Pageviews per Unique Visitors'),
		};
		this.analytics$ = pluckDistinct(store, 'analytics')
		this.isRoot$ = pluckDistinct(store, 'currentuser', 'access', 'isAdmin');
		// On change of currentOrganisationID$ and currentExhibitionID$ update the currentSiteId$ stream
		observableCombineLatest(
			this.currentOrganisationID$.pipe(switchMap(organisationID =>
				pluckDistinct(store, 'models', 'Organisation', 'entities', organisationID)
			)),
			this.currentExhibitionID$.pipe(switchMap(exhibitionID =>
				pluckDistinct(store, 'models', 'Exhibition', 'entities', exhibitionID)
			)),
			(organisation, exhibition) => {
				if (organisation) {
					this.analyticsForOrganisation$.next(organisation.analyticsForOrganisation);
					if (organisation.analyticsForOrganisation) return organisation.analyticsSiteId;
					else if (exhibition) return exhibition.analyticsSiteId;
				}
				return null;
			}
		).pipe(distinctUntilChanged()).subscribe(currentSiteId => {
			this.currentSiteId = currentSiteId
		})

		// on change of the Organisation entities in the store
		// update currentOrganisationID$ stream if there is only one organisation
		this.organisationOptions$ = pluckDistinct(store, 'models', 'Organisation', 'entities').pipe(map(entities => {
			const entries = Object.entries(entities);
			if (entries.length === 1) {
				this.currentOrganisationID$.next(entries[0][0]);
				const organisation: any = entries[0][1];
				if (organisation.analyticsForOrganisation) this.currentSiteId =  organisation.analyticsSiteId;
			}
			return entries.reduce((acc, [id, data]: any[]) => {
				return [...acc, { value: id, label: data.name }];
			}, []);
		}));

		this.exhibitionOptions$ = observableCombineLatest(
			this.currentOrganisationID$.pipe(switchMap(organisationID =>
				pluckDistinct(store, 'models', 'Organisation', 'entities', organisationID)
			)),
			pluckDistinct(store, 'models', 'Exhibition', 'entities'),
			(organisation: any, entities: any) => {
				if (!organisation || organisation.analyticsForOrganisation) return [];
				const entries =  Object.entries(entities);
				const exhibitionOptions = entries.reduce((acc, [id, data]: [string, any]) => {
							if (data.organisationID === organisation._id) acc.push({ value: id, label: data.name });
							return acc;
					  }, []);
				if (exhibitionOptions.length === 1) {
					this.currentExhibitionID$.next(exhibitionOptions[0].value);
					const exhibition: any = entries[0][1]
					this.currentSiteId = exhibition.analyticsSiteId;
				}
				return exhibitionOptions;
			}
		);

		this.datasourceOptions$ = observableCombineLatest(
			this.currentOrganisationID$.pipe(switchMap(organisationID =>
				pluckDistinct(store, 'models', 'Organisation', 'entities', organisationID)
			)),
			pluckDistinct(store, 'models', 'DataSource', 'entities'),
			(organisation: any, entities: any) => {
				if (!organisation) return [];
				const entries =  Object.entries(entities);
				return {
					android: entries.reduce((acc, [id, data]: [string, any]) => {
							if (data.organisationID === organisation._id && data.appStoreIds.android) acc.push({ value: id, label: data.appStoreIds.android.split('.').pop() });
							return acc;
					  }, []),
					ios: entries.reduce((acc, [id, data]: [string, any]) => {
							if (data.organisationID === organisation._id && data.appStoreIds.ios) acc.push({ value: id, label: data.appStoreIds.ios });
							return acc;
					  }, []),
				}
			}
		);

		this.currentExhibitionID$.subscribe((exhibitionID: string) => {
			if (exhibitionID !== this.lastExhibitionId) {
				if (exhibitionID) {
					this.store.dispatch(
						new socketActions.GetCollectionItem({ collectionName: 'Exhibition', id: exhibitionID })
					);
				}
				if (this.lastExhibitionId) {
					this.store.dispatch(
						new socketActions.LeaveCollectionItem({
							collectionName: 'Exhibition',
							id: this.lastExhibitionId,
						})
					);
				}
			}
			this.lastExhibitionId = exhibitionID;
		});

		dependencies.forEach(collectionName => {
			this.store.dispatch(new socketActions.GetCollectionList({ collectionName }));
		});
		this.store.dispatch(new socketActions.GetCollectionFullList({ collectionName: 'DataSource' }));

	}

	isGraphType() {
		return graphTypes.includes(this.analyticsType);
	}

	ngOnDestroy() {
		dependencies.forEach(collectionName => {
			this.store.dispatch(new socketActions.LeaveCollectionList({ collectionName }));
		});
		this.store.dispatch(new socketActions.LeaveCollectionList({ collectionName: 'DataSource' }));
	}

	onChange(event) {
		console.log("event", event);
		this.store.dispatch(new socketActions.GetAnalyticsData(event));
		this.currentOrganisationID$.next(event.organisationID);
		this.currentExhibitionID$.next(event.exhibitionID);
		this.analyticsType = event.analyticsType;
	}
}
