/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { statusActions } from '../status/actions';
import { updateField } from '../utils';

const initialState = {
	online: true,
	pendingRequests: {},
	uploading: 0,
}

export function statusReducer(state = initialState, action) {
	let newState = state;
	if (action.type === statusActions.CONNECT) {
		newState = updateField(newState, ['online'], true);
	} else if (action.type === statusActions.DISCONNECT) {
		newState = updateField(newState, ['online'], false);
	} else if (action.type === statusActions.ADD_PENDING) {
		newState = updateField(newState, ['pendingRequests', action.payload], true);
	} else if (action.type === statusActions.REMOVE_PENDING) {
		newState = updateField(newState, ['pendingRequests', action.payload], false);
	} else if (action.type === statusActions.ADD_UPLOAD) {
		newState = updateField(newState, ['uploading'], newState.uploading + 1);
	} else if (action.type === statusActions.REMOVE_UPLOAD) {
		newState = updateField(newState, ['uploading'], newState.uploading - 1);
	}

	return newState;
}
