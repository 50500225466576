/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {of as observableOf, combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {takeUntil, observeOn, delay, switchMap, filter, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { Store } from '@ngrx/store';

import { SocketConnectionService } from '../services/socket-connection.service';
import { statusActions } from '../status/actions';
import { pluckDistinct } from '../utils';

@Injectable()
export class StatusEffects {
	static CLOSE_TIMEOUT = 3000;

	private connected$ = observableCombineLatest(
			this.socketService.connected$,
			this.socketService.loggedIn$,
			pluckDistinct(this.store, 'currentuser', 'currentuser', '_id'),
		).pipe(
		map(([connected, connectedSecure, userId]) => {
			// the secure socket may be disconnected while the user isn't logged in
			return connected && (connectedSecure || !userId);
		}));

	@Effect()
	dispatchConnect$ = this.connected$.pipe(filter(connected => connected),
		filter(connected => connected),
		map(() => statusActions.connect()),);

	@Effect()
	dispatchDisconnect$ = this.connected$.pipe(filter(connected => !connected),
		// wait CLOSE_TIMEOUT after the websocket connection closes
		switchMap(() => {
			return observableOf(null).pipe(
				delay(StatusEffects.CLOSE_TIMEOUT),
				observeOn(animationFrame),
				takeUntil(this.actions$.pipe(ofType(statusActions.CONNECT))),
				map(() => statusActions.disconnect()));
		})
	);

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private socketService: SocketConnectionService
	) {}
}
