/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { schema, labels } from '../../../models/tour/tourSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { TourFormComponent } from '../_tours/components/tour-form.component';
import { getFormData$, getListData$, beforeSave } from '../_tours/config';
import { selectors } from '../selectors';

export const quizConfig = new ModelConfig({
	name: 'quiz',
	namePlural: 'quiz',
	collectionName: 'Tour',
	icon: 'icon-report-card',
	clientPathPrefix: '',
	publishable: true,
	dependencies: [{ name: 'UnifiedArticle', inSidebar: false }, { name: 'Location', inSidebar: false }],
	schema,
	labels,
	formComponent: TourFormComponent,
});

Object.assign(quizConfig, {
	getFormData$,
	getListData$,
	beforeSave,
});
