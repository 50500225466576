
		<div class="list">
			<div *ngFor="let item of value">
				{{
					item === 'builder2'
						? 'editor v2'
						: item === 'editor'
						? 'editor v3'
						: item === 'custom-map'
						? 'indoor map'
						: item === 'distortable-image-map'
						? 'outdoor map'
						: item
				}}
				<span class="icon-remove" (click)="remove(item)"></span>
			</div>
		</div>
		<div class="input-widget">
			<select (change)="propagateChange()" (change)="add($event.target.value)">
				<option value="" disabled i18n>... select an article type</option>
				<option *ngFor="let type of articleTypeOptions" [value]="type.value">{{ type.label }}</option>
			</select>
		</div>
	