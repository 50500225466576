/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { styletemplateConfig } from '../../_styletemplates/config';
import * as CollectionActions from '../../socket.actions';
import { selectors } from '../../selectors';
import { pluckDistinct, CollectionJoiner } from '../../utils';
import { takeUntil, map } from 'rxjs/operators';


@Component({
	selector: 'input-styletemplate',
	template: `
<div class="input-widget">
	<select
		[(ngModel)]="value"
		(change)="propagateChange()">
		<option [ngValue]="null" selected i18n>... select a style template</option>
		<option
			*ngFor="let template of ((styletemplates$ | async) | filter:(currentUserOrg$ | async)._id)"
			[value]="template._id">{{template.name}}
		</option>
	</select>
</div>
	`,
	styleUrls: ['./input-styletemplate.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputStyleTemplateComponent), multi: true }],
})
export class InputStyleTemplateComponent extends CustomValueAccessor {
	public styletemplates$;
	private styletemplatesEntities;
	public currentUserOrg$;

	public destroyed$ = new Subject();

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);
		CollectionJoiner.getUntil(store, [styletemplateConfig.collectionName], this.destroyed$);
		this.currentUserOrg$ = pluckDistinct(store, 'currentuser', 'organisation');
		this.styletemplates$ = pluckDistinct(store, 'models', 'StyleTemplate').pipe(
			takeUntil(this.destroyed$),
			map(selectors.getAlphabeticList)
		);

		this.value = null;
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
