/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export class Schema {

	static ObjectId = function() {};

	constructor(object) {
		let model = object;

		model.isSchema = true;
		model.methods = {};
		model.add = function(obj) {
			model = Object.assign({}, model, obj);
		};
		model.plugin = function(pluginFkt, options) {
			model.methods = {};
			model.statics = {};
			pluginFkt(model, options);
			delete model.methods;
			delete model.statics;
		};
		model.pre = function() {};
		return model;
	}
};
