/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
const getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition', index: true, unique: false, required: true },
	name: { type: String, required: true, unique: false, sparse: true, trim: true, label: 'Name' },
	coverID: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image' }, // Has to be type Image
	coverIDVersion: { type: Number },
	languageContent: { type: [require('./languageTourSchema').definition], required: true },
	exhibitIDs: [{ type: Schema.ObjectId, ref: 'Exhibit' }],
	pathLocationID: { type: Schema.ObjectId, ref: 'Location' },
	startLocationID: { type: Schema.ObjectId, ref: 'Location' },
	isQuiz: { type: Boolean, default: false },
	isLive: { type: Boolean, default: false },
	tags: [{ type: String }],
	isShareable: { type: Boolean, default: false },
	shareTargetUrl: { type: String, default: null, required: false },
	addOverview: { type: Boolean, default: false },
	endTourOnlyOnCommand: { type: Boolean, default: false },
	hideTourPathInExhibition: { type: Boolean, default: false },
	isUpToDate: { type: Boolean, default: false, writable: false },
	publishedVersions: [
		{
			version: { type: Number },
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
			mediaObjects: [
				{
					id: { type: Schema.ObjectId, ref: 'Article' },
					version: { type: Number },
				},
			],
			mediaFiles: [{ type: Schema.ObjectId }],
			articleIDs: [{ type: Schema.ObjectId, ref: 'Article' }],
		},
	],
	hasArArticles: { type: Boolean, default: false },
};
var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Tour',
	labels: getLabels(schemaDefinition),
	schema: ModelSchema,
	definition: schemaDefinition,
};
