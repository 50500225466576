/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	languageISO: { type: String, required: true },
	title: { type: String },
	description: { type: String },
	disabled: { type: Boolean, default: false },
};
var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
