/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', required: false },
	exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition', required: false },
	name: {
		type: String,
		enum: [
			'root',
			'admin',
			'curator',
			'editor',
			'designer',
			'url',
		],
		required: true,
	},
	token: { type: String },
};

module.exports = {
	definition: def,
};
