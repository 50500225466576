<div [formGroup]="formGroup">
	<modal-component [clean]="true" #mapModal [fullSize]="true">
		<div #mapContainer class="leaflet-map-container"></div>
		<div #mapMakerHost></div>
		<div class="as-text">
			<div *ngIf="coordinates[0]" [ngSwitch]="shape">
				<div *ngSwitchCase="'marker'">
					<h3 i18n>Marker</h3>
					<div class="as-text__ctrl">
						<div>
							{{crs === 'Simple' ? 'X' : 'lat'}}: {{coordinates[0].lat}}, {{crs === 'Simple' ? 'Y' : 'lng'}}: {{coordinates[0].lng}}
						</div>
						<span class="icon-remove" (click)="patch(null)"></span>
					</div>
				</div>
				<div *ngSwitchCase="'polygon'">
					<h3>Polygon</h3>
					<div class="as-text__ctrl">
						<div *ngIf="coordinates">
							<div *ngFor="let point of coordinates">
								{{crs === 'Simple' ? 'X' : 'lat'}}: {{point.lat}}, {{crs === 'Simple' ? 'Y' : 'lng'}}: {{point.lng}}
							</div>
						</div>
						<span class="icon-remove" (click)="patch(null)"></span>
					</div>
				</div>
				<div *ngSwitchCase="'polyline'">
					<h3>Polyline</h3>
					<div class="as-text__ctrl">
						<div *ngIf="coordinates">
							<div *ngFor="let point of coordinates">
								{{crs === 'Simple' ? 'X' : 'lat'}}: {{point.lat}}, {{crs === 'Simple' ? 'Y' : 'lng'}}: {{point.lng}}
							</div>
						</div>
						<span class="icon-remove" (click)="patch(null)"></span>
					</div>
				</div>
				<span *ngSwitchDefault i18n>nothing selected</span>
			</div>
			<footer>
				<button type="button" (click)="save(); mapModal.close()" i18n>Ok</button>
			</footer>
		</div>
	</modal-component>
	<div class="lat-long-inputs" *ngIf="!disabled && shape === 'marker'">
		<label>
			{{crs === 'Simple' ? 'X' : 'lat'}} <input type="number" [disabled]="disabled" [value]="coordinates[0]?.lat" (input)="inputCoord('lat', $event.target.value)" step="0.000001">
		</label>
		<label>
			{{crs === 'Simple' ? 'Y' : 'lng'}} <input type="number" [disabled]="disabled" [value]="coordinates[0]?.lng" (input)="inputCoord('lng', $event.target.value)" step="0.000001">
		</label>
	</div>
	<div class="lat-long-inputs lat-long-inputs-polygon" *ngIf="!disabled && coordinates?.length && (shape === 'polygon' || shape === 'polyline')">
		<div *ngFor="let point of coordinates; let i = index">
			<label>
				{{crs === 'Simple' ? 'X' : 'lat'}} <input type="number" [disabled]="disabled" [value]="point.lat" (input)="inputCoordPolygon(i, 'lat', $event.target.value)" step="0.000001">
			</label>
			<label>
				{{crs === 'Simple' ? 'Y' : 'lng'}} <input type="number" [disabled]="disabled" [value]="point.lng" (input)="inputCoordPolygon(i, 'lng', $event.target.value)" step="0.000001">
			</label>
			<br>
		</div>
	</div>

	<div class="select-btn" [attr.disabled]="disabled || null" (click)="openMap($event)">
		<span class="icon-add"></span> <ng-container i18n>select map location</ng-container>
	</div>
	<div
		*ngIf="!disabled && shape && coordinates?.length && coordinates[0]?.length"
		class="preview"
		(click)="openMap($event)"
		[ngStyle]="{'background-image': 'url('+ staticImage +')'}"
		></div>
</div>
