<div [formGroup]="group">

	<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="upddDisable"
		[definition]="definition.upddDisable" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.upddDisable : false">
		Disable Update Daemon
	</form-input>

	<label *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])">
		Wifi (boxxes Config)
	</label>

	<!-- those types only need 2.4 GHz for mesh -->
	<div class="columns" *ngIf="showForBoxxtypes(['mediaplayer', 'gate', 'ticketprinter'])">
		<div class="column is-half">
			<!-- first child 1/2 -->
			<form-input class="input-label-left" [hasDisable]="hasDisable" formControlName="channel"
				[definition]="definition.channel" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.channel : []">
				2.4 GHz Channel
			</form-input>
		</div>
	</div>

	<div class="columns" *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])">
		<div class="column is-half">
			<!-- first child 1/2 -->
			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="disableAp"
				[definition]="definition.disableAp" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.disableAp : false">
				Disable 2.4 GHz AP
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="enableQoS"
				[definition]="definition.enableQoS" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings?.enableQoS : false">
				Enable QoS
			</form-input>

			<form-input class="input-label-left" maxLength="32" [hasDisable]="hasDisable" formControlName="ssid"
				[definition]="definition.ssid"
				[dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings?.ssid : []">
				2.4 GHz SSID
			</form-input>

			<form-input class="input-label-left" [hasDisable]="hasDisable" formControlName="channel"
				[definition]="definition.channel" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.channel : []">
				2.4 GHz Channel
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="tXDbm"
				[definition]="definition.tXDbm" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.tXDbm : []">
				<div>2.4 GHz TX power (dBm)</div>
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="kickDbmThreshold"
				[definition]="definition.kickDbmThreshold" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.kickDbmThreshold : []">
				<div>2.4 GHz Android kick threshold (dBm)</div>
			</form-input>

			<form-input class="input-label-left" [hasDisable]="hasDisable" formControlName="htmode"
				[definition]="definition.htmode" [options]="nHTModeOptions" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.htmode : []">
				2.4 GHz HTMode
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="captivePortalQuotaWAN"
				[definition]="definition.captivePortalQuotaWAN" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalQuotaWAN : []">
				<div>Per-device Internet traffic limit (B)</div>
			</form-input>
		</div>
		<div class="column is-half">
			<!-- last child 1/2 -->
			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="disableAp5"
				[definition]="definition.disableAp5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.disableAp5 : false">
				Disable 5 GHz AP
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="enableQoS5"
				[definition]="definition.enableQoS5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.enableQoS5 : false">
				Enable QoS 5
			</form-input>

			<form-input class="input-label-left" maxLength="32" [hasDisable]="hasDisable" formControlName="ssid5"
				[definition]="definition.ssid5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings?.ssid5 : []">
				5 GHz SSID
			</form-input>

			<form-input class="input-label-left" [hasDisable]="hasDisable" formControlName="channel5"
				[definition]="definition.channel5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.channel5 : []">
				5 GHz Channel
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="tXDbm5"
				[definition]="definition.tXDbm5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.tXDbm5 : []">
				<div>5 GHz TX Power (dBm)</div>
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="kickDbmThreshold5"
				[definition]="definition.kickDbmThreshold5" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.kickDbmThreshold5 : []">
				<div>5 GHz Android kick threshold (dBm)</div>
			</form-input>

			<form-input class="input-label-left" [hasDisable]="hasDisable" formControlName="htmode5"
				[definition]="definition.htmode5" [options]="acHTModeOptions" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.htmode5 : []">
				5 GHz HTMode
			</form-input>
		</div>
	</div>

	<form-input class="check-label-right" *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])"
		[hasDisable]="hasDisable" formControlName="disableWiFiLEDs" [definition]="definition.disableWiFiLEDs"
		[dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.disableWiFiLEDs : false">
		Disable WiFi LEDs
	</form-input>

	<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="enableQoSMesh"
		[definition]="definition.enableQoSMesh" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.enableQoSMesh : false">
		Enable QoS Mesh
	</form-input>

	<div class="spacer" *ngIf="!showForBoxxtypes(['gate'])"></div>

	<label *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])">
		Captive Portal
	</label>
	<div class="columns" *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])">
		<div class="column is-half">
			<!-- first child 1/2 -->
			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="captivePortalDisablePopup"
				[definition]="definition.captivePortalDisablePopup" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalDisablePopup : false">
				Disable Captive Popup
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable"
				formControlName="captivePortalBlockAppleUpdates"
				[definition]="definition.captivePortalBlockAppleUpdates" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalBlockAppleUpdates : false">
				Block Apple Updates
			</form-input>

			<form-input class="input-number" [hasDisable]="hasDisable" formControlName="captivePortalCacheExpires"
				[definition]="definition.captivePortalCacheExpires" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalCacheExpires : []">
				<div>Webserver Cache Expires After (minutes)</div>
			</form-input>

		</div>
		<div class="column is-half">
			<!-- last child 1/2 -->
			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="captivePortalEnableInet"
				[definition]="definition.captivePortalEnableInet" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalEnableInet : false">
				Enable full Internet Access
			</form-input>

			<form-input class="check-label-right" *ngIf="showForBoxxtypes(['mother', 'child', 'hub', 'all'])"
				[hasDisable]="hasDisable" formControlName="ssl" [definition]="definition.ssl"
				[dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.ssl : false">
				Enable HTTPS
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="captivePortalIsolate"
				[definition]="definition.captivePortalIsolate" [dataSourceSettings]="(dataSourceSettings) ? dataSourceSettings.captivePortalIsolate : false">
				Isolate
			</form-input>
		</div>
	</div>

	<div class="columns" formGroupName="dnsWhiteList" *ngIf="!group.value.captivePortalEnableInet">
		<div class="column is-half">
			<label> DNS white list </label>
			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="android"
				[definition]="definition.dnsWhiteList.android"
				[dataSourceSettings]="(dataSourceSettings && dataSourceSettings.dnsWhiteList) ? dataSourceSettings.dnsWhiteList.android : false">
				Android App Download
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="ios"
				[definition]="definition.dnsWhiteList.ios"
				[dataSourceSettings]="(dataSourceSettings && dataSourceSettings.dnsWhiteList) ? dataSourceSettings.dnsWhiteList.ios : false">
				iOS App Download
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="socialMedia"
				[definition]="definition.dnsWhiteList.socialMedia"
				[dataSourceSettings]="(dataSourceSettings && dataSourceSettings.dnsWhiteList) ? dataSourceSettings.dnsWhiteList.socialMedia : false">
				Social Media
			</form-input>

			<form-input class="check-label-right" [hasDisable]="hasDisable" formControlName="youtube"
				[definition]="definition.dnsWhiteList.youtube"
				[dataSourceSettings]="(dataSourceSettings && dataSourceSettings.dnsWhiteList) ? dataSourceSettings.dnsWhiteList.youtube : false">
				Youtube
			</form-input>
		</div>
		<div class="column is-half">
			<label>Custom DNS whitelist</label>
			<div>
				<input-text-list formControlName="custom" [hasDisable]="hasDisable" validation="hostname"></input-text-list>
			</div>
		</div>
	</div>


	<div class="columns" *ngIf="!showForBoxxtypes(['gate', 'externalmediaplayer'])">

		<div formGroupName="duty" class="column is-half">
			<label>Boxx Duty from</label>
			<form-input [hasDisable]="hasDisable" formControlName="from" [definition]="definition.duty.from">
			</form-input>
		</div>
		<div formGroupName="duty" class="column is-half">
			<label>to</label>
			<form-input [hasDisable]="hasDisable" formControlName="to" [definition]="definition.duty.to"></form-input>
		</div>
	</div>
</div>
