<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

		<div class="box-1-1">
			<div>
				<div>
					<label>name</label>
					<input type="text" formControlName="name">
				</div>
				<div *ngIf="id">
					<label>version String</label>
					<div>
						{{data.model.versionStrg}}
					</div>
				</div>
				<div class="spacer"></div>
				<input-switch formControlName="deployToWeb">
					deploy To Web
				</input-switch>
				<input-switch formControlName="deployToPreview">
					deploy To Preview
				</input-switch>
				<input-switch formControlName="deployToOfflineInit">
					deploy to offline
				</input-switch>
				<div class="spacer"></div>
				<label>Data sources that use this package</label>
				<div class="data-sources">
					<div *ngFor="let dataSource of data.datasources">
						<a [routerLink]="'/admin/datasource/edit/'+dataSource._id">{{dataSource.name}}</a>
					</div>
				</div>
			</div>

			<div class="file-uploads">
				<label>Package</label>
				<input-file #inputFile accept=".ipk,.deb" [uploadPath]="'/api/systempks/files'" formControlName="fileID">
					<p *ngIf="inputFile.value === data.fileID">{{ data.packageInfo.Package }}</p>
					<p *ngIf="inputFile.value !== data.fileID && inputFile.fileInfo">{{ inputFile.fileInfo.originalName }}</p>
				</input-file>
			</div>
		</div>


</ng-container>
