/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var,vars-on-top */
var Schema = require('mongoose').Schema;

var def = {
	maintenanceMode: { type: Boolean, default: true },
	maintenanceModeMessage: {
		type: String,
		default: 'We are currently updating the system. Please come back later.',
	},

	requets: {
		defaultQueue: { type: String, enum: ['0' /* Immediate */, '1'], default: '1' },
		upgradeBatchSize: { type: Number, min: 0, default: 0 /* upgrade all */ },
	},
};

var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'CmsSetting',
	schema: ModelSchema,
	definition: def,
};
