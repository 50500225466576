<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<div>
		<label>UUid</label>
		<input type="text" formControlName="uuid">
	</div>
	<div>
		<label>Major</label>
		<input type="number" step="1" formControlName="major">
	</div>
	<div>
		<label>Minor</label>
		<input type="number" step="1" formControlName="minor">
	</div>
	<div>
		<label>Bias</label>
		<input type="number" step="1" formControlName="bias">
	</div>
	<div>
		<label>Type</label>
		<select formControlName="type">
			<option *ngFor="let type of model.schema.type.enum" value="{{type}}">
				{{type}}
			</option>
		</select>
	</div>
	<div *ngIf="formGroup.value.type === 'extender'">
		<label>extended Beacon</label>
		<select formControlName="extendedBeaconID">
			<option value="" disabled>... select a beacon</option>
			<option [value]="beacon._id" *ngFor="let beacon of data.beacons">
				{{beacon.major}} - {{beacon.minor}} ({{beacon.comments}})
			</option>
		</select>
	</div>
	<div class="spacer"></div>
	<div *ngIf="formGroup.value.type === 'delayedTrigger'" class="input-number">
		<div>Delay</div>
		<div>
			<input type="number" step="1" formControlName="delay"> seconds
		</div>
	</div>
	<div>
		<label>Comments</label>
		<textarea cols="30" rows="4" formControlName="comments"></textarea>
	</div>

</ng-container>
