/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { definition } from '../../../../../models/dataSource/dataSourceSchema';

@Component({
	selector: 'input-variable-storage',
	template: `
		<style>
			:host(.ng-invalid) >>> select {
				border: 1px solid #cd0026;
			}
		</style>
		<div class="list">
			<div *ngFor="let item of value">
				<div>{{ item.name }} ({{ item.comments }})</div>
				<i>{{ item.type }}</i>
				<div>
					{{ item.scope }}
				</div>
				<div>
					{{ item.storeURL }}
				</div>

				<span class="icon-remove" (click)="remove(item)"></span>
			</div>
		</div>
		<div>
			<select-array [optionSets]="optionSets" [clearOnComplete]="false" [(ngModel)]="selectArray">
			</select-array>
			<button (click)="add($event)">add</button>
		</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputVariableStorageComponent), multi: true },
	],
})
export class InputVariableStorageComponent extends CustomValueAccessor {
	public optionSets = [
		{
			label: 'Name',
			placeholder: '...',
			type: 'text',
		},
		{
			label: 'Scope',
			placeholder: 'Select a scope...',
			options: definition.variableStorage[0].scope.enum.map(value => ({
				value: `${value}`,
				label: `${value}`,
			})),
			type: 'select',
		},
		{
			label: 'type',
			placeholder: 'Select a type...',
			options: definition.variableStorage[0].type.enum.map(value => ({
				value: `${value}`,
				label: `${value}`,
			})),
			type: 'select',
		},
		{
			label: 'storeURL',
			placeholder: '...',
			type: 'text',
		},
		{
			label: 'default',
			placeholder: '...',
			type: 'text',
		},
		{
			label: 'comments',
			placeholder: '...',
			type: 'text',
		},
	];
	public selectArray = [];

	ngOnInit() {
		this.value = this.value || [];
	}

	add(event) {
		event.preventDefault();

		const [name, scope, type, storeURL, deflt, comments] = this.selectArray;
		if (!this.value) this.value = [];

		if (name && scope && type) {
			this.value = [
				...this.value,
				{
					name,
					scope,
					type,
					storeURL,
					default: deflt,
					comments,
				},
			];
			this.selectArray = [];
		}
	}

	remove(role) {
		this.value = this.value.filter(i => i !== role);
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(value);
	}
}
