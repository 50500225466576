/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SelectModule } from 'ng-select';

import { NavContextContainer } from './nav-context.container';
import { NavContextComponent } from './nav-context.component';
import { NavContextEffects } from './effects';

@NgModule({
	imports: [
		CommonModule,
		SelectModule,
		EffectsModule.forFeature([NavContextEffects]),
	],
	exports: [NavContextContainer],
	declarations: [
		NavContextContainer,
		NavContextComponent,
	],
})
export class NavContextModule { }
