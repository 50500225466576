<div
		class="input-file"
		[ngClass]="{
			'input-file--iconized': iconized,
			'input-file--small': small,
			'input-file--disabled': disabled
		}">
		<ng-container *ngIf="!iconized">
			<media-viewer
				*ngIf="viewable()"
				[source]="getUrl()"
				[type]="type"
				[fullSize]="true"
				class="input-file__preview {{type}}">
			</media-viewer>
			<div *ngIf="!viewable()" class="input-file__preview {{type}}">
				<ng-content></ng-content>
			</div>
		</ng-container>

		<div class="input-file__body {{type}}">

			<div *ngIf="!iconized && canUpdate" class="input-file__hover-overlay">
				<span>Drop file</span>
			</div>

			<div class="input-file__menu" (mouseenter)="showMenu = true" (mouseleave)="showMenu = false">
				<span class="menu-btn icon-circled-chevron-down" [ngClass]="{'menu-btn--active': showMenu}"></span>

				<div class="menu" [ngClass]="{'menu--active': showMenu}">
					<div [hidden]="!viewable()" (click)="modal.open()">
						<span class="icon-zoom-to-extents"></span>
						<ng-container i18n>View</ng-container>
					</div>
					<label [hidden]="!canUpdate" [attr.for]="inputId">
						<span class="icon-upload-to-cloud"></span>
						<ng-container i18n>Upload</ng-container>
					</label>
					<a target="_blank" [hidden]="!value" [href]="getUrl()" [download]="downloadName || ''">
						<span class="icon-download-from-cloud"></span>
						<ng-container i18n>Download</ng-container>
					</a>
					<ng-content select="[slot=menu]"></ng-content>
				</div>
			</div>

			<file-dropzone
				*ngIf="canUpdate"
				[accept]="(accept && accept[0] === '.') ? '*' : (accept || getAcceptMime())"
				(fileDrop)="handleChange($event.event)">
				<span>Update file</span>
			</file-dropzone>
			<progress [attr.uploading]="uploading" [attr.value]="progress" max="100"></progress>
		</div>

		<modal-component #modal [fullSize]="true" [clean]="true">
			<media-viewer
				class="{{type}}"
				*ngIf="viewable() && modal?.isOpen()"
				[source]="getUrl()"
				[type]="type">
			</media-viewer>
		</modal-component>

		<input hidden type="file" [attr.accept]="accept || getAcceptMime()" (change)="handleChange($event)" [id]="inputId">
	</div>