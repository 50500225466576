/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';

import { ModelConfig } from '../../modelConfigs/ModelConfig';
import { modelConfigs } from '../../modelConfigs';
import { getValidators } from '../../services/schemaTools';
import { actions } from '../../actions';
import { DynamicModelFormComponent } from '../../components/dynamic-model-form.component';
import { ModalComponent } from '../../modals/modal.component';
import { ModelEditFooterComponent } from '../../components/model-edit-footer.component';

@Component({
	selector: 'form-modal',
	template: `
	<modal-component
		#modal
		(openEvent)="onOpen()"
		(openClose)="onClose()">

		<header>
			<ng-content select="header"></ng-content>
		</header>

		<dynamic-model-form
			#dynamicModelForm
			[collectionName]="modal.isOpen() ? collectionName : null"
			[id]="id"
			[extendDefaultData]="extendDefaultData"
			[data]="data$ | async"
			slot="body">
			<ng-content></ng-content>
		</dynamic-model-form>

		<footer>
			<model-edit-footer
				#footer
				(saveEvent)="this.close(); this.saveEvent.emit($event)"
				(closeEvent)="this.close()"
				[formGroup]="dynamicModelForm.formGroup"
				[model]="dynamicModelForm.modelConfig"
				[id]="dynamicModelForm.id"
				[data]="dynamicModelForm.data">
			<ng-content select="footer"></ng-content>
			</model-edit-footer>
		</footer>

	</modal-component>
	`,
	styleUrls: ['./index.sass'],
})
export class FormModalComponent {
	@Input() collectionName: string;
	@Input() id = '';
	@Input() extendDefaultData: any;

	@ViewChild('modal', { static: false }) modalComponent: ModalComponent;
	@ViewChild('dynamicModelForm', { static: true }) dynamicModelFormComponent: DynamicModelFormComponent;
	@ViewChild('footer', { static: true }) footer: ModelEditFooterComponent;

	@Output() openEvent = new EventEmitter<any>();
	@Output() closeEvent = new EventEmitter<any>();
	@Output() saveEvent = new EventEmitter<any>();

	isOpen = false;
	data$: Observable<any>;

	constructor(private store: Store<any>) {}

	ngAfterViewInit() {
		this.modalComponent.openEvent.subscribe(event => this.openEvent.emit(event));
		this.modalComponent.closeEvent.subscribe(event => this.closeEvent.emit(event));
	}

	ngOnChanges(changes) {
		if (changes.collectionName || changes.id) {
			this.data$ = modelConfigs[this.collectionName].getFormData$(this.store, this.id)
		}
	}

	onOpen() {
		if (this.collectionName) {
			this.data$ = modelConfigs[this.collectionName].getFormData$(this.store, this.id)
		}
		if (this.dynamicModelFormComponent) {
			this.dynamicModelFormComponent.reset();
			this.dynamicModelFormComponent.onDataChange();
		}
		this.isOpen = true;
	}

	onClose() {
		this.isOpen = false;
	}

	open() {
		this.modalComponent.open();
	}
	close() {
		this.modalComponent.close();
	}
	save() {
		this.footer.save();
	}
}
