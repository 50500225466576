/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import * as moment from 'moment';

import * as socketActions from './socket.actions';
import { actions } from './actions';
import { modelConfigs } from './modelConfigs';
import { currentuserActions } from './_currentuser/actions';
import {
	updateField,
	deleteField,
	fillDefaults,
} from './utils';
import { sortListOfObjects, getSortedItems } from './services/sortListOfObjects';

const initialState: any = Object.values(modelConfigs).reduce(
	(state, model) => ({ ...state, [model.collectionName]: model.initialState }),
	{
		articleID2name: {},
	}
);

function insertData(object, id, key, ids) {
	if (!object[id]) object[id] = {};
	if (!object[id][key]) object[id][key] = new Set();
	ids.forEach(_id => {
		object[id][key].add(_id);
	});
}

/**
 * Updates state models in response to actions affecting collections
 * (from sockets,the remaining REST api, UI sorting, logout)
 */
export function collectionReducer(state = initialState, action) {
	let newState = state;

	switch (action.type) {

		case socketActions.COLLECTION_ITEM_DELETED: {

			const { collectionName, data: id } = (action as socketActions.CollectionItemDeleted).payload;

			newState = { ...state }

			if (state[collectionName]) {
				const deletedMap = {};
				const newEntities = { ...state[collectionName].entities };
				delete newEntities[id];
				deletedMap[id] = true;
				const wasNotDeleted = item => !deletedMap[item._id]
				const newSorted = state[collectionName].sortedItems.filter(wasNotDeleted);
				const newAlphaSorted = state[collectionName].alphabeticSortedItems.filter(wasNotDeleted);
				newState[collectionName] = {
					...state[collectionName],
					entities: newEntities,
					sortedItems: newSorted,
					alphabeticSortedItems: newAlphaSorted,
				};
			}


			return newState;

		}
		case socketActions.COLLECTION_ITEM_CREATED:
		case socketActions.COLLECTION_ITEM_UPDATED:
		case socketActions.COLLECTION_LIST_ITEM_UPDATED:
		case socketActions.COLLECTION_LIST_UPDATED:
		case socketActions.COLLECTION_FULL_LIST_ITEM_UPDATED:
		case socketActions.COLLECTION_FULL_LIST_UPDATED: {
			const { collectionName } = action.payload;
			let data = Array.isArray(action.payload.data)
				? action.payload.data
				: [action.payload.data];
			const incomplete = action.type === socketActions.COLLECTION_LIST_UPDATED
				|| action.type === socketActions.COLLECTION_LIST_ITEM_UPDATED;

			if (collectionName === 'CmsSetting') {
				data[0].name = 'Settings';
			}

			if (collectionName === 'Survey') {
				const newSurveyEntities = {};
				const entities = { ...newState.Survey.entities };

				for (const id in newState.Survey.entities) {
					entities[id] = updateField(entities[id], 'data', [...entities[id].data]);
				}

				data = Object.values(data.reduce((acc, item) => {
					if (item.articleID in acc) {
						const surveyArticle = acc[item.articleID];

						// skip previously processed surveys
						if (!(item._id in surveyArticle.processedSurveys)) {
							surveyArticle.processedSurveys[item._id] = surveyArticle.data.length;
							surveyArticle.data.push(item);
						} else {
							surveyArticle.data[surveyArticle.processedSurveys[item._id]] = item;
						}
					} else {
						const article = newState.Article.entities[item.articleID];
						acc[item.articleID] = {
							_id: item.articleID,
							organisationID: item.organisationID,
							data: [item],
							processedSurveys: { [item._id]: 0 },
							name: article ? article.name : '!Error: article missing',
						};
					}
					return acc;
				}, entities))

				for (const dataItem of data) {
					sortListOfObjects(dataItem.data, 'changeLog.editedOn', true);
				}
			}
			if (collectionName === 'Article') {
				let updatedSurvey = false;

				data
					.filter(({_id}) => _id in state.Survey.entities)
					.forEach(({_id, name}) => {
						state.Survey.entities[_id].name = name;
						updatedSurvey = true;
					})

					if (updatedSurvey) {
						newState.Survey.alphabeticSortedItems = getSortedItems(newState.Survey, 'name', false);
					}
			}

			newState = updateField(newState, [collectionName, 'initialized'], true);

			const newEntities = data
				// Do not update entries that already have a higher version
				.map(item => {
					if (!item || !item._id) { return null; }

					item.incomplete = incomplete;

					const itemInState = state[collectionName].entities[item._id];
					let stateChangeLog = (itemInState && itemInState.changeLog) || { version: -1 };
					let itemChangeLog = item.changeLog || { version: -1 };
					const incompleteInState = !itemInState || itemInState.incomplete;
					let sameVersion = itemChangeLog.version === stateChangeLog.version;

					// also check articleID version for unified articles if the exhibit versions are equal
					if (collectionName === 'UnifiedArticle' && sameVersion) {
						const stateArticleChangeLog = (itemInState && itemInState.articleID && itemInState.articleID.changeLog) || { version: -1 };
						const itemArticleChangeLog = (item.articleID && item.articleID.changeLog) || { version: -1 };

						// if the articleID data shows a change when the exhibit data doesn't
						// use the article changeLog
						if (stateArticleChangeLog.version < itemArticleChangeLog.version) {
							stateChangeLog = stateArticleChangeLog;
							itemChangeLog = itemArticleChangeLog;
						} else {
							sameVersion = stateArticleChangeLog.version === itemArticleChangeLog.version;
						}
					}

					if (sameVersion) {
						// if the incoming data is incomplete, but the data already in the
						// state is full, then use the data in the state, but update the
						// isUpToDate field
						if (incomplete && !incompleteInState) {
							return { ...itemInState, isUpToDate: item.isUpToDate };
						}

						return item;
					}

					const olderInState = stateChangeLog.version < itemChangeLog.version;

					if ((!incomplete && incompleteInState) || olderInState) {
						return item;
					}

					// no update
					return null;
				})
				.reduce(
					(acc, entity) => {
						if (!entity) return acc;

						const versionEntities = (acc[entity._id] && acc[entity._id].versionEntities) || {};

						fillDefaults(entity, modelConfigs[collectionName].defaultData);
						acc[entity._id] = { ...entity, versionEntities };

						return acc;
					},
					{ ...state[collectionName].entities }
				);

			newState = updateField(newState, [collectionName, 'entities'], newEntities);

			if (collectionName === 'BoxxEvent') {
				const newByBoxx = {};

				data.forEach(eventData => {
					const boxxID = eventData.boxxID;

					if (!(boxxID in newByBoxx)) {
						newByBoxx[boxxID] = [...newState.BoxxEvent.byBoxx[boxxID] || []];
					}

						newByBoxx[boxxID].push(eventData);
				});

				newState = updateField(newState, ['BoxxEvent', 'byBoxx'], {
					...newState.BoxxEvent.byBoxx,
					...newByBoxx,
				});
			}

			const updatedArticles = collectionName === 'Article'
				? data
				: collectionName === 'UnifiedArticle'
					? (data && data
							.filter(ua => !!ua.articleID && typeof ua.articleID === 'object')
							.map(ua => ua.articleID))
					: null;

			// create a lookup index for article names since exhibits need names when displayed
			if (updatedArticles) {
				newState.articleID2name = updatedArticles.reduce(
					(acc, article) => {
						acc[article._id] = article.name;
						return acc;
					},
					{ ...newState.articleID2name }
				);
			}

			if (['AppLog', 'Organisation'].includes(collectionName)) {
				Object.values(newState.AppLog.entities).forEach((applog: any) => {
					const orgName = newState.Organisation.entities[applog.organisationID]
						? newState.Organisation.entities[applog.organisationID].name
						: 'no org';
					const date = moment(applog.createdOn).format('YYYY-MM-DD HH:MM');
					applog.name = `${orgName} - ${applog.platform.deviceName} ${date}`;
				});
			}

			// update the exhibit names if either exhibits or articles were updated
			if (updatedArticles || collectionName === 'Exhibit') {
				Object.values(newState.Exhibit.entities).forEach((exhibitItem: any) => {
					exhibitItem.name = newState.articleID2name[exhibitItem.articleID] || '';
				});
			}

			if (collectionName === 'UnifiedArticle') {
				Object.values(newState.UnifiedArticle.entities).forEach((unifiedarticle: any) => {
					unifiedarticle.name =
						unifiedarticle.articleID && unifiedarticle.articleID.name
							? unifiedarticle.articleID.name
							: '!Error: article missing';
				});
			}

			newState[collectionName].alphabeticSortedItems = getSortedItems(
				newState[collectionName],
				'name',
				false
			);
			newState[collectionName].sortedItems = getSortedItems(
				newState[collectionName],
				newState[collectionName].sort.objectPath,
				newState[collectionName].sort.reverse
			);

			return newState;
		}
		case socketActions.VERSION_OF_ITEM_UPDATED: {
			const { collectionName, data, versionNumber } = (action as socketActions.VersionOfItemUpdated).payload;
			// let modelEntity = state[collectionName].entities[data._id] || {
			// 	incomplete: true,
			// 	versionEntities: {},
			// 	changeLog: {},
			// };
			// modelEntity = updateField(modelEntity, ['versionEntities', versionNumber], data);
			// newState = updateField(newState, [collectionName, 'entities', data._id], modelEntity);
			// return newState;
			const modelEntity = state[collectionName].entities[data._id];
			modelEntity.versionEntities = Object.assign({}, modelEntity.versionEntities, {
				[versionNumber]: data,
			});
			return state;
		}

		case socketActions.VERSION_LIST_UPDATED:
			action.payload.data.sort((a, b) => a.version >= b.version ? 1 : -1).reverse();
			state[action.payload.collectionName].entities[action.payload.id].versions = action.payload.data;
			return state;

		case actions.FILTER_MULTI: {
			return assignStateModel(state, action, { filter: action.payload });
		}

		case actions.SORT_MULTI: {
			const { collectionName } = action;
			const sort = {
				objectPath: action.payload,
				reverse:
					state[collectionName].sort.objectPath !== action.payload
						? false
						: !state[collectionName].sort.reverse,
			};

			return assignStateModel(state, action, {
				sort,
				sortedItems: getSortedItems(newState[collectionName], sort.objectPath, sort.reverse),
			});
		}

		case currentuserActions.LOGOUT_SUCCESS:
			return Object.assign({}, initialState);

		default:
			return state;
	}
}

function assignStateModel(state, { collectionName }, props) {
	return Object.assign({}, state, {
		[collectionName]: Object.assign({}, state[collectionName], props),
	});
}

function addNames(entities, article2name) {
	return Object.values(entities).reduce((acc, entity: any) => {
		if (entity.articleID in article2name) {
			entity.name = article2name[entity.articleID];
		}
		return Object.assign(acc, { [entity._id]: entity });
	}, {});
}
