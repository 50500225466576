/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Observable } from 'rxjs';

import { schema } from '../../../models/boxx/boxxStateSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';

export const boxxStateConfig = new ModelConfig({
	collectionName: 'BoxxState',
	apiEndpoint: 'boxxState',
	schema,
});
