/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';
import { RouterModule } from '@angular/router';
import { SystempkFormComponent } from './components/systempk-form';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule
	],
	exports: [
		SystempkFormComponent,
	],
	declarations: [
		SystempkFormComponent,
	],
	entryComponents: [
		SystempkFormComponent,
	],
})
export class SystempkModule { }
