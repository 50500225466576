<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>
	<div class="first-row">
		<label i18n>Name</label>
		<input type="text" formControlName="name" i18n-placeholder placeholder="… media name">
	</div>
	<div>
		<label i18n>Tags</label>
		<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
	</div>
	<ng-container *ngIf="formGroup.value.type">
		<label i18n>Type</label>
		<div>{{formGroup.value.type}}</div>
	</ng-container>

	<div class="spacer"></div>

	<input-custom-fields [fields]="data.customFields" formControlName="customFields"></input-custom-fields>

	<div class="spacer"></div>

	<div *ngIf="isFileSizeExceeded">
		<p i18n>The file size is too big. The maximum allowed file size is less than or equal to 1GB</p>
	</div>

	<div class="spacer"></div>
	<div>
		<input-media-3d
			*ngIf="formGroup.value.type === '3DModel'"
			(fileChange)="onFileChange($event)"
			[languageOptions]="data.languageOptions"
			[type]="formGroup.value.type"
			[id]="data.model._id"
			[customFields]="data.customFieldsLanguage"
			formControlName="languageContent">
		</input-media-3d>

		<input-language-mediaobject
			*ngIf="formGroup.value.type !== '3DModel'"
			[languageOptions]="data.languageOptions"
			(fileChange)="onFileChange($event)"
			[type]="formGroup.value.type"
			[id]="data.model._id"
			[customFields]="data.customFieldsLanguage"
			formControlName="languageContent"></input-language-mediaobject>
	</div>

	<div class="spacer"></div>

	<div *ngIf="data.hasLocationNav">
		<label class="large">Locations</label>
		<input-locations
			[organisationID]="data.organisationID"
			[access]="data.access"
			formControlName="triggers"
		></input-locations>
		<div class="add-new-location" (click)="createLocationModal.open()">
			<span class="icon-add"></span>
			Create new location
		</div>
	</div>
	<form-modal #createLocationModal collectionName="Location">
		<h2>Create a new location</h2>
	</form-modal>

</ng-container>
