
	<div class="modal show">
		<div class="modal__body" (click)="$event.stopPropagation()">
			<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100" height="100">
			<h1 i18n>Maintenance mode</h1>
			<p *ngIf="!message" i18n>
				We are currently updating the CMS. Please come back later.
			</p>
			<p>
				{{message}}
			</p>
		</div>
	</div>
