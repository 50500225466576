
<div class="input-widget">
	<div class="tags">
		<div *ngFor="let ltag of value">{{ltag}} <span class="icon-clear-field_S" (click)="remove(ltag)"></span></div>
		<input
			[type]="type"
			[attr.min]="min"
			[attr.max]="max"
			[attr.step]="step"
			[attr.placeholder]="placeholder"
			[(ngModel)]="tag"
			(change)="propagateChange()"
			(input)="onInput()"
			(keydown)="onKeyDown($event)"
			(blur)="add()"/>
	</div>
	<span
		(click)="add(); $event.preventDefault()"
		class="icon-add"
		[title]="addButtonTitle"
		[ngClass]="{disable: invalid}"></span>
</div>
	