/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { traverseObject, flattenObject, getRecursivePaths } from '../../../utils';
import * as moment from 'moment';
import { parse as parseCsv } from 'json2csv';

const nestedKeyDelimeter = ':'

function objectsToCsv(arrayOfObjects) {
	const keySet = new Set();

	// get all keys
	for (const item of arrayOfObjects) {
		for (const paths of getRecursivePaths(item)) {
			keySet.add(paths.join(nestedKeyDelimeter))
		}
	}

	const fields = Array
		.from(keySet)
		.map(cleanKey)

	const rows = arrayOfObjects
		.map(item => flattenObject(item, nestedKeyDelimeter))
		.map(item => Object.keys(item).reduce((acc, field) => {
			acc[cleanKey(field)] = item[field]
			return acc;
		}, {}))

	return parseCsv(rows, {
		fields,
		defaultValue: '',
	})
}

function cleanKey(key) {
	return key.replace(/^\d{10}\d+\|/, '').trim()
}

function cleanValue(value) {
	if (typeof value !== 'string') { return value }

	return value
		.replace(/(^\|\[)|(\]$)/g, '')
		.replace(/\d{10}\d+(_\d+)?\|/g, '')
		.trim()
}

@Component({
	selector: 'surveys-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class SurveyFormComponent {
	@Input() id = '';
	@Input() data: any;
	@Input() formGroup: FormGroup;

	formattedItems: any[];

	Math = Math;

	ngOnChanges(changes) {
		if (changes.data) {
			this.formattedItems = (this.data.model.data || []).map(item => {
				const qAndAs = [];

				traverseObject(item.data, (subValue, path, next) => {
					// remove question ID
					const fieldName = path[path.length - 1];
					const question = fieldName.split(/[0-9]*[|]/, 2)[1] || fieldName;
					// for keys of nested questions, set empty answer
					const answer = typeof subValue !== 'object' || !subValue
						? subValue
						: '';

					qAndAs.push({
						depth: path.length - 1,
						question,
						answer,
					});

					next(subValue);
				});

				return {
					date: moment(item.changeLog.editedOn || item.changeLog.createdOn).format('YYYY-MM-DD HH:mm'),
					fields: qAndAs,
				};
			});
		}
	}

	downloadCsv() {
		const csv = objectsToCsv(
			(this.data.model.data || []).map(({ changeLog, data }) => ({ Date: changeLog.editedOn, ...data }))
		)

		const element = document.createElement('a');
		element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
		element.setAttribute('download', 'survey-data.csv');
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
}
