/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	dataSourceID: { type: Schema.ObjectId, ref: 'DataSource', index: true, unique: false },
	exhibitionID: {
		type: Schema.ObjectId,
		ref: 'Exhibition',
		index: true,
		sparse: true,
		unique: false,
		default: null,
	},
	name: { type: String, index: true, unique: true, required: true },
	comments: { type: String },
	chargingStatus: {
		type: String,
		enum: ['charging', 'discharging', 'full', 'unknown'],
		default: 'unknown',
		isField: true,
	},
	deviceModel: { type: String },
	deviceOS: { type: String },
	deviceOSVers: { type: String },
	appVers: { type: String },
	webClientVers: { type: String },
};
var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'LoanerDevice',
	schema: ModelSchema,
	definition: def,
};
