/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { InputLanguageMediaObjectComponent } from '../../../forminputs/input-language-mediaobject.component/input-language-mediaobject.component';
@Component({
	selector: 'media-form',
	templateUrl: './index.html',
})
export class MediaFormComponent {
	public GIGABYTE = Math.pow(1024, 3);
	public MAX_SIZE = 1 * this.GIGABYTE;
	public isFileSizeExceeded = false;
	@Input() id = '';
	@Input() data: any;
	@Input() formGroup: FormGroup;
	onFileChange(fileInfo) {
		if (fileInfo.length <= this.MAX_SIZE) {
			this.formGroup.controls['languageContent'].setValidators(null);
			this.isFileSizeExceeded = false;
			this.formGroup.patchValue({
				type: fileInfo.type,
				name: this.formGroup.value.name || fileInfo.originalName,
			});
		}else {
			this.isFileSizeExceeded = true;
			this.formGroup.controls['languageContent'].setValidators([Validators.maxLength(1)]);
		}
	}
}
