/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileDropzoneComponent } from './file-dropzone.component';
import { FileDropService } from './file-drop.service';


@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		FileDropzoneComponent,
	],
	declarations: [
		FileDropzoneComponent,
	],
	providers: [
		FileDropService,
	],
})
export class FileDropModule { }
