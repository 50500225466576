/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'boxx-dashboard-component',
	// <div *ngIf="data.boxxStates[boxx._id]" class="boxx-state">
	// </div>
	template: `
<div class="boxx-dashboard">
	<h1>Boxx Dashboard</h1>
	<div *ngFor="let org of boxxesDataGrouped">
		<h3>{{org.name}}</h3>
		<div class="boxx-dashboard__list">
			<div *ngFor="let boxx of org.boxxes">
				<div
					class="boxx-status"
					[ngClass]="boxx.state.status"
					[title]="boxx.state.status">
				</div>
				<div class="dashboard-box first-box">
					<div>
						<a [routerLink]="'/admin/boxx/edit/'+boxx._id" class="boxx-name">{{boxx.name}}</a>
						<div class="mac-address">{{boxx.macAddress}}</div>
					</div>
					<div>
						<div class="boxx-type">{{boxx.specificConfig.boxxType}}</div>
						<div class="hardware-model">{{boxx.hardware.model}}</div>
					</div>
					<div class="dashboard-comments" [attr.title]="boxx.comments">
						{{ boxx.comments }}
					</div>
				</div>
				<div class="dashboard-box">
					<label>Tbp</label>
					<div>{{boxx.specificConfig.talkBackPort}}</div>
				</div>
				<div class="dashboard-box">
					<label i18n>Load</label>
					<div>
						<span
							class="boxx-load"
							[ngClass]="{'boxx--on': boxx.state.load <= boxx.specificConfig.workloadThreshold}">
								{{boxx.state.load}}
						</span>
						/ {{boxx.specificConfig.workloadThreshold}}
					</div>
				</div>
				<div class="dashboard-box">
					<label>Stor</label>
					<div>…</div>
				</div>
				<div class="dashboard-box">
					<label>❤ Beat</label>
					<div class="duty">{{boxx.state.heartbeatDay}}<br />{{boxx.state.heartbeatTime}}</div>
				</div>
				<div class="dashboard-box">
					<label>Duty</label>
					<div class="duty">{{dateToTime(boxx?.config?.duty?.from)}}<br />{{dateToTime(boxx?.config?.duty?.to)}}</div>
				</div>
				<div class="dashboard-box">
					<label>QOS</label>
					<div class="qos">
						<span [ngClass]="{'boxx--on': boxx.config.enableQoS === true}">2</span>
						<span [ngClass]="{'boxx--on': boxx.config.enableQoS5 === true}">5</span>
						<span [ngClass]="{'boxx--on': boxx.config.enableQoSMesh === true}">M</span>
					</div>
				</div>
				<div class="dashboard-box">
					<label>Chan</label>
					<div>{{boxx.config.channel || '…'}} {{boxx.config.channel5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>Pwr</label>
					<div>{{boxx.config.tXDbm || '…'}} {{boxx.config.tXDbm5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>HTmode</label>
					<div class="htmode">{{boxx.config.htmode || '…'}} {{boxx.config.htmode5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>SSID</label>
					<div class="duty">{{boxx.config.ssid}}<br />{{boxx.config.ssid5}}</div>
				</div>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxxDashboardComponent {
	@Input() data: any;

	public boxxesDataGrouped = [];

	ngOnChanges() {
		const boxxIndex = this.data.boxxes
			.map(boxx => {
				const newBoxxData = { ...boxx };
				// Exhbition config or boxx config that overwrites it
				const oc = boxx.overWriteExhibitionConfig;
				const keys = [
					'duty',
					'ssid',
					'ssid5',
					'htmode',
					'htmode5',
					'enableQoS',
					'enableQoS5',
					'enableQoSMesh',
					'tXDbm',
					'tXDbm5',
					'channel',
					'channel5',
				];
				if (boxx._id in this.data.datasources) {
					const dc = this.data.datasources[boxx._id];
					const boxxData = keys.reduce((acc, key) => ({ ...acc, [key]: dc[key] }), {});
					keys.forEach(key => {
						if (oc[key] !== undefined && oc[key] !== null) boxxData[key] = oc[key];
					});
					newBoxxData.config = boxxData;
				} else {
					newBoxxData.config = keys.reduce((acc, key) => ({ ...acc, [key]: oc[key] }), {});
				}
				// Get state data for box
				const state = this.data.boxxStates[boxx._id];
				if (!state) {
					newBoxxData.state = {
						load: '…',
						status: 'unkown',
						lastHeartbeat: '…',
						loadAverage: '…',
					};
				} else {
					const heartbeatDate = new Date(state.lastHeartbeat);
					// "uptime": " 15:41:01 up  6:01,  load average: 0.14, 0.12, 0.09",
					let load = '…';
					if (state.uptime) {
						const loadAverage = state.uptime.split('load average: ')[1];
						load = (loadAverage || '').split(',')[2];
					}
					newBoxxData.state = {
						load: load || '…',
						status: state.status,
						heartbeatDay: `${zeroPad(heartbeatDate.getDate(), 2)}.${zeroPad(
							heartbeatDate.getMonth() + 1,
							2
						)}.`,
						heartbeatTime: `${zeroPad(heartbeatDate.getHours(), 2)}:${zeroPad(
							heartbeatDate.getMinutes(),
							2
						)}`,
					};
				}
				return newBoxxData;
			})
			.reduce((acc, boxx) => {
				if (boxx.organisationID in acc) acc[boxx.organisationID].push(boxx);
				else acc[boxx.organisationID] = [boxx];
				return acc;
			}, {});

		this.data.organisations.sort((a, b) => (a.name > b.name ? -1 : 1));
		this.boxxesDataGrouped = this.data.organisations.map(({ name, _id }) => ({
			name,
			boxxes: boxxIndex[_id],
		})).filter(({boxxes}) => boxxes && boxxes.length);
	}

	dateToTime(dateString) {
		const date = new Date(dateString);
		return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
	}
}

function zeroPad(num, size) {
	const s = `000000000${num}`;
	return s.substr(s.length - size);
}
