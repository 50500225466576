/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export const info = `<div>
	An exhibition is a collection of <a href="/exhibit">exhibits</a>.

	<br>
	<br>

	If your organisation has more than one exhibition, visitors will be able to
	choose one from a list which shows the cover image, title and description in
	their selected language.
</div>`;
