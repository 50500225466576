
	<modal-component
		#modal
		(openEvent)="onOpen()"
		(openClose)="onClose()">

		<header>
			<ng-content select="header"></ng-content>
		</header>

		<dynamic-model-form
			#dynamicModelForm
			[collectionName]="modal.isOpen() ? collectionName : null"
			[id]="id"
			[extendDefaultData]="extendDefaultData"
			[data]="data$ | async"
			slot="body">
			<ng-content></ng-content>
		</dynamic-model-form>

		<footer>
			<model-edit-footer
				#footer
				(saveEvent)="this.close(); this.saveEvent.emit($event)"
				(closeEvent)="this.close()"
				[formGroup]="dynamicModelForm.formGroup"
				[model]="dynamicModelForm.modelConfig"
				[id]="dynamicModelForm.id"
				[data]="dynamicModelForm.data">
			<ng-content select="footer"></ng-content>
			</model-edit-footer>
		</footer>

	</modal-component>
	