/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { definition, nHTModes, acHTModes } from '../../../../models/boxx/boxxConfigSchema';

@Component({
	selector: 'boxx-config-form',
	templateUrl: './boxx-config-form.component.html',
	styleUrls: ['./boxx-config-form.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxxConfigFormComponent {
	@Input() group: FormGroup;
	@Input() hasDisable;
	@Input() boxxType;
	@Input() dataSourceSettings;

	public nHTModeOptions = Object.entries(nHTModes).map(([value, label]) => ({value, label}));
	public acHTModeOptions = Object.entries(acHTModes).map(([value, label]) => ({value, label}));

	public definition = definition;

	showForBoxxtypes(arrayOfBoxxtypes) {
		let found = false;

		arrayOfBoxxtypes.forEach(boxxtype => {
			if (this.boxxType === boxxtype) {
				found = true;
			}
		});

		return found;
	}
}
