/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable, Subject, merge } from 'rxjs';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Component, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../reducers';
import { pluckDistinct } from '../../utils';
import * as socketActions from '../../socket.actions';
import { navigationItems } from '../../../../models/organisation/organisationSchema';

const dependenciesFull = ['Organisation'];
const dependenciesMinimal = ['Location', 'Tour', 'Ticket', 'CalendarEvent', 'Survey', 'GlossaryTerm'];

@Component({
	selector: 'admin-dashboard',
	template: `
		<div>
			<admin-dashboard-component [data]="data$ | async"></admin-dashboard-component>
		</div>
	`,
})
export class AdminDashboardContainer {
	public data$;

	constructor(private store: Store<AppState>) {
		dependenciesFull.forEach((collectionName) => {
			this.store.dispatch(new socketActions.GetCollectionFullList({ collectionName }));
		});
		dependenciesMinimal.forEach((collectionName) => {
			this.store.dispatch(new socketActions.GetCollectionList({ collectionName }));
		});

		// survey, playlist, map, tour, quiz, ticketing, glossary, calendar
		this.data$ = observableCombineLatest(
			pluckDistinct(store, 'models', 'Organisation', 'entities').pipe(
				map((entities) => Object.values(entities))
			),
			pluckDistinct(store, 'models', 'Location', 'entities').pipe(map((entities) => Object.values(entities))),
			pluckDistinct(store, 'models', 'Tour', 'entities').pipe(map((entities) => Object.values(entities))),
			pluckDistinct(store, 'models', 'Ticket', 'entities').pipe(map((entities) => Object.values(entities))),
			pluckDistinct(store, 'models', 'CalendarEvent', 'entities').pipe(
				map((entities) => Object.values(entities))
			),
			pluckDistinct(store, 'models', 'Survey', 'entities').pipe(map((entities) => Object.values(entities))),
			pluckDistinct(store, 'models', 'GlossaryTerm', 'entities').pipe(map((entities) => Object.values(entities))),

			(_organisations: any, locations, tours, tickets, calendarEvents, surveyResults, glossaryTerms) => {
				const usageItemsMap = {
					location: locations,
					// playlist: 'playlist',
					tickets: tickets,
					surveys: surveyResults,
					calendar: calendarEvents,
					glossary: glossaryTerms,
					tour: tours,
					quiz: tours,
				};
				const _navItems = Object.values(navigationItems);

				const oneMonthPrior = new Date();
				oneMonthPrior.setMonth(oneMonthPrior.getMonth() - 1);

				const organisations = _organisations.map(({ _id, name, enableNavUi }) => ({
					name,
					features: _navItems.map((itemName) => {
						let usage = 0;
						let usageLastMonth = 0;
						if (itemName in usageItemsMap) {
							const items = usageItemsMap[itemName].filter(({ organisationID }) => organisationID === _id);
							if (itemName === 'tour') {
								usage = items.filter(({ isQuiz }) => !isQuiz).length;
								usageLastMonth = items.filter(
									({ isQuiz, changeLog }) =>
										!isQuiz && Date.parse(changeLog.editedOn) > oneMonthPrior.getTime()
								).length;
							} else if (itemName === 'quiz') {
								usage = items.filter(({ isQuiz }) => isQuiz).length;
								usageLastMonth = items.filter(
									({ isQuiz, changeLog }) =>
										isQuiz && Date.parse(changeLog.editedOn) > oneMonthPrior.getTime()
								).length;
							} else {
								usage = items.length;
								usageLastMonth = items.filter(
									({ changeLog }) => Date.parse(changeLog.editedOn) > oneMonthPrior.getTime()
								).length;
							}
						}
						return { name: itemName, _id, enabled: enableNavUi.includes(itemName), usage, usageLastMonth };
					}),
				}));
				const features = _navItems.map((itemName) => ({
					name: itemName,
					organisations: organisations.filter(({ features }) =>
						features.some((feature) => itemName === feature.name && feature.enabled)
					),
				}));
				// features.sort((a, b) => a.organisations.length < b.organisations.length ? 1 : -1)

				return {
					organisations,
					features,
				};
			}
		);
	}

	ngOnDestroy() {
		[...dependenciesFull, ...dependenciesMinimal].forEach((collectionName) => {
			this.store.dispatch(new socketActions.LeaveCollectionList({ collectionName }));
		});
	}
}
