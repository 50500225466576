/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema } from '../../../models/ticket/ticketSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { TicketFormComponent } from './components/ticket-form';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const ticketConfig = new ModelConfig({
	collectionName: 'Ticket',
	clientPathPrefix: '/admin',

	dependencies: [
		{ name: 'DataSource' },
	],

	schema,

	formComponent: TicketFormComponent,
});

Object.assign(ticketConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'DataSource').pipe(map(selectors.getAlphabeticList)),
			(model, datasources) => ({ model, datasources }),
		);
	},

	getItemTitle(item) {
		return item.code;
	},
});
