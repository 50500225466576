/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { definition as schema, landingModes } from '../../../models/dataSource/dataSourceSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { DatasourceFormComponent } from './components/datasource-form.component';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const datasourceConfig = new ModelConfig({
	name: 'app',
	namePlural: 'apps',
	icon: 'icon-smartphone-tablet',
	collectionName: 'DataSource',
	clientPathPrefix: '',
	dependencies: [
		{ name: 'Exhibit', inSidebar: false },
		{ name: 'Article', inSidebar: false },
		{ name: 'Location', inSidebar: false },
		{ name: 'Exhibition', inSidebar: false },
		{ name: 'UnifiedArticle', inSidebar: false },
		{ name: 'Tour', inSidebar: false },
		{ name: 'ArticleList', inSidebar: false },
		{ name: 'Boxx', inSidebar: false },
	],
	schema,
	formComponent: DatasourceFormComponent,
	publishable: true,
});

Object.assign(datasourceConfig, {
	getFormData$(store, id, versionId?) {
		const models$ = pluckDistinct(store, 'models');

		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(models$, 'DataSource', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Exhibit', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Article', 'entities').pipe(
				map(entities =>
					Object.values(entities).filter(({ type }) => ['custom-map', 'distortable-image-map'].includes(type))
				)
			),
			pluckDistinct(models$, 'Organisation', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Location', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Exhibition', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'UnifiedArticle', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Tour', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'ArticleList', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Boxx', 'entities'),
			pluckDistinct(store, 'currentuser'),
			(
				model: any,
				datasources: any,
				exhibits: any[],
				customMapAricles: any[],
				organisations,
				locationEntitiesList,
				exhibitions: any[],
				unifiedArticles: any[],
				tours: any[],
				articleLists: any[],
				boxxes: any,
				currentUser: any
			) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;

				// FIXME edit ng-select so it accepts number as an input value
				if (typeof model.boxxesConfig.channel === 'number') {
					model.boxxesConfig.channel = `${model.boxxesConfig.channel}`;
				}
				if (typeof model.boxxesConfig.channel5 === 'number') {
					model.boxxesConfig.channel5 = `${model.boxxesConfig.channel5}`;
				}

				return {
					model,
					currentUser,
					hasPwaFeature: model.organisationID && organisations.some(({_id, enableNavUi}) => _id === model.organisationID && enableNavUi.includes('progressive web app')),
					customMapAricles: organisationID
						? customMapAricles.filter(ex => ex.organisationID === organisationID)
						: customMapAricles,
					exhibits: organisationID ? exhibits.filter(ex => ex.organisationID === organisationID && ex.isLive) : exhibits.filter(({ isLive }) => isLive),
					exhibitions: id ? exhibitions.filter(({dataSourceID}) => dataSourceID === id) : [],
					unifiedArticles,
					tours,
					boxxes,
					articleLists,
					datasources: datasources.filter(({ _id, type }) => _id !== this.id && type !== 'sub'),
					landingModes: datasourceConfig.schema.landingMode.enum.map(value => ({
						value,
						label: landingModes[value],
					})),
					polygonLocations: locationEntitiesList.filter(
						location => (!organisationID || location.organisationID === organisationID) && location.type === 'geoLocation' && location.geoLocation.shape === 'polygon'
					),
					organisationID,
					hasAutoPublishingFeature: model.organisationID && organisations.some(({_id, enableNavUi}) => _id === model.organisationID && enableNavUi.includes('scheduler for publishing')),
				};
			}
		);
	},
	getPublishEnabled(item: any, data: any) {
		return item && !item.isUpToDate;
	},
});
