/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { uid } from '../../utils';

@Component({
	selector: 'overwrite-context-component',
	styleUrls: ['./overwrite-context.component.sass'],
	template: `
<div [formGroup]="form" class="overwrite-context">
	<div *ngIf="id && hasKey(form.value, 'organisationID') && data.organisation._id != form.value.organisationID">

		<div *ngIf="preventChange && updateOwnerOrgName(form.value)">
			<ng-container i18n>Belongs to</ng-container> <i> {{ ownerOrgName }}.</i>
			<br><br>
		</div>

		<ng-container *ngIf="!preventChange">
			<input
				type="checkbox"
				[attr.id]="checkboxId"
				(click)="updateForm()">
				<label [attr.for]="checkboxId">
					<span>
					<ng-container *ngIf="updateOwnerOrgName(form.value)"><ng-container i18n>Belongs to</ng-container> <i> {{ ownerOrgName }}.</i> </ng-container>
					<ng-container i18n>Click to move to</ng-container> <i>{{data.organisation.name || 'No Organisation'}}.</i>
					</span>
				</label>
			<input type="hidden" formControlName="organisationID">
		</ng-container>
	</div>
</div>
`,
})
export class OverwriteContextComponent {
	@Input() form;
	@Input() data;
	@Input() id;
	@Input() preventChange = false;

	public ownerOrgName = '';
	public checkboxId = `orgidover-${uid()}`;

	constructor(public i18n: I18n) {}

	hasKey(obj, key) {
		return key in obj;
	}
	ngOnChanges(changes) {
		if ((changes.data || changes.id) && !this.id && this.form) {
			if (this.hasKey(this.form.value, 'organisationID')) {
				this.form.patchValue({ organisationID: this.data.organisation._id });
			}
		}
	}
	updateForm() {
		if (this.hasKey(this.form.value, 'organisationID') && this.data.organisation._id !== this.form.value.organisationID) {
			this.form.patchValue({
				organisationID: (document.getElementById(this.checkboxId) as any).checked
					? this.data.organisation._id || null
					: this.form.value.organisationID,
			});
			this.form.markAsDirty();
		}
	}

	updateOwnerOrgName(formValue) {
		const ownerOrgId = this.form.value.organisationID;
		const ownerOrg = this.data.organisations[ownerOrgId];

		this.ownerOrgName = ownerOrgId
			? ownerOrg ? ownerOrg.name : ''
			: this.i18n('No organisation');

		return this.ownerOrgName;
	}
}
