
<div class="list">
	<div *ngFor="let navItem of value; let i = index" >
		<span> {{navItem}} </span>
		<span class="icon-remove" (click)="remove(navItem)"></span>
	</div>
</div>
<div class="input-widget">
	<ng-select
		(selected)="add($event)"
		[options]="navigationOptions"
		i18n-placeholder
		placeholder="… select feature"></ng-select>
</div>
	