/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { definition as schema, labels } from '../../../models/exhibition/exhibitionSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { ExhibitionFormComponent } from './components/exhibition-form.component';
import { selectors } from '../selectors';
import { info } from './info';
import { isoLang2name, fixLanguageContent, pluckDistinct, setItemFieldDefault } from '../utils';
import { map } from 'rxjs/operators';

export const exhibitionConfig = new ModelConfig({
	collectionName: 'Exhibition',
	icon: 'icon-spotlight_L',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'UnifiedArticle', inSidebar: true },
		{ name: 'Article', inSidebar: true },
		{ name: 'Location', inSidebar: true },
		{ name: 'DataSource', inSidebar: false },
		{ name: 'StyleTemplate', inSidebar: false },
		{ name: 'MediaObject', inSidebar: true },
		{ name: 'Organisation', inSidebar: false },
		{ name: 'Tour', inSidebar: false },
	],

	schema,
	labels,

	initialState: {
		sort: {
			objectPath: 'changeLog.editedOn',
			reverse: false,
			sortedItems: [],
			alphabeticSortedItems: [],
		},
	},
	publishable: true,

	formComponent: ExhibitionFormComponent,
	infoHtml: info,
});

Object.assign(exhibitionConfig, {
	getFormData$(store, id, versionId?) {
		const models$ = pluckDistinct(store, 'models');

		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(models$, 'DataSource', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'StyleTemplate').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(models$, 'UnifiedArticle', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(models$, 'Organisation').pipe(map(selectors.getEntities)),
			pluckDistinct(models$, 'Location').pipe(map(selectors.getEntities)),
			pluckDistinct(models$, 'Tour', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(models$, 'Article', 'entities').pipe(
				map(entities =>
					Object.values(entities).filter(({ type }) => ['custom-map', 'distortable-image-map'].includes(type))
				)
			)
		).pipe(
			map(
				([
					model,
					dataSources,
					styleTemplates,
					unifiedArticles,
					organisationEntities,
					locationEntities,
					tourEntities,
					currentUser,
					customMapAricles,
				]) => {
					const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
					const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
					const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
					const languageOptions = (languages || []).map(value => ({
						value: `${value}`,
						label: `${isoLang2name[value]}`,
					}));
					const languageOptions2 = languageOptions.map(v => ({ _id: v.value, name: v.label }));
					const showShareOption = modelOrg
						? modelOrg.showShareOption
						: currentUser.organisation.showShareOption;
					unifiedArticles = unifiedArticles.filter(ua => ua.exhibitionID === model._id);

					if (!id) {
						setItemFieldDefault(model, 'defaultLanguage', languageOptions2);
						setItemFieldDefault(model, 'landingExhibitID', unifiedArticles);
					}

					model.languageContent = fixLanguageContent({
						languageOptions,
						languageContent: model.languageContent,
					});

					return {
						model,
						dataSources: organisationID
							? dataSources.filter(ds => ds.organisationID === organisationID)
							: dataSources,
						modelDataSource: dataSources.find(({ _id }) => _id === model.dataSourceID) || { type: '' },
						styleTemplates: modelOrg
							? styleTemplates.filter(st => modelOrg.styleTemplates.includes(st._id))
							: styleTemplates,
						orgStyleTemplates: modelOrg ? modelOrg.styleTemplates : [],
						hasSiteId: modelOrg && !modelOrg.analyticsForOrganisation,
						hasLocationNav: modelOrg && modelOrg.enableNavUi.includes('location'),
						access: currentUser.access,
						unifiedArticles,
						liveArticles: unifiedArticles.filter(({ isLive }) => isLive),
						currentUser,
						languageOptions,
						languageOptions2,
						organisationID,
						locationEntities,
						polygonLocations: Object.values(locationEntities).filter(
							(location: any) =>
								(!organisationID || location.organisationID === organisationID) &&
								location.type === 'geoLocation' &&
								location[location.type].shape === 'polygon'
						),
						showShareOption,
						tourEntities: id ? tourEntities.filter(({ exhibitionID }) => exhibitionID === id) : tourEntities,
						customMapAricles: organisationID
							? customMapAricles.filter(ex => ex.organisationID === organisationID)
							: customMapAricles,
					};
				}
			)
		);
	},
	beforeSave(formGroup, data) {
		if (!formGroup.value.dataSourceID && data.dataSources[0]) {
			formGroup.patchValue({ dataSourceID: data.dataSources[0]._id });
		}
		if (!formGroup.value.styleTemplateID && data.orgStyleTemplates) {
			formGroup.patchValue({ styleTemplateID: data.orgStyleTemplates[0] });
		}
		if (formGroup.value.styleTemplateID === 'undefined') {
			formGroup.patchValue({ styleTemplateID: null });
		}
		return true;
	},
});
