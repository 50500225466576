/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint no-cond-assign: "off", no-unused-expressions: "off" */
import { Directive, Input } from '@angular/core';

// ## Mask Input
// Format input according to specified mask while the user is typing
// usage:
// ```
// <input type="text" mask="##:##:##:##:##:##">
// ```
// the `mask` property triggers this directive. Masks only use `#` as wildcard
// character. Is should be escabable, this is however untested.
@Directive({
	selector: 'input[mask]',
	// properties: ['mask: mask'],
	host: {
		'(input)': 'transform($event)',
	},
})
export class InputMaskDirective {
	@Input() mask: string;

	/**
	 * ### transform
	 * Here we read the input value and set it after transforming this,
	 * this function is need to create a easibly testable class.
	 * @param {Event} event the keyup event from the Input
	 */
	transform(event) {
		const newVal = this.maskInput(event.target.value);
		if (newVal && event.target.value !== newVal) {
			event.target.value = newVal;
		}
	}
	/**
	 * ### maskInput
	 * This is the actual mask input transformation function. It will only work if
	 * `this.maks` is set to a mask.
	 * @param {String} value string that should be transformed
	 * @return {String} transformed string
	 */
	maskInput(value) {
		if (!value) return '';
		// + Carlos R. L. Rodrigues
		// @ http://jsfromhell.com/string/mask [rev. #1]
		// modified by Falko github.com/select
		const m = this.mask.split('');
		const l = m.length;
		const s = value.split('');
		let j = 0;
		let h = '';
		for (let i = -1; ++i < l;) {
			if (m[i] !== '#') {
				if (m[i] === '\\' && (h += m[++i])) continue;
				h += m[i];
				if (s[j] === h) {
					h = '';
					j++;
					continue;
				}
				i + 1 === l && (s[j - 1] += h, h = '');
			} else {
				if (!s[j] && !(h = '')) break;
				(s[j] = h + s[j++]) && (h = '');
			}
		}
		const out = s.join('') + h;
		return out.replace(/[^\w:]/, '')
	}
}
