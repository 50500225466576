/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	boxxType: {
		type: String,
		lowercase: true,
		default: 'child',
		enum: ['mother', 'child', 'hub', 'mediaplayer', 'gate', 'externalmediaplayer', 'ticketprinter'],
		alias: 'boxType',
		required: true,
	},
	talkBackPort: {
		type: Number,
		default: null,
		index: true,
		unique: false,
		sparse: true,
		softUnique: true,
	},
	// FIXME the default threshold value should be derived from
	// `harwareSchema.boxxModelInfo` and the default value of
	// `harwareSchema.definition.model`
	workloadThreshold: { type: Number, default: 1 },
	disableMesh: { type: Boolean, default: false },
	privateLANStaticIP: { type: String, default: null },
	webstationURL: { type: String, default: null },
	triggerhappydmxMode: {
		type: String,
		lowercase: true,
		default: 'none',
		enum: ['none', 'artnet_in', 'artnet_in_attachedonly', 'cmd_out', 'cmd_out_drive'],
	},
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
