
		<div class="exhibition-preview" *ngIf="id && !!getPreviewUrl()">
			<div class="modes">
				<button
					type="button"
					(click)="showVersion = 'latest'"
					[ngClass]="{ 'button--blue-ghost': showVersion != 'latest' }"
					i18n
				>
					latest
				</button>
				<button
					type="button"
					(click)="showVersion = 'published'"
					[ngClass]="{ 'button--blue-ghost': showVersion != 'published' }"
					i18n
				>
					published
				</button>
			</div>
			<div class="exhibition-preview__controls">
				<span class="icon-left-arrow_L" (click)="increaseDeviceIndex(-1)"></span>
				<div>{{ devices[currentDevice].name }}</div>
				<span class="icon-right-arrow_L" (click)="increaseDeviceIndex(1)"></span>
			</div>
			<div class="phone">
				<div #previewFrame class="preview-frame">
					<iframe
						class="exhibition-preview__iframe"
						[src]="sanitizer.bypassSecurityTrustResourceUrl(getPreviewUrl())"
						allowfullscreen="false"
						frameborder="0"
					></iframe>
				</div>
				<img [src]="'/assets/img/' + devices[currentDevice].name + '.svg'" alt="phone frame" />
			</div>
		</div>
	