/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('../schemaWrapper');
var getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	exhibitionID: {
		type: Schema.ObjectId,
		ref: 'Exhibition',
		index: true,
		unique: false,
		required: true,
		label: 'Exhibition',
	},
	immortal: { type: Boolean, default: false },
	articleID: { type: Schema.ObjectId, ref: 'Article', required: true, label: 'Article' },
	articleIDVersion: { type: Number, writable: false },
	mediaFiles: [{ type: Schema.ObjectId, writable: false }], // FIXME this field is not filled yet
	codes: [{ type: Number }],
	isInExhibitList: { type: Boolean, default: true },
	triggers: [
		{
			locationID: { type: Schema.ObjectId, ref: 'Location' },
			triggerType: {
				type: String,
				enum: ['trigger', 'forceTrigger', 'forceOneShotTrigger'],
				default: 'trigger',
			},
		},
	],
	openInModal: { type: Boolean, default: false },
	tags: [{ type: String }],
	isLive: { type: Boolean, default: false },
	isUpToDate: { type: Boolean, default: false, writable: false },

	publishedVersions: [
		{
			writable: false,
			version: { type: Number },
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
			articleID: { type: Schema.ObjectId, ref: 'Article' },
			articleVersion: { type: Number, alias: 'articleIDVersion' },
			mediaObjects: [
				{
					id: { type: Schema.ObjectId, ref: 'MediaObject' },
					version: { type: Number },
				},
			],
			mediaFiles: [{ type: Schema.ObjectId }],
			originalMediaFiles: [{ type: Schema.ObjectId }],
			locations: [
				// TODO this is not use yet
				{
					id: { type: Schema.ObjectId, ref: 'Location' },
					version: { type: Number },
				},
			],
			packages: [{ type: String }],
		},
	], // Sorted List of Version which have been published

	code: { type: Number },
	locationIDs: [{ type: Schema.ObjectId, ref: 'Location' }],
};
var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Exhibition',
	labels: getLabels(schemaDefinition),
	schema: ModelSchema,
	definition: schemaDefinition,
};
