/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {pluck, filter} from 'rxjs/operators';
import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { Router } from '@angular/router';
import { messageActions } from '../../core/_messages/actions';

import { actions } from '../../actions';
import * as SocketActions from '../../socket.actions';
import { getValidators, removeNonWritableFields } from '../../services/schemaTools';
import { ModelConfig } from '../../modelConfigs/ModelConfig';
import { getSaveResult$ } from '../../utils';

@Component({
	selector: 'model-edit-footer',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
	preserveWhitespaces: true,
})
export class ModelEditFooterComponent {
	@Input() id: string = null;
	@Input() cloneId: string = null;
	@Input() model: ModelConfig;
	@Input() data: any;
	@Input() formGroup: FormGroup;
	@Input() overwriteContext = false;
	@Input() versionId: string;
	@Input() versionIdHuman: number;
	@Input() uploading: boolean;

	@Output() events = new EventEmitter<any>();
	@Output() saveEvent = this.events.pipe(filter(event => !/FAIL|ERROR/.test(event.type)));
	@Output() errorEvent = this.events.pipe(filter(event => /FAIL|ERROR/.test(event.type)));
	@Output() closeEvent = new EventEmitter<any>();
	public collectionNameRoute = '';

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		public router: Router,
	) {
		store.pipe(pluck('routeInfo', 'collectionName')).subscribe((collectionName: string) => {
			this.collectionNameRoute = collectionName;
		})
	}

	ngOnChanges(changes) {
		if (changes.cloneId && this.cloneId) {
			this.formGroup.markAsDirty();
		}
	}
	deleteItem(collectionName: string, id: string) {
		this.store.dispatch(new SocketActions.DeleteCollectionItem({ collectionName, id }));
	}

	// returns true if the save was attempted, false if model.beforeSave()
	// returned false or the form is invalid
	//
	// TODO: consider making an ItemSaveService with a save method similar to the
	// one below. ModelConfig can have a `save(itemSaveService, item)` method
	// which by default simply calls itemSaveService.save(item). For more
	// complicated things like unifiedarticle, a speccialized
	// unifiedarticleConfig.save method can be provided.
	// OR consider making the ModelConfig stuff Servicey
	save({
		formGroup = this.formGroup,
		formData = this.data,
		collectionName = this.model.collectionName,
		id = this.id,
		revertVersion = false,
		versionNumber = 0,
	} = {}) {
		// if model.beforeSave is a function and it returns false, don't try to save
		const beforeSaveResult = this.model.beforeSave && this.model.beforeSave(formGroup, formData, this);

		if (beforeSaveResult === false || !formGroup.valid || this.uploading) {
			return false;
		}

		const data = {
			...(formData.model || formData),
			id: id,
			...formGroup.value,
		};

		removeNonWritableFields(data, this.model.schema);

		const saveAction = revertVersion
			? new SocketActions.RequestRevertToVersionOfItem({ collectionName, id, versionNumber })
			: id
				? new SocketActions.UpdateCollectionItem({ collectionName, id, data })
				: new SocketActions.CreateCollectionItem({ collectionName, data });

		this.store.dispatch(saveAction);

		formGroup.markAsPristine();

		getSaveResult$(this.actions$, collectionName, data.id, revertVersion)
			.subscribe(result => this.events.emit(result));

		return true;
	}

	popupInvalidInpusText() {
		const message = this.invalidInputsText();
		if (message) this.store.dispatch(messageActions.error(message));
	}

	invalidInputsText(formGroup = this.formGroup, labels = this.model.labels, invalidFields = []) {
		if (this.uploading) {
			return 'Please wait until all uploads are complete before saving.';
		}

		invalidFields = Object.entries(formGroup.controls).reduce((acc: string[], [fieldName, field]) => {
			if (field instanceof FormGroup) {
				return this.invalidInputsText(field, labels[fieldName] || {}, acc);
			}

			if (field.invalid) acc.push(labels[fieldName] || fieldName);

			return acc;
		}, invalidFields) as any[];

		if (formGroup !== this.formGroup) {
			return invalidFields;
		}

		if (!invalidFields.length) return '';
		return `
			<p>This item can't be saved until all fields have been completed.</p>
			<p>The missing ${invalidFields.length === 1 ? 'field is' : 'fields are'}:</p>
			<ul>
				${invalidFields.map(field => `<li>${field}</li>`).join('')}
			</ul>`;
	}

	onPublishClick(event) {
		event.preventDefault();
		this.store.dispatch(new SocketActions.PublishChanges({ collectionName: this.model.collectionName, id: this.id }));
	}
	close() {
		this.closeEvent.emit();
	}
}
// d3 x-ais going top in responsive
