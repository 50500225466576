
<div class="wrapper">
	<table>
		<thead>
			<tr>
				<th i18n>DEVICE NAME</th>
				<th i18n i18n-title title="The views of the page by all visitors on a certain Device.">PAGEVIEWS</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let entry of data">
				<td>{{entry.label}}</td>
				<td>{{entry.nb_visits}}</td>
			</tr>
		</tbody>
	</table>
</div>
	