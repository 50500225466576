/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	customMapZip: { type: Schema.ObjectId },
	mapMetaData: {
		maxZoom: { type: Number },
		minZoom: { type: Number, default: 0 },
		bounds: { type: [[Number]] },
		center: { type: [Number] },
		attribution: { type: String },
		crs: { type: String },
		layers: [{
			maxZoom: { type: Number },
			minZoom: { type: Number, default: 0 },
			bounds: { type: [[Number]] },
			fileExt: { type: String },
			width: { type: Number },
			height: { type: Number },
			mimeType: { type: String },
			templateUrl: { type: String },
		}],
		mapLayerToExhibitionId: [{
			type: Schema.ObjectId,
			ref: 'Exhibition',
		}],
		// distortableImageCorners - the image that is used is the cover image of this article
		// format: `[{lat: ..., lng: ...}, ...]` NE, NW, SE, SW
		distortableImageCorners: { type: Object },
	},
	files: [{
		path: { type: String },
		file: { type: Schema.ObjectId },
	}],
	// FIXME legacy remove when all webclients are updated
	// now located in mapMetaData
	mapLayerToExhibitionId: [{
		type: Schema.ObjectId,
		ref: 'Exhibition',
	}],
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
