/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { messageActions } from '../../core/_messages';
import { InputLanguageComponent } from '../input-language.component/input-language.component';
import { uid } from '../../utils';

@Component({
	selector: 'input-text-list',
	template: `
<div *ngIf="hasDisable">
	<input
		type="checkbox"
		class="btn-checkbox"
		attr.id="{{inputUid}}-checkbox"
		(change)="handleCheckbox($event.target.checked)"
		[checked]="value != null">
	<label attr.for="{{inputUid}}-checkbox"></label>
</div>
<div class="list">
	<div *ngFor="let item of valueAsList">{{item}}<span class="icon-remove" (click)="remove(item)"></span></div>
</div>
<div class="input-widget">
	<input type="text" [(ngModel)]="url" [disabled]="disabled || (value == null && hasDisable)"/>
	<button class="button" (click)="add($event)" [disabled]="disabled || (value == null && hasDisable)" i18n>add</button>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextListComponent), multi: true }],
})
export class InputTextListComponent extends CustomValueAccessor {
	@Input() hasDisable;
	@Input() disabled;
	@Input() validation;
	@Output() change = new EventEmitter(false);

	public url: String;
	public inputUid = uid();
	private hostNameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);
	}

	ngOnInit() {
		this.value = '';
	}

	handleCheckbox(checkState) {
		if (!checkState) this.value = null;
		else this.value = '';
	}

	get valueAsList() {
		return this.value ? this.value.split(' ') : [];
	}
	add($event) {
		const valueList = [...this.valueAsList]

		switch (this.validation) {
			case 'hostname':
				if (!this.hostNameRegex.test(this.url.toString())) {
					this.store.dispatch(messageActions.error(`${this.i18n('Please enter a valid hostname')}`));
					return;
				}
				break;
			default:
				break;
		}
		valueList.push(this.url);
		this.value = valueList.join(' ')
		$event.preventDefault();
		this.url = '';
		this.propagateChange()
		this.change.emit(true);
	}
	remove(target) {
		this.value = this.valueAsList.filter(item => item !== target).join(' ');
		this.propagateChange()
		this.change.emit(true);
	}
}
