/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable ,  BehaviorSubject } from 'rxjs';

import {switchMap, pluck} from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { Store } from '@ngrx/store';

import { actions } from '../../actions';
import * as SocketActions from '../../socket.actions';

@Component({
	selector: 'change-log',
	template: `<change-log-component
	(loadVersion)="onLoadVersion($event)"
	[changeLog]="changeLog || {}"
	[versions]="(versions$ | async) || []"
	[pathToCurrent]="pathToCurrent"
	[users]="users$ | async"></change-log-component>`,
})
export class ChangeLogContainer {
	@Input() id: string;
	@Input() collectionName: string;
	@Input() changeLog: any;

	@Output() close = new EventEmitter(false);

	public users$: Observable<any>;
	public versions$: Observable<any>;
	public pathToCurrent: string;

	private id$ = new BehaviorSubject('');
	private collectionName$ = new BehaviorSubject('');

	constructor(protected store: Store<any>) {
		store.dispatch(new SocketActions.GetCollectionList({ collectionName: 'User' }));
		this.users$ = store.pipe(pluck('models', 'User', 'entities'));
		this.versions$ = observableCombineLatest(
			this.id$,
			this.collectionName$,
			(id, collectionName) => ({ id, collectionName })
		).pipe(switchMap((options: any) =>
			store.pipe(pluck(
				'models',
				options.collectionName,
				'entities',
				options.id,
				'versions'
			))
		));
		store.select('routeInfo').subscribe(({path, id}) => {
			this.pathToCurrent = `${path}/edit/${id}`;
		})
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if (changes.id) {
			this.id$.next(changes.id.currentValue);
		}
		if (changes.collectionName) {
			this.collectionName$.next(changes.collectionName.currentValue);
		}
	}

	loadVersions() {
		this.store.dispatch(new SocketActions.GetVersionList({ collectionName: this.collectionName, id: this.id }));
	}

	onLoadVersion(versionId) {
		this.store.dispatch(new SocketActions.GetVersionOfItem({ collectionName: this.collectionName, id: this.id, versionNumber: versionId }));
		this.close.emit(true);
	}
	ngOnDestroy() {
		this.store.dispatch(new SocketActions.LeaveCollectionList({ collectionName: 'User' }));
	}
}
