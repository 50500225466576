/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { routes } from './collection.routes';
import { EffectsModule } from '@ngrx/effects';
import { VirtualScrollModule } from 'angular2-virtual-scroll';

import { ModelEntryComponent } from './components/model-entry.component';
import { ModelGreetingComponent } from './components/model-greeting.component';
import { ModelEditComponent } from './components/model-edit.component';
import { ModelListComponent } from './components/model-list.component';
import { ModelContextComponent } from './components/model-context.component';
import { ExhibitionPreviewComponent, ExhibitionPreviewContainer } from './components/exibition-preview';

import { Effects } from './effects';
import { SocketEffects } from './socket.effects';
import { SocketEventsService } from './services/socket-events.service';

import { FormInputsModule } from './forminputs';
import { LoadingModule } from './loading/module';
import { UnifiedArticleModule } from './_unifiedarticles';
import { ModalsModule } from './modals/module';
import { MediaModule } from './_media/module';
import { LocationModule } from './_locations/module';
import { ArticleModule } from './_articles/module';
import { ExhibitModule } from './_exhibits/module';
import { ExhibitionModule } from './_exhibitions/module';
import { ArticlelistModule } from './_articleLists/module';
import { SurveyModule } from './_survey/module';
import { GlossaryTermModule } from './_glossaryTerm/module';
import { CalendarEventModule } from './_calendarEvent/module';
import { TourModule } from './_tours/module';
import { UserModule } from './_users/module';
import { OrganisationModule } from './_organisations/module';

import { BoxxModule } from './_boxxs/module';
import { BeaconModule } from './_beacons/module';
import { LoanerdeviceModule } from './_loanerdevices/module';
import { DatasourceModule } from './_datasources/module';
import { StyletemplateModule } from './_styletemplates/module';
import { SystempkModule } from './_systempks/module';
import { TicketModule } from './_tickets/module';
import { ApplogModule } from './_applogs/module';
import { CmsSettingsModule } from './_cmssettings/module';

import { EditRouteSaveGuard } from './services/edit-route-save-guard';
import { RouteInfo } from './services/route-info';

@NgModule({
	imports: [
		FormInputsModule,
		LoadingModule,
		CommonModule,
		ModalsModule,
		BrowserModule,
		routes,
		EffectsModule.forFeature([Effects, SocketEffects]),
		VirtualScrollModule,

		UnifiedArticleModule,
		MediaModule,
		LocationModule,
		ExhibitModule,
		ExhibitionModule,
		ArticleModule,
		ArticlelistModule,
		SurveyModule,
		GlossaryTermModule,
		CalendarEventModule,
		TourModule,
		UserModule,
		OrganisationModule,
		CmsSettingsModule,

		BoxxModule,
		BeaconModule,
		LoanerdeviceModule,
		DatasourceModule,
		StyletemplateModule,
		SystempkModule,
		TicketModule,
		ApplogModule,
	],
	declarations: [
		ModelEntryComponent,
		ModelGreetingComponent,
		ModelEditComponent,
		ModelListComponent,
		ModelContextComponent,
		ExhibitionPreviewComponent,
		ExhibitionPreviewContainer,
	],
	providers: [
		EditRouteSaveGuard,
		RouteInfo,
		SocketEventsService,
	],
})
export class CollectionModule { }
