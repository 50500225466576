/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import * as moment from 'moment';
// import locale_de from 'moment/locale/de';
// moment.locale('de', locale_de);
import { boxxModelInfo } from '../../../../../models/boxx/hardwareSchema';

@Component({
	selector: 'boxx-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class BoxxFormComponent implements OnInit {
	@Input() data: any;
	@Input() model: any;
	@Input() id = '';
	@Input() formGroup: FormGroup;

	public boxxConfigForm: any;
	public last100: any[] = [];
	public dataSourceSettings: any[] = [];

	constructor() {}

	ngOnInit() {
		if (this.formGroup.value.dataSourceID && this.data.datasources.length > 0) {
			this.getDataSourceSettings(this.data.datasources, this.formGroup.value.dataSourceID);
		}
	}

	ngOnChanges(changes) {
		if (changes.data) {
			console.log(this.data.boxxEvents);
			this.last100 = [...this.data.boxxEvents || []].sort((a, b) => {
				a = Date.parse(a.timeStamp);
				b = Date.parse(b.timeStamp);
				return b - a;
			}).slice(0, 100);
		}
	}

	prettyDate(timeStamp) {
		return timeStamp.replace(/[T]/g, ' ').slice(0, (timeStamp.length - 8))
		// const momentDate = moment(timeStamp)
		// console.log('## time: ',timeStamp, momentDate.format('DD.MM.YYYY HH:MM'))
		// return moment(new Date(timeStamp)).format('DD.MM.YYYY HH:MM');
	}

	onWebstationURLInput(event) {
		if (event.target.value === '') {
			this.formGroup.value.specificConfig.webstationURL = null;
		}
	}

	setThreshold(event) { // It would be better to do this on the server side, oh well.
		const { threshold } = boxxModelInfo[event.target.value];
		this.formGroup.patchValue({ specificConfig: {workloadThreshold: threshold} }, {emitEvent: false});
	}

	showForBoxxtypes(arrayOfBoxxtypes) {
		const selectedBoxxtype = this.formGroup.value.specificConfig.boxxType;
		let found = false;

		arrayOfBoxxtypes.forEach(boxxtype => {
			if (selectedBoxxtype === boxxtype) {
				found = true;
			}
		});

		return found;
	}

	getDataSourceSettings(dataSources, selectedDataSourceId) {
		const {boxxesConfig} = dataSources.find((dataSource) => dataSource._id === selectedDataSourceId);
		this.dataSourceSettings = boxxesConfig;
	}
}
