/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { definition as schema, labels } from '../../../models/systemPk/systemPkSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { SystempkFormComponent } from './components/systempk-form';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const systempkConfig = new ModelConfig({
	collectionName: 'SystemPK',
	clientPathPrefix: '/admin',

	schema,
	labels,

	formComponent: SystempkFormComponent,

	dependencies: [{ name: 'DataSource', inSidebar: false }],
});

Object.assign(systempkConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'DataSource', 'entities').pipe(
				map((entites) =>
					Object.values(entites).filter(({ systemPks, customJsModules, blocks }) =>
						[...blocks, ...customJsModules, ...systemPks].includes(id)
					)
				)
			),
			(model: any, datasources: any) => {
				return {
					model,
					datasources,
				};
			}
		);
	},
	getListData$(store) {
		return pluckDistinct(store, 'models', this.collectionName).pipe(map(selectors.getListAndSort));
	},
});
