
<div [formGroup]="form" class="overwrite-context">
	<div *ngIf="id && hasKey(form.value, 'organisationID') && data.organisation._id != form.value.organisationID">

		<div *ngIf="preventChange && updateOwnerOrgName(form.value)">
			<ng-container i18n>Belongs to</ng-container> <i> {{ ownerOrgName }}.</i>
			<br><br>
		</div>

		<ng-container *ngIf="!preventChange">
			<input
				type="checkbox"
				[attr.id]="checkboxId"
				(click)="updateForm()">
				<label [attr.for]="checkboxId">
					<span>
					<ng-container *ngIf="updateOwnerOrgName(form.value)"><ng-container i18n>Belongs to</ng-container> <i> {{ ownerOrgName }}.</i> </ng-container>
					<ng-container i18n>Click to move to</ng-container> <i>{{data.organisation.name || 'No Organisation'}}.</i>
					</span>
				</label>
			<input type="hidden" formControlName="organisationID">
		</ng-container>
	</div>
</div>
