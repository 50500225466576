/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Input,
} from '@angular/core';

import { definition as beaconSettingsSchema } from '../../../../models/dataSource/beaconSettingsSchema';

@Component({
	selector: 'beacon-settings',
	templateUrl: './index.html',
})
export class BeaconSettingsComponent {
	@Input() data: any;
	@Input() formGroup;

	schema = beaconSettingsSchema;
}
