/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'admin-dashboard-component',
	template: `
		<div class="admin-dashboard">
			<h1>Admin Dashboard</h1>
			<div
				class="button"
				[ngClass]="{ 'button--blue-ghost': timeSpan.getTime() == timeNow.getTime() }"
				(click)="timeSpan = timeNow"
				i18n
			>
				All Time
			</div>
			<div
				class="button"
				[ngClass]="{ 'button--blue-ghost': timeSpan.getTime() != timeNow.getTime() }"
				(click)="lastMonth()"
				i18n
			>
				last month
			</div>
			<div *ngFor="let org of data.organisations">
				<h3>{{ org.name }}</h3>
				<div class="features">
					<div *ngFor="let feature of org.features" [ngClass]="{ active: feature.enabled }">
						{{ feature.name }}
						<span *ngIf="feature.usage && timeSpan.getTime() == timeNow.getTime()" class="usage">{{
							feature.usage
						}}</span>
						<span *ngIf="feature.usage && timeSpan.getTime() != timeNow.getTime()" class="usage">{{
							feature.usageLastMonth
						}}</span>
						<span *ngIf="feature.enabled" class="icon-checkmark"></span>
					</div>
				</div>
			</div>
			<h2>Features</h2>
			<div *ngFor="let features of data.features; let i = index">
				<h3>{{ features.name }} ({{ features.organisations.length }})</h3>
				<div class="feature-orgs">
					<div *ngFor="let featureOrg of features.organisations">
						{{ featureOrg.name }}
						<span *ngIf="timeSpan.getTime() == timeNow.getTime()" class="usage">{{
							featureOrg.features[i].usage
						}}</span>
						<span *ngIf="timeSpan.getTime() != timeNow.getTime()" class="usage">{{
							featureOrg.features[i].usageLastMonth
						}}</span>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['./admin-dashboard.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminDashboardComponent {
	@Input() data: any;
	public timeSpan = new Date();
	public timeNow = new Date();

	lastMonth() {
		this.timeSpan = new Date();
		this.timeSpan.setMonth(this.timeNow.getMonth() - 1);
	}
}
