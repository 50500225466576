/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema } from '../../../models/beacon/beaconSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { BeaconFormComponent } from './components/beacon-form.component';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const beaconConfig = new ModelConfig({
	collectionName: 'Beacon',
	clientPathPrefix: '/admin',

	schema,

	sortModes: [
		{ name: 'date', objectPath: 'changeLog.editedOn' },
	],

	formComponent: BeaconFormComponent,
});

Object.assign(beaconConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'Beacon', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'currentuser'),
			(model: any, beaconsRaw: any[], currentUser: any) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const beaconsFilterd = organisationID ? beaconsRaw.filter(be => be.organisationID === organisationID) : beaconsRaw;
				return {
					model,
					beacons: beaconsFilterd.filter(b => (b._id !== model._id) && ['trigger', 'delayedTrigger', 'area'].includes(b.type)),
				};
			},
		);
	},
	getItemTitle(item) {
		return `${item.major}:${item.minor}`
	},
});
