/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';

import { LoanerdeviceFormComponent } from './components/loanerdevice-form';


@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		LoanerdeviceFormComponent,
	],
	declarations: [
		LoanerdeviceFormComponent,
	],
	entryComponents: [
		LoanerdeviceFormComponent,
	],
})
export class LoanerdeviceModule { }
