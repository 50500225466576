/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';

import { FormInputsModule } from '../forminputs';
import { ApplogFormComponent } from './components/applog-form';

@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		ApplogFormComponent,
	],
	declarations: [
		ApplogFormComponent,
	],
	entryComponents: [
		ApplogFormComponent,
	],
})
export class ApplogModule { }
