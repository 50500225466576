/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaViewerComponent } from './media-viewer.component';
import { ThreeDComponent } from './three-d.component';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		MediaViewerComponent,
		ThreeDComponent,
	],
	exports: [
		MediaViewerComponent,
		ThreeDComponent,
	],
})
export class MediaViewerModule {}
