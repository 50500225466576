/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { relations as exhibition } from '../../../models/exhibition/exhibitionSchema';
import { relations as boxx } from '../../../models/boxx/boxxSchema';
import { relations as beacon } from '../../../models/beacon/beaconSchema';
import { relations as article } from '../../../models/article/articleSchema';
import { relations as location } from '../../../models/location/locationSchema';
import { relations as datasource } from '../../../models/dataSource/dataSourceSchema';


export const relations = {
	exhibition,
	boxx,
	beacon,
	article,
	location,
	datasource,
}
