export function previewDomain(dataSource) {
	const isStaging = window.location.host.includes('staging');
	if (window.location.host.includes('localhost')) return 'http://localhost:10101/';
	if (dataSource.storeOnS3) {
		if (isStaging) {
			return 'http://shoutrlabs.staging.publicweb.s3-website.eu-central-1.amazonaws.com';
		}
		return 'http://shoutrlabs.cms.publicweb.s3-website.eu-central-1.amazonaws.com';
	}
	if (window.location.host === 'databankmuseum.kz') return 'https://www.databankmuseum.kz';
	return isStaging ? 'https://web.staging.shoutrlabs.com' : 'https://web.cms.shoutrlabs.com';
}
