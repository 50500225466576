/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'loading-spinner',
	template: `
	<div
		class="centered-loader"
		role="status"
		[attr.aria-label]="loading ? 'Loading' : 'Loading complete'">
		<div class="loader" [class.hidden]="!loading"></div>
	</div>
	<div><ng-content></ng-content></div>
`,
styleUrls: ['./index.sass'],
})
export class LoadingSpinnerComponent {
	@Input() loading: boolean;
}

