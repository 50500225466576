/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'ng-select';

import { AdminDashboardContainer } from './components/admin-dashboard.container';
import { AdminDashboardComponent } from './components/admin-dashboard.component';

@NgModule({
	imports: [CommonModule, SelectModule],
	exports: [AdminDashboardContainer],
	declarations: [
		AdminDashboardContainer,
		AdminDashboardComponent
	],
})
export class AdminDashboardModule {}
