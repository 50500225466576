/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { isoLang2name } from '../../utils';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';

@Component({
	selector: 'input-language-content',
	template: `
<div
	[ngClass]="{invalid: missingValues}">
	<div *ngIf="this.missingValues" class="invalid-message" i18n>
		The organisation is missing or there are no languages set for your organisation.
	</div>
	<div
		[ngClass]="{'media-files-list--hide': this.missingValues}"
		[dragula]="bagName"
		[(dragulaModel)]="value"
		class="drag-container media-files-list">
		<div
			*ngFor="let languageItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					{{isoLang2name[languageItem.languageISO]}}
				</div>
				<div *ngIf="enableDrag && value && value.length > 1" class="drag-handle">
					<span class="make-default-hint" i18n>(drag left / right to change order in app)</span>
					<span class="make-default-hint" i18n>(disable option is not available for default language)</span>
				</div>
				<div *ngFor="let field of fields">
					<label>{{ field.label }}</label>
					<input-text
						*ngIf="field.multiline"
						cols="30"
						rows="4"
						[placeholder]="field.placeholder || field.label"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem[field.prop]"></input-text>
					<input
						*ngIf="!field.multiline"
						type="text"
						[attr.placeholder]="field.placeholder || field.label"
						(change)="propagateChange()"
						[attr.disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem[field.prop]">
				</div>
				<div class="spacer"></div>
				<div *ngIf="canDisableLanguage && (languageItem.languageISO !== defaultLanguage)" class="can-disable">
					<input
						class="sl-form__switch"
						[attr.id]="languageItem.languageISO+'disable'"
						[(ngModel)]="languageItem.disabled"
						(change)="propagateChange()"
						type="checkbox" />
					<label [attr.for]="languageItem.languageISO+'disable'" ></label>
					<span i18n>Disable</span>
				</div>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./input-language-content.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLanguageContentComponent), multi: true }],
})
export class InputLanguageContentComponent extends CustomValueAccessor {
	@Input() enableDrag: boolean;
	@Input() canDisableLanguage = false;
	@Input() formGroup: any;
	@Input() fields = [
		{ prop: 'title', label: this.i18n('User-visible Title'), placeholder: this.i18n('… title'), multiline: false },
		{ prop: 'description', label: this.i18n('… description'), multiline: true }
	];
	@Input() defaultLanguage: string;

	@Output() contentFixed = new EventEmitter<any[]>();

	public isoLang2name = isoLang2name;
	public missingValues = true;

	constructor(drake: DragulaService, changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, public i18n: I18n) {
		super(i18n);

		this.initOptions({
			drake,
			drakeOptions: {
				direction: 'horizontal',
			},
			bagName: `langcontent-${Math.random()}`,
			changeDetectorRef,
		});

		this._value = [];
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(value);
		this.missingValues = !this.value || !this.value.length;
	}
}
