/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppHttp } from '../../../services/AppHttp';
import config from '../../../config';
import { environment } from '../../../../environments/environment';
import { previewDomain } from '../../../utils';

@Component({
	selector: 'exhibition-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class ExhibitionFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	public editExhibitId: string;
	public model: any;
	public copyActive = {};
	public hostname = config.hostname === 'localhost' ? 'staging.shoutrlabs.com' : config.hostname;


	constructor(public cdr: ChangeDetectorRef, private appHttp: AppHttp) {}

	canPreview() {
		return (
			this.id &&
			this.formGroup.value.landingExhibitID &&
			this.formGroup.value.styleTemplateID &&
			this.formGroup.value.languageContent &&
			this.formGroup.pristine
		);
	}

	onCreateDatasource({ data }) {
		this.formGroup.patchValue({ dataSourceID: data._id });
	}

	onCreateArticle({ data }) {
		if (!this.formGroup.value.landingExhibitID && (
			// not sure of the order of create action subscription vs reducer calls
			this.data.unifiedArticles.length === 0 || (
				this.data.unifiedArticles.length === 1 &&
				this.data.unifiedArticles[0]._id === data._id
			)
		)) {
			this.formGroup.patchValue({ landingExhibitID: data._id });
		}
	}

	copyToClipboard(path, id) {
		if (!path) return;

		const input = document.createElement('input');
		document.body.appendChild(input);
		input.value = /^http/.test(path) ? `${path}` : `${config.baseURL}/${path}`;
		input.select();

		try {
			document.execCommand('copy');
			this.copyActive[id] = true;
			setTimeout(() => {
				this.copyActive[id] = null;
			}, 300);
		} catch (error) {
			console.warn(`Could not copy to clipboard. ${error}`);
		}
		document.body.removeChild(input);
	}

	toggleUrlToken(event) {
		event.preventDefault();
		if (!this.data.model._id) return;
		this.appHttp[this.data.model.urlToken ? 'delete' : 'get'](
			`/exhibitions/${this.data.model._id}/urltoken`
		).subscribe(() => {});
	}
	previewDomain() {
		return previewDomain(this.data.modelDataSource);
	}
}
