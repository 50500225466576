/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'message-component',
	template: '<div class="{{data.type}} message-content" [hidden]="data.hidden" [innerHTML]="data.content"></div>',
	styleUrls: ['./message.component.sass'],
})
export class MessageComponent {
	@Input() data;
}
