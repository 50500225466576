/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner';
import { RequestIndicatorComponent } from './request-indicator';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		LoadingSpinnerComponent,
		RequestIndicatorComponent,
	],
	exports: [
		LoadingSpinnerComponent,
		RequestIndicatorComponent,
	],
})
export class LoadingModule {}
