/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { schema, labels } from '../../../models/mediaObject/mediaObjectSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { MediaFormComponent } from './components/media-form.component';
import { selectors } from '../selectors';
import { info } from './info';
import { isoLang2name, pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';
import * as socketActions from '../socket.actions';

export const mediaConfig = new ModelConfig({
	name: 'media',
	namePlural: 'media',
	collectionName: 'MediaObject',
	icon: 'icon-collection',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'Exhibition', inSidebar: true },
		{ name: 'UnifiedArticle', inSidebar: true },
		{ name: 'Exhibit', inSidebar: true },
		{ name: 'Article', inSidebar: true },
		{ name: 'Location', inSidebar: false },
	],
	schema,
	labels,

	formComponent: MediaFormComponent,
	infoHtml: info,
});

const requestOrgIds = new Set()
let currentOrganisationID = null;

Object.assign(mediaConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(store, 'models', 'Organisation').pipe(map(selectors.getEntities)),
			(model: any, currentUser: any, organisationEntities) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
				if (organisationID && !requestOrgIds.has(organisationID)) {
					requestOrgIds.add(organisationID);
					currentOrganisationID = organisationID;
					store.dispatch(
						new socketActions.GetCollectionItem({ collectionName: 'Organisation', id: organisationID })
					);
				}
				const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
				const languageOptions = (languages || []).map(value => ({value: `${value}`, label: `${isoLang2name[value]}`}));
				const customFields = modelOrg ? modelOrg.customMediaObjectFields.filter(f => !f.isLanguageBased) : [];
				const customFieldsLanguage = modelOrg ? modelOrg.customMediaObjectFields.filter(f => f.isLanguageBased) : [];
				const hasLocationNav = modelOrg && modelOrg.enableNavUi.includes('location')
				return {
					model,
					organisationID,
					customFields,
					customFieldsLanguage,
					languageOptions,
					hasLocationNav,
					access: currentUser.access,
				};
			}
		);
	},
});
