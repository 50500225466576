/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest, merge as observableMerge,  Observable ,  Subject ,  BehaviorSubject } from 'rxjs';

import {takeUntil, switchMap, pluck, skip, map} from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, Input, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

import * as actions from '../socket.actions';
import { currentuserActions } from '../_currentuser/actions';
import { nameToCollectionName } from '../modelConfigs';
import { RouteInfo } from '../services/route-info';
import { pluckDistinct, CollectionJoiner } from '../utils';
import config from '../config';


@Component({
	template: `
	<div class="sl-listview__wrapper">
		<nav
			#leftColumn
			[ngStyle]="{ width: (this.columns.left.styleWidth$ | async), flex: (columns.left.styleWidth$ | async) ? 'none' : '' }">

			<model-list
				[data]="listData$ | async"
				[id]="id"
				[access]="access$ |async"
				[model]="model$ | async"></model-list>

		</nav>

		<div
			class="resize-bar"
			draggable="true"
			(mousedown)="resizeStart($event, 'left')">
		</div>

		<main class="sl-listview__form">
			<router-outlet></router-outlet>
		</main>

		<ng-container *ngIf="hasSidebarDeps(model$ | async)">
			<div
				class="resize-bar"
				draggable="true"
				(mousedown)="resizeStart($event, 'right')">
			</div>

			<aside
				#rightColumn
				[ngStyle]="{
					width: (this.columns.right.styleWidth$ | async),
					flex: (columns.right.styleWidth$ | async) ? 'none' : ''
				}">
				<!-- we wil convert this in to bread crumbs feature
				that's the reason we are hiding this for now -->
				<!-- <model-context></model-context> -->
				<exhibition-preview-container *ngIf="!isLocalhost"></exhibition-preview-container>
				<div *ngIf="isLocalhost">Preview is hidden on localhost</div>
			</aside>
		</ng-container>
	</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./model-entry.component.sass'],
})
export class ModelEntryComponent {
	@ViewChild('leftColumn', { static: true }) leftColumnRef;
	@ViewChild('rightColumn', { static: true }) rightColumnRef;

	public model$: Observable<any>;
	public listData$: Observable<any>;
	public access$: Observable<any>;
	public id: string;
	public modelConfig: any;

	public resizeColumn: string = null;
	public columns = {
		left:  { resizeWidth$: new BehaviorSubject(0), styleWidth$: null },
		right: { resizeWidth$: new BehaviorSubject(0), styleWidth$: null },
	};
	public resizePrevX = 0;
	public navStyleWidth = '';
	public isLocalhost = config.hostname === 'localhost';

	private minColumnWidth = 100;
	public destroyed$ = new Subject();
	private depManager: CollectionJoiner;

	private _resizeEnd = event => this.resizeEnd(event);

	constructor(protected routeInfo: RouteInfo, protected store: Store<any>) {
		this.model$ = routeInfo.params$.pipe(pluck('modelConfig'));

		['left', 'right'].forEach((side: 'left' | 'right') => {
			this.columns[side].styleWidth$ = observableMerge(
				pluckDistinct(this.store, 'currentuser', `${side}ColumnStyle`) as Observable<string>,
				this.columns[side].resizeWidth$.pipe(
					skip(1),
					map(width => width + 'px')
				)
			);
		});

		pluckDistinct(this.store, 'routeInfo')
			.pipe(
				takeUntil(this.destroyed$),
			)
			.subscribe(info => {
				this.id = info.id;
			});

		this.depManager = new CollectionJoiner(store, [], this.destroyed$);
	}

	ngOnInit() {
		this.listData$ = this.model$.pipe(switchMap(modelConfig => {
			return modelConfig.getListData$(this.store);
		}));

		this.access$ = pluckDistinct(this.store, 'currentuser', 'access');

		// load current model and model-list dependencies
		observableCombineLatest(this.model$, this.routeInfo.params$.pipe(pluck('id'))).pipe(
			takeUntil(this.destroyed$))
			.subscribe(([{ collectionName, dependencies }, id]) => {
				// leave previously joined rooms
				this.depManager.replace([
					{ name: collectionName },
					...dependencies
						.filter(dep => dep.inList),
				]);

				if (id) {
					this.store.dispatch(new actions.GetDependencyMapsData());
				}
			});

		document.addEventListener('mousemove', this._resizeEnd);
		document.addEventListener('mouseup', this._resizeEnd);
		document.addEventListener('blur', this._resizeEnd);
	}

	hasSidebarDeps(model) {
		return model.dependencies.some(dep => dep.inSidebar);
	}

	resizeStart(event, side) {
		if (event.type !== 'mousedown') return;

		this.resizeColumn = side;
		this.resizePrevX = event.clientX;

		const column = this.columns[this.resizeColumn];
		column.resizeWidth$.next(this[`${side}ColumnRef`].nativeElement.getBoundingClientRect().width);

		event.preventDefault();
	}

	resizeEnd(event?) {
		if (!this.resizeColumn) return;

		const column = this.columns[this.resizeColumn];
		let newWidth = column.resizeWidth$.value;

		if (event) {
			const delta = (this.resizeColumn === 'left' ? + 1 : -1 ) * (event.clientX - this.resizePrevX);
			newWidth += delta;

			if (event.type === 'mouseup' || event.type === 'blur') {
				newWidth = Math.max(newWidth, this.minColumnWidth) || this.minColumnWidth;
			}

			column.resizeWidth$.next(newWidth);

			this.resizePrevX = event.clientX;
			event.preventDefault();
		}

		if (!event || event.type === 'mouseup') {
			const action = this.resizeColumn === 'left'
				? currentuserActions.resizeLeftColumn(newWidth)
				: currentuserActions.resizeRightColumn(newWidth);

			this.store.dispatch(action);
			this.resizeColumn = null;
		}
	}

	ngOnDestroy() {
		this.resizeEnd();

		document.removeEventListener('mousemove', this._resizeEnd);
		document.removeEventListener('mouseup', this._resizeEnd);
		document.removeEventListener('blur', this._resizeEnd);

		this.destroyed$.next();
		this.destroyed$.complete();
	}
}

