/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { navigationItems } from '../../../../../../backend/models/organisation/organisationSchema';


@Component({
	selector: 'input-nav-ui',
	template: `
<div class="list">
	<div *ngFor="let navItem of value; let i = index" >
		<span> {{navItem}} </span>
		<span class="icon-remove" (click)="remove(navItem)"></span>
	</div>
</div>
<div class="input-widget">
	<ng-select
		(selected)="add($event)"
		[options]="navigationOptions"
		i18n-placeholder
		placeholder="… select feature"></ng-select>
</div>
	`,
	styleUrls: ['./input-nav-ui.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputNavUiComponent), multi: true }],
})
export class InputNavUiComponent extends CustomValueAccessor {
	public navigationOptions = navigationItems.map(item => ({ value: item, label: item }))
	add(event) {
		if (!this.value.includes(event.value)) {
			this.value.push(event.value);
			this.propagateChange();
		}
	}
	remove(navItem) {
		this.value = this.value.filter(i => i !== navItem)
	}
}
