
	<div class="sl-form">
	<dynamic-model-form
		#dynamicModelForm
		collectionName="User"
		[id]="id$ | async"
		[data]="data$ | async"
		class="sl-form__content">
	</dynamic-model-form>
	<model-edit-footer
		[formGroup]="dynamicModelForm.formGroup"
		[model]="userConfig"
		[id]="id$ | async"
		[data]="data$ | async">
	</model-edit-footer>
	</div>