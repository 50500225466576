
<div class="wrapper">
<table>
	<thead>
		<tr>
			<th>PAGE TITLE</th>
			<th i18n i18n-title title="The overall views of the page by all visitors.">OVERALL PAGEVIEWS</th>
			<th i18n i18n-title title="Unique pageviews count multiple page views from the same visitor as one. 30 min of inactivity is considered a new visit.">UNIQUE PAGEVIEWS</th>
			<th i18n i18n-title title="The average amount of time a visitor spends on this page.">AVG. TIME ON PAGE</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let entry of data">
			<td>{{entry.label}}</td>
			<td>{{entry.nb_hits}}</td>
			<td>{{entry.nb_visits}}</td>
			<td>{{formatSeconds(entry.avg_time_on_page)}}</td>
		</tr>
	</tbody>
</table>
</div>
	