
		<div class="memory-game-form">
			<div [formGroup]="formGroup">
				<input-media-list
					formControlName="mediaObjectsForGame"
					[mediaObjects]="mediaObjects"
					[organisationID]="organisationID"
					type="audio,video,image"
				></input-media-list>
		</div>
	