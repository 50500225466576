/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {from as observableFrom, of as observableOf,  Observable } from 'rxjs';

import {tap, map, pluck, first, switchMap, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { generateResponse, getErrorMessage } from './services/generateResponse';
import { AppHttp } from './services/AppHttp';

import { actions } from './actions';
import * as CollectionActions from './socket.actions';
import { messageActions } from './core/_messages/actions';
import { currentuserActions } from './_currentuser/actions';
import { modelConfigs } from './modelConfigs';
import { RouteInfo } from './services/route-info';
import { ModelConfig } from './modelConfigs/ModelConfig';

function getModel(action: any) {
	return modelConfigs[action.collectionName || action.payload.collectionName];
}

function addOrganisationIDifNotSet(obj, organisationID) {
	if (!obj.organisationID) return Object.assign({}, obj, { organisationID });
	return obj;
}

@Injectable()
export class Effects {
	private organisationID;
	private _loaded = {};


	// reset loaded list on login
	@Effect({ dispatch: false })
	login = this.actions$.pipe(
		ofType(currentuserActions.LOGIN_SUCCESS),
		tap(() => {
			this._loaded = {};
		})
	);


	@Effect()
	loadFilter$ = this.actions$.pipe(
		ofType(actions.FILTER_REQUEST),
		map((action: any) => ({ apiEndpoint: getModel(action).apiEndpoint, action })),
		mergeMap(({ apiEndpoint, action }) =>
			this.appHttp
				.get(
					`/${apiEndpoint}/?limit=${action.limit}&skip=${action.skip}&filter=${JSON.stringify(action.filter)}`
				)
				.pipe(
					mergeMap(response => observableFrom(generateResponse(response, {})))
				)
		),
	);

	constructor(
		private actions$: Actions,
		private appHttp: AppHttp,
		protected routeInfo: RouteInfo,
		protected router: Router,
		protected store: Store<any>,
	) {
		this.store.select('currentuser').subscribe((cuser: any) => {
			this.organisationID = cuser.organisation._id;
		});
	}
}
