
	<style>
:host(.ng-invalid) >>> .input-widget {
	border: 1px solid #CD0026;
}
	</style>
<div class="input-widget">

	<div *ngIf="isAdmin">
		<label i18n>Root role</label>
	</div>
	<div *ngIf="isAdmin && !cmsRootVisible">
		<label (click)=showCmsRoot() style="cursor: pointer" i18n>Click here to show CMS-Admin option</label>
	</div>
	<div *ngIf="isAdmin && cmsRootVisible">
 		<input type="checkbox" [checked]="checkedRoot">
		<label (click)="checkedRoot = !checkedRoot; this.handleRootCheckbox(checkedRoot)" i18n>is CMS administrator?</label>
	</div>

	<div *ngIf="!checkedRoot || !isAdmin">
		<div *ngIf="organisations.length === 1">
			<ng-select
				(selected)="onSelectSingleOrgRole()"
				[(ngModel)]="singleOrgRole"
				[options]="optionSets[1].options"
				i18n-placeholder
				placeholder="… select role">
			</ng-select>
		</div>
		<div *ngIf="organisations.length > 1">
			<label i18n>Organisation specific roles</label>
			<div class="list">
				<div *ngFor="let role of this.filterValue()">
					{{getOrgName(role.organisationID)}} ({{role.name}}) <span class="icon-remove" (click)="remove(role)"></span>
				</div>
			</div>
			<div *ngIf="this.optionSets[0]?.options.length">
				<select-array
					[optionSets]="optionSets"
					[clearOnComplete]="true"
					(change)="selectChanged($event)">
				</select-array>
			</div>
		</div>
	</div>
</div>
	