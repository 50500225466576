/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { routes } from './routes';
import { CurrentuserEffects } from './effects';
import { FormContainer } from './components/form.container/form.container';
import { PasswordComponent } from './components/password.component/password.component';
import {
	AccountProfileComponent,
	AccountPasswordComponent,
	AccountNavComponent
} from './components/account';
import { LoginComponent } from './components/login.component';
import { ResetPasswordComponent } from './components/reset-password.component';
import { UserModule } from '../_users/module';
import { Validate2FCodeComponent } from './components/validate2FCode.component';


@NgModule({
	imports: [
		routes,
		UserModule,
		EffectsModule.forFeature([CurrentuserEffects]),
	],
	exports: [
		LoginComponent,
		FormContainer,
		PasswordComponent,
		Validate2FCodeComponent,
	],
	declarations: [
		LoginComponent,
		ResetPasswordComponent,
		FormContainer,
		PasswordComponent,
		AccountProfileComponent,
		AccountPasswordComponent,
		AccountNavComponent,
		Validate2FCodeComponent,
	],
})
export class Module { }
