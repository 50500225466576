/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import config from '../config';

const mediaApiPath = `${config.baseURL}/api/mediafiles/`;

@Component({
	selector: 'three-d',
	template: `
	<style>
	.viewer-3d__container {
		display: inline-block;
		width: 100%;
		height: 100%;
	}
	.viewer-3d__host,
	.viewer-3d__container canvas {
		width: 100%;
		height: 100%;
	}
	</style>
	<div class="viewer-3d__host">
		<div #viewerHost class="viewer-3d__container"></div>
		<div class="viewer-3d__controls">
			<span
				class="icon-visible"
				(click)="makeScreenshot()"
				title="upload preview screenshot"></span>
			<span
				(click)="emitParameters()"
				class="icon-download-from-cloud"
				title="export the viewer parameters"></span>
		</div>
	</div>
	`,
	styleUrls: ['./media-viewer.component.sass'],
})
export class ThreeDComponent {
	@Input() source: any = null;
	@Output() screenshot = new EventEmitter(false);
	@Output() viewer3dParams = new EventEmitter(false);

	viewer: any = null;
	@ViewChild('viewerHost', { static: true }) viewerHost: ElementRef;

	onWindowResize = () => {
		if (!this.viewer) { return; }
		this.viewer.resizeToContainer();
	};

	constructor(private container: ElementRef) {
	}

	async ngAfterViewInit() {
		const { default: Viewer3D } = await import('viewer-3d' /* webpackChunkName: "viewer-3d" */);

		this.viewer = new Viewer3D({
			container: this.viewerHost.nativeElement,
			showGui: true,
		});

		this.reload();
		this.viewer.resizeToContainer();

		window.addEventListener('resize', this.onWindowResize);
	}

	emitParameters() {
		console.log('this.viewer.getCurrentSettings()', this.viewer.getCurrentSettings());
		this.viewer3dParams.emit(this.viewer.getCurrentSettings());
	}

	ngOnChanges(changes) {
		if (changes.source && this.viewer) {
			this.reload();
		}
	}

	reload() {
		this.viewer.clear();

		if (this.source) {
			// if the source is like { basePath: 'url/', model: 'x.obj', mtl: 'x.mtl', format: 'obj' }
			if (this.source.model) {
				console.error('not implemented anymore')
				// this.viewer.load(this.source);
			} else {
				this.loadLanguageMediaObject(this.source, mediaApiPath);
			}
		}

	}

	loadLanguageMediaObject({ source, threeD }, mediaApiPath = '/api/mediafiles/') {
		const { viewerOptions = {} } = threeD;

		const modelUrl = `${mediaApiPath}${source}`;
		const resourceMap = Object.entries(threeD.resourceMap).reduce((acc, [id, filename]: [string, string]) => {
				acc[`${mediaApiPath}${id}/2048`] = filename;
				return acc;
			}, {});

		this.viewer.load({
			modelUrl: `${mediaApiPath}${source}`,
			format: 'obj',
			resourceMap,
			hdrUrls: ['px', 'nx', 'py', 'ny', 'pz', 'nz'].map(item => `${config.baseURL}/js/assets/img/256_SketchFab/${item}.hdr`),
			options: Object.assign({
				exposure: 2.6,
				spin: false,
				rendererOptions: { preserveDrawingBuffer: true },
			}, viewerOptions),
		});
	}

	makeScreenshot() {
		this.viewerHost.nativeElement.querySelector('canvas').toBlob((blob) => {
			this.screenshot.emit(blob);
		})
	}

	ngOnDestroy() {
		if (this.viewer) {
			this.viewer.destroy();
			this.viewer = null;
		}

		window.removeEventListener('resize', this.onWindowResize);
	}
}
