/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {from as observableFrom,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';

// FIXME this file should be refactored, it's only used here
import { generateResponse } from '../services/generateResponse';
import { AppHttp } from '../services/AppHttp';

import * as collectionActions from '../socket.actions';
import { currentuserActions } from '../_currentuser/actions';
import { actions as newActions } from '../actions';
import { messageActions } from '../core/_messages/actions';
import { SocketConnectionService } from '../services/socket-connection.service';
import { mergeMap, switchMap, withLatestFrom, map, tap, filter } from 'rxjs/operators';

@Injectable()
export class CurrentuserEffects {

	@Effect()
	logout$ = this.actions$.pipe(
		ofType(currentuserActions.LOGOUT_REQUEST),
		switchMap(() =>
			this.appHttp
				.get('/logout')
				.pipe(map(response =>
					generateResponse(response, { type: currentuserActions.LOGOUT_SUCCESS })
				))
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	@Effect({ dispatch: false })
	presistCurrentUser$ = this.actions$.pipe(
		ofType(
			currentuserActions.TOGGLE_EXTENDNAV,
			currentuserActions.CURRENTUSER_SET_ORG,
			currentuserActions.RESIZE_LEFT_COLUMN,
			currentuserActions.RESIZE_RIGHT_COLUMN
		),
		withLatestFrom(this.store.select('currentuser')),
		map(([action, currentuser]) => {
			try {
				const cu = JSON.parse(JSON.stringify(currentuser));
				// This can become way to big for the local storage and must be delted
				// or local storage will crash.
				delete cu.dependencyMaps;
				localStorage.currentuser = JSON.stringify(cu);
			} catch (error) {
				console.log('currentuser', currentuser);
				console.error(`Could not set localstorage currentuser ${error}`)
			}
		})
	);

	@Effect({ dispatch: false })
	resetPassSucccess$ = this.actions$.pipe(
		ofType(currentuserActions.RESET_PASSWORD_SUCCESS),
		map((action: any) => {
			this.store.dispatch(messageActions.info(action.payload.message));

			if (/resetpassword/i.test(this.router.url)) {
				this.router.navigate(['account/login']);
			}
		})
	);

	@Effect({ dispatch: false })
	logoutSucccess$ = this.actions$.pipe(
		ofType(
			currentuserActions.LOGOUT_SUCCESS,
			currentuserActions.LOAD_CURRENTUSER_FAIL
		),
		map(() => {
			localStorage.removeItem('currentuser');
			sessionStorage.removeItem('userAuthenticated');
			this.socket.disconnect();
			if (!this.router.url.includes('/account/login')) {
				this.router.navigate(['account/login']);
			}
		})
	);

	@Effect()
	loginSucccess$ = this.actions$.pipe(
		ofType(
			currentuserActions.LOGIN_SUCCESS,
			currentuserActions.LOAD_CURRENTUSER_SUCCESS,
			collectionActions.CURRENT_USER_DATA_UPDATED,
		),
		withLatestFrom(this.store.select('currentuser')),
		tap(([action, currentuser]) => {
			if (action.type === currentuserActions.LOGIN_SUCCESS) {
				this.socket.disconnect();
				this.socket.reconnect();
			}
			if ( action.type === collectionActions.CURRENT_USER_DATA_UPDATED ) {
				if (currentuser.currentuser.enableTOTP && !sessionStorage.getItem('userAuthenticated')) {
					this.router.navigate(['account/validateCode']);
				} else {
					const next = this.activatedRoute.snapshot.queryParams.next;
					if (next) this.router.navigate([next]);
					else this.router.navigate(['/']);
				}
			}
		}),
		map(() => {
			return new collectionActions.GetCollectionFullList({ collectionName: 'Organisation' });
		})
	);

	@Effect()
	login$ = this.actions$.pipe(
		ofType(currentuserActions.LOGIN_REQUEST),
		switchMap((action: any) =>
			this.appHttp
			.post({ url: '/login', body: action.data }).pipe(
				map(response =>
					generateResponse(response, { type: currentuserActions.LOGIN_SUCCESS })
				),
			)
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	@Effect()
	resetPass$ = this.actions$.pipe(
		ofType(currentuserActions.RESET_PASSWORD_REQUEST),
		switchMap((action: any) =>
			this.appHttp
			.post({ url: '/resetpassword', body: action.data }).pipe(
				map(response =>
					generateResponse(response, { type: currentuserActions.RESET_PASSWORD_SUCCESS })
				),
			)
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	@Effect()
	orgChange$ = this.actions$.pipe(
		ofType(collectionActions.COLLECTION_FULL_LIST_UPDATED),
		filter(({ payload }: any) => payload.collectionName === 'Organisation'),
		map(({ payload }) => {
			if (payload.data.length === 1) {
				return currentuserActions.setOrganisation(payload.data[0]);
			} else {
				return { type: 'NOOP' };
			}
		})
	);

	@Effect()
	changePassword$ = this.actions$.pipe(
		ofType(currentuserActions.CURRENTUSER_CHANGE_PASSWORD),
		switchMap((action: any) =>
			this.appHttp
				.put({
					url: `/currentuser/password`,
					body: JSON.stringify(action.payload),
				}).pipe(map(response =>
					generateResponse(response, {
						type: currentuserActions.CURRENTUSER_CHANGE_PASSWORD_SUCCESS,
					})
				))
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	@Effect()
	validate2FactorAuthenticationCode$ = this.actions$.pipe(
		ofType(currentuserActions.CURRENTUSER_VALIDATE_2FCODE),
		switchMap((action: any) =>
			this.appHttp
			.post({ url: '/validate2FactorCode', body: action.data }).pipe(
				map(response => {
					const result = generateResponse(response, { type: currentuserActions.CURRENTUSER_VALIDATE_2FCODE_SUCCESS })
					const { payload } = result[0];
					if ('delta' in payload) {
						this.router.navigate(['/exhibition']);
						sessionStorage.setItem('userAuthenticated', 'true');
					} else {
						sessionStorage.setItem('userAuthenticated', 'false');
						this.store.dispatch(currentuserActions.twoFactorSetAuthenticationStatus({
							userNotAuthenticated : false
						}));
						this.store.dispatch(messageActions.warn(payload.message));
					}
					return result;
				}
				),
			)
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	@Effect()
	resend2factorEmail$ = this.actions$.pipe(
		ofType(currentuserActions.CURRENTUSER_RESEND_2FACTOREMAIL),
		switchMap((action: any) =>
			this.appHttp
			.get({ url: '/validate2FactorCode/resend2FactorCode'}).pipe(
				map(response =>
					generateResponse(response, { type: currentuserActions.CURRENTUSER_RESEND_2FACTOREMAIL_SUCCESS })
				),
			)
		),
		mergeMap((actions: any[]) => observableFrom(actions))
	);

	constructor(
		private actions$: Actions,
		private appHttp: AppHttp,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<any>,
		private socket: SocketConnectionService,
	) {
		appHttp.get({ url: '/loggedin' }).subscribe(res => {
			if (res.status === 403) {
				store.dispatch({ type: currentuserActions.LOAD_CURRENTUSER_FAIL });
			}
		});
	}
}
