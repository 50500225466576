/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { RouterModule, UrlSegment } from '@angular/router';

import { EditRouteSaveGuard } from './services/edit-route-save-guard';
import { ModelEntryComponent } from './components/model-entry.component';
import { ModelEditComponent } from './components/model-edit.component';
import { ModelGreetingComponent } from './components/model-greeting.component';

import { modelConfigs } from './modelConfigs';

const [newModelsRe, newAdminModelsRe] = Object.values(modelConfigs)
	.reduce((acc, config) => {
		const [regular, admin] = acc;

		if (config.clientPathPrefix === '') {
			regular.push(config.name);
		} else if (config.clientPathPrefix === '/admin') {
			admin.push(config.name);
		}

		return acc;
	}, [[], []])
	.map(modelNames => RegExp(`^(${modelNames.join('|')})$`));

export function modelRouteMatcher (segments, group, route) {
	let modelSegment = segments[0];

	if (!modelSegment) {
		// redirect to exhibition
		modelSegment = new UrlSegment('exhibition', {});
	}

	const consumed = [modelSegment];

	if (modelSegment.path === 'admin') {
		if (segments[1] && newAdminModelsRe.test(segments[1].path)) {
			modelSegment = segments[1];
			consumed.push(modelSegment);
		} else {
			return null;
		}
	} else if (!newModelsRe.test(modelSegment.path)) {
		return null;
	}

	return ({
		consumed,
		posParams: {
			'modelName': modelSegment,
		}
	});
}

export const routes = RouterModule.forChild([
	{
		matcher: modelRouteMatcher,
		component: ModelEntryComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: ModelGreetingComponent,
			},
			{
				path: 'create',
				component: ModelEditComponent,
				canDeactivate: [EditRouteSaveGuard],
			},
			{
				path: 'create/:cloneId',
				component: ModelEditComponent,
				canDeactivate: [EditRouteSaveGuard],
			},
			{
				path: 'create/:cloneId/version/:versionId',
				component: ModelEditComponent,
				canDeactivate: [EditRouteSaveGuard],
			},
			{
				path: 'edit/:id',
				component: ModelEditComponent,
				canDeactivate: [EditRouteSaveGuard],
			},
			{
				path: 'edit/:id/version/:versionId',
				component: ModelEditComponent,
				canDeactivate: [EditRouteSaveGuard],
			},
		],
	},
]);

