/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { definition as roleDef } from '../../../../models/user/roleSchema';

const roles = roleDef.name.enum.filter(role => role !== 'url');

const roleOptions = roles.map(roleName => ({
	value: roleName,
	label: roleName,
}));

const roleOptionsNoRoot = roleOptions.filter(({ value }) => value !== 'root');
// const rootRole = roleOptions.filter(({ value }) => value === 'root');

@Component({
	selector: 'input-roles',
	template: `
	<style>
:host(.ng-invalid) >>> .input-widget {
	border: 1px solid #CD0026;
}
	</style>
<div class="input-widget">

	<div *ngIf="isAdmin">
		<label i18n>Root role</label>
	</div>
	<div *ngIf="isAdmin && !cmsRootVisible">
		<label (click)=showCmsRoot() style="cursor: pointer" i18n>Click here to show CMS-Admin option</label>
	</div>
	<div *ngIf="isAdmin && cmsRootVisible">
 		<input type="checkbox" [checked]="checkedRoot">
		<label (click)="checkedRoot = !checkedRoot; this.handleRootCheckbox(checkedRoot)" i18n>is CMS administrator?</label>
	</div>

	<div *ngIf="!checkedRoot || !isAdmin">
		<div *ngIf="organisations.length === 1">
			<ng-select
				(selected)="onSelectSingleOrgRole()"
				[(ngModel)]="singleOrgRole"
				[options]="optionSets[1].options"
				i18n-placeholder
				placeholder="… select role">
			</ng-select>
		</div>
		<div *ngIf="organisations.length > 1">
			<label i18n>Organisation specific roles</label>
			<div class="list">
				<div *ngFor="let role of this.filterValue()">
					{{getOrgName(role.organisationID)}} ({{role.name}}) <span class="icon-remove" (click)="remove(role)"></span>
				</div>
			</div>
			<div *ngIf="this.optionSets[0]?.options.length">
				<select-array
					[optionSets]="optionSets"
					[clearOnComplete]="true"
					(change)="selectChanged($event)">
				</select-array>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputRolesComponent), multi: true },
	],
})
export class InputRolesComponent extends CustomValueAccessor {
	@Input() organisations: any[];
	@Input() isAdmin;
	@Input() roles;

	checkedRoot = false;
	cmsRootVisible = false;

	public optionSets = [];
	public singleOrgRole;

	ngOnInit() {
		this.value = this.value || [];
	}

	ngOnChanges() {
		this.updateOptionSets();
	}

	filterValue() {
		return (this.value || []).filter(item => this.organisations.some(f => f._id === item.organisationID));
	}

	organisationIDInOrganisations(organisationID) {
		let found = false;

		this.organisations.forEach(org => {
			if (org._id === organisationID) found = true;
		});

		return found;
	}

	getOrgName(id) {
		if (!id) return '';
		return this.organisations.find((org: any) => org._id === id).name;
	}

	add([organisationID, roleName]) {
		if (!this.value) this.value = [];

		if (roleName === 'root' || (roleName && organisationID)) {
			this.value = [
				...this.value,
				{
					name: roleName,
					organisationID,
				},
			];
		}

		this.updateOptionSets();
	}

	handleRootCheckbox(checked) {
		if (checked) {
			this.add([undefined, 'root']);
		} else {
			this.remove({ name: 'root', organisationID: undefined });
			this.remove({ name: 'root', organisationID: null });
		}
	}

	selectChanged(selectArrayComponent) {
		const [organisationID, name] = selectArrayComponent.value;

		if (organisationID && name !== undefined) {
			this.add([organisationID, name]);
			selectArrayComponent.value = [];
		}

		this.updateOptionSets();
	}

	remove(role) {
		this.value = this.value.filter(i => i.organisationID !== role.organisationID);
		this.updateOptionSets();
	}

	onSelectSingleOrgRole() {
		this.value = [{organisationID: this.organisations[0]._id, name: this.singleOrgRole}];
	}

	updateOptionSets() {
		// If there is only one organisation available to the user show the role for that orga.
		if (this.organisations.length === 1 && this.value) {
			const role = this.value.find(
				({ organisationID }) => organisationID === this.organisations[0]._id
			);
			if (role) this.singleOrgRole = role.name
		}
		// Caluclate avilable roles and organisations.
		const usedOrgs = (this.value || []).map(({ organisationID }) => organisationID);
		const adminOrgIds = (this.roles || [])
			.filter(role => role.name === 'admin')
			.map(role => role.organisationID);
		const userIsAdminInOrgs = this.isAdmin
			? this.organisations
			: this.organisations.filter(({ _id }) => adminOrgIds.includes(_id));
		const adminAndNotUsed = userIsAdminInOrgs
			.filter(({ _id }) => !usedOrgs.includes(_id))
			.map(org => ({ value: org._id, label: org.name }));

		this.optionSets = [
			{
				label: this.i18n('Organisation'),
				placeholder: this.i18n('Select an org...'),
				options: [{ value: null, label: '\u00A0' }, ...adminAndNotUsed],
				type: 'select',
			},
			{
				label: this.i18n('Role'),
				placeholder: this.i18n('Select a role...'),
				options: roleOptionsNoRoot.filter(({ value }) => this.isAdmin || value !== 'designer'),
				type: 'select',
			},
		];

		if (this.value && this.value.find(i => i.name === 'root')) {
			this.checkedRoot = true;
		} else {
			this.checkedRoot = false;
		}
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(value);
		this.updateOptionSets();
	}

	showCmsRoot() {
		this.cmsRootVisible = true;
	}
}
