/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	EventEmitter,
	Input,
	Output,
	HostListener,
	ChangeDetectorRef,
	ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'modal-component',
	template: `
<div class="modal" [ngClass]="{'is-open': _isOpen, 'modal--clean': clean, 'modal--full-size': fullSize}">
	<div class="modal__bg" (click)="close()"></div>
	<span class="modal__close icon-delete_sign" (click)="close()"></span>

	<div class="modal__content">
		<div class="modal__header">
			<ng-content select="header"></ng-content>
		</div>

		<div #bodyElement class="modal__body" (click)="bodyClick($event, bodyElement)">
			<ng-content></ng-content>
		</div>

		<div class="modal__footer">
			<ng-content select="footer"></ng-content>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
})
export class ModalComponent {

	@Input() fullSize = false;
	@Input() clean = false;
	@Output() openEvent = new EventEmitter(false);
	@Output() closeEvent = new EventEmitter(false);

	_isOpen = false;

	constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

	ngOnDestroy() {
		this.elementRef.nativeElement.remove();
	}

	open() {
		if (!this._isOpen) {
			this.moveToModalRoot();
			this._isOpen = true;
			this.openEvent.emit();
		}

		this.cdr.markForCheck();
	}

	close() {
		if (this._isOpen) {
			this._isOpen = false;
			this.closeEvent.emit();
		}

		this.cdr.markForCheck();
	}

	isOpen() {
		return this._isOpen;
	}

	bodyClick (event, bodyElement) {
		if (this.clean && event.target === bodyElement) {
			this.close();
		}
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(kbdEvent: KeyboardEvent) {
		if (kbdEvent.keyCode == 27 && this._isOpen) {
			this.close();
		}
	}

	moveToModalRoot() {
		// expects a '[modal-root]' element in the document which should be above all other elements
		document.body.querySelector('[modal-root]').appendChild(this.elementRef.nativeElement);
	}
}

