<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<h1>{{formGroup.controls.name.value}}</h1>

	<!-- ---------------------Status information-------------------------- -->
	<div class="box-3-1 status">
		<table class="status-table">
			<tr>
				<td i18n>type</td>
				<td>{{formGroup.controls.type.value}}</td>
			</tr>
			<tr *ngIf="formGroup.value.type?.includes('cloud')">
				<td>cloud updates</td>
				<td>{{formGroup.controls.preventCloudUpdates.value ? 'disabled' : 'enabled'}}</td>
			</tr>
			<tr *ngIf="formGroup.value.type?.includes('boxx')">
				<td>boxx updates</td>
				<td>{{formGroup.controls.boxxesConfig.controls.upddDisable.value ? 'disabled' : 'enabled'}}</td>
			</tr>
			<tr *ngIf="formGroup.value.appStoreIds?.android">
				<td>Android App</td>
				<td><a [href]="'https://play.google.com/store/apps/details?id='+formGroup.value.appStoreIds.android">Play Store</a></td>
			</tr>
			<tr *ngIf="formGroup.value.appStoreIds?.ios">
				<td>iOS App</td>
				<td><a [href]="'https://apps.apple.com/de/app/shoutr-app/id'+formGroup.value.appStoreIds.ios">App Store</a></td>
			</tr>

		</table>

		<div>
			<div>
				<a
					*ngIf="id && formGroup.value.type?.includes('cloud')"
					class="button"
					[attr.href]="previewDomain() + '/' + id + '/apps/webapp-navigation-bar'"
					target="_blank"
					 i18n>Visit published</a
				>
			</div>
		</div>
	</div>

	<h3 i18n>Publishing</h3>
	<div class="box-1-1">
		<div>
			<div class="publishing-overview__app">
				<label i18n>App Configuration</label>
				<div class="publishing-overview__header">
					<div
						class="is-live"
						[title]="data.model.publishing.state"
						[ngClass]="{
							'item--unpublished-changes': !data.model.isUpToDate,
							'item--is-live': data.model.publishing.state === 'published',
							'item--publish-in-progress': data.model.publishing.state === 'publishing',
							'item--publish-queued': data.model.publishing.state === 'queued'
						}"
					></div>
					<i>
						<ng-container *ngIf="data.model.isUpToDate; else elseBlock" i18n>
							All changes are published.
						</ng-container>
						<ng-template #elseBlock i18n>
							There are unpublished changes.
						</ng-template>
					</i>
					<button
							(click)="onPublishClick('DataSource', id)"
							[disabled]="data.model.isUpToDate"
							class="button"
							i18n
						>
						publish
					</button>
				</div>
			</div>
			<div class="publishing-overview">
				<label i18n>Exhibitions</label>
				<div *ngFor="let exhibition of data.exhibitions">
					<div class="publishing-overview__header">
						<div
							class="is-live"
							[ngClass]="{
								'item--unpublished-changes': !exhibition.isUpToDate,
								'item--is-live': exhibition.isLive
							}"
						></div>
						<a [routerLink]="'/exhibition/edit/'+exhibition._id">{{exhibition.name}}</a>
						<button
							(click)="onPublishClick('Exhibition', exhibition._id)"
							[disabled]="exhibition.isUpToDate || !exhibition.isLive"
							class="button"
							i18n
						>
							publish
						</button>
					</div>
					<div class="publishing-overview__exhibits" *ngIf="filterExhibits(exhibition).length">
						<label i18n>Articles</label>
						<div class="publishing-overview__exhibits-list">
							<div *ngFor="let unifiedArticle of filterExhibits(exhibition)" class="publishing-overview__header">
								<div
									class="is-live"
									[ngClass]="{
										'item--unpublished-changes': !unifiedArticle.isUpToDate,
										'item--is-live': unifiedArticle.isLive
									}"
								></div>
								<a [routerLink]="'/unifiedarticle/edit/'+unifiedArticle._id">{{unifiedArticle.articleID?.name}}</a>
								<button
									(click)="onPublishClick('UnifiedArticle', unifiedArticle._id)"
									[disabled]="unifiedArticle.isUpToDate || !unifiedArticle.isLive"
									class="button"
									i18n
								>
									publish
								</button>
							</div>
						</div>
					</div>
					<div class="publishing-overview__exhibits" *ngIf="filterTours(exhibition).length">
						<label i18n>Tours</label>
						<div class="publishing-overview__exhibits-list">
							<div *ngFor="let tour of filterTours(exhibition)" class="publishing-overview__header">
								<div
									class="is-live"
									[ngClass]="{
										'item--unpublished-changes': !tour.isUpToDate,
										'item--is-live': tour.isLive
									}"
								></div>
								<a [routerLink]="'/tour/edit/'+tour._id">{{tour.name}}</a>
								<button
									(click)="onPublishClick('Tour', tour._id)"
									[disabled]="tour.isUpToDate || !tour.isLive"
									class="button"
									i18n
								>
									publish
								</button>
							</div>
						</div>
					</div>
					<div class="publishing-overview__exhibits" *ngIf="filterQuizz(exhibition).length">
						<label i18n>Quizz</label>
						<div class="publishing-overview__exhibits-list">
							<div *ngFor="let tour of filterQuizz(exhibition)" class="publishing-overview__header">
								<div
									class="is-live"
									[ngClass]="{
										'item--unpublished-changes': !tour.isUpToDate,
										'item--is-live': tour.isLive
									}"
								></div>
								<a [routerLink]="'/tour/edit/'+tour._id">{{tour.name}}</a>
								<button
									(click)="onPublishClick('Tour', tour._id)"
									[disabled]="tour.isUpToDate || !tour.isLive"
									class="button"
									i18n
								>
									publish
								</button>
							</div>
						</div>
					</div>
					<div class="publishing-overview__exhibits" *ngIf="fitlerArticleLists(exhibition).length">
						<label i18n>Playlists</label>
						<div class="publishing-overview__exhibits-list">
							<div *ngFor="let articleList of fitlerArticleLists(exhibition)" class="publishing-overview__header">
								<div
									class="is-live"
									[ngClass]="{
										'item--unpublished-changes': !articleList.isUpToDate,
										'item--is-live': articleList.isLive
									}"
								></div>
								<a [routerLink]="'/playlist/edit/'+articleList._id">{{articleList.name}}</a>
								<button
									(click)="onPublishClick('ArticleList', articleList._id)"
									[disabled]="articleList.isUpToDate || !articleList.isLive"
									class="button"
									i18n
								>
									publish
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="publishing-infos" [ngClass]="{active: extendPublishing}">
				<span [ngClass]="{active: data.model.publishing.state !== 'published'}"
					>{{data.model.publishing.state}}</span
				>
				<div *ngFor="let item of data.model.publishing.publishingRuns">
					<table>
						<tr>
							<td><label i18n>Source</label></td>
							<td>{{item.sources.join(', ')}}</td>
						</tr>
						<tr>
							<td><label i18n>Date</label></td>
							<td>{{toDate(item.date)|date:'HH:mm dd.MM.yyyy'}}</td>
						</tr>
						<tr>
							<td><label i18n>Duration</label></td>
							<td>{{item.duration}} s</td>
						</tr>
						<tr>
							<td><label i18n>Errors</label></td>
							<td class="publishing-infos__errors">{{item.publishingErrors.join(', ')}}</td>
						</tr>
					</table>

					<div class="spacer"></div>
				</div>
				<div class="publishing-infos__extend" (click)="extendPublishing = !extendPublishing">
					<div class="icon-expand-arrow_L"></div>
				</div>
			</div>
		</div>
	</div>

	<div>
		<h3 i18n>Web Client</h3>
		<div>
			<label i18n>navigation map</label>
			<div class="row">
				<form-input
					class="check-label-right"
					formControlName="hasOnlineNavigationMap"
					[formGroup]="formGroup"
					type="boolean"
					i18n
				>
					Online map
				</form-input>
				<form-input
					type="enum"
					[disabled]="!formGroup.value.hasOnlineNavigationMap"
					formControlName="onlineMapBoundsLocationID"
					placeholder="… select a polygon location as bounds"
					i18n-placeholder
					[blankEntry]="true"
					[modelItems]="data.polygonLocations"
				>
				</form-input>
				<div i18n>Map bounds</div>
			</div>
			<form-input
				type="enum"
				[disabled]="formGroup.value.hasOnlineNavigationMap"
				formControlName="customMapArticleID"
				placeholder="… select a custom map article"
				i18n-placeholder
				[blankEntry]="true"
				[modelItems]="data.customMapAricles"
			></form-input>
		</div>
		<div>
			<label class="large" i18n>Locations</label>
			<input-locations
				[organisationID]="data.organisationID"
				[access]="data.access"
				formControlName="triggers"
			></input-locations>
			<div class="add-new-location" (click)="createLocationModal.open()">
				<span class="icon-add"></span>
				Create new location
			</div>
		</div>
		<form-modal #createLocationModal collectionName="Location">
			<h2 i18n> Create a new location </h2>
		</form-modal>
	</div>

	<!-- Progressive web app config -->
	<div formGroupName="progressiveWebApp" *ngIf="data.hasPwaFeature && formGroup.value.type?.includes('cloud')">
		<h3>Progressive Web App</h3>
		<div class="columns">
			<div class="column">
				<div>
					<label i18n>Short Name (max 12 characters) (required for PWA)</label>
					<input type="text" formControlName="short_name" placeholder="… short_name" />
				</div>
				<div>
					<label i18n>Name (max 45 characters) (required for PWA)</label>
					<input type="text" formControlName="name" placeholder="… name" />
				</div>
				<div>
					<label i18n>description (required for PWA)</label>
					<input type="text" formControlName="description" placeholder="… description" />
				</div>
				<div>
					<label i18n>icon (min size 512x512)</label>
					<input-media-file
						type="image"
						[organisationID]="data.organisationID"
						formControlName="icon"
					></input-media-file>
				</div>
			</div>
			<div class="column">
				<div>
					<label i18n>background color</label>
					<input type="text" formControlName="background_color" placeholder="… #fff" />
				</div>
				<div>
					<label i18n>theme color</label>
					<input type="text" formControlName="theme_color" placeholder="… #000" />
				</div>
				<div>
					<label i18n>display</label>
					<select formControlName="display" value="standalone">
						<option [value]="dm" *ngFor="let dm of progressiveWebAppDisplayModes">
							{{dm}}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>

	<!-- config for auto publishing -->
	<div formGroupName="autoPublishing" *ngIf="data.hasAutoPublishingFeature && formGroup.value.type?.includes('cloud') || formGroup.value.type?.includes('boxxes') || formGroup.value.type?.includes('boxxes&cloud')">
		<h3>Schedule Auto Publishing</h3>
		<div class="columns">
			<div class="column">
				<div>
					<label i18n>Day of Week (required for Scheduling)</label>
					<select formControlName="dayOfWeek">
						<option [value]="dayOfWeek" *ngFor="let dayOfWeek of dayOfWeek">
							{{dayOfWeek}}
						</option>
					</select>
				</div>
				<div>
					<label i18n>Day of Month (required for Scheduling)</label>
					<select formControlName="dayOfMonth">
						<option [value]="i+1" *ngFor="let dayOfMonth of dayOfMonth; let i = index">
							{{dayOfMonth}}
						</option>
					</select>
				</div>
			</div>
			<div class="column">
				<div>
					<label i18n>Month (required for Scheduling)</label>
					<select formControlName="month">
						<option [value]="month" *ngFor="let month of month">
							{{month}}
						</option>
					</select>
				</div>
				<div>
					<label i18n>Hour</label>
					<select formControlName="hour">
						<option [value]="i" *ngFor="let hr of hour; let i = index">
							{{hr}}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>


	<!-- ----------------------Admin Area----------------------------- -->
	<div class="spacer" *ngIf="data.currentUser.access.isAdmin"></div>
	<div class="admin-area" *ngIf="data.currentUser.access.isAdmin">
		<h2 i18n>shoutr labs admin features</h2>
		<div *ngIf="data.model.dataSourceTotalFileSize > 0">
			<label>File Size </label>
			<span class="file-size-info">
				{{convertBytesToHumanReadableSize(data.model.dataSourceTotalFileSize)}}
			</span>
		</div>
		<div class="first-row">
			<div><label>name</label> <input type="text" formControlName="name" /></div>
			<div>
				<a target="_blank" [href]="getLocalhostDomainPrefix()+'/datasourceconfig/f/'+id">Visit datasource config</a> <br>
				<a target="_blank" [href]="getLocalhostDomainPrefix()+'/datasourceconfig/pf/'+id">Visit published datasource</a>
			</div>
		</div>
		<div>
			<label>Type</label>
			<select formControlName="type" (change)="onTypeChange()">
				<option value="null" disabled selected>... select a type</option>
				<option *ngFor="let type of model.schema.type.enum" value="{{ type }}">{{ type }}</option>
			</select>
			<div class="spacer"></div>
			<div class="box-1-1">
				<a *ngIf="id" class="button" (click)="restoreRepository()">Rebuild repository</a>
			</div>
		</div>
		<div>
			<label>Platform</label>
			<select formControlName="platform">
				<option value="null" disabled selected>... select a type</option>
				<option *ngFor="let type of model.schema.platform.enum" value="{{ type }}">{{ type }}</option>
			</select>
		</div>
		<div>
			<ng-container
				*ngIf="formGroup.controls.type.value === 'sub' || formGroup.controls.type.value === 'derivative'"
			>
				<label>Main Datasource</label>
				<select formControlName="mainDataSourceID">
					<option [value]="datasource._id" *ngFor="let datasource of data.datasources"
						>{{ datasource.name }}</option
					>
				</select>
			</ng-container>
			<ng-container *ngIf="formGroup.controls.type.value !== 'sub'">
				<label>Related Datasource <small>(use for mediaplayer DS to bind to a DS)</small> </label>
				<select formControlName="relatedDataSourceID">
					<option [value]="datasource._id" *ngFor="let datasource of data.datasources"
						>{{ datasource.name }}</option
					>
				</select>
			</ng-container>
		</div>
		<div>
			<label>systemPks</label>
			<input-systempks formControlName="systemPks"></input-systempks>
		</div>
		<div *ngIf="formGroup.controls.type.value !== 'derivative'">
			<div *ngIf="formGroup.value.type?.includes('boxx')">
				<label>Captive Portal Exhibit</label>
				<form-input
					type="enum"
					formControlName="captivePortalExhibitID"
					placeholder="… select a captive portal exhibit"
					[blankEntry]="true"
					[modelItems]="data.exhibits"
				>
				</form-input>
			</div>
			<div>
				<label>landing mode</label>
				<ng-select formControlName="landingMode" [options]="data.landingModes"> </ng-select>
			</div>
			<div>
				<label>landing exhibit</label>
				<form-input
					type="enum"
					formControlName="landingExhibitID"
					placeholder="… select a landing page exhibit"
					[blankEntry]="true"
					[modelItems]="data.exhibits"
				>
				</form-input>
			</div>

			<div class="spacer"></div>
			<div class="box-1-1">
				<div>
					<div class="columns">
						<input
							class="sl-form__switch"
							id="compressMedia"
							type="checkbox"
							formControlName="compressMedia"
						/>
						<label for="compressMedia"></label> Compress media
					</div>
					<div class="columns">
						<input
							class="sl-form__switch"
							id="removeMissingLanguagePosts"
							type="checkbox"
							formControlName="removeMissingLanguagePosts"
						/>
						<label for="removeMissingLanguagePosts"></label> Remove posts if language is missing
					</div>
					<div class="columns">
						<input-switch formControlName="preventCloudUpdates" class="column">
							prevent cloud updates
						</input-switch>
					</div>
				</div>
				<div>
					<div class="columns" *ngIf="formGroup.value.type?.includes('cloud')">
						<input class="sl-form__switch" id="storeOnS3" type="checkbox" formControlName="storeOnS3" />
						<label for="storeOnS3"></label> Store packages on Amazon S3
					</div>
					<div class="columns">
						<div class="column">
							<label>Facebook App Id (Social Sharing)</label>
							<input type="text" formControlName="facebookAppId" placeholder="… facebook app id" />
						</div>
					</div>
				</div>
			</div>

			<!-- Web Client config -->
			<div>
				<h3>Web Client</h3>
				<div>
					<label>Custom JS Modules</label>
					<input-systempks formControlName="customJsModules"></input-systempks>
				</div>
				<div>
					<label>Block packages: </label>
					<input-systempks formControlName="blocks"></input-systempks>
				</div>
				<div>
					<label>Style Template</label>
					<input-styletemplate formControlName="styleTemplateID"></input-styletemplate>
				</div>

				<div *ngIf="formGroup.controls.type.value !== 'derivative'">
					<div>
						<label>Navigation Menu JSON</label>
						<input-json formControlName="navigationJson"></input-json>
					</div>
				</div>

				<web-client-config [formGroup]="formGroup.get('configWebClient')" formGroupName="configWebClient">
				</web-client-config>

			</div>

			<div formGroupName="appStoreIds">
				<h3>App Store</h3>
				<div class="columns">
					<div class="column">
						<label>App Store (Apple)</label>
						<input type="text" formControlName="ios" placeholder="… app store id" />
					</div>
					<div class="column">
						<label>Google Play Store</label>
						<input type="text" formControlName="android" placeholder="… app store id" />
					</div>
				</div>
			</div>
			
			<div>
				<h3>Payment Settings</h3>
				<div formGroupName="payment">
					<div class="columns">
						<input-switch formControlName="active" class="column"> Payment is activated </input-switch>
					</div>
					<div class="columns">
						<div class="column">
							<label>Loaner Key</label> <input type="text" formControlName="loanerKey" />
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<label>Ticket life time (min)</label> <input type="number" formControlName="ticketLifeTime" />
						</div>
						<div class="column">
							<label>Master Key</label> <input type="text" formControlName="masterKey" />
						</div>
					</div>
				</div>
			</div>

			<ng-container formGroupName="MQTTSupport" class="box-1-1">
				<h3>MQTT</h3>
				<div formGroupName="MQTTUserCredentials" class="box-1-1">
					<div><label>Username</label> <input type="text" formControlName="MQTTUsername" /></div>
					<div><label>Password</label> <input type="password" formControlName="MQTTPassword" /></div>
				</div>
				<div formGroupName="mobile" class="box-1-1">
					<div class="check-label-right">
						<input class="sl-form__switch" id="mqtt-mobile" type="checkbox" formControlName="active" />
						<label for="mqtt-mobile"></label> Mobile
					</div>
					<div><label>Mobile Host</label> <input type="text" formControlName="host" /></div>
					<div><label>Port</label> <input type="number" formControlName="port" /></div>
				</div>

				<div formGroupName="mediaPlayers" class="box-1-1">
					<div class="check-label-right">
						<input class="sl-form__switch" id="mqtt-media-players" type="checkbox" formControlName="active" />
						<label for="mqtt-media-players"></label> Media players
					</div>
					<div><label>Media player Host</label> <input type="text" formControlName="host" /></div>
					<div><label>Port</label> <input type="number" formControlName="port" /></div>
				</div>
				<div formGroupName="customTopic" class="box-1-1">
					<div class="check-label-right">
						<input class="sl-form__switch" id="mqtt-custom-topic" type="checkbox" formControlName="active" />
						<label for="mqtt-custom-topic"></label> Custom Topic
					</div>
					<div><label>Base Topic</label> <input type="text" formControlName="baseTopic" /></div>
					<div><label>Topic</label> <input type="text" formControlName="topic" /></div>
				</div>
			</ng-container>

			<div *ngIf="formGroup.value.type?.includes('boxx')">
				<h3>Boxxes config</h3>
				<div formGroupName="boxxesConfig">
					<boxx-config-form [group]="formGroup.controls.boxxesConfig" boxxType="all"></boxx-config-form>
				</div>
			</div>
			<div class="spacer"></div>
			<div *ngIf="formGroup.value.type?.includes('boxx')" class="box-1-1">
				<div>
					<label>offline Threshold in Minutes</label>
					<input type="number" formControlName="offlineThresholdMinutes" />
				</div>
				<div></div>
			</div>

			<div class="spacer"></div>

			<h3>Loaners</h3>
			<div formGroupName="configLoanerDevice">
				<div class="columns">
					<input-switch formControlName="enableBrightnessControl" class="column">
						Enable brightness control
					</input-switch>

					<div class="input-number" class="column">
						<div>Display brightness</div>
						<input type="number" step="0.1" min="0" max="1" formControlName="brightness" />
					</div>
				</div>
				<div class="columns">
					<input-switch formControlName="showDeviceInfo" class="column"> Show device info </input-switch>

					<input-switch formControlName="disableLoanerAnalytics" class="column">
						Disable loaner analytics
					</input-switch>
				</div>
				<div class="columns">
					<input-switch formControlName="preventSleep" class="column">Reset after</input-switch>
				</div>
				<div class="box-1-1">
					<div>
						<label>Time of inactivity</label> <input type="number" formControlName="inActivityIntervall" />
					</div>
					<input-switch formControlName="resetAfterInactivity" class="column">
						Reset after Inactivity
					</input-switch>
				</div>
			</div>

			<div>
				<h3>Beacon Settings</h3>
				<beacon-settings [formGroup]="formGroup.get('beaconSettings')" formGroupName="beaconSettings">
				</beacon-settings>
			</div>

			<div>
				<h3>Variable Storage</h3>
				<input-variable-storage formControlName="variableStorage"></input-variable-storage>
			</div>
			<hr />
		</div>
	</div>
</ng-container>
