/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { articleConfig } from '../../../_articles/config';
import config from '../../../config';


@Component({
	selector: 'input-media-list',
	template: `
<div class="exhibit-list">
	<div [dragula]='bagName' [(dragulaModel)]='value' class="drag-container">
		<div
			*ngFor="let id of value; let i = index;">
			<div>
				<h2>{{i + 1}}</h2>
				<span *ngIf="mediaObjects" [ngClass]="{'warning--message': mediaObjects[id]?.name === null}">
					{{(mediaObjects[id]?.name !== null) ? mediaObjects[id]?.name : 'Media could not be accessed'}}
				</span>
			</div>
			<div class="menu">
				<span class="icon-drag-reorder drag-handle"></span>
				<span class="icon-delete_sign" (click)="remove(i)"></span>
			</div>
		</div>
	</div>
	<a
		class="exhibit-list__add"
		(click)="selectMediaModal.open(); $event.preventDefault()">
		<span class="icon-add"></span>
		<div i18n>Add media</div>
	</a>
	<modal-component #selectMediaModal [clean]="true">
		<select-media
			*ngIf="selectMediaModal.isOpen()"
			[typeFilter]="type"
			[organisationID]="organisationID"
			(selectmedia)="add($event); selectMediaModal.close(); showMenu = false"></select-media>
	</modal-component>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputMediaListComponent), multi: true }],
})
export class InputMediaListComponent extends CustomValueAccessor {
	@Input() mediaObjects = {};
	@Input() type = 'audio';
	@Input() organisationID;

	constructor(drake: DragulaService, changeDetectorRef: ChangeDetectorRef, public i18n: I18n) {
		super(i18n);
		this.initOptions({
			drake,
			drakeOptions: {
				direction: 'vertical',
			},
			bagName: `unifiedarticlelist-${Math.random()}`,
			changeDetectorRef,
		});
		this.value = [];
	}
	add(id) {
		this.value = [...this.value, id];
	}
	remove(targetIndex) {
		this.value = this.value.filter((item, index) => index !== targetIndex);
	}
}
