
		<div class="list">
			<div *ngIf="hasTrigger">
				<div> <label i18n>Location</label> </div>
				<div> <label i18n>Trigger</label> </div>
				<span></span>
			</div>
			<div *ngFor="let entry of value">
				<div> {{ itemFromID(entry.locationID).name }} </div>
				<div *ngIf="hasTrigger"> {{ entry.triggerType }} </div>
				<span class="icon-remove" (click)="remove(entry)"></span>
			</div>
		</div>
		<div>
			<select-array
				*ngIf="optionSets[0].options.length"
				[optionSets]="optionSets"
				[clearOnComplete]="true"
				(complete)="add($event)"
			>
			</select-array>
			<div *ngIf="!optionSets[0].options.length">
				<p i18n>There are no locations available.</p>
			</div>
		</div>
	