
<div class="input-widget input-json">
	<textarea
		[ngModel]="text"
		(ngModelChange)="onTextChange($event)"
		required="required"
		(input)="$event.stopPropagation()"
		(change)="$event.stopPropagation()"
		[ngClass]="{invalid: !!validate()}">
	</textarea>
	<div class="error-text">
		{{errorText}}
	</div>
</div>
	