/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {switchMap} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';

import { getValidators } from '../../../services/schemaTools';
import { actions } from '../../../actions';
import { userConfig } from '../../../_users/config';
import { pluckDistinct } from '../../../utils';
import { selectors } from '../../../selectors';

@Component({
	selector: 'currentuser-form-container',
	template: `
	<div class="sl-form">
	<dynamic-model-form
		#dynamicModelForm
		collectionName="User"
		[id]="id$ | async"
		[data]="data$ | async"
		class="sl-form__content">
	</dynamic-model-form>
	<model-edit-footer
		[formGroup]="dynamicModelForm.formGroup"
		[model]="userConfig"
		[id]="id$ | async"
		[data]="data$ | async">
	</model-edit-footer>
	</div>`,
})
export class FormContainer {
	public data$;
	formGroup: FormGroup;
	userConfig = userConfig;
	id$: Observable<string>;

	constructor(private store: Store<any>, formBuilder: FormBuilder) {
		this.id$ = pluckDistinct(store, 'currentuser', 'currentuser', '_id'),

		this.data$ = this.id$.pipe(switchMap(id => userConfig.getFormData$(store, id)));

		this.formGroup = getValidators(formBuilder, userConfig.schema, userConfig.defaultData);
	}

	// save(data) {
	// 	console.log("save data", data);
	// 	this.store.dispatch(actions.patch(userConfig.collectionName, data));
	// }
}
