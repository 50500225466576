module.exports = {
	analyticsTypes: {
		uniqueVisitorsOverTime: 'uniqueVisitorsOverTime',
		pageviewsOverTime: 'pageviewsOverTime',
		pageviewsPerUniqueVisitorOverTime: 'pageviewsPerUniqueVisitorOverTime',
		deviceModel: 'deviceModel',
		visitsPerTime: 'visitsPerTime',
		pageTitles: 'pageTitles',
		googlePlayStoreInstalls: 'googlePlayStoreInstalls',
	},
};
