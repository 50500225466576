
<div class="input-widget">
	<select
		[(ngModel)]="value"
		(change)="propagateChange()">
		<option [ngValue]="null" selected i18n>... select a style template</option>
		<option
			*ngFor="let template of ((styletemplates$ | async) | filter:(currentUserOrg$ | async)._id)"
			[value]="template._id">{{template.name}}
		</option>
	</select>
</div>
	