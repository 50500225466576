
<div class="input-widget">
	<div class="icon-filter"></div>
	<div
		class="filters"
		*ngIf="this.filterBoxes.length">
		<div *ngFor="let name of filterBoxes">
			{{name}} <span class="icon-clear-field_S" (click)="clear()"></span>
		</div>
	</div>
	<input
		#textBox
		[style.display]="this.filterBoxes.length ? 'none' : ''"
		type="text"
		[value]="value"
		(keyup.enter)="this.value = $event.target.value"
		(keyup.escape)="clear()"
		(keyup)="keyup.emit($event)"
		i18n-placeholder
		placeholder="… filter"/>
	<span (click)="clear()" class="clear icon-clear-field_S"></span>
</div>
	