<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<pre>{{ data | json }}</pre>

	<a
		[href]="config.baseURL + '/api/applogs/' + data._id + '/data'"
		class="button"
		[attr.download]="data._id + '.log'"
		i18n
		>Download Log</a
	>
</ng-container>
