
<div class="input-widget select-array" *ngIf="value !== null">
	<div *ngFor="let optionSet of optionSets; let optionSetIndex = index;">
		<div [ngSwitch]="optionSet.type">
			<div *ngSwitchCase="'text'">
				<label>{{optionSet.label}}</label>
				<input
					[(ngModel)]="value[optionSetIndex]"
					(input)="onSelect($event, optionSetIndex)"
					[placeholder]="optionSet.placeholder"
					type="text" />
			</div>
			<ng-select
				*ngSwitchDefault
				(selected)="onSelect($event, optionSetIndex)"
				[(ngModel)]="value[optionSetIndex]"
				[options]="optionSet.options"
				[label]="optionSet.label"
				[placeholder]="optionSet.placeholder">
			</ng-select>
		</div>
	</div>
</div>
	