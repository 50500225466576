/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import appConfig from '../../../config';

@Component({
	selector: 'article-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class ArticleFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	public baseURL = appConfig.baseURL;
	public model: any;

	hasUnsavedChanges() {
		return !(this.id && this.formGroup.pristine);
	}

	editAppBtnTitleText() {
		return this.hasUnsavedChanges() ? 'Please save your changes first' : 'Edit the article in the editor';
	}

	preventClick(event) {
		return this.hasUnsavedChanges() ? event.preventDefault : event;
	}
}


