/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import { Component, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import * as isoLangs from '../../../../../models/languageObjects/isoLangs';
import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { selectors } from '../../../selectors';
import { articleConfig } from '../../../_articles/config';
import config from '../../../config';


@Component({
	selector: 'input-article',
	template: `
	<style>
:host(.ng-invalid) >>> .add-new {
	border: 1px solid #CD0026;
}
	</style>
<div *ngIf="value && articleFromID(value)" class="article">
	<label class="large" i18n>Article</label>
	<div class="first-row">
		<div class="title">{{articleFromID(value).name}}</div>
	</div>
	<div class="box-1-1">
		<div> <!-- first child 1/2 -->
			<label i18n>cover</label>
			<div
				class="cover"
				[ngStyle]="{'background-image': getUrl(articleFromID(value))}"></div>
		</div>
		<div> <!-- last child 1/2 -->
			<label i18n>languages</label>
			<div *ngFor="let lc of articleFromID(value).languageContent">{{isoLangsObj[lc.languageISO]}}</div>
			<div class="spacer"></div>
			<label i18n>comments</label>
			{{articleFromID(value).components}}
		</div>
	</div>
	<div class="input-article__footer">
		<div (click)="editArticleModal.open()">
			<span class="icon-edit"></span> edit
		</div>
		<div (click)="value = null">
			<span class="icon-remove"></span> remove
		</div>
	</div>

	<form-modal #editArticleModal collectionName="Article" [id]="value">
		<h2 i18n>Edit this exhibit's article</h2>
	</form-modal>
</div>
<div class="input-widget" *ngIf="!(value && articleFromID(value))">
	<div class="add-new" (click)="$event.preventDefault(); selectArticleModal.open()" i18n><span class="icon-collection"></span> select article</div>
</div>

<modal-component #selectArticleModal>
	<select-article
		*ngIf="selectArticleModal.isOpen()"
		(selectarticle)="value = $event; selectArticleModal.close()">
	</select-article>
</modal-component>
	`,
	styleUrls: ['./input-article.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputArticleComponent), multi: true }],
})
export class InputArticleComponent extends CustomValueAccessor {

	private isoLangsObj = {};
	private articleConfig;
	private mediaObjectEntities;
	private articleEntites;

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		isoLangs.forEach((lang) => { this.isoLangsObj[lang.iso] = lang.name; });
		this.articleConfig = articleConfig;
		observableCombineLatest(
			store.pipe(pluck('models', 'Article'), map(selectors.getAlphabeticList), ),
			store.pipe(pluck('models', 'MediaObject'), map(selectors.getEntities), ),
			(articles, mediaobjects) => ({ articles, mediaobjects }),
		).subscribe((data) => {
			this.mediaObjectEntities = data.mediaobjects;
		});

		store.pipe(pluck('models', 'Article'),
			map(selectors.getEntities), )
			.subscribe((ent) => {
				this.articleEntites = ent;
			});
	}
	getUrl(article) {
		const mediaObject = this.mediaObjectEntities[article.coverID];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source})`;
	}

	articleFromID(id) {
		return this.articleEntites[id];
	}
}
