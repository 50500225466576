/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';

import { FormInputsModule } from '../forminputs/forminputs.module';

import { routes } from './routes';

import { TicketsComponent } from './components/tickets.component';
import { TicketsFilterComponent } from './components/tickets-filter.component';

@NgModule({
	imports: [
		FormInputsModule,
		routes,
	],
	exports: [
		TicketsComponent,
	],
	declarations: [
		TicketsComponent,
		TicketsFilterComponent,
	],
})
export class PaymentsModule { }
