/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable ,  Subject } from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { pluckDistinct } from '../../utils';

import appConfig from '../../config';

@Component({
	selector: 'exhibition-preview-container',
	template: `
	<exhibition-preview
		[id]="id"
		[updateCounter]="count"
		[collectionName]="collectionName"
		[exhibitEntities]="exhibitEntities$ | async">
	</exhibition-preview>
	`,
})
export class ExhibitionPreviewContainer {
	public config = appConfig;
	public id: string;
	public collectionName: string;
	public count = 0;
	public exhibitEntities$: Observable<any>;

	private destroyed$ = new Subject();

	constructor(protected store: Store<any>) {
		this.store.select('routeInfo').pipe(takeUntil(this.destroyed$)).subscribe(routeInfo => {
			this.id = routeInfo.id;
			this.collectionName = routeInfo.collectionName;
			this.count++;
			if (routeInfo.versionId !== undefined) this.collectionName = '';
		});

		this.exhibitEntities$ = pluckDistinct(this.store, 'models', 'Exhibit', 'entities');

		observableCombineLatest(
				pluckDistinct(this.store, 'models', 'Exhibition'),
				pluckDistinct(this.store, 'models', 'Article'),
				this.exhibitEntities$,
			).pipe(
			takeUntil(this.destroyed$))
			.subscribe(() => {
				this.count++;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
