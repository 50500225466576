/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var */
var Schema = require('mongoose').Schema;
var getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	name: { type: String, required: true },
	languageContent: {
		type: [
			{
				languageISO: { type: String, required: true },
				term: { type: String, required: true },
				keywords: [{ type: String, required: true }],
				definition: { type: String, required: true },
			},
		],
		required: true,
	},
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'GlossaryTerm',
	labels: getLabels(schemaDefinition),
	schema: ModelSchema,
	definition: schemaDefinition,
};
