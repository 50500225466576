<form
	class="sl-form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()">

	<div class="sl-form__content">
		<div class="row">
			<div>
				<label i18n>Old password</label>
				<input type="password" formControlName="oldPassword">
			</div>
		</div>

		<div class="row">
			<div>
				<label i18n>New password</label>
				<input type="password" [minlength]="MIN_LENGTH" formControlName="newPassword">
			</div>
			<div class="sl-form__error-inline" *ngIf="form.controls.newPassword.dirty && !form.controls.newPassword.valid && form.controls.newPassword.errors.minlength" i18n>
				Your password needs to be at least {{ MIN_LENGTH }} characters long.
			</div>
		</div>

		<div class="row">
			<div>
				<label i18n>Repeat new password</label>
				<input type="password" [minlength]="MIN_LENGTH" formControlName="newPasswordRepeated">
			</div>
			<div class="sl-form__error-inline" *ngIf="form.controls.newPasswordRepeated.dirty && !form.controls.newPasswordRepeated.valid && form.controls.newPasswordRepeated.errors.minlength" i18n>
				Your password needs to be at least {{ MIN_LENGTH }} characters long.
			</div>
		</div>

		<div *ngIf="form.dirty && !form.valid" class="sl-form__error">
			<p *ngIf="form.errors && form.errors.areEqual" i18n>
				Passwords do not match!
			</p>
		</div>
	</div>

	<div class="sl-form__footer">
		<div></div>
		<input type="submit" value="Save" class="button button--white-ghost" [disabled]="!(form.valid && form.dirty)">
	</div>
</form>
