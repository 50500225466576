/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { CanDeactivate }	from '@angular/router';
import { Observable } from 'rxjs';

import { ModelEditComponent } from '../components/model-edit.component';
import { DynamicModelFormComponent } from '../components/dynamic-model-form.component';

/**
 * Ask to navigation from model-edit when the current form has unsaved changes
 */
export interface EditRouteComponent {
	dynamicModelForm: DynamicModelFormComponent;
	uploading?: boolean;
	askToSave: () => Promise<boolean>;
}

@Injectable()
export class EditRouteSaveGuard implements CanDeactivate<EditRouteComponent> {

	async canDeactivate(
		component: EditRouteComponent,
	): Promise<boolean> {

		if (component.uploading) {
			return component.askToSave();
		}

		const dynamicModelForm = component.dynamicModelForm;

		return (dynamicModelForm && dynamicModelForm.formGroup && dynamicModelForm.formGroup.dirty)
			? component.askToSave()
			: true;
	}
}
