/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, Validators } from '@angular/forms';
import {filter, map, pluck, switchMap} from 'rxjs/operators';

import { currentuserActions } from '../actions';

@Component({
	selector: 'validate2fa-component',
	template: `
		<form class="login" [formGroup]="form" (ngSubmit)="onSubmit()">
			<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100" />
			<h1>shoutr labs</h1>
			<div class="form">
				<h2 i18n>Authenticate Your Account</h2>
				<div>
					<label i18n>Authentication Code</label>
					<input type="text" formControlName="authenticationCode" />
				</div>

				<button class="button" type="submit" [disabled]="!(form.valid && form.dirty)">Validate Code</button>
			<div class="forgot-password">
				<a href="javascript:void(0);" (click)="resend2factorEmail();" i18n *ngIf="!userNotAuthenticated">Resend a new code</a>
		    </div>
			</div>
			<div class="footer">
				<a href="https://www.shoutrlabs.com/datenschutz/?lang=en" i18n>Privacy</a>
				| <a href="https://www.shoutrlabs.com/impressum/?lang=en" i18n>Imprint</a>
			</div>
		</form>
	`,
	styleUrls: ['./login.component.sass'],
})
export class Validate2FCodeComponent {
	public form;
	public userNotAuthenticated = true;

	constructor(private fb: FormBuilder, private store: Store<any>) {
		this.fb = fb;
		this.store.pipe(pluck('currentuser')).subscribe(user => {
			this.userNotAuthenticated = user.userNotAuthenticated;
		})
	}

	ngOnInit() {
		this.form = this.fb.group({
			authenticationCode: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
		});
	}

	onSubmit() {
		if (this.form.valid) {
			this.store.dispatch(
				currentuserActions.validate2FactorAuthenticationCode({
					token: this.form.controls['authenticationCode'].value,
				})
			);
		}
		return false;
	}
	resend2factorEmail() {
		this.store.dispatch(currentuserActions.resend2factorEmail());
	}
}
