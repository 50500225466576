/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { messageActions } from './actions';

const initialState = {
	sticky: [],
	transient: [],
};

export function reducer(state = initialState, action) {
	if (
		action.type !== messageActions.MESSAGE_ERROR &&
		action.type !== messageActions.MESSAGE_WARN &&
		action.type !== messageActions.MESSAGE_INFO &&
		action.type !== messageActions.MESSAGE_CLOSE
	) {
		return state;
	}

	state = { ...state };

	if (action.type === messageActions.MESSAGE_CLOSE) {
		state.sticky = state.sticky.filter(message => message.id !== action.payload);
		state.transient = state.transient.filter(message => message.id !== action.payload);
	} else {
		const newMessage = {
			closeable: true,
			...action.payload,
			type: action.type.replace('MESSAGE_', '').toLowerCase(),
		};

		// prepend message to start of list
		const targetList = action.payload.sticky ? 'sticky' : 'transient';

		state[targetList] = [newMessage].concat(state[targetList]);
	}

	return state;
}
