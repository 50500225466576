/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint no-nested-ternary: "off"*/

const byString = (object, objectPath) => {
	// convert indexes to properties
	objectPath = objectPath.replace(/\[(\w+)\]/g, '.$1');
	// strip a leading dot
	objectPath = objectPath.replace(/^\./, '');
	const objectPathParts = objectPath.split('.');
	let k;

	for (k of objectPathParts) {
		if (k in object) {
			object = object[k];
		} else {
			throw new Error(`Path ${objectPath} does not exist`);
			// throw { error: `Path ${objectPath} does not exist` };
		}
	}
	return object;
};

export function sortListOfObjects (list, objectPath, reverse?) {
	list.sort((a, b) => {
		let ret = 1;

		try {
			const valA = byString(a, objectPath).toLowerCase();
			ret = -1;
			const valB = byString(b, objectPath).toLowerCase();

			if (reverse) return valA === valB ? 0 : valA < valB ? 1 : -1;
			return valA === valB ? 0 : valA > valB ? 1 : -1;
		} catch (e) {
			// console.warn('Error sorting: ', e, a, b);
			return ret * (reverse ? -1 : 1);
		}
	});
};

export function getSortedItems(
	modelState: any,
	sortByObjectPath: string,
	reverse?: boolean
) {
	const itemsList = Object.values(modelState.entities);

	sortListOfObjects(itemsList, sortByObjectPath, reverse);

	return itemsList;
}
