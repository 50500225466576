/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {map} from 'rxjs/operators';
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import * as CollectionActions from '../../socket.actions';
import { selectors } from '../../selectors';
import { mediaConfig } from '../../_media/config';
import config from '../../config';

@Component({
	selector: 'input-media-file',
	template: `
<div class="input-media-file">
	<div class="preview">
		<div [ngSwitch]="type" class="background">
			<div *ngSwitchCase="'image'" [ngStyle]="{'background-image': 'url(' + getUrl() + ')'}"></div>
		  <audio controls preload="none" *ngSwitchCase="'audio'" [src]="getUrl()">
			   Your browser does not support the <code>audio</code> element.
			</audio>
			<video controls preload="none" *ngSwitchCase="'video'" [src]="getUrl()" [attr.poster]="getUrl('thumbSource')">
			   Your browser does not support the <code>audio</code> element.
			</video>
		  <div *ngSwitchDefault></div>
		</div>
		<div class="preview__overlay">
			<span *ngIf="mediaObjectEntities[value]">{{mediaObjectEntities[value].name}}</span>
		</div>
		<div class="btn-menu-group">
			<span
					*ngIf="value"
					class="menu-btn icon-circled-chevron-down"
					(click)="showMenu = !showMenu"
					[ngClass]="{'menu-btn--active': showMenu}"></span>
			<span
				*ngIf="!value"
				class="add-btn icon-add"
				(click)="$event.preventDefault(); selectMediaModal.open()"></span>
		</div>
	</div>

	<div class="menu" [ngClass]="{'menu--active': showMenu, 'menu--left': menuDir === 'left'}" *ngIf="value">
		<a target="_blank" [hidden]="!value" [href]="getUrl()" download>
			<span class="icon-download-from-cloud"></span>
			Download
		</a>
		<div (click)="$event.preventDefault(); selectMediaModal.open()">
			<span class="icon-collection"></span>
			Change
		</div>
		<div (click)="value = null; showMenu = false">
			<span class="icon-remove"></span>
			Remove
		</div>
	</div>
</div>

<modal-component #selectMediaModal [clean]="true">
	<select-media
		*ngIf="selectMediaModal.isOpen()"
		[typeFilter]="type"
		[organisationID]="organisationID"
		(selectmedia)="setValue($event); selectMediaModal.close(); showMenu = false"></select-media>
</modal-component>
	`,
	styleUrls: ['./input-media-file.component.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputMediaFileComponent), multi: true },
	],
})
export class InputMediaFileComponent extends CustomValueAccessor {
	@Input() type;
	@Input() organisationID;
	@Input() menuDir;

	@Output() change = new EventEmitter(false);

	public mediaObjectEntities = {};
	public showMenu;
	private subscription: Subscription;

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		store.dispatch(new CollectionActions.GetCollectionList({ collectionName: mediaConfig.collectionName }));
		this.subscription = store
			.select(s => s.models.MediaObject).pipe(
			map(selectors.getEntities))
			.subscribe((entities) => {
				this.mediaObjectEntities = entities;
			});
	}

	ngOnDestroy() {
		this.store.dispatch(new CollectionActions.LeaveCollectionList({ collectionName: mediaConfig.collectionName }));
		this.subscription.unsubscribe();
	}

	setValue(id) {
		this.value = id;
		this.change.emit(id);
	}

	getUrl(source = 'source') {
		if (!this.value) return '';
		if (!this.mediaObjectEntities) return '';
		const mediaObject = this.mediaObjectEntities[this.value];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0][source]}`;
	}
}
