/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { AppHttp } from '../../../services/AppHttp';
import { previewDomain, bytesToHumanReadableSize } from '../../../utils';
import { progressiveWebAppDisplayModes } from '../../../../../models/dataSource/dataSourceSchema';
import { dayOfWeek } from '../../../../../models/dataSource/dataSourceSchema';
import { month } from '../../../../../models/dataSource/dataSourceSchema';
import { dayOfMonth } from '../../../../../models/dataSource/dataSourceSchema';
import { hour } from '../../../../../models/dataSource/dataSourceSchema';
import * as SocketActions from '../../../socket.actions';

@Component({
	selector: 'datasource-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class DatasourceFormComponent {
	@Input() id = '';
	@Input() model: any;
	@Input() data: any;
	@Input() formGroup: FormGroup;

	public progressiveWebAppDisplayModes = progressiveWebAppDisplayModes;
	public dayOfWeek = dayOfWeek;
	public month = month;
	public dayOfMonth = dayOfMonth;
	public hour = hour;
	public extendPublishing = false;

	constructor(private appHttp: AppHttp, private store: Store<any>) {}

	ngOnChanges(changes) {
		if (changes.formGroup && this.formGroup) {
			// if the type is 'sub' then it must have a mainDataSourceID set
			this.formGroup.controls.mainDataSourceID.setValidators([
				formControl =>
					this.formGroup.controls.type.value === 'sub' ? Validators.required(formControl) : null,
			]);
		}
	}

	onTypeChange() {
		this.formGroup.patchValue({ mainDataSourceID: null }, { emitEvent: false });
	}
	restoreRepository() {
		this.appHttp.post(`/datasource/${this.id}/republish`).subscribe();
	}

	previewDomain() {
		return previewDomain(this.data.model);
	}

	convertBytesToHumanReadableSize(size) {
		return bytesToHumanReadableSize(size);
	}
	toDate(dateString) {
		return new Date(dateString);
	}
	filterExhibits(exhibition) {
		return this.data.unifiedArticles.filter(({ exhibitionID }) => exhibitionID === exhibition._id);
	}
	filterTours(exhibition) {
		return this.data.tours.filter(({ exhibitionID, isQuiz }) => !isQuiz && exhibitionID === exhibition._id);
	}
	filterQuizz(exhibition) {
		return this.data.tours.filter(({ exhibitionID, isQuiz }) => isQuiz && exhibitionID === exhibition._id);
	}
	fitlerArticleLists(exhibition) {
		return this.data.articleLists
			.filter(({ boxxID }) => boxxID && this.data.boxxes[boxxID])
			.map(item => ({ ...item, dataSourceID: this.data.boxxes[item.boxxID].dataSourceID }));
	}
	onPublishClick(collectionName, id) {
		event.preventDefault();
		this.store.dispatch(new SocketActions.PublishChanges({ collectionName, id }));
	}
	getLocalhostDomainPrefix() {
		if (window.location.host.includes('localhost')) return 'http://localhost:3000';
		return '';
	}
}
