
<div class="media-files-list">
	<div [dragula]='bagName' [(dragulaModel)]='value' class="drag-container">
		<div
			*ngFor="let listItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					<label class="drag-handle">
						<ng-container i18n>Article</ng-container>
						<span class="make-default-hint" *ngIf="i != 0;" i18n>(drag to move)</span>
					</label>
					{{articleFromID(listItem.articleID) && articleFromID(listItem.articleID).name}}
					<div
						class="cover"
						[ngStyle]="{'background-image': getUrl(articleFromID(listItem.articleID))}"></div>
				</div>
				<div class="input-number">
					<label i18n>Duration <small> in s</small></label>
					<div>
						<input type="number" (input)="setDuration(listItem, $event)" [value]="listItem.duration"/>
					</div>
				</div>
				<div class="input-number">
					<label i18n>Jump to<small> on end</small></label>
					<div>
						<input type="number" (input)="setJumpTo(listItem, $event)" [value]="listItem.jumpTo"/>
					</div>
				</div>
				<div class="input-number"  *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label i18n>Stop Duration <small> in s</small></label>
					<div>
						<input type="number" (input)="setStopDuration(listItem, $event)" [value]="listItem.stopDuration"/>
					</div>
				</div>
				<div class="input-number" *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label>DMX ID</label>
					<div>
						<input type="number" (input)="setDmxID(listItem, $event)" [value]="listItem.dmxID"/>
					</div>
				</div>
				<div class="input-number" *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label>CAST ID</label>
					<div>
						<input type="number" (input)="setCastID(listItem, $event)" [value]="listItem.castID"/>
					</div>
				</div>
				<div class="" *ngIf="articleEntities[listItem.articleID]?.type != 'audiosync-media'">
					<label>TRIGGER</label>
					<div>
						<select (change)="setTrigger(listItem, $event)" [value]="listItem.trigger">
							<option [value]="triggerOption" *ngFor="let triggerOption of triggerOptions">{{triggerOption}}</option>
						</select>
					</div>
				</div>
			<div class="check-label-right"  *ngIf="articleEntities[listItem.articleID]?.type !== 'audiosync-media'">
				<input (change)="propagateChange()" class="sl-form__switch" [attr.id]="i + 'loop'" type="checkbox" [(ngModel)]="listItem.loop">
				<label [attr.for]="i + 'loop'"></label>
				Loop
			</div>
			<div class="check-label-right"  *ngIf="articleEntities[listItem.articleID]?.type !== 'audiosync-media'">
				<input (change)="propagateChange()" class="sl-form__switch" [attr.id]="'autoStartContent-' + i" type="checkbox" [(ngModel)]="listItem.autoStartContent">
				<label [attr.for]="'autoStartContent-' + i"></label>
				Auto start content
			</div>

			</div>
			<div class="footer">
				<div (click)="remove(i); $event.preventDefault()" i18n>
					<span class="icon-minus"></span>REMOVE
				</div>
			</div>
		</div>
	</div>
	<div class="media-files-item media-files-list__new-item body" (click)="selectArticleModal.open(); $event.preventDefault()" i18n>
		<span class="icon-add"></span> Add an article
	</div>
	<div class="spacer"> </div>
</div>

<modal-component #selectArticleModal>
<select-article
	#selectArticle
	*ngIf="selectArticleModal.isOpen()"
	(selectarticle)="add($event); selectArticleModal.close()">
</select-article>
</modal-component>
	