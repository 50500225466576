/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
const getLabels = require('../getLabels').getLabels;

var articleTypes = require('../../helpers/consts').ARTICLE_TYPES;

var prefabTypes = ['markerBasedTracking', 'extendedMarkerTracking', 'markerLessTracking', 'extendedContinousTracking'];

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition', index: true, unique: false },
	isDirty: { type: Boolean, default: false },
	name: { type: String, required: true, unique: false, sparse: true, trim: true, label: 'Name' },
	coverID: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image' }, // Has to be type Image
	coverIDVersion: { type: Number },
	type: {
		type: String,
		required: true,
		enum: Object.keys(articleTypes),
		default: 'builder',
		isField: true,
	},
	isShareable: { type: Boolean, default: false },
	shareTargetUrl: { type: String, default: null, required: false },
	isLoop: { type: Boolean, default: false },
	immortal: { type: Boolean, default: false },
	delayBetweenLoops: { type: Number }, // in seconds
	comments: { type: String, trim: true },
	tags: [{ type: String }],
	languageContent: { type: [require('./languageArticleSchema').definition], required: true },
	mediaObjectID: { type: Schema.ObjectId, ref: 'MediaObject' },
	// only for admin
	arContent: require('./arContentSchema').definition,
	customMapContent: require('./customMapContentSchema').definition,
	// FIXME legacy remove when all webclients are updated;
	// now located in customMapContent.mapMetaData
	distortableImageCorners: { type: Object },
	customFileID: { type: Schema.ObjectId },
	externalURL: { type: String },
	builderContent: { type: String, default: null }, // String in JSON format
	editorContent: { type: Object, default: null }, // String in JSON format
	// not filled by user
	mediaObjects: [{ type: Schema.ObjectId, ref: 'MediaObject' }], // filled in webbuilder, not CMS
	publishedInfos: [
		{
			version: { type: Number },
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
			mediaObjects: [
				{
					id: { type: Schema.ObjectId, ref: 'Article' },
					version: { type: Number },
				},
			],
			mediaFiles: [{ type: Schema.ObjectId }],
			originalMediaFiles: [{ type: Schema.ObjectId }],
		},
	],
	audioSyncStart: { type: Date, default: null },
	isQuizArticle: { type: Boolean, default: false },
	prefabType: { type: String, enum: prefabTypes, default: 'markerBasedTracking' },
	game: require('./gameSchema').definition,
	// Sorted List of Version which have been published

	// To quickly check access restriction based on organistion.
};
var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog
	
module.exports = {
	name: 'Article',
	schema: ModelSchema,
	definition: schemaDefinition,
	articleTypes,
	prefabTypes,
	labels: Object.assign(getLabels(schemaDefinition), {
		languageContent: 'Language content',
	}),
	relations: [
		{
			op: 'del',
			rel: 'Exhibit',
			path: 'articleID',
			deleteRelatives: true,
		},
	],
};
