
<div [dragula]='bagName' [(dragulaModel)]='value' class="list">
	<div *ngFor="let iso of value; let i = index" class="drag-handle">
		<span>
			{{languages[iso].name}}
			<span *ngIf="i === 0" i18n>(fallback language)</span>
			<span *ngIf="i !== 0" i18n>(drag up to make fallback language)</span>
		</span>
		<span class="icon-remove" (click)="deleteIso = iso;confirmDeleteModal.open()"></span>
	</div>
</div>
<modal-component #confirmDeleteModal>
	<ng-container i18n>Deleting a language also deletes all language content in exhibitions, exhibits, and media files.</ng-container>
	<br />
	<button type="button" class="button button--red" (click)="remove(deleteIso);confirmDeleteModal.close()" i18n>Delete</button>
</modal-component>
<div class="input-widget">
	<input-language
		(change)="setSelectedLang($event); propagateChange()"
		[excludeLanguages]="value || []"
		[(ngModel)]="langInput"></input-language>
</div>
	