/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { AppState } from '../../reducers';
import { ticketConfig } from '../../_tickets/config';

@Component({
	selector: 'tickets-filter',
	template: `
	<ng-content></ng-content>
	<div class="columns">
	<span class="column is-5" i18n>Year</span>
		<span class="column">
			<select type="date" [(ngModel)]="year">
				<option [value]="0" i18n>All years</option>
				<option
					*ngFor="let year of years; let i = index"
					[value]="year">{{ year }}</option>
			</select>
		</span>
	</div>
	<div class="columns" [ngStyle]="{ visibility: this.year ? '' : 'hidden' }">
		<span class="column is-5" i18n="Quater as in quater of the year">Quarter</span>
		<span class="column">
			<select type="date" [(ngModel)]="quarter">
				<option [value]="0" i18n>All quarters</option>
				<option
					*ngFor="let q of [1, 2, 3, 4]"
					[value]="q" i18n="Q for quater of the year">Q{{ q }}</option>
			</select>
		</span>
	</div>
	`,
	styleUrls: ['./tickets.component.sass'],
})
export class TicketsFilterComponent {
	@Input() tickets: any[];
	@Input() organisation: any;
	@Output() filterEvent = new EventEmitter<any[]>();
	result = [];
	convertedCount = 0;

	currentYear = new Date().getFullYear();
	currentQuarter = getQuarter(new Date().getMonth());
	years: number[];
	_year = this.currentYear;
	_quarter = this.currentQuarter;

	set year(value: any) {
		this._year = parseFloat(value);

		if (!this._year) {
			this.quarter = 0;
		}

		this.filter();
	}
	get year() {
		return this._year;
	}

	set quarter(value: any) {
		this._quarter = parseFloat(value);
		this.filter();
	}
	get quarter() {
		return this._quarter;
	}

	constructor(private cdr: ChangeDetectorRef) {
		this.years = new Array(this.currentYear - 2009).fill(null).map((_, i) =>
			this.currentYear - i
		);

	}

	ngAfterViewInit() {
		this.filter();
	}

	filter() {
		const date = new Date();

		let filtered = this.tickets || [];
		this.convertedCount = 0;

		if (this.year) {
			filtered = filtered.filter(ticket => {
				const createdOn = new Date(ticket.createdOn);

				const included = createdOn.getFullYear() === this.year
					&& (this.quarter === 0 || this.quarter === getQuarter(createdOn.getMonth()));

				if (included && ticket.convertedOn) {
					this.convertedCount++;
				}

				return included;
			});
		}

		this.result = filtered;
		this.filterEvent.emit(filtered);
	}

	ngOnChanges(changes) {
		this.filter();
	}
}

function getQuarter(month) {
	return Math.floor(month / 3) + 1
}
