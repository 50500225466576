
<div
	[ngClass]="{invalid: missingValues}">
	<div *ngIf="this.missingValues" class="invalid-message">
		The organisation is missing or there are no languages set for your organisation.
	</div>
	<div
		[ngClass]="{'media-files-list--hide': this.missingValues}"
		[dragula]="bagName"
		[(dragulaModel)]="value"
		class="drag-container media-files-list">
		<div
			*ngFor="let languageItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					{{isoLang2name[languageItem.languageISO]}}
				</div>
				<div *ngIf="enableDrag" class="drag-handle">
					<span class="make-default-hint" i18n>(drag left / right to change order in app)</span>
				</div>
				<div>
					<label i18n>User-visible Title</label>
					<input
						type="text"
						i18n-placeholder
						required="true"
						placeholder="… title"
						(change)="propagateChange()"
						[attr.disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.title">
				</div>
				<div>
					<label i18n>Description</label>
					<input-text
						cols="30"
						rows="4"
						i18n-placeholder
						placeholder="… description"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.description"></input-text>
				</div>
				<div >
					<label i18n>content</label>
					<input-text
						cols="30"
						rows="4"
						i18n-placeholder
						placeholder="… text"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.content"></input-text>
				</div>
				<div class="spacer"></div>
				<div *ngIf="canDisableLanguage" class="can-disable">
					<input
						class="sl-form__switch"
						[attr.id]="languageItem.languageISO+'disable'"
						[(ngModel)]="languageItem.disabled"
						(change)="propagateChange()"
						type="checkbox" />
					<label [attr.for]="languageItem.languageISO+'disable'"></label>
					Disable
				</div>
			</div>
		</div>
	</div>
</div>
	