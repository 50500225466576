/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { MessageComponent } from './message.component/message.component';
import { MessagesComponent } from './messages.component/messages.component';
import { MessagesEffects } from './effects';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([MessagesEffects]),
	],
	exports: [MessagesComponent],
	declarations: [
		MessageComponent,
		MessagesComponent,
	],
})
export class MessagesModule { }
