/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable ,  Subject } from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect } from '@ngrx/effects';

import { pluckDistinct } from '../utils';

@Component({
	selector: 'request-indicator',
	template: `
	<loading-spinner [loading]="loading">
		<ng-content></ng-content>
	</loading-spinner>
`,
})
export class RequestIndicatorComponent {
	@Input() set ids (value: string[]) {
		this.ids$.next(value);
	}
	@Input() requireStarted = false;

	public destroyed$ = new Subject();
	private ids$ = new Subject<string[]>();
	public loading = true;
	private pendingRequests$: Observable<{ [key: string]: boolean }>
		= pluckDistinct(this.store, 'status', 'pendingRequests');

	constructor (private store: Store<any>, private cdr: ChangeDetectorRef) {
		observableCombineLatest(this.pendingRequests$, this.ids$).pipe(
			takeUntil(this.destroyed$))
			.subscribe(([pendingRequests, ids]) => {
				this.loading = !ids || ids.some(id => {
					return this.requireStarted
						? pendingRequests[id] !== false
						: !!pendingRequests[id];
				});

				this.cdr.markForCheck();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}

