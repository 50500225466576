<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<div class="download">
		<button
			(click)="downloadCsv()"
			type="button" i18n>
			<span class="icon-download-from-cloud"></span> Download CSV
		</button>
	</div>

	<div class="surveys">

		<div class="table-head">
			<div class="table-row">
				<span class="table-heading" i18n>Questions</span>
				<span class="table-heading" i18n>Answers</span>
			</div>
		</div>

		<div *ngFor="let item of formattedItems" class="survey-item">
			<ng-container *ngIf="item.fields.length">
				<div class="survey-title">{{item.date}}</div>

				<div class="table-container flex">

					<div
						class="table is-bordered is-narrow is-hoverable is-fullwidth">
						<div class="table-body">
							<div
								class="table-row"
								*ngFor="let field of item.fields">
								<code class="table-data">
									<span class="survey-question-indent" [style.width]="field.depth + 'em'"></span>
									{{ field.question }}
								</code>
								<code class="table-data">{{ field.answer }}</code>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

</ng-container>
