<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<div class="first-row">
		<input class="sl-form__switch" id="maintenanceMode" type="checkbox" formControlName="maintenanceMode">
		<label for="maintenanceMode"></label>
			<ng-container i18n>Maintenance mode</ng-container>
	</div>
	<label i18n>maintenance Mode Message</label>
	<textarea formControlName="maintenanceModeMessage" i18n-placeholder placeholder="… add a maintenance mode message here"></textarea>

	<div formGroupName="requets">
		<h2 i18n>Network Requests</h2>

		<label>AppHttp Request Default Queue</label>
		<ng-select formControlName="defaultQueue" [options]="[{ label: 'Immediate', value: '0' }, { label: 'Idle', value: '1' }]">
		</ng-select>

		<label>Idle Queue Upgrade Batch Size</label>
		<input formControlName="upgradeBatchSize" type="number" min="1">
	</div>

</ng-container>
