/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputsModule } from '../forminputs';

import { MediaModule } from '../_media/module';
import { UserFormComponent } from './components/user-form';

@NgModule({
	imports: [
		FormInputsModule,
		MediaModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		UserFormComponent,
		FormInputsModule,
	],
	declarations: [
		UserFormComponent,
	],
	entryComponents: [
		UserFormComponent,
	],
})
export class UserModule { }
