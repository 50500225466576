/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';

@Component({
	selector: 'input-commands',
	template: `
<div class="list">
	<div>
		<label i18n>name</label>
		<label i18n>command</label>
		<div></div>
	</div>
	<div *ngFor="let item of value">
		<div>{{item.name}}</div>
		<div>{{item.command}}</div>
		<div><span class="icon-remove" (click)="remove(item.name)"></span></div>
	</div>
</div>
<div class="input-widget">
	<input [(ngModel)]="name" type="text" i18n-placeholder placeholder="… name">
	<input [(ngModel)]="command" type="text" i18n-placeholder placeholder="… command">
	<button type="button" (click)="add()" i18n><span class="icon-add"></span> add</button>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCommandsComponent), multi: true },
	],
})
export class InputCommandsComponent extends CustomValueAccessor {
	public name: string;
	public command: string;

	add() {
		if (!this.value) this.value = [];
		if (!this.value.some(({ name }) => name === this.name) && this.name && this.command) {
			this.value = [...this.value, { name: this.name, command: this.command }];
			this.name = '';
			this.command = '';
		}
	}
	remove(itemName) {
		this.value = this.value.filter(({ name }) => name !== itemName);
	}
}
