
<div class="sl-listview__wrapper">
	<nav [ngStyle]="{ width: (leftColumnStyle$ | async), flex: (leftColumnStyle$ | async) ? 'none' : '' }">
		<a routerLink="/account/profile" class="box" i18n>
			Profile
		</a>
		<a routerLink="/account/password" class="box" i18n>
			Password
		</a>
	</nav>

	<main>
		<ng-content></ng-content>
	</main>
</div>
