
<div >
	<div *ngIf="layers && layers.length">
			<div class="columns is-mobile">
				<div class="column is-2"><label>layer</label></div>
				<div class="column"><label>Exhibition</label></div>
			</div>
			<div *ngFor="let layer of layers; let i = index" class="columns is-mobile">
				<div class="column is-2">{{ i }}:</div>
				<div class="column">
					<ng-select
						placeholder="... select an exhibition"
						[ngModelOptions]="{standalone: true}"
						[(ngModel)]="value[i]"
						(selected)="onLayerExhibitionChange()"
						[options]="exhibitionOptions"
						></ng-select>
				</div>
			</div>
		</div>
</div>
	