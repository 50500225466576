
<div class="list">
	<div *ngFor="let pkid of value">{{getName(pkid)}} <span class="icon-remove" (click)="remove(pkid)"></span></div>
</div>
<div class="input-widget">
	<select
		(change)="propagateChange()"
		[(ngModel)]="selectStyleTemplate"
		[ngClass]="{empty: !selectStyleTemplate}"
		(change)="add()">
		<option value="" disabled placeholder>... select a style template</option>
		<option
			*ngFor="let spk of ((styletemplates$ | async) | filter:(routeInfoOrgaID | async))"
			[value]="spk._id" [hidden]="value.includes(spk._id)">{{spk.name}}
		/option>
	</select>
</div>
	