<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

		<div class="first-row">
			<div>
				<label>name</label>
				<input type="text" formControlName="name">
			</div>
			<div>
				<label>charging Status</label>
				<div>
					{{data.model?.chargingStatus}}
				</div>
			</div>
		</div>
		<div>
			<label>app Vers</label>
			<input type="text" formControlName="appVers">
		</div>
		<div>
			<label>device OS</label>
			<input type="text" formControlName="deviceOS">
		</div>
		<div>
			<label>web Client Vers</label>
			<input type="text" formControlName="webClientVers">
		</div>

</ng-container>
