
<div class="change-log">
	<div *ngIf="changeLog.editor">
		<label i18n>Last edited by</label>
		<div class="change-log__item-top">
			<i>{{getUserName(changeLog.editor)}}</i> at <span class="change-log__date">{{prettyDate(changeLog.editedOn)}}</span>
		</div>
	</div>
	<div>
		<label i18n>Created by</label>
		<div class="change-log__item-top">
			<i>{{getUserName(changeLog.author)}}</i> at <span class="change-log__date">{{prettyDate(changeLog.createdOn)}}</span>
		</div>
	</div>
	<div class="version-list">
		<label i18n>Versions</label>
		<div *ngIf="!versions?.length" i18n>… loading versions</div>
		<div
			*ngFor="let version of versions; let i = index;"
			class="change-log__item">
			<i>{{getUserName(!version.editor ? version.author : version.editor)}}</i>
			<span>
				<ng-container i18n>at</ng-container> <span class="change-log__date">{{prettyDate(!version.editedOn ? version.createdOn : version.editedOn)}}</span> <ng-container i18n>version</ng-container> <b>{{version.version}}</b>
			</span>
			<div class="menu" *ngIf="i !== 0">
				<!--<button (click)="loadVersion.emit(version.version)" i18n>load version {{version.version}}</button>-->
			</div>
			<div class="menu" *ngIf="i === 0">
				<!--<a class="button" [routerLink]="pathToCurrent" i18n>load current version</a>-->
			</div>
		</div>
	</div>
</div>
	