
<div class="exhibit-list" [ngClass]="{invalid: !(value && value.length)}">
	<div [dragula]='bagName' [(dragulaModel)]='value' class="drag-container">
		<div
			*ngFor="let id of value; let i = index;">
			<div>
				<h2>{{i + 1}}</h2>
				<span>
					{{unifiedArticles[id]?.articleID.name}}
				</span>
			</div>
			<div class="menu">
				<span class="icon-drag-reorder drag-handle"></span>
				<span class="icon-delete_sign" (click)="remove(i)"></span>
			</div>
		</div>
	</div>
	<a
		class="exhibit-list__add"
		(click)="selectArticleModal.open(); $event.preventDefault()">
		<span class="icon-add"></span>
		<div i18n>Add article</div>
	</a>
	<modal-component #selectArticleModal [clean]="true">
		<select-unified-article
			#selectArticle
			*ngIf="selectArticleModal.isOpen()"
			[exhibitionID]="exhibitionID"
			[quiz]="quiz"
			(selectarticle)="add($event); selectArticleModal.close()">
		</select-unified-article>
	</modal-component>
</div>
	