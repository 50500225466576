
<div class="list">
	<div>
		<label i18n>name</label>
		<label i18n>command</label>
		<div></div>
	</div>
	<div *ngFor="let item of value">
		<div>{{item.name}}</div>
		<div>{{item.command}}</div>
		<div><span class="icon-remove" (click)="remove(item.name)"></span></div>
	</div>
</div>
<div class="input-widget">
	<input [(ngModel)]="name" type="text" i18n-placeholder placeholder="… name">
	<input [(ngModel)]="command" type="text" i18n-placeholder placeholder="… command">
	<button type="button" (click)="add()" i18n><span class="icon-add"></span> add</button>
</div>
	