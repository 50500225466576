<div class="nav-context" [ngClass]="{ 'is-open': showOrgList }">
	<div class="name name--alert" *ngIf="data.organisations.length === 0">missing organization</div>

	<div class="name">

		<div class="name-text" [ngClass]="{'name--selected': data.currentuser.organisation.name}">
			<ng-container *ngIf="data.currentuser.organisation.name; else elseBlock">
				{{ data.currentuser.organisation.name}}
			</ng-container>
			<ng-template #elseBlock i18n>
			 ALL ORGANISATIONS
			</ng-template>
		</div>

		<ng-select
			(selected)="orgchange.emit($event.value); showOrgList = false"
			(opened)="showOrgList = true"
			(closed)="showOrgList = false"
			[hidden]="data.organisations.length <= 1 && !data.currentuser.access.isAdmin"
			[options]="options">
		</ng-select>

	</div>
</div>
