/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import deepExtend from 'deep-extend';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../forminputs/CustomValueAccessor';
import { getFileAcceptAttr, mimeToMediaType, updateField, getFieldValue, pluckDistinct, updateFieldMut, deleteField } from '../../utils';
import { ModalComponent } from '../../modals/modal.component';
import { AppHttp } from '../../services/AppHttp';
import { mediaConfig } from '../../_media/config';
import { messageActions } from '../../core/_messages/actions';
import config from '../../config';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'input-media-3d',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputMedia3dComponent), multi: true },
	],
})
export class InputMedia3dComponent extends CustomValueAccessor {
	@Input() type;
	@Input() languageOptions: any[];
	@Input() isCreate: boolean;
	@Input() id: string = null;
	@Input() customFields: any[];

	@Output() fileChange = new EventEmitter();

	@ViewChild('threeDModal', { static: false }) threeDModal: ModalComponent;

	public pendingSync = {
		source: null,
		targets: null,
		fieldPaths: null,
		message: '',
		mode: null,
	};
	private isClone: boolean;
	public languageIso2name: any;
	public missingLanguageOptions = true;
	public viewThreeD = null;
	public destroyed$ = new Subject();

	public get languageItem() {
		return this._value && this._value[0];
	}

	public set languageItem(item) {
		this.value = this.languageOptions.map((langOption, index) => {
			return {
				...item,
				...this._value[index],
				languageISO: langOption.value,
				threeD: item.threeD,
				source: item.source,
				thumbSource: item.thumbSource,
			};
		});
	}
	private xhr: any;

	constructor(changeDetectorRef: ChangeDetectorRef, private appHttp: AppHttp, private store: Store<any>,  public i18n: I18n) {
		super(i18n);
		this.initOptions({ changeDetectorRef });
		window['m'] = this;
	}

	ngOnChanges(changes) {
		const lo = this.languageOptions || [];
		this.languageIso2name = lo.reduce((acc, lang) => ({ ...acc, [lang.value]: lang.label }), {});
		this.missingLanguageOptions = !this.languageOptions.length;

		(this._value || []).forEach(addThreeDField);
	}

	openThreeDViewer(item) {
		this.viewThreeD = item;
		this.threeDModal.open();
	}

	getThumbUrl(item) {
		return item && item.thumbSource
			? `${config.baseURL}/api/mediafiles/${item.thumbSource}`
			: '';
	}

	getFixedValue(value) {
		if (
			value &&
			value.length &&
			this.languageOptions.length
		) {
			// setTimeout(() => {
				const first = value[0];
				addThreeDField(first);

				const source = first.source;
				const curentLanguages = new Set(value.map(({ languageISO }) => languageISO));

				return [
					// remove access languages
					...value.filter(({ languageISO }) => languageISO in this.languageIso2name),
					// add missing languages
					...this.languageOptions.filter(langOption => !curentLanguages.has(langOption.value)).map(lang => ({
						file: null,
						source,
						languageISO: lang.value,
						title: '',
						description: '',
						threeD: lang.threeD || { ...first.threeD },
					})),
				];

				this.changeDetectorRef.markForCheck();
			// });
		}
	}

	writeValue(value) {
		this.value = value;
	}

	add(event) {
		if (!this.value) this._value = [];

		let previousAllowedType = this.type || 'misc';

		const files = Array.from(event.target.files || event.dataTransfer.files).filter((file: any) => {
			const fileMediaType = mimeToMediaType(file.type);
			const isAllowed = previousAllowedType === 'misc' || fileMediaType === previousAllowedType;

			if (isAllowed) {
				previousAllowedType = fileMediaType;
			}

			return isAllowed;
		});

		if (!files.length) return;

		this.value = [
			{
				file: files[0],
				source: '',
				languageISO: this.languageOptions[0].value,
				threeD: this.languageOptions[0].threeD,
				title: '',
				description: '',
				altText: '',
				attribution: '',
				idx: this._value.length,
			},
		];
	}

	onFileChange(fileInfo) {
		this.type = fileInfo.type;
		this.languageItem.source = fileInfo._id;
		this.languageItem.file = null;

		const format = fileInfo.originalName.split('.').pop();

		updateFieldMut(this.languageItem, 'threeD.format', format);
		this.languageItem = updateField(this.languageItem, ['threeD', 'resourceMap', fileInfo._id], fileInfo.originalName);

		this.propagateChange();
		this.fileChange.emit(fileInfo);
	}

	onPreviewChange(fileInfo) {
		this.value = [...this._value];
	}

	onMtlChange(fileInfo) {
		this.languageItem.threeD.material = fileInfo._id;
		this.languageItem.threeD.resourceMap[fileInfo._id] = fileInfo.originalName;

		this.languageItem = this.languageItem;
	}

	onMtlRemove(mtl) {
		this.languageItem.threeD.material = null;
		this.removeResource(this.languageItem.threeD.material);
	}

	addTexture(event) {
		const files = Array.from(event.target.files || event.dataTransfer.files).filter((file: any) =>
			/^image/.test(file.type)
		);

		if (!files.length) return;

		const existingTextures = this.languageItem.threeD.textures;
		const newLangItem = updateField(this.languageItem, 'threeD.textures', [...existingTextures, ...files])

		this.value = [newLangItem];
	}

	removeResource(id) {
		this.value = [deleteField(this._value[0], ['threeD', 'resourceMap', id])];
	}

	onTextureUpload(fileInfo) {
		this.languageItem = updateField(this.languageItem, ['threeD', 'resourceMap', fileInfo._id], fileInfo.originalName)
	}

	onTextureRemove(textureId) {
		this.languageItem.threeD.textures = this.languageItem.threeD.textures.filter(t => t !== textureId);
		this.removeResource(textureId);
	}

	getValue(sourceId) {
		return this.value.find(file => file.source === sourceId);
	}

	getAcceptMime() {
		return getFileAcceptAttr(this.type);
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(this.getFixedValue(value));
	}

	onViewer3dParams(_event) {
		console.log('onViewer3dParams', _event);
		this.languageItem.threeD.viewerOptions = _event
		this.changeDetectorRef.detectChanges();
	}
	onScreenshot(_event) {
		console.log('onScreenshot event', _event);
		if (this.xhr) this.xhr.abort();
		this.xhr = uploadFile({
			file: _event,
			url: `${config.baseURL}/api/mediafiles`,
			onreadystatechange: (event) => {
				const xhr = event.target;

				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						const fileInfo = JSON.parse(xhr.responseText);
						// fileInfo.type = mimeToMediaType(fileInfo.contentType);
						// fileInfo.prevId = this.value;
						// console.log("fileInfo", fileInfo);
						this.languageItem.thumbSource = fileInfo._id
						this.onPreviewChange(fileInfo._id);
					} else {
						this.store.dispatch(messageActions.error(`${this.i18n('File upload failed:')} ${xhr.responseText || xhr.status}`));
					}
				}
			},
			onprogress: (e: any) => {
				if (e.lengthComputable /*&& !this.destroyed*/) {
					const progress = Math.round((e.loaded * 100) / e.total);
					console.log('progress', progress);
					// Must trigger change detection manually since the event happens async
					// and angular does not know about the change.
					// this.changeDetectorRef.detectChanges();
				}
			},
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}

function addThreeDField(languageItem) {
	languageItem.threeD = Object.assign(
		{ resourceMap: {}, textures: [], format: 'obj', viewerOptions: {} },
		languageItem.threeD);

	languageItem.threeD.textures = languageItem.threeD.textures.filter(Boolean);
}

export function uploadFile(options) {
	const xhr = new XMLHttpRequest();
	const formData = new FormData();

	formData.append('file', options.file, 'screenshot.png');

	Object.keys(options)
		.filter(key => key.indexOf('on') === 0 && (!xhr.upload || key !== 'onprogress'))
		.forEach(listenerKey => {
			xhr[listenerKey] = options[listenerKey];
		});

	if (xhr.upload) {
		xhr.upload.onprogress = options.onprogress;
	}

	xhr.open('POST', options.url); // identifier vom file ist `file`
	xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');
	xhr.withCredentials = true;
	xhr.send(formData);

	return xhr;
}
