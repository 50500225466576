/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormInputsModule } from '../forminputs';

import { ExhibitionFormComponent } from './components/exhibition-form.component';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule,
	],
	exports: [
		ExhibitionFormComponent,
	],
	declarations: [
		ExhibitionFormComponent,
	],
	entryComponents: [
		ExhibitionFormComponent,
	],
})
export class ExhibitionModule { }
