/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Output,
	Input,
	ViewChild,
	EventEmitter,
	forwardRef,
	ViewEncapsulation,
	ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';

import { CustomValueAccessor } from '../CustomValueAccessor';
import * as isoLangs from '../../../../models/languageObjects/isoLangs';
import { FilterArrayPipe } from '../pipes/filter-array.pipe';

@Component({
	selector: 'input-language',
	template: `
<div class="input-widget">
	<ng-select
		(selected)="setLanguage($event)"
		[(ngModel)]="value"
		[options]="langOptions"
		i18n-placeholder
		placeholder="… language">
	</ng-select>
</div>
	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./input-language.component.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLanguageComponent), multi: true },
	],
})
export class InputLanguageComponent extends CustomValueAccessor {
	@ViewChild('languages', { static: true }) languagesEl;

	@Input() excludeLanguages = [];

	@Output() change = new EventEmitter(false);

	public langOptions = isoLangs.map(({ iso, name }) => ({ value: iso, label: name }));
	protected isoLangsObj;
	protected filterPipe = new FilterArrayPipe();
	protected selectedLang;
	protected _showLang;

	ngOnInit() {
		this.isoLangsObj = isoLangs.reduce((acc, lang) => {
			acc[lang.iso] = lang.name;
			return acc;
		}, {});
	}

	ngOnChanges(changes) {
		this.langOptions = isoLangs.filter(lang => !this.excludeLanguages.includes(lang.iso))
			.map(({ iso, name }) => ({ value: iso, label: name }));
	}

	setLanguage(langOption) {
		this.value = langOption.value;
		this.change.emit(langOption);
	}
}
