<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container
		[preventChange]="true"
		[form]="formGroup"
		[id]="id"
	></overwrite-context-container>

	<div class="first-row">
		<div>
			<label i18n>Name</label>
			<input type="text" formControlName="name" i18n-placeholder placeholder="… playlist name" />
		</div>
		<div class="is-live">
			<label i18n>public</label>
			<input
				class="sl-form__switch"
				id="isLive"
				type="checkbox"
				[ngClass]="{ 'is-live--unpublished-changes': !data.model.isUpToDate }"
				formControlName="isLive"
			/>
			<label isLive [item]="data.model" for="isLive"></label>
		</div>
	</div>

	<div>
		<label i18n>style template</label>
		<select formControlName="styleTemplateID">
			<option [ngValue]="null" selected></option>
			<option [value]="styleTemplate._id" *ngFor="let styleTemplate of data.styleTemplates">{{
				styleTemplate.name
			}}</option>
		</select>
	</div>

	<div>
		<label i18n>Device</label>
		<select formControlName="boxxID">
			<option value="null" disabled selected i18n>... select a device</option>
			<option [value]="boxx._id" *ngFor="let boxx of data.boxxes">{{ boxx.name }}</option>
		</select>
	</div>

	<input-articlelist formControlName="list"></input-articlelist>

	<div>
		<label i18n>Triggerdevice</label>
		<select formControlName="triggerDevice">
			<option *ngFor="let triggerDevice of schema.triggerDevice.enum" value="{{ triggerDevice }}">{{ triggerDevice }}</option>
		</select>
	</div>

	<div>
		<label i18n>Tags</label>
		<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
	</div>
</ng-container>
