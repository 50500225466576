
<div class="input-widget">
	<input
		type="checkbox"
		class="btn-checkbox"
		attr.id="{{inputUid}}-checkbox"
		[disabled]="selfDisabled"
		(change)="handleCheckbox($event.target.checked)"
		[checked]="checked"
		[(ngModel)]="checked">
		<label attr.for="{{inputUid}}-checkbox"></label>
	<ng-content></ng-content>
</div>
	