
		<style>
			:host(.ng-invalid) >>> select {
				border: 1px solid #cd0026;
			}
		</style>
		<div class="list">
			<div *ngFor="let item of value">
				<div>{{ item.name }} ({{ item.comments }})</div>
				<i>{{ item.type }}</i>
				<div>
					{{ item.scope }}
				</div>
				<div>
					{{ item.storeURL }}
				</div>

				<span class="icon-remove" (click)="remove(item)"></span>
			</div>
		</div>
		<div>
			<select-array [optionSets]="optionSets" [clearOnComplete]="false" [(ngModel)]="selectArray">
			</select-array>
			<button (click)="add($event)">add</button>
		</div>
	