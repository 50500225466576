/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {of as observableOf,  Observable ,  Subject } from 'rxjs';

import {switchMap, take, pluck, tap, catchError, filter, map} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect } from '@ngrx/effects';
import {
	HttpClient,
	HttpHeaders,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';

import { statusActions } from '../status/actions';
import { cmssettingsConfig } from '../_cmssettings/config';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import config from '../config';

const API_ENDPOINT = `${config.baseURL}/api`;

interface Options {
	url: string;
	id?: string;
	body?: any;
	method?: string;
	responseType?: 'json' | 'arraybuffer' | 'blob' | 'text';
};

interface RequestInfo {
	options: Options,
	httpRequest: HttpRequest<any>,
	observable: Subject<HttpResponse<any>> | Observable<HttpResponse<any>>,
}

function getOptions(optionsOrUrl: Options | string, method, body?): Options {
	if (typeof optionsOrUrl === 'string') {
		return {
			method,
			body,
			url: optionsOrUrl,
			responseType: 'json',
		}
	}

	return {
		body,
		responseType: 'json',
		...optionsOrUrl,
		method,
	};
}

@Injectable()
export class AppHttp {
	@Effect({ dispatch: false })
	private networkSettings$ = pluckDistinct(this.store, 'models', cmssettingsConfig.collectionName, 'entities').pipe(
		map(entities => {
			const cmsSettings: any = Object.values(entities)[0];
			return cmsSettings && cmsSettings.requets;
		}),
		filter(Boolean)
	);

	_runningRequests: RequestInfo[] = []

	constructor(private _http: HttpClient, private store: Store<any>) {
	}

	get(options: Options | string) {
		return this._requestHelper(getOptions(options, 'GET'));
	}

	post(options: Options | string, body?) {
		return this._requestHelper(getOptions(options, 'POST', body));
	}

	put(options: Options | string, body?) {
		return this._requestHelper(getOptions(options, 'PUT', body));
	}

	delete(options: Options | string) {
		return this._requestHelper(getOptions(options, 'DELETE'));
	}

	patch(options: Options | string, body?) {
		// also use PUT here since we do not implement patch on the backend
		return this._requestHelper(getOptions(options, 'PUT', body));
	}

	_requestHelper(options: Options) {
		const { method, url, body } = options
		const httpRequest = new HttpRequest(method, `${API_ENDPOINT}${url}`, body, {
			...options,
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			withCredentials: true,
		});

		let request$ = this._http.request(httpRequest).pipe(
			filter(response => response instanceof HttpResponse),
			catchError(res => observableOf(res)
		));

		const requestInfo = { options, httpRequest, observable: request$ };

		this._runningRequests.push(requestInfo);

		request$ = request$.pipe(tap(() => {
			this._runningRequests.splice(this._runningRequests.indexOf(requestInfo), 1);
		}));

		if (options.id) {
			return this.store.pipe(pluck('status', 'pendingRequests'),
				take(1),
				switchMap(pendingRequests => {
					// do nothing extra if already pending
					if (pendingRequests[options.id]) {
						return request$;
					}

					// timeouts are to avoid ExpressionChangedAfterItHasBeenCheckedError
					setTimeout(() => {
						this.store.dispatch(statusActions.addPending(options.id))
					}, 0);

					return request$.pipe(tap(() => {
						setTimeout(() => {
							this.store.dispatch(statusActions.removePending(options.id))
						}, 0);
					}));
				}));
		}

		return request$;
	}
}

const enum Queue {
	Immediate,
	Idle,
};
