<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<div class="first-row">
		<div>
			<label i18n>First Name</label>
			<input type="text" formControlName="firstName">
		</div>
		<div>
			<label i18n>Last Name</label>
			<input type="text" formControlName="lastName">
		</div>

	</div>

	<div class="spacer"></div>

	<div class="box-1-1">
		<div>
			<label i18n>Avatar</label>
			<input-media-file type="image" formControlName="coverID"></input-media-file>
		</div>
		<div>
			<div>
				<label i18n>Email</label>
				<input type="email" pattern="^.+@.+\.\w+$" formControlName="email">
			</div>
			<div>
				<label i18n>Address</label>
				<textarea cols="30" rows="4" formControlName="address"></textarea>
			</div>
		</div>
	</div>

	<div class="spacer"></div>

	<div>
		<div><label i18n>Select Language</label></div>
		<div>
			<input type="radio" value="de" id="de" name="type" (change)="switchLanguage('de')" /><label
			for="de"
			i18n
			>DE</label>

		</div>
		<div>
			<input type="radio" value="en" id="en" name="type" (change)="switchLanguage('en')" /><label
			for="en"
			i18n
			>EN</label>
		</div>
	</div>

	<div class="spacer"></div>

	<div>
		<label i18n>enable TOTP?</label>
		<form-input  type="boolean" formControlName="enableTOTP" i18n></form-input>
	</div>


	<div *ngIf="data.currentuser.currentuser._id == id">
		<label i18n>API Key</label>
		<!-- <input type="text" formControlName="apiKey" readonly> -->
		<div>
			<button type="button"(click)="getApiKey()" i18n>Generate API Key</button>
			<span class="name plain info--text apikey-data-spacing">Clicking this button will reset the current API key. The API key will only be visible until you logout.</span>
			<ng-container *ngIf="data.currentuser.apiKey !== ''">
				<span class="apikey-data-spacing">{{data.currentuser.apiKey}}</span>
				<span
				class="icon-copy v-align--middle"
				title="Copy Key"
				(click)="
					copyToClipboard(
						data.currentuser.apiKey
					)
				"
				[ngClass]="{ 'icon--active': copyActive }"
					></span>
			</ng-container>
		</div>
	</div>
	<hr *ngIf="data.currentuser.access.isAdmin || data.currentuser.access.isOrgAdmin || id">

	<div *ngIf="data.currentuser.access.isAdmin || data.currentuser.access.isOrgAdmin">
		<label i18n>User Role</label>
		<input-roles [organisations]="data.organisations" [isAdmin]="data.currentuser.access.isAdmin"
			[roles]="data.currentuser.currentuser.roles" formControlName="roles">
		</input-roles>
	</div>

	<div class="spacer"></div>

	<div *ngIf="id">
		<button type="button" (click)="resetPassword()" class="button button--red-ghost" i18n>Reset Password</button>
	</div>

</ng-container>
