/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';

@Component({
	selector: 'input-date-range',
	template: `
		<div class="input-date-range">
			<my-date-range-picker
				[disabled]="disabled"
				[(ngModel)]="model"
				[options]="{ dateFormat: 'dd.mm.yyyy', showClearBtn: false }"
				(dateRangeChanged)="onPickDates($event)"
			>
			</my-date-range-picker>
		</div>
	`,
	styleUrls: ['./index.sass'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputDateRangeComponent), multi: true },
	],
})
export class InputDateRangeComponent extends CustomValueAccessor {
	@Input() disabled = false;
	@Input() beginDate: Date = null;
	@Input() endDate: Date = null;
	@Output() dateRange: EventEmitter<Object> = new EventEmitter(false);

	public _value: { beginDate: Date; endDate: Date } = null;
	public model: any = {};

	set value(value: { beginDate: Date | number | string; endDate: Date | number | string }) {
		// try to convert value to type expeccted by my-date-picker
		for (let [key, data] of Object.entries(value)) {
			if (typeof data === 'string') {
				data = new Date(data);
			} else if (typeof data === 'number') {
				const time = data;
				data = new Date();
				data.setTime(time);
			}
			value[key] = data;
		}

		this.set_value(value);
		this.updateModel(value);
		this.dateRange.emit(value);
	}

	get value() {
		return this._value;
	}

	onPickDates({ beginJsDate, endJsDate }) {
		if (beginJsDate != null && endJsDate != null) this.value = { beginDate: beginJsDate, endDate: endJsDate };
	}

	updateModel({ beginDate, endDate }) {
		this.model.endDate = { year: endDate.getFullYear(), month: endDate.getMonth(), day: endDate.getDate() };
		this.model.beginDate = {
			year: beginDate.getFullYear(),
			month: beginDate.getMonth(),
			day: beginDate.getDate(),
		};
	}

	ngOnInit() {
		if (this.beginDate != null && this.endDate != null) {
			this.set_value({ beginDate: this.beginDate, endDate: this.endDate });
			this.updateModel({ beginDate: this.beginDate, endDate: this.endDate });
		}
	}
}
