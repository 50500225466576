
	<div class="sl-listview__wrapper">

		<nav>
			<h1 i18n>Tickets</h1>

			<div class="ticket-counts">
				<div class="ticket-count" (click)="selectAll()">
					<div class="ticket-count-number">
						{{ filter.result.length }}
					</div>
					<div class="ticket-count-title" i18n>
						Created
					</div>
				</div>

				<div class="ticket-count" (click)="selectConverted()">
					<div class="ticket-count-number">
						{{ filter.convertedCount }}
					</div>
					<div class="ticket-count-title" i18n>
						Redeemed
					</div>
				</div>

				<div class="ticket-count" (click)="selected.size ? selectNone() : selectAll()">
					<div class="ticket-count-number">
						{{ selected.size }}
					</div>
					<div class="ticket-count-title" i18n>
						Selected
					</div>
				</div>

				<button [disabled]="deleting" (click)="deleteModal.open()" [class.hidden]="!selected.size" type="button" i18n>Delete Selection</button>
			</div>

			<a target="download" class="ticket-csv-download" [attr.href]="getCsvUrl((org$ | async)._id, filter.year, filter.quarter)">
				<button type="button" i18n>Download CSV</button>
			</a>

			<tickets-filter
				#filter
				(filterEvent)="onFilter($event)"
				[tickets]="(tickets$ | async).items">
				<h3 i18n>Filters</h3>
			</tickets-filter>

		</nav>

		<main class="table-container flex">
			<div class="table-head">
				<div class="table-row">
					<span class="table-heading {{ column.className }}" *ngFor="let column of columns">
						{{ column.title }}
					</span>
				</div>
			</div>

			<div
				class="table is-striped is-narrow is-hoverable is-fullwidth">
				<div class="table-body">
					<div
						class="table-row ticket-row"
						[ngClass]="{ 'is-selected': selected.has(ticket._id) }"
						(click)="toggleSelected(ticket)"
						*ngFor="let ticket of formattedTickets">
						<code class="table-data {{ column.className }}" *ngFor="let column of columns">{{ ticket[column.name] }}</code>
					</div>
				</div>
			</div>
		</main>

		<modal-component #deleteModal>

			<div *ngIf="!deleting">
				<p i18n> Are you sure you'd like to delete these tickets?  </p>
				<div class="modal__buttons">
					<button type="button" (click)="deleteSelected(deleteModal)" class="button--red" i18n>Delete</button>
					<button
						type="button"
						(click)="deleteModal.close()">
						Don't delete
					</button>
				</div>
			</div>
			<div *ngIf="deleting">
				<p i18n> Deleting Tickets </p>
				<p i18n>{{ selected.size }} remaining</p>
			</div>
		</modal-component>
	</div>
	