
<div class="input-media-file">
	<div class="preview">
		<div [ngSwitch]="type" class="background">
			<div *ngSwitchCase="'image'" [ngStyle]="{'background-image': 'url(' + getUrl() + ')'}"></div>
		  <audio controls preload="none" *ngSwitchCase="'audio'" [src]="getUrl()">
			   Your browser does not support the <code>audio</code> element.
			</audio>
			<video controls preload="none" *ngSwitchCase="'video'" [src]="getUrl()" [attr.poster]="getUrl('thumbSource')">
			   Your browser does not support the <code>audio</code> element.
			</video>
		  <div *ngSwitchDefault></div>
		</div>
		<div class="preview__overlay">
			<span *ngIf="mediaObjectEntities[value]">{{mediaObjectEntities[value].name}}</span>
		</div>
		<div class="btn-menu-group">
			<span
					*ngIf="value"
					class="menu-btn icon-circled-chevron-down"
					(click)="showMenu = !showMenu"
					[ngClass]="{'menu-btn--active': showMenu}"></span>
			<span
				*ngIf="!value"
				class="add-btn icon-add"
				(click)="$event.preventDefault(); selectMediaModal.open()"></span>
		</div>
	</div>

	<div class="menu" [ngClass]="{'menu--active': showMenu, 'menu--left': menuDir === 'left'}" *ngIf="value">
		<a target="_blank" [hidden]="!value" [href]="getUrl()" download>
			<span class="icon-download-from-cloud"></span>
			Download
		</a>
		<div (click)="$event.preventDefault(); selectMediaModal.open()">
			<span class="icon-collection"></span>
			Change
		</div>
		<div (click)="value = null; showMenu = false">
			<span class="icon-remove"></span>
			Remove
		</div>
	</div>
</div>

<modal-component #selectMediaModal [clean]="true">
	<select-media
		*ngIf="selectMediaModal.isOpen()"
		[typeFilter]="type"
		[organisationID]="organisationID"
		(selectmedia)="setValue($event); selectMediaModal.close(); showMenu = false"></select-media>
</modal-component>
	