/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { SurveyFormComponent } from './components/survey';
import { FormInputsModule } from '../forminputs';
import { PrettyObjectComponent } from './components/prettyObject';


@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		SurveyFormComponent,

	],
	declarations: [
		SurveyFormComponent,
		PrettyObjectComponent,
	],
	entryComponents: [
		SurveyFormComponent,
	],
})
export class SurveyModule { }
