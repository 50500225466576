/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { distinctUntilChanged, map, startWith, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { modelConfigs, nameToCollectionName, nameToModel } from '../modelConfigs';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { ModelEditComponent } from '../components/model-edit.component';
import { actions } from '../routeInfo/actions';
import { shallowEqual } from '../utils';

/**
 * A service which pulls the combined params from the current route and the
 * parent routes to provide the current model name, modelConfig, id and version
 * through a single params$ observable.
 *
 * The updates are also saved to the store through a ROUTE_CHANGED action.
 */
@Injectable()
export class RouteInfo {
	params$: Observable<{ id?: string; versionId?: string, modelName?: string; modelConfig?: ModelConfig; [key: string]: any }>;
	id$: Observable<ModelConfig>;
	navigationEnd$: Observable<any>;
	_params: any;
	modelEditInstance: ModelEditComponent = null;

	constructor(public router: Router, public route: ActivatedRoute, private store: Store<any>) {
		this.navigationEnd$ = router.events.pipe(filter(event => event instanceof NavigationEnd));

		this.params$ = this.navigationEnd$.pipe(
			startWith(null),
			/*
			// the switchMap method below seemed to be causing issues. It's easier to
			// just get the param values directly since they are BehaviorSubjects
			.switchMap(() => {
				const paramsObservables = getAllParamsObservables([], route);

				return Observable.combineLatest(...paramsObservables, (...params) =>
					params.reduce((acc, params) => Object.assign(acc, params), {})
				);
			});
			*/
			map(() => {
				const paramsObservables = getAllParamsObservables([], route);

				const params = paramsObservables.reduce((acc, p$) => {
					Object.assign(acc, p$.value);
					return acc;
				}, {});

				return params;
			}),
			distinctUntilChanged(shallowEqual),
			map(params => ({
				...params,
				modelConfig: nameToModel[params.modelName] || null,
			}))
		);

		this.params$.subscribe(params => {
			this._params = params;
			const config: any = nameToModel[params.modelName] || {};
			store.dispatch(
				actions.routeChange(
					nameToCollectionName[params.modelName],
					params.id,
					`${config.clientPathPrefix}/${params.modelName}`,
					params.versionId
				)
			);
		});
	}
}

function getAllParamsObservables(acc = [], route) {
	acc.push(route.params);

	const childParams = route.children.forEach(childRoute => {
		getAllParamsObservables(acc, childRoute);
	});

	return acc;
}
