/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Read the documentation in 'socket.actions.ts'

import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as socketio from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import config from '../config';
import { statusActions } from '../status/actions';
import * as collectionActions from '../socket.actions';

const port = location.port ? `:${config.port}` : '';
const socketURL = `ws${location.protocol === 'https:' ? 's' : ''}://${config.hostname}${port}`;

function getPendingId(event, data) {
	if (
		event === collectionActions.GET_COLLECTION_LIST ||
		event === collectionActions.GET_COLLECTION_FULL_LIST ||
		event === collectionActions.GET_COLLECTION_ITEM
	) {
		return `${data.collectionName}${data.id ? `:${data.id}` : ''}`;
	}

	return null;
}

/**
 * Low level socket connection service (based on socket.io and rxjs)
 * Used by the SocketEventsService
 */
@Injectable()
export class SocketConnectionService {
	private socket: SocketIOClient.Socket;
	private secureSocket: SocketIOClient.Socket;
	connected$ = new BehaviorSubject<boolean>(false);
	loggedIn$ = new BehaviorSubject<boolean>(false);
	private listenerMap = {};

	private requests = new Map();
	private _requiestCounter = 0;

	constructor(private store: Store<any>) {
		this.socket = socketio(socketURL);
		this.socket.on('connect', () => this.connected$.next(true));
		this.socket.on('disconnect', () => this.connected$.next(false));
		this.secureSocket = socketio(`${socketURL}/secure`);
		this.secureSocket.on('ready', () => this.loggedIn$.next(true));
		this.secureSocket.on('disconnect', () => this.loggedIn$.next(false));
	}

	async _emit(event: string, secure: boolean, data?: any) {
		const socket = secure ? this.secureSocket : this.socket;

		const requestId = this._requiestCounter++;

		const pendingId = getPendingId(event, data);

		return new Promise((resolve, reject) => {
			const connected = (secure ? this.connected$ : this.loggedIn$).getValue();
			if (!connected) {
				reject({ error: 'disconnected' });
				return;
			}
			try {
				socket.emit(event, { ...data, requestId });
				if (pendingId) {
					this.store.dispatch(statusActions.addPending(pendingId));
				}
				this.requests.set(requestId, { resolve, reject, pendingId });
			} catch (error) {
				reject(error);
			}
		});

	}
	async emit(event: string, data?: any) {
		return this._emit(event, false, data);
	}
	async emitSecure(event: string, data?: any) {
		if (!this.loggedIn$.getValue()) return void console.warn('Secure socket is disconnected, can not emit!');
		return this._emit(event, true, data);
	}

	_listen(event: string, secure: boolean): Observable<any> {
		return new Observable(observer => {
			this.listenerMap[event] = observer;
			const socket = secure ? this.secureSocket : this.socket;
			socket.on(event, data => {
				// console.log(event, data);

				if (data) {
					const request = this.requests.get(data.requestId);

					if (request) {
						if (request.pendingId) {
							this.store.dispatch(statusActions.removePending(request.pendingId));
						}

						request.resolve(data);
						this.requests.delete(data.requestId);
					}
				}

				observer.next(data);
			});
			// dispose of the event listener when unsubscribed
			return () => this.socket.off(event);
		});
	}
	listen(event: string): Observable<any> {
		return this._listen(event, false);
	}
	listenSecure(event: string): Observable<any> {
		return this._listen(event, true);
	}
	reconnect() {
		this.socket.connect();
		this.secureSocket.connect();
	}
	disconnect() {
		this.secureSocket.disconnect();
		this.socket.disconnect();
	}
}
