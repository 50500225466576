/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {map} from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectors } from '../../selectors';
import config from '../../config'

@Component({
	selector: 'list-item-background',
	template: `<div>
	<div [ngStyle]="{'background-image': getBackroundUrl()}" class="media-background"></div>
	</div>`,
})
export class ListItemBackgroundComponent {
	@Input() mediaObjectId;
	@Input() itemType

	private mediaObjectEntities = {};

	constructor(private store: Store<any>) {
		store
			.select(s => s.models.MediaObject).pipe(
			map(selectors.getEntities))
			.subscribe((entities) => {
				this.mediaObjectEntities = entities;
			});
	}
	getBackroundUrl() {
		if (!this.itemType) return '';
		if (this.itemType === 'audio') return 'url(~src/assets/img/audio.svg)';
		if (this.itemType === 'video') return 'url(~src/assets/img/video.svg)';
		if (!this.mediaObjectId) return '';
		if (!this.mediaObjectEntities) return '';
		const mediaObject = this.mediaObjectEntities[this.mediaObjectId];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}/200)`;
	}
}
