/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';


@Component({
	selector: 'input-custom-fields',
	template: `
<div *ngIf="fields?.length" class="input-widget">
	<label i18n>Custom Fields</label>
	<div *ngFor="let field of fields">
		<div> {{field.label}} </div>
		<div [ngSwitch]="field.type">
			<input
				*ngSwitchCase="'String'"
				type="text"
				(input)="onChangeField(field, $event.target.value)"
				[value]="getItemValue(field.name)"
				placeholder="…">
			<input
				*ngSwitchCase="'Number'"
				(input)="onChangeField(field, $event.target.value)"
				[value]="getItemValue(field.name)"
				type="number"
				placeholder="…">
			<input-json
				*ngSwitchCase="'Object'"
				(input)="onChangeField(field, $event)"
				[value]="getItemValue(field.name)"
				type="number"
				placeholder="…"></input-json>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCustomFieldsComponent), multi: true }],
})
export class InputCustomFieldsComponent extends CustomValueAccessor {
	@Input() fields: any;

	public value = {};

	onChangeField(field, value) {
		if (field.type === 'Number') value = parseInt(value, 10);
		this.value = Object.assign({}, this.value, { [field.name]: value });
	}
	getItemValue(name) {
		return this.value ? this.value[name] : '';
	}
}
