/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	brightness: { type: Number, min: 0, max: 1, default: 0.7 },
	enableBrightnessControl: { type: Boolean, default: false },
	resetAfterInactivity: { type: Boolean, default: false },
	inActivityIntervall: { type: Number, default: 600 },
	showDeviceInfo: { type: Boolean, default: false },
	preventSleep: { type: Boolean, default: false },
	disableLoanerAnalytics: { type: Boolean, default: false },
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
