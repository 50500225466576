/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { SelectModule } from 'ng-select';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';

import { LoadingModule } from '../loading/module';
import { MediaViewerModule } from '../media-viewer/module';
import { InputFileComponent } from './input-file.component/input-file.component';
import { InputFilesExtendedComponent } from './input-files-extended.component/input-files-extended.component';
import { InputLanguageComponent } from './input-language.component/input-language.component';
import { InputLanguageMediaObjectComponent } from './input-language-mediaobject.component/input-language-mediaobject.component';
import { InputMedia3dComponent } from './input-media-3d.component';
import { InputLanguagesComponent } from './input-languages.component/input-languages.component';
import { InputTagsComponent } from './input-tags.component/input-tags.component';
import { InputTextComponent } from './input-text.component';
import { InputTimeComponent } from './input-time.component/input-time.component';
import { InputLanguageStringComponent } from './input-language-string.component/input-language-string.component';
import { InputMediaFileComponent } from './input-media-file.component/input-media-file.component';
import { InputRolesComponent } from './input-roles.component';
import { InputStyleTemplateComponent } from './input-styletemplate.component/input-styletemplate.component';
import { InputLanguageContentComponent } from './input-language-content.component/input-language-content.component';
import { OverwriteContextComponent } from './overwrite-context.component/overwrite-context.component';
import { OverwriteContextContainer } from './overwrite-context.container/overwrite-context.container';
import { InputFilterComponent } from './input-filter.component/input-filter.component';
import { InputSwitchComponent } from './input-switch.component/input-switch.component';
import { InputJsonComponent } from './input-json.component';
import { InputDateComponent } from './input-date-time.component';
import { InputDateRangeComponent } from './input-date-range.component';
import { WebClientConfigComponent } from './web-client-config';
import { BeaconSettingsComponent } from './beacon-settings';
import { InputTextListComponent } from './input-text-list.component';
import { ButtonConfirmDeleteComponent } from './button-confirm-delete.component/button-confirm-delete.component';
import { InputLocationsComponent } from './input-locations.component/input-locations.component';
import { InputMapComponent } from './input-map.component';
import { InputCustomFieldsComponent } from './input-custom-fields.component';


import { ListItemBackgroundComponent } from './list-item-background.component/list-item-background.component';
import { BoxxConfigFormComponent } from './boxx-config-form.component/boxx-config-form.component';
import { SelectArticleComponent } from './select-article.component/select-article.component';
import { SelectMediaComponent } from './select-media.component/select-media.component';

import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { ObjectKeyValuePipe } from './pipes/objectKeyValueList.pipe';

import { InputMaskDirective } from './directives/input-mask.directive';
import { IsLiveDirective } from './directives/is-live.directive';
import { InputDisableComponent } from './input-disable.component';
import { FormInputComponent } from './form-input.component';

import { SelectArrayComponent } from './select-array.component/select-array.component';
import { ModalsModule } from '../modals/module';
import { FormModalComponent } from './form-modal.component';
import { ModelEditFooterComponent } from '../components/model-edit-footer.component';
import { ChangeLogComponent, ChangeLogContainer } from '../components/change-log.component';
import { DynamicModelFormComponent } from '../components/dynamic-model-form.component';
import { FileDropModule } from '../file-dropzone/module';


@NgModule({
	imports: [
		LoadingModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		DragulaModule,
		SelectModule,
		MyDatePickerModule,
		MyDateRangePickerModule,
		ModalsModule,
		FileDropModule,
		MediaViewerModule
	],
	declarations: [
		InputFileComponent,
		InputFilesExtendedComponent,
		InputLanguageStringComponent,
		InputLanguageComponent,
		InputLanguageMediaObjectComponent,
		InputMedia3dComponent,
		InputLanguagesComponent,
		InputTagsComponent,
		InputTextComponent,
		InputTimeComponent,
		InputTextListComponent,
		InputMediaFileComponent,
		InputRolesComponent,
		InputStyleTemplateComponent,
		InputLanguageContentComponent,
		InputSwitchComponent,
		InputJsonComponent,
		InputDisableComponent,
		FormInputComponent,
		OverwriteContextContainer,
		OverwriteContextComponent,
		InputFilterComponent,
		InputDateComponent,
		InputDateRangeComponent,
		ButtonConfirmDeleteComponent,
		InputLocationsComponent,
		InputMapComponent,
		ModelEditFooterComponent,
		ChangeLogComponent,
		ChangeLogContainer,
		DynamicModelFormComponent,
		FormModalComponent,
		WebClientConfigComponent,
		BeaconSettingsComponent,
		InputCustomFieldsComponent,

		ListItemBackgroundComponent,
		BoxxConfigFormComponent,
		SelectArticleComponent,
		SelectMediaComponent,

		SelectArrayComponent,

		ObjectKeyValuePipe,
		FilterArrayPipe,

		IsLiveDirective,
		InputMaskDirective,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DragulaModule,
		SelectModule,
		MyDatePickerModule,
		MyDateRangePickerModule,
		ModalsModule,
		FileDropModule,

		InputFileComponent,
		InputFilesExtendedComponent,
		InputLanguageStringComponent,
		InputLanguageComponent,
		InputLanguageMediaObjectComponent,
		InputMedia3dComponent,
		InputLanguagesComponent,
		InputTagsComponent,
		InputTextComponent,
		InputTimeComponent,
		InputMediaFileComponent,
		InputStyleTemplateComponent,
		InputLanguageContentComponent,
		InputSwitchComponent,
		InputJsonComponent,
		InputDisableComponent,
		FormInputComponent,
		OverwriteContextContainer,
		InputFilterComponent,
		InputDateComponent,
		InputDateRangeComponent,
		ButtonConfirmDeleteComponent,
		InputLocationsComponent,
		InputMapComponent,
		InputCustomFieldsComponent,

		ModelEditFooterComponent,
		ChangeLogContainer,
		DynamicModelFormComponent,
		FormModalComponent,
		ListItemBackgroundComponent,
		BoxxConfigFormComponent,
		SelectArticleComponent,
		WebClientConfigComponent,
		BeaconSettingsComponent,

		SelectArrayComponent,
		SelectMediaComponent,

		ObjectKeyValuePipe,
		FilterArrayPipe,
		InputRolesComponent,

		InputMaskDirective,
		IsLiveDirective,
	],
})
export class FormInputsModule { }

