/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {startWith} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { InputFileComponent } from '../../../forminputs/input-file.component/input-file.component';
import config from '../../../config';

@Component({
	selector: 'custom-map-content-form',
	template: `<div [style.display]="mapId ? '' : 'none'" [formGroup]="formGroup">
		<div #mapMakerHost></div>
		<input-file
			hidden
			#mapZipInput
			accept=".zip"
			formControlName="customMapZip"
			uploadPath="/api/customMapContent"></input-file>
	</div>`,
	styleUrls: ['./index.sass'],
})
export class CustomMapContentFormComponent {
	@Input() formGroup: FormGroup;
	@Input() mapId = '';

	@ViewChild('mapMakerHost', { static: true }) mapMakerHost: ElementRef;
	@ViewChild('mapZipInput', { static: true }) mapZipInput: InputFileComponent;

	protected mapMaker: any;

	constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef) {}

	async ngAfterViewInit() {
		const MapMaker = (await import('map-maker')).default;
		this.mapMaker = this.mapMaker || new MapMaker({
			el: this.mapMakerHost.nativeElement,
			propsData: { mode: 'create', downloadable: false, debug: false },
		});
		this.mapMaker.map.scrollWheelZoom.disable();
		this.mapMaker.$on('zip-updated', async () => {
			const zipFile = await this.mapMaker.getZipBlob();
			zipFile.name = zipFile.name || this.formGroup.value.name + '.zip';
			this.formGroup.controls.customMapZip.markAsDirty();
			// TODO: upload only before save
			this.mapZipInput.setFile(zipFile);
			this.cdr.detectChanges();
		});

		const zipFileId = this.formGroup.value.customMapZip
		if (zipFileId) {
			this.mapMaker.loadArticleMap(zipFileId, config.baseURL);
		}
	}

	ngOnChanges(changes) {
		if (changes.mapId && this.mapMaker) {
			this.mapMaker.loadArticleMap(this.mapId, config.baseURL);
		}
	}

	ngOnDestroy() {
		this.mapMaker.$destroy();
	}
}
