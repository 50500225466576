/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Pipe } from '@angular/core';

// # Filter Array of Objects
// usage
// ```
// <li *ngFor="#boxx of boxxes | filter:query">...</li>
// ```
@Pipe({ name: 'filter' })
export class FilterArrayPipe {
	transform(value, query) {
		query = query || '';
		if (value && query) {
			query = query.toLowerCase();
			return value.filter(item =>
				Object.keys(item).filter(key =>  // expect item to be object
					(typeof item[key] === 'string' || item[key] instanceof String) && (item[key].toLowerCase().indexOf(query) !== -1),
				).length > 0,
			);
		}
		return value;
	}
}
