/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Read the documentation in 'socket.actions.ts'

import { first, pluck, tap, filter, skip, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { SocketEventsService } from './services/socket-events.service';
import * as socketActions from './socket.actions';
import { actions } from './actions';
import { currentuserActions } from './_currentuser/actions';
import { RouteInfo } from './services/route-info';
import { modelConfigs } from './modelConfigs';
import { timer } from './utils';
import { messageActions } from './core/_messages/actions';
import { statusActions } from './status/actions';
import { ModelConfig } from './modelConfigs/ModelConfig';

function addOrganisationIDifNotSet(obj, organisationID) {
	if (!obj.organisationID) return Object.assign({}, obj, { organisationID });
	return obj;
}

function getItemKey({ collectionName, id }: { collectionName: string; id: string }) {
	return `${collectionName}:${id}`;
}

const LEAVE_DELAY = 20000;

function download(filename: string, text: string) {
	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}

@Injectable()
export class SocketEffects {
	private organisationID;
	private haveDepMaps = false;

	private refCountMaps = {
		list: new Map(),
		fullList: new Map(),
		item: new Map(),
	};

	// send message from the server
	@Effect()
	notAuthenticated$: Observable<Action> = this.socketEvents.notAuthenticated$.pipe(
		map(data => {
			return { type: currentuserActions.LOGOUT_SUCCESS };
		})
	);

	@Effect()
	message$: Observable<Action> = this.socketEvents.message$.pipe(
		map(data => {
			const { type, message } = data;
			if (type) return messageActions[type](message);
			else return { type: 'NOOP' };
		})
	);

	// re-join rooms on reconnect
	@Effect({ dispatch: false })
	reconnect$ = this.socketEvents.loggedIn$.pipe(
		skip(1),
		filter(loggedIn => loggedIn),
		tap(() => {
			Array.from(this.refCountMaps.list.entries()).forEach(([collectionName, count]) => {
				if (count < 1) return;

				this.socketEvents.getList({ collectionName });
			});

			Array.from(this.refCountMaps.fullList.entries()).forEach(([collectionName, count]) => {
				if (count < 1) return;

				this.socketEvents.getFullList({ collectionName });
			});

			Array.from(this.refCountMaps.item.entries()).forEach(([key, count]) => {
				if (count < 1) return;

				const [collectionName, id] = key.split(':');

				this.socketEvents.getItem({ collectionName, id });
			});
		})
	);

	// clear refCounts on logout
	@Effect({ dispatch: false }) // effect will not dispatch any actions
	logout$ = this.actions$.pipe(
		ofType(currentuserActions.LOGOUT_SUCCESS),
		tap(() => {
			Object.values(this.refCountMaps).forEach(map => map.clear());
			this.haveDepMaps = false;
		})
	);

	// clear haveDeps on logout/disconnect
	@Effect({ dispatch: false })
	resetHaveDeps$ = this.actions$.pipe(
		ofType(currentuserActions.LOGOUT_SUCCESS, statusActions.DISCONNECT),
		tap(() => {
			this.haveDepMaps = false;
		})
	);

	// -------------
	@Effect()
	createItem$ = this.actions$.pipe(
		ofType(socketActions.CREATE_COLLECTION_ITEM),
		map((action: socketActions.CreateCollectionItem) => action.payload),
		switchMap(
			actionProps =>
				new Observable(observer => {
					actionProps.data = addOrganisationIDifNotSet(actionProps.data, this.organisationID);

					this.socketEvents.createItem(actionProps).then((response: any) => {
						if (response.error) {
							observer.next({ ...actionProps, type: actions.ADD_FAIL });
						}

						observer.complete();
					});

					return () => {};
				})
		)
	);

	@Effect()
	itemCreated$: Observable<Action> = this.socketEvents.itemCreated$.pipe(
		// listen to the socket for ITEM CREATED event
		map(
			data => new socketActions.CollectionItemCreated(data) // ask the the store to populate the notes
		)
	);
	@Effect({ dispatch: false })
	redirectOnCreated$ = this.actions$.pipe(
		ofType(socketActions.COLLECTION_ITEM_CREATED),
		tap(async (action: socketActions.CollectionItemCreated) => {
			const routeModelConfig = await this.routeInfo.params$
				.pipe(
					pluck('modelConfig'),
					first()
				)
				.toPromise();

			const modelConfig = modelConfigs[action.payload.collectionName];

			// only navigate if the model of the item and the route are the same
			// and the user didn't navigate to a new item
			// and the user is at an "edit" or "create" route
			if (modelConfig === routeModelConfig && /\/(create|edit)\b/.test(this.router.url)) {
				this.router.navigateByUrl(
					`${modelConfig.clientPathPrefix}/${modelConfig.name}/edit/${action.payload.data._id}`
				);
			}
		})
	);

	// -------------
	@Effect({ dispatch: false })
	getList$ = this.actions$.pipe(
		ofType(socketActions.GET_COLLECTION_LIST, socketActions.GET_COLLECTION_FULL_LIST),
		tap(
			async ({
				type,
				payload: data,
			}: socketActions.GetCollectionList | socketActions.GetCollectionFullList) => {
				await this.whenLoggedIn();

				const full = type === socketActions.GET_COLLECTION_FULL_LIST;
				const refCounts = full ? this.refCountMaps.fullList : this.refCountMaps.list;
				const methodName = full ? 'getFullList' : 'getList';

				const refCount = (refCounts.get(data.collectionName) || 0) + 1;

				refCounts.set(data.collectionName, refCount);

				// don't request again if joined before
				if (refCount > 1) return;

				this.socketEvents[methodName](data);
			}
		)
	);
	@Effect()
	listUpdated$: Observable<Action> = this.socketEvents.listUpdated$.pipe(
		map(data => new socketActions.CollectionListUpdated(data))
	);
	@Effect()
	listItemUpdated$: Observable<Action> = this.socketEvents.listItemUpdated$.pipe(
		map(data => new socketActions.CollectionListItemUpdated(data))
	);
	@Effect()
	fullListUpdated$: Observable<Action> = this.socketEvents.fullListUpdated$.pipe(
		map(data => new socketActions.CollectionFullListUpdated(data))
	);
	@Effect()
	fullListItemUpdated$: Observable<Action> = this.socketEvents.fullListItemUpdated$.pipe(
		map(data => new socketActions.CollectionFullListItemUpdated(data))
	);
	@Effect({ dispatch: false })
	leaveList$ = this.actions$.pipe(
		ofType(socketActions.LEAVE_COLLECTION_LIST, socketActions.LEAVE_COLLECTION_FULL_LIST),
		tap(
			async ({
				type,
				payload: data,
			}: socketActions.LeaveCollectionList | socketActions.LeaveCollectionFullList) => {
				await timer(LEAVE_DELAY);

				const full = type === socketActions.LEAVE_COLLECTION_FULL_LIST;
				const refCounts = full ? this.refCountMaps.fullList : this.refCountMaps.list;
				const methodName = full ? 'leaveFullList' : 'leaveList';

				const refCount = Math.max(0, (refCounts.get(data.collectionName) || 0) - 1);

				refCounts.set(data.collectionName, refCount);

				if (refCount > 0) return;

				this.socketEvents[methodName](data);
			}
		)
	);

	// -------------
	@Effect({ dispatch: false })
	getItem$ = this.actions$.pipe(
		ofType(socketActions.GET_COLLECTION_ITEM),
		tap(async ({ type, payload: data }: socketActions.GetCollectionItem) => {
			await this.whenLoggedIn();

			const key = getItemKey(data);

			const refCount = (this.refCountMaps.item.get(key) || 0) + 1;

			if (refCount > 1) return;

			this.socketEvents.getItem(data);

			if (data.join) {
				this.refCountMaps.item.set(key, refCount);
			}
		})
	);
	@Effect()
	itemUpdated$: Observable<Action> = this.socketEvents.itemUpdated$.pipe(
		map(data => new socketActions.CollectionItemUpdated(data))
	);
	@Effect({ dispatch: false })
	leaveItem$ = this.actions$.pipe(
		ofType(socketActions.LEAVE_COLLECTION_ITEM),
		tap(async ({ type, payload: data }: socketActions.LeaveCollectionItem) => {
			await timer(LEAVE_DELAY);

			const key = getItemKey(data);

			const refCount = Math.max(0, (this.refCountMaps.item.get(key) || 0) - 1);

			this.refCountMaps.item.set(key, refCount);

			if (refCount > 0) return;

			this.socketEvents.leaveItem(data);
		})
	);

	// -------------
	@Effect({ dispatch: false })
	updateItem$ = this.actions$.pipe(
		ofType(socketActions.UPDATE_COLLECTION_ITEM),
		map((action: socketActions.UpdateCollectionItem) => action.payload),
		tap(data => this.socketEvents.updateItem(data))
	);

	// -------------
	@Effect({ dispatch: false })
	deleteItem$ = this.actions$.pipe(
		ofType(socketActions.DELETE_COLLECTION_ITEM),
		map((action: socketActions.DeleteCollectionItem) => action.payload),
		tap(data => this.socketEvents.deleteItem(data))
	);

	@Effect()
	itemDeleted$: Observable<Action> = this.socketEvents.itemDeleted$.pipe(
		map(data => new socketActions.CollectionItemDeleted(data))
	);

	@Effect({ dispatch: false })
	redirectOnDeleted$ = this.actions$.pipe(
		ofType(socketActions.COLLECTION_ITEM_DELETED),
		switchMap(({ payload }: socketActions.CollectionItemDeleted) => {
			return this.routeInfo.params$.pipe(
				first(),
				tap(({ modelConfig, id }) => {
					if (id === payload.data && modelConfig.collectionName === payload.collectionName) {
						this.router.navigateByUrl(`${modelConfig.clientPathPrefix}/${modelConfig.name}`);
					}
				})
			);
		})
	);

	// -------------
	@Effect({ dispatch: false })
	getVersionList$ = this.actions$.pipe(
		ofType(socketActions.GET_VERSION_LIST),
		map((action: socketActions.GetVersionList) => action.payload),
		tap(data => this.socketEvents.getVersionList(data))
	);
	@Effect()
	versionListUpdated$: Observable<Action> = this.socketEvents.versionListUpdated$.pipe(
		map(data => new socketActions.VersionListUpdated(data))
	);

	// -------------
	@Effect({ dispatch: false })
	getVersionOfItem$ = this.actions$.pipe(
		ofType(socketActions.GET_VERSION_OF_ITEM),
		map((action: socketActions.GetVersionOfItem) => action.payload),
		tap(async data => {
			await this.whenLoggedIn();
			this.socketEvents.getVersionOfItem(data);
		})
	);
	@Effect()
	versionOfItemUpdated$: Observable<Action> = this.socketEvents.versionOfItemUpdated$.pipe(
		map(data => {
			const modelConfig = modelConfigs[data.collectionName];
			this.router.navigateByUrl(`/${modelConfig.name}/edit/${data.id}/version/${data.versionNumber}`);
			return new socketActions.VersionOfItemUpdated(data);
		})
	);

	// -------------
	@Effect({ dispatch: false })
	requestRevertToVersionOfItem$ = this.actions$.pipe(
		ofType(socketActions.REQUEST_REVERT_TO_VERSION_OF_ITEM),
		map((action: socketActions.RequestRevertToVersionOfItem) => action.payload),
		tap(async data => {
			this.socketEvents.requestRevertToVersionOfItem(data);
		})
	);
	@Effect()
	revertToVersionOfItemDone$: Observable<Action> = this.socketEvents.revertToVersionOfItemDone$.pipe(
		map(data => {
			console.log('revertToVersionOfItemDone$', data);
			const modelConfig = modelConfigs[data.collectionName];
			this.router.navigateByUrl(`${modelConfig.name}/edit/${data.id}`);
			return messageActions.success(this.i18n('Sucessfully revert to version ') + `${data.versionNumber + 1}`);
		})
	);

	// -------------
	@Effect()
	currentUserDataUpdated$: Observable<Action> = this.socketEvents.currentUserDataUpdated$.pipe(
		map(data => new socketActions.CurrentUserDataUpdated(data))
	);

	@Effect({ dispatch: false })
	GetDependencyMapsData$ = this.actions$.pipe(
		ofType(socketActions.GET_DEPENDENCY_MAPS_DATA),
		filter(() => !this.haveDepMaps),
		tap(() => {
			this.socketEvents.getDependencyMapData();
			this.haveDepMaps = true;
		})
	);

	@Effect()
	dependencyMapsDataUpdated$: Observable<Action> = this.socketEvents.dependencyMapsDataUpdated$.pipe(
		map(data => new socketActions.DependencyMapsDataUpdated(data))
	);

	@Effect({ dispatch: false })
	predictMapDownloadSize$ = this.actions$.pipe(
		ofType(socketActions.PREDICT_MAP_DOWNLOAD_SIZE),
		tap(this.socketEvents.predictMapDownloadSize)
	);

	@Effect()
	mapDownloadSizePredicted$ = this.socketEvents.mapDownloadSizePredicted$.pipe(
		map(data => new socketActions.MapDownloadSizePredicted(data))
	);

	@Effect()
	updateAnalyticsData$ = this.socketEvents.analyticsDataUpdated$.pipe(
		map(data => new socketActions.AnalyticsDataUpdated(data))
	);

	@Effect({ dispatch: false })
	downloadAnalyticsData$ = this.socketEvents.analyticsDataDownload$.pipe(
		map(data => new socketActions.AnalyticsDataDownload(data)),
		tap(data => {
			download(data.payload.filename, data.payload.data);
		})
	);

	@Effect({ dispatch: false })
	getAnalyticsData$ = this.actions$.pipe(
		ofType(socketActions.GET_ANALYTICS_DATA),
		map((action: socketActions.GetAnalyticsData) => action.payload),
		tap(data => this.socketEvents.getAnalyticsData(data))
	);

	@Effect({ dispatch: false })
	getApiKey$ = this.actions$.pipe(
		ofType(socketActions.GET_API_KEY),
		tap(() => this.socketEvents.getApiKey())
	);

	@Effect()
	apiKeyUpdated$: Observable<Action> = this.socketEvents.apiKeyUpdated$.pipe(
		map(data => new socketActions.ApiKeyUpdated(data))
	);

	@Effect({ dispatch: false })
	getMapInformation$ = this.actions$.pipe(
		ofType(socketActions.GET_MAP_INFORMATION),
		tap(data => this.socketEvents.getMapInformation(data))
	);

	@Effect({ dispatch: false })
	startMapDownload$ = this.actions$.pipe(
		ofType(socketActions.START_MAP_DOWNLOAD),
		tap(data => this.socketEvents.startMapDownload(data))
	);

	@Effect()
	mapInformationUpdated$ = this.socketEvents.mapInformationUpdated$.pipe(
		map(data => new socketActions.MapInformationUpdated(data))
	);

	@Effect({ dispatch: false })
	recreateThumbNail$ = this.actions$.pipe(
		ofType(socketActions.RECREATE_THUMB_NAIL),
		map((action: socketActions.RecreateThumbNail) => action.payload),
		tap(data => this.socketEvents.recreateThumbNail(data))
	);

	@Effect({ dispatch: false })
	publish$ = this.actions$.pipe(
		ofType(socketActions.PUBLISH_CHANGES),
		map((action: socketActions.PublishChanges) => action.payload),
		tap(data => this.socketEvents.publishChanges(data))
	);

	constructor(
		private actions$: Actions,
		private socketEvents: SocketEventsService,
		private routeInfo: RouteInfo,
		private router: Router,
		private store: Store<any>,
		private i18n: I18n
	) {
		this.store.select('currentuser').subscribe((cuser: any) => {
			this.organisationID = cuser.organisation._id;
		});
	}

	async whenLoggedIn() {
		return this.socketEvents.loggedIn$
			.pipe(
				filter(Boolean),
				first()
			)
			.toPromise();
	}
}
