/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { LOCALE_ID, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@angular/common/http';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { environment } from '../environments/environment';

import { APP_REDUCERS } from './reducers';

import { StatusEffects } from './status/effects';

import { AppHttp } from './services/AppHttp';
import { SocketConnectionService } from './services/socket-connection.service';

// Collection modules
import { Module as CurrentuserModule } from './_currentuser/module';
import { CollectionModule } from './collection.module';
import { CoreModule } from './core';
import { PaymentsModule } from './payments/module';
import { AnalyticsModule } from './analytics/module';
import { AdminDashboardModule } from './admin-dashboard/module';

// Our root component
import { AppComponent } from './app.component';
import { DragulaModule } from 'ng2-dragula';

const routes = [
	{
		path: '',
		redirectTo: '/exhibition',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: '/exhibition',
	},
];

const devTools = environment.production
	? []
	: [StoreDevtoolsModule.instrument({ maxAge: 25 /* Retains last 25 states */ })];

@NgModule({
	imports: [
		BrowserModule,
		RouterModule.forRoot(routes),
		HttpClientModule,
		DragulaModule.forRoot(),

		CollectionModule,
		CoreModule,
		PaymentsModule,
		AnalyticsModule,
		AdminDashboardModule,

		CurrentuserModule,
		StoreModule.forRoot(APP_REDUCERS),
		EffectsModule.forRoot([StatusEffects]),
		...devTools,
	],
	declarations: [AppComponent],
	providers: [
		AppHttp,
		SocketConnectionService,
		{ provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
		{
			provide: TRANSLATIONS,
			useFactory: locale => {
				locale = locale || 'en'; // default to english if no locale provided
				return require(`raw-loader!../i18n/messages.${locale}.xlf`);
			},
			deps: [LOCALE_ID],
		},
		I18n,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
