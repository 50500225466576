/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema, labels } from '../../../models/articleList/articleListSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { PlaylistFormComponent } from './components/playlist-form';

import { boxxConfig } from '../_boxxs/config';
import { exhibitionConfig } from '../_exhibitions/config';
import { styletemplateConfig } from '../_styletemplates/config';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const articlelistConfig = new ModelConfig({
	name: 'playlist',
	collectionName: 'ArticleList',
	icon: 'icon-media_L',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'Article', inSidebar: true },
		{ name: 'Boxx', inSidebar: false },
		{ name: 'StyleTemplate', inSidebar: false },
	],

	schema,
	labels,

	publishable: true,

	formComponent: PlaylistFormComponent,
});

Object.assign(articlelistConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'Organisation', 'entities'),
			pluckDistinct(store, 'models', 'Boxx').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'models', 'StyleTemplate', 'entities'),
			pluckDistinct(store, 'currentuser'),
			(model: any, organisationEntities, boxxes: any, styleTemplateEntities: any, currentUser: any) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const boxxesRefined = organisationID
					? boxxes.filter(boxx => boxx.organisationID === organisationID)
					: boxxes;
				let styleTemplates = [];
				if (!organisationID) {
					styleTemplates = Object.values(styleTemplateEntities);
				} else {
					const organisation = (organisationEntities || {})[organisationID];
					if (organisation && Object.keys(styleTemplateEntities).length) {
						styleTemplates =  organisation.styleTemplates
							.filter(_id => _id in styleTemplateEntities)
							.map(_id => styleTemplateEntities[_id]);
					}
				}
				return {
					model,
					currentUser,
					styleTemplates,
					boxxes: boxxesRefined.filter(
						boxx =>
							boxx.specificConfig.boxxType === 'mediaplayer' ||
							boxx.specificConfig.boxxType === 'externalmediaplayer'
					),
				};
			}
		);
	},
});
