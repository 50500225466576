/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {pluck, map} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectors } from '../../selectors';

import { currentuserActions } from '../../_currentuser/actions';


@Component({
	selector: 'nav-context',
	template: `<nav-context-component
				(orgchange)="setOrganisation($event)"
				[data]="model$ | async">
		</nav-context-component>`,
})
export class NavContextContainer {
	public model$;
	constructor(private store: Store<any>) {
		this.model$ = observableCombineLatest(
				store.pipe(pluck('models', 'Organisation'),map(selectors.getAlphabeticList),),
				store.pipe(pluck('models', 'Exhibition'),map(selectors.getAlphabeticList),),
				store.select('currentuser')
			).pipe(
			map(([organisations, exhibitions, currentuser]) => {
				return ({ organisations, exhibitions, currentuser });
			},
		));
	}

	setOrganisation(o) {
		this.store.dispatch(currentuserActions.setOrganisation(o));
	}
}
