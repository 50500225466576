/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component } from '@angular/core';

@Component({
	template: '<h1>The page you are looking for can not be found</h1>',
})
export class NotFoundComponent {}
