/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'media-viewer',
	template: `
	<style>
	:host(*) {
		display: flex;
		display: contents;
		max-width: 100%;
		max-height: 100%;
		align-self: center;
	}
	</style>
	<div class="media-viewer type-{{type}}" [ngClass]="{ 'full-size': fullSize }">
		<ng-container [ngSwitch]="type">
			<img *ngSwitchCase="'image'" [src]="source">
		  <audio controls preload="none" *ngSwitchCase="'audio'" [src]="source">
			  Your browser does not support the <code>audio</code> element.
			</audio>
			<video controls preload="none" *ngSwitchCase="'video'" [src]="source">
			  Your browser does not support the <code>audio</code> element.
			</video>
			<three-d
				*ngSwitchCase="'3DModel'"
				[source]="source"
				(getParamsCounter)="viewer3dParamsCounter"
				(screenshot)="screenshot.emit($event)"
				(viewer3dParams)="viewer3dParams.emit($event)">
				Your browser does not support WebGL.
			</three-d>
			<div class="ng-content-container" *ngSwitchDefault>
				<ng-content></ng-content>
			</div>
		</ng-container>
	</div>`,
	styleUrls: ['./media-viewer.component.sass'],
})
export class MediaViewerComponent {
	// a url can be provided directly for images, audio and video. for 3D models,
	// the languageContent with source (.obj/.fbx) and threeD fields must be given
	@Input() source;
	@Input() type = null;
	@Input() fullSize;
	@Output() screenshot = new EventEmitter(false);
	@Output() viewer3dParams = new EventEmitter(false);
}
