/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';

@Component({
	selector: 'input-date-time',
	template: `
	<div class="input-date-time">
		<my-date-picker
			[disabled]="disabled"
			[ngModel]="_value === null ? null : { jsdate: _value }"
			[options]="{ dateFormat: 'dd.mm.yyyy', showClearDateBtn: false }"
			(dateChanged)="onPickDate($event)">
		</my-date-picker>
		<input-time [disabled]="disabled" [ngModel]="_value" (inputTime)="this.propagateChange();">
		</input-time>
	</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputDateComponent), multi: true }],
})
export class InputDateComponent extends CustomValueAccessor {
	@Input() disabled = false;

	public _value: Date = null;

	set value (value: Date|number|string) {
		// try to convert value to type expeccted by my-date-picker
		if (typeof value === 'string') {
			value = new Date(value);
		} else if (typeof value === 'number') {
			const time = value;
			value = new Date();
			value.setTime(time);
		}

		this.set_value(value);
	}

	get value () {
		return this._value && this._value.getTime();
	}

	onPickDate({ jsdate }) {
		this.value = jsdate;
	}
}
