/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var EVENTIDS = [
	'reboot',
	'wifirestart',
	'integrityfail',
	'mwanchange',
	'webstationcrash',
	'webstationnodisplay',
	'quotaexceeded',
];
var def = {
	organisationID: {
		type: Schema.ObjectId,
		ref: 'Organisation',
		index: true,
		unique: false,
	},
	dataSourceID: {
		type: Schema.ObjectId,
		ref: 'DataSource',
		index: true,
		unique: false,
	},
	boxxID: { type: Schema.ObjectId, ref: 'Boxx', index: true, unique: false },
	timeStamp: { type: Date },
	timeStampOriginal: { type: String },
	escalation: { type: String, enum: ['warning', 'critical'] },
	eventID: { type: String, enum: EVENTIDS },
	data: {
		reason: { type: String },
		uptime: { type: String },
		package: { type: String },
		file: { type: String },
	},
};

var ModelSchema = new Schema(def);

module.exports = {
	name: 'BoxxEvent',
	schema: ModelSchema,
	definition: def,
	EVENTIDS,
};
