/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {delay, takeUntil} from 'rxjs/operators';
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable ,  Subject } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import config from '../../config';
import { mediaConfig } from '../../_media/config';
import { actions } from '../../actions';
import * as CollectionActions from '../../socket.actions';
import { selectors } from '../../selectors';
import { pluckDistinct, CollectionJoiner } from '../../utils';


@Component({
	selector: 'select-media',
	template: `
		<header class="filter" (click)="$event.stopPropagation()">
			<input-filter
				#filterComponent
				[items]="list"
				(filter)="filteredItems = $event.result"
				(keyup.enter)="selectFirst()">
			</input-filter>
			<div class="sort-menu">
				<div i18n>Sort</div>
				<div
					[ngClass]="{'sort-direction--active': (data.sort.objectPath == 'changeLog.editedOn')}"
					(click)="sorting('changeLog.editedOn')">
					<div i18n>by date</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.sort.reverse}"></div>
				</div>
				<div
					[ngClass]="{'sort-direction--active': (data.sort.objectPath == 'name')}"
					(click)="sorting('name')">
					<div i18n>by name</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.sort.reverse}"></div>
				</div>
			</div>
		</header>

		<div class="list">
			<div
				*ngFor="let item of filteredItems"
				(click)="selectmedia.emit(item._id);"
				[ngStyle]="{backgroundImage: getThumb(item)}">
					<span>{{item.name}}</span>
				</div>
			<a class="list--add-new" (click)="$event.preventDefault(); newModal.open()">
				<span class="icon-add"></span>
				<div i18n>new media</div>
			</a>
		</div>

		<form-modal #newModal collectionName="MediaObject" i18n>
			Create a new media item
		</form-modal>
	`,
	styleUrls: ['./select-media.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectMediaComponent), multi: true }],
})
export class SelectMediaComponent extends CustomValueAccessor {
	public mediaConfig = mediaConfig;
	public list;
	public filteredItems;
	public data;
	private destroyed$ = new Subject();
	private depManager: CollectionJoiner;

	public showModal = false;

	private sort;

	@Input() typeFilter: '' = null;
	@Input() organisationID;
	@Output() selectmedia = new EventEmitter(false);

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		selectors.getSortedListForOrg$(pluckDistinct(store, 'models', mediaConfig.collectionName), store).pipe(
			takeUntil(this.destroyed$))
			.subscribe((data) => {
				this.data = data;
				this.filterList();
			});

		this.depManager = new CollectionJoiner(store, [], this.destroyed$.pipe(delay(0)));
	}

	ngOnInit() {
		setTimeout(() => {
			this.depManager.replace([mediaConfig.collectionName]);
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();

		setTimeout(() => {
			this.store.dispatch(new CollectionActions.LeaveCollectionList({ collectionName: mediaConfig.collectionName }));
		});
	}

	ngOnChanges(changed) {
		if (changed.organisationID || changed.typeFilter) {
			this.filterList();
		}
	}

	filterList() {
		const types = (this.typeFilter || '').split(',')
		this.list = this.data.list.filter(
			item => types.includes(item.type) && (!this.organisationID || this.organisationID === this.organisationID)
		);
	}

	selectFirst() {
		const first = this.filteredItems[0];

		if (first) {
			this.selectmedia.emit(first._id);
		}
	}

	open() { this.showModal = true; }
	close() { this.showModal = false; }
	toggle() { this.showModal = !this.showModal; }

	getThumb(mediaObject) {
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		if (mediaObject.type === 'audio') return 'url(/assets/img/audio.svg)';
		if (mediaObject.type === 'video') return 'url(/assets/img/video.svg)';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}/200)`;
	}

	sorting(objectPath) {
		this.store.dispatch(actions.sort(mediaConfig.collectionName, objectPath));
	}

}
