/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { messageActions } from './actions';
import { filter, delay, map } from 'rxjs/operators';


@Injectable()
export class MessagesEffects {
	static MESSAGE_TIMEOUT = 10000;

	@Effect()
	autoCloseMessages = this.actions$.pipe(
		ofType(
			messageActions.MESSAGE_ERROR,
			messageActions.MESSAGE_WARN,
			messageActions.MESSAGE_INFO),
		filter((action: any) => !action.payload.sticky),
		delay(MessagesEffects.MESSAGE_TIMEOUT),
		map((action: any) => {
			return messageActions.close(action.payload.id);
		})
	);

	constructor(private actions$: Actions<any>, private store: Store<any>) {	}
}
