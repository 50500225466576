/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import deepExtend from 'deep-extend';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../forminputs/CustomValueAccessor';
import { getFileAcceptAttr, mimeToMediaType, updateField, getFieldValue, pluckDistinct, bytesToHumanReadableSize } from '../../utils';
import { ModalComponent } from '../../modals/modal.component';
import { AppHttp } from '../../services/AppHttp';
import { mediaConfig } from '../../_media/config';
import { messageActions } from '../../core/_messages/actions';
import config from '../../config';
import { takeUntil } from 'rxjs/operators';
import * as socketActions from '../../socket.actions';


@Component({
	selector: 'input-language-mediaobject',
	templateUrl: './input-language-mediaobject.component.html',
	styleUrls: ['./input-language-mediaobject.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputLanguageMediaObjectComponent),
			multi: true,
		},
	],
})
export class InputLanguageMediaObjectComponent extends CustomValueAccessor {
	@Input() type;
	@Input() languageOptions: any[];
	@Input() isCreate: boolean;
	@Input() id: string = null;
	@Input() customFields: any[];

	@Output() fileChange = new EventEmitter();

	@ViewChild('syncLangsModal', { static: false }) syncLangsModal: ModalComponent;

	public pendingSync = {
		source: null,
		targets: null,
		fieldPaths: null,
		message: '',
		mode: null,
	};
	public languageIso2name: any;
	public missingLanguageOptions = true;
	public destroyed$ = new Subject();
	public isRoot = false;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private appHttp: AppHttp,
		private store: Store<any>,
		public i18n: I18n
	) {
		super(i18n);
		this.initOptions({ changeDetectorRef });
		pluckDistinct(store, 'currentuser', 'access', 'isAdmin').subscribe((isRoot) => {
			this.isRoot = isRoot;
		});
	}

	openSyncLangsDialog(
		sourceItem,
		sourceIndex,
		mode,
		prevSource,
		fieldPaths = [{ path: 'thumbSource', title: 'Preview image', checked: true }],
		message = mode === 'sync'
			? `Would you like to synchronize the language versions by using the
				${this.languageIso2name[sourceItem.languageISO]} media file for other languages?`
			: `The updated language shared the same media file as the languages
				listed below. Would you also like to update these language versions?`
	) {
		this.pendingSync.source = sourceItem;
		this.pendingSync.fieldPaths = fieldPaths;

		this.pendingSync.targets = this.value.map((item, i) => {
			if (i === sourceIndex || (mode === 'update' && item.source !== prevSource)) {
				return null;
			}

			return {
				lang: this.languageIso2name[item.languageISO],
				title: item.title,
				checked: true,
				synced:
					item.source === sourceItem.source &&
					fieldPaths.every(({ path }) => {
						return getFieldValue(item, path) === getFieldValue(sourceItem, path);
					}),
			};
		});

		this.pendingSync.message = message;

		this.syncLangsModal.open();
	}

	syncLangs({ source: sourceItem, targets, fieldPaths }) {
		fieldPaths = fieldPaths.filter((field) => field.checked);

		fieldPaths.push({ path: 'source' });

		this.value = this.value.map((item, index) => {
			if (!targets[index] || !targets[index].checked) {
				return item;
			}

			return fieldPaths.reduce((acc, { path }) => {
				const sourceValue = getFieldValue(sourceItem, path);
				return updateField(
					acc,
					path,
					!!sourceValue && typeof sourceValue === 'object' ? deepExtend({}, sourceValue) : sourceValue
				);
			}, item);
		});
	}

	ngOnChanges(changes) {
		const lo = this.languageOptions || [];
		this.languageIso2name = lo.reduce((acc, lang) => ({ ...acc, [lang.value]: lang.label }), {});
		this.missingLanguageOptions = !this.languageOptions.length;
	}

	getThumbUrl(item) {
		return item && item.thumbSource ? `${config.baseURL}/api/mediafiles/${item.thumbSource}` : '';
	}

	recreateThumbnail(item, index) {
		// TODO: block editing until request completes
		this.store.dispatch(new socketActions.RecreateThumbNail({ mediaObjectID: this.id, languageItem: item }));
	}

	fixValue() {
		if (
			this.value &&
			this.value.length &&
			this.languageOptions.length &&
			!(
				this.value.every(({ languageISO }) => languageISO in this.languageIso2name) &&
				this.value.length === this.languageOptions.length
			)
		) {
			setTimeout(() => {
				const first = this.value[0];

				const source = first.source;
				const curentLanguages = new Set(this.value.map(({ languageISO }) => languageISO));
				this.value = [
					// remove access languages
					...this.value.filter(({ languageISO }) => languageISO in this.languageIso2name),
					// add missing languages
					...this.languageOptions
						.filter((lang) => !curentLanguages.has(lang.value))
						.map((lang) => ({
							file: null,
							source,
							languageISO: lang.value,
							title: '',
							description: '',
						})),
				];

				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value) {
		this.value = value;
	}

	add(event) {
		if (!this.value) this.value = [];

		let previousAllowedType = this.type || 'misc';

		const files = Array.from(event.target.files || event.dataTransfer.files).filter((file: any) => {
			const fileMediaType = mimeToMediaType(file.type);
			const isAllowed = previousAllowedType === 'misc' || fileMediaType === previousAllowedType;

			if (isAllowed) {
				previousAllowedType = fileMediaType;
			}

			return isAllowed;
		});

		if (!files.length) return;

		this.value = [
			{
				file: files[0],
				source: '',
				languageISO: this.languageOptions[0].value,
				title: '',
				description: '',
				altText: '',
				attribution: '',
				idx: this.value.length,
			},
		];
	}

	askPendingUpdates(prevSource, languageItem, index) {
		const hasPendingUpdates = this.value.some(
			({ source }, idx) => source && source === prevSource && index !== idx
		);

		if (hasPendingUpdates) {
			this.openSyncLangsDialog(languageItem, index, 'update', prevSource);
		}
	}

	onFileChange(fileInfo, languageItem, index) {
		this.type = fileInfo.type;

		this.askPendingUpdates(fileInfo.prevId, languageItem, index);

		languageItem.source = fileInfo._id;
		languageItem.file = null;

		this.fixValue();

		this.propagateChange();
		this.fileChange.emit(fileInfo);
	}

	onPreviewChange(fileInfo, languageItem, index) {
		this.askPendingUpdates(languageItem.source, languageItem, index);

		this.value = [...this.value];
	}

	canHaveThumb(type) {
		return /^(image|video|3DModel)$/.test(type);
	}

	getValue(sourceId) {
		return this.value.find((file) => file.source === sourceId);
	}

	getAcceptMime() {
		return getFileAcceptAttr(this.type);
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(value);
	}

	convertBytesToHumanReadableSize(size) {
		return bytesToHumanReadableSize(size);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
