
<div class="input-widget">
	<ng-select
		(selected)="setLanguage($event)"
		[(ngModel)]="value"
		[options]="langOptions"
		i18n-placeholder
		placeholder="… language">
	</ng-select>
</div>
	