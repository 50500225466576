/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';

@Component({
	selector: 'input-files-extended',
	template: `
<div class="list">
	<div *ngFor="let item of value; let idx=index">
		{{item.name}}
		<span class="icon-remove" (click)="remove(idx)"></span>
	</div>
</div>
<div class="input-widget">
	<input-file [type]="type" [accept]="accept" [uploadPath]="uploadPath" (fileChange)="onFileChange($event)"></input-file>
</div>
	`,
	styleUrls: ['./input-files-extended.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputFilesExtendedComponent), multi: true }],
	inputs: ['uploadPath'],
})
export class InputFilesExtendedComponent extends CustomValueAccessor {
	@Input() type = null;
	@Input() accept = null;
	public uploadPath;

	ngOnInit() {
		this.value = this.value || [];
	}

	onFileChange(fileInfo) {
		this.value = [...this.value, {
			fileID: fileInfo._id,
			name: fileInfo.filename,
			mimeType: fileInfo.contentType,
		}];
	}

	remove(idx) {
		this.value = this.value.filter((id, i) => i !== idx);
	}
}
