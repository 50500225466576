/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const Schema = require('mongoose').Schema;

const nHTModes = {
	HT20: 'HT20 single 20MHz channel',
	'HT40-': 'HT40- 2x 20MHz channels, primary/control channel is upper, secondary channel is below',
	'HT40+': 'HT40+ 2x 20MHz channels, primary/control channel is lower, secondary channel is above',
	HT40:
		'HT40 2x 20Mz channels, auto selection of upper or lower secondary channel on versions 14.07 and above',
	NONE: 'NONE disables 802.11n rates and enforce the usage of legacy 802.11 b/g/a rates',
};

const acHTModes = {
	HT20: 'HT20 single 20MHz channel',
	'HT40-': 'HT40- 2x 20MHz channels, primary/control channel is upper, secondary channel is below',
	'HT40+': 'HT40+ 2x 20MHz channels, primary/control channel is lower, secondary channel is above',
	HT40:
		'HT40 2x 20Mz channels, auto selection of upper or lower secondary channel on versions 14.07 and above',
	NONE: 'NONE disables 802.11n rates and enforce the usage of legacy 802.11 b/g/a rates',
	VHT20:
		'VHT20 channel width in 802.11ac, extra channels are picked according to the specification',
	VHT40:
		'VHT40 channel width in 802.11ac, extra channels are picked according to the specification',
	VHT80:
		'VHT80 channel width in 802.11ac, extra channels are picked according to the specification',
	VHT160:
		'VHT160 channel width in 802.11ac, extra channels are picked according to the specification',
};

const nHTModeValues = Object.keys(nHTModes);
nHTModeValues.push(null);

const acHTModeValues = Object.keys(acHTModes);
acHTModeValues.push(null);

const definition = {
	upddDisable: { type: Boolean, default: false },
	ssid: { type: String, default: 'shoutr.Boxx', trim: true },
	ssid5: { type: String, default: 'shoutr.Boxx', trim: true },
	tXDbm: { type: Number, default: null },
	tXDbm5: { type: Number, default: null },
	kickDbmThreshold: { type: Number, default: null },
	kickDbmThreshold5: { type: Number, default: null },
	// channel: min: 1 max: 11
	channel: { type: Number, default: 11, enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
	channel5: { type: Number, default: 36, enum: [36, 40, 44, 48] }, // min: 36, 40 44 48
	htmode: { type: String, default: 'HT20', enum: nHTModeValues },
	htmode5: { type: String, default: 'HT20', enum: acHTModeValues },
	disableAp: { type: Boolean, default: false },
	disableAp5: { type: Boolean, default: false },
	enableQoS: { type: Boolean, default: true, alias: 'QoS' },
	enableQoS5: { type: Boolean, default: true, alias: 'QoS5' },
	enableQoSMesh: { type: Boolean, default: true, alias: 'QoSMesh' },
	captivePortalDomain: { type: String, default: 'shoutr.boxx', alias: 'Domain' },
	captivePortalCacheExpires: { type: Number, default: 20 },
	captivePortalDisablePopup: { type: Boolean, default: false },
	captivePortalEnableInet: { type: Boolean, default: false },
	captivePortalEnablePaywallBoxx: { type: Boolean, default: false },
	captivePortalBlockAppleUpdates: { type: Boolean, default: true },
	captivePortalQuotaWAN: { type: Number, min: 0, default: null },
	captivePortalIsolate: { type: Boolean, default: false },
	ssl: { type: Boolean, default: false, alias: 'captivePortalSSL' },
	disableWiFiLEDs: { type: Boolean, default: false },
	webstationURL: { type: String, default: null },

	duty: {
		from: { type: Date, default: new Date(1982, 6, 9, 0, 0, 0) },
		to: { type: Date, default: new Date(1982, 6, 9, 23, 59, 0) },
	},
	dnsWhiteList: {
		android: { type: Boolean, default: true },
		ios: { type: Boolean, default: true },
		socialMedia: { type: Boolean, default: true },
		youtube: { type: Boolean, default: true },
		custom: { type: String, default: null },
	},
};
const ModelSchema = new Schema(definition);

module.exports = {
	schema: ModelSchema,
	definition,
	nHTModes,
	acHTModes,
};
