/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable ,  Subject } from 'rxjs';
import {map, pluck, takeUntil} from 'rxjs/operators';
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { articleConfig } from '../../_articles/config';
import { actions } from '../../actions';
import * as CollectionActions from '../../socket.actions';
import config from '../../config'

import { selectors } from '../../selectors';
import { CollectionJoiner } from '../../utils';

@Component({
	selector: 'select-article',
	template: `
		<div class="filter" (click)="$event.stopPropagation()">
			<input-filter
				#filterComponent
				[items]="data.articles.list"
				(filter)="filteredItems = $event.result"
				(keyup.enter)="selectFirst()">
			</input-filter>
			<div class="sort-menu">
				<div i18n> Sort </div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'changeLog.editedOn')}"
					(click)="sorting('changeLog.editedOn')">
					<div i18n>by date</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'name')}"
					(click)="sorting('name')">
					<div i18n>by name</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
			</div>
		</div>

		<div class="list">
			<div
				*ngFor="let article of filteredItems"
				(click)="selectarticle.emit(article._id)"
				[ngStyle]="{backgroundImage: getUrl(article)}">
					<span>{{article.name}}</span>
				</div>
			<a class="list--add-new" (click)="$event.preventDefault(); newModal.open()">
				<span class="icon-add"></span>
				<div i18n>new article</div>
			</a>
		</div>

		<form-modal #newModal collectionName="Article" i18n>
			Create a new article
		</form-modal>
	`,
	styleUrls: ['./select-article.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectArticleComponent), multi: true }],
})
export class SelectArticleComponent extends CustomValueAccessor {
	public data;
	public filteredItems;
	private destroyed$ = new Subject();

	public createArticleUrl = `${articleConfig.clientPathPrefix}/${articleConfig.name}/create`;

	private sort;

	@Output() selectarticle = new EventEmitter(false);

	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		observableCombineLatest(
				selectors.getSortedListForOrg$(store.pipe(pluck('models', 'Article')), store),
				this.store.pipe(pluck('models', 'MediaObject'), map(selectors.getEntities), ),
				(articles, mediaobjects) => ({ articles, mediaobjects }),
			).pipe(
			takeUntil(this.destroyed$))
			.subscribe((data) => {
				this.data = data;
			});
	}

	ngOnInit() {
		CollectionJoiner.getUntil(this.store, ['Article', 'MediaObject'], this.destroyed$);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	getUrl(article) {
		const mediaObject = this.data.mediaobjects[article.coverID];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}/200)`;
	}

	selectFirst() {
		const first = this.filteredItems[0];

		if (first) {
			this.selectarticle.emit(first._id);
		}
	}

	sorting(objectPath) {
		this.store.dispatch(actions.sort('Article', objectPath));
	}

}
