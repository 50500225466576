/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var */
var Schema = require('mongoose').Schema;
const getLabels = require('../getLabels').getLabels;
var triggerTypes = require('../../helpers/consts').MEDIATRIGGERTYPES;
var triggerDevices = require('../../helpers/consts').TRIGGERDEVICES;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	name: { type: String, required: true, unique: false, sparse: true, trim: true, label: 'Name' },
	boxxID: { type: Schema.ObjectId, ref: 'Boxx' },
	triggerDevice: { type: String, enum: Object.values(triggerDevices), default: 'none' },
	list: [
		{
			articleID: { type: Schema.ObjectId, ref: 'Article' },
			duration: { type: Number }, // duration in seconds
			stopDuration: { type: Number },
			loop: { type: Boolean, default: false },
			dmxID: { type: Number, default: 1 },
			castID: { type: Number },
			autoStartContent: { type: Boolean, default: false },
			jumpTo: { type: Number },
			trigger: { type: String, enum: Object.keys(triggerTypes), default: triggerTypes.default },
		},
	],
	isUpToDate: { type: Boolean, default: false, writable: false },
	publishedVersions: [
		{
			version: { type: Number },
			styleTemplateID: { type: Schema.ObjectId, ref: 'StyleTemplate' },
			list: [
				{
					articleID: { type: Schema.ObjectId, ref: 'Article' },
					version: { type: Number },
					mediaFiles: [{ type: Schema.ObjectId }],
					duration: { type: Number }, // duration in seconds
					stopDuration: { type: Number },
					loop: { type: Boolean, default: false },
					dmxID: { type: Number },
					castID: { type: Number },
					autoStartContent: { type: Boolean, default: false },
					jumpTo: { type: Number },
					trigger: { type: String, enum: Object.keys(triggerTypes), default: triggerTypes.default },
				},
			],
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
		},
	],
	tags: [{ type: String }],
	isLive: { type: Boolean, default: false },
	styleTemplateID: { type: Schema.ObjectId, ref: 'StyleTemplate', label: 'Style template' },
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'ArticleList',
	labels: getLabels(schemaDefinition),
	schema: ModelSchema,
	definition: schemaDefinition,
};
