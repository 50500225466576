/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var boxxModelInfo = {
	'UniFI AP AC PRO': { threshold: 1, platform: 'openwrt', architecture: 'mips24_kc' },
	'EnGenius EPG5000': { threshold: 1, platform: 'openwrt', architecture: 'ar71xx' },
	'EnGenius ESR1750': { threshold: 1, platform: 'openwrt', architecture: 'ar71xx' },
	'TP-Link WDR4300': { threshold: 1, platform: 'openwrt', architecture: 'ar71xx' },
	'Intel NUC': { threshold: 4, platform: 'debian', architecture: 'amd64' },
	'Raspberry Pi 3': { threshold: 4, platform: 'debian', architecture: 'armhf' },
	'Raspberry Pi Zero W': { threshold: 1, platform: 'debian', architecture: 'armhf' },
	'ProLiant DL360 G7': { threshold: 8, platform: 'openwrt', architecture: 'x86_64' },
	'Thomas Krenn RI1102H': { threshold: 2, platform: 'openwrt', architecture: 'x86_64' },
	'Lenovo ThinkServer TS140': { threshold: 4, platform: 'openwrt', architecture: 'x86_64' },
	'Dell Inspiron 545': { threshold: 4, platform: 'openwrt', architecture: 'x86_64' },
};

var def = {
	model: {
		type: String,
		enum: ['UniFI AP AC PRO', 'EnGenius EPG5000', 'EnGenius ESR1750', 'TP-Link WDR4300', 'Intel NUC', 'Raspberry Pi 3', 'Raspberry Pi Zero W', 'ProLiant DL360 G7', 'Thomas Krenn RI1102H',
			'Lenovo ThinkServer TS140', 'Dell Inspiron 545', 'Unknown'],
		default: 'EnGenius EPG5000',
		required: true,
	},
	usbStick: {
		model: {
			type: String,
			enum: ['SanDisk Ultra Fit', 'Intenso Micro Line', 'SanDisk Cruzer Fit', 'Unknown'],
			default: 'Unknown',
			isField: true,
			required: true,
		},
		size: { type: Number },
	},
};

var ModelSchema = new Schema(def);

module.exports = {
	definition: def,
	schema: ModelSchema,
	boxxModelInfo,
};
