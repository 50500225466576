/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { uid } from '../../utils';

@Component({
	selector: 'input-switch',
	template: `
<div class="input-widget">
	<div *ngIf="hasDisable">
		<input
			type="checkbox"
			class="btn-checkbox"
			attr.id="{{inputUid}}-checkbox"
			(change)="handleCheckbox($event.target.checked)"
			[checked]="value != null">
		<label attr.for="{{inputUid}}-checkbox"></label>
	</div>

	<div class="btn-switch">
	  <input
	  	class="sl-form__switch"
	  	type="checkbox"
		attr.id="{{inputUid}}-switch"
		[checked]="placeholder || value == true"
	  	(change)="handleSwitch($event.target.checked)"
		[disabled]="disabled || (value == null && hasDisable)">
	  <label attr.for="{{inputUid}}-switch"></label>
		<ng-content></ng-content>
	</div>
</div>
	`,
	styleUrls: ['./input-switch.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSwitchComponent), multi: true }],
})
export class InputSwitchComponent extends CustomValueAccessor {
	@Input() disabled;
	@Input() hasDisable;
	@Input() value;
	@Input() placeholder;

	public inputUid = uid();

	handleSwitch(switchState) {
		this.value = switchState;
	}

	handleCheckbox(checkState) {
		if (!checkState) this.value = null;
		else this.value = false;
		console.log('InputSwitchComponent: ', checkState);
	}

}
