/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema, labels } from '../../../models/organisation/organisationSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { OrganisationFormComponent } from './components/organisation-form.component';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const organisationConfig = new ModelConfig({
	collectionName: 'Organisation',
	icon: 'icon-organization_L',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'DataSource', inSidebar: false },
		{ name: 'User', inSidebar: true },
	],

	schema,
	labels,

	formComponent: OrganisationFormComponent,
});

Object.assign(organisationConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(store, 'models', 'DataSource', 'entities').pipe(map(entities => Object.values(entities))),
			(model: any, currentUser: any, dataSources: any[]) => {
				return {
					model,
					access: currentUser.access,
					hasDataSource: model._id ? dataSources.some(ds => ds.organisationID === model._id) : false,
				}
		 	},
		);
	},

	getListData$(store) {
		return pluckDistinct(store, 'models', this.collectionName).pipe(map(selectors.getListAndSort));
	},
});
