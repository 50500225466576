import * as socketActions from '../socket.actions';

export const initialState = { queueSize: 0, tileCount: 0 };

export function customMapReducer(state = initialState, action) {
	if (action.type === socketActions.MAP_INFORMATION_UPDATED) {
		return Object.assign(state, action.payload);
	}

	return state;
}
