/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Store } from '@ngrx/store';
import { Component, Input } from '@angular/core';
import { messageActions } from '../actions';

import { MessageComponent } from '../message.component/message.component';

@Component({
	selector: 'messages-component',
	template: `
	<div class="messages">
		<ng-container *ngFor="let messageList of ['sticky', 'transient']">
			<ul *ngIf="messages[messageList].length">
				<li *ngFor="let item of messages[messageList]">
					<message-component [data]="item"></message-component>
					<div *ngIf="item.closeable" (click)="close(item.id)" class="message-close">
						<span class="icon-cancel"></span>
					</div>
				</li>
			</ul>
		</ng-container>
	</div>
`,
	styleUrls: ['./messages.component.sass'],
})
export class MessagesComponent {
	@Input() messages;

	constructor(private store: Store<any>) {
	}

	ngOnInit() {
		const that = this;
	}

	close(id) {
		this.store.dispatch(messageActions.close(id));
	}

	ngOnChange(changes) {
		console.log(this.messages);
	}
}
