import * as socketActions from '../socket.actions';
import { analyticsTypes } from '../../../models/analytics';

export const initialState = {}

export function analyticsReducer (state = initialState, action) {
	if (action.type === socketActions.ANALYTICS_DATA_UPDATED) {
		console.log('ANALYTICS_VISITS_OVER_TIME_UPDATED', action);
		return Object.assign(state, { [action.payload.analyticsType]: action.payload.data })
	}

	return state
}

