<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

		<div>
			<label i18n>code</label>
			<input type="text" formControlName="code">
		</div>
		<div>
			<label>webClientUUID</label>
			<input type="text" formControlName="webClientUUID">
		</div>

</ng-container>
