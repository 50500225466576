/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';


@Component({
	selector: 'input-json',
	template: `
<div class="input-widget input-json">
	<textarea
		[ngModel]="text"
		(ngModelChange)="onTextChange($event)"
		required="required"
		(input)="$event.stopPropagation()"
		(change)="$event.stopPropagation()"
		[ngClass]="{invalid: !!validate()}">
	</textarea>
	<div class="error-text">
		{{errorText}}
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputJsonComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputJsonComponent),
			multi: true,
		}
	],
})
export class InputJsonComponent extends CustomValueAccessor {
	public text;
	public errorText;

	@Output() change = new EventEmitter();
	@Output() input = this.change;

	constructor(public i18n: I18n) {
		super(i18n);
		this.value = null;
	}

	onTextChange(text) {
		try {
			this.value = JSON.parse(text);
		} catch (e) {
			this.value = undefined;
			this.text = text;
		}

		this.change.emit(this.value);
	}

	validate(formControl?: FormControl) {
		try {
			JSON.parse(this.text);
		} catch (e) {
			this.errorText = `Validation failed: ${e}`;
			return { parseError: true };
		}
		this.errorText = ''
		return null;
	}

	get value() {
		return this._value;
	}

	set value(value: any) {
		this.text = (JSON.stringify as any)(value, ' ', 1);

		this.set_value(value);
	}
}
