/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { CollectionName } from '../modelConfigs';

export const actions = {
	routeChange: (collectionName: CollectionName, id: string, path: string, versionId: string) => ({
		type: actions.ROUTE_CHANGED,
		data: {
			collectionName,
			id,
			path,
			versionId,
		}
	}),

	ROUTE_CHANGED: 'ROUTE_CHANGED',

};
