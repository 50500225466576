/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	threshold: { type: Number, default: 65 },
	beaconCount: { type: Number, default: 1 },
	blockerThreshold: { type: Number, default: 65 },
	bockerBeaconCount: { type: Number, default: 1 },
	blockerBeaconAdvantage: { type: Number, default: 10 },
};
var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
