/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	ApplicationRef,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
} from '@angular/core';

import { FileDropService } from './file-drop.service';
import { fileIsAccepted } from '../utils';

@Component({
	selector: 'file-dropzone',
	template: `
	<div
		[hidden]="droppableTypes.length <= 0"
		class="file-dropzone"
		(dragenter)="onDragChange($event)"
		(dragleave)="onDragChange($event)"
		(drop)="onDrop($event)">
		<div class="file-dropzone-border">
			<div class="file-dropzone-content" #contentRef>
				<ng-content>
				</ng-content>
			</div>
		</div>

		<span *ngIf="!contentRef.childNodes.length">Drop {{ droppableTypes.length }} {{ droppableTypes.length === 1 ? 'file' : 'files' }}</span>
	</div>
	`,
	styleUrls: [
		'./file-dropzone.component.sass',
	],
})
export class FileDropzoneComponent {
	@Input() accept = '*';
	@Input() multiple = false;
	@Output() fileDrop = new EventEmitter(false);
	@Output() dragEnd = new EventEmitter(false);
	@Output() dragStart = new EventEmitter(false);

	public droppableTypes = [];
	public active = false;

	constructor(private fileDropService: FileDropService, private cdr: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.fileDropService.add(this);
	}

	ngOnDestroy() {
		this.fileDropService.remove(this);
	}

	start(event) {
		this.droppableTypes = this.getAcceptableTypes(event);
		this.cdr.detectChanges();
		this.dragStart.emit();
	}

	end() {
		this.droppableTypes = [];
		this.active = false;
		this.cdr.detectChanges();
		this.dragEnd.emit();
	}

	onDragChange(event) {
		this.active = event.type === 'dragenter';
		this.cdr.detectChanges();

		event.preventDefault();
	}

	onDrop(event) {
		this.fileDrop.emit({
			event,
			files: Array.from(event.dataTransfer.files)
				.filter((file: any) => this.canAccept(file)),
		});

		event.preventDefault();
	}

	canAccept(file) {
		const accepts = this.accept.split(',');

		return accepts.some(type => fileIsAccepted(file, type));
	}

	getAcceptableTypes(event) {
		return Array.from(event.dataTransfer.items)
			.filter((item: any) =>
				item.kind === 'file' && this.canAccept(item)
			)
			.map((item: any) => item.type);
	}
}
