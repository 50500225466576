/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	active: { type: Boolean, default: false },
	ticketLifeTime: { type: Number, default: 360 }, // in minutes
	masterKey: { type: String, default: '' },
	// This is auto Generated from exhibitionSchema on pre save first time
	loanerKey: { type: String },
};
var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
