/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
	selector: 'tour-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class TourFormComponent {
	@Input() id = '';
	@Input() data: any;
	@Input() formGroup: FormGroup;

	public tourLanguageFields = [
		{
			prop: 'title',
			label: this.i18n('User-visible Title'),
			placeholder: this.i18n('… title'),
			multiline: false,
		},
		{
			prop: 'description',
			label: this.i18n('Description'),
			placeholder: this.i18n('… description'),
			multiline: true,
		},
		{
			prop: 'information',
			label: this.i18n('Longer information'),
			multiline: true,
			placeholder: this.i18n('… longer information text'),
		},
	];
	constructor(public i18n: I18n) {}
}
