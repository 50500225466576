/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/**
 * Created by bewr on 26.05.17.
 */
var Schema = require('mongoose').Schema;

var def = {
	beaconTimeout: { type: Number, default: 5000 }, // in milliseconds
	scanInterval: { type: Number, default: 1500 }, // in millisconds
	onlyExhibitionBeacons: { type: Boolean, default: true },
	rssiFilterAlpha: { type: Number, default: 0 },
	notSeenForOneScanMalus: { type: Number, default: 0 },
	medianWindowWidth: { type: Number, default: 0 },
	androidBias: { type: Number, default: 0 },
	iosBias: { type: Number, default: 0 },
	nearestBeaconAlgParameters: {
		blockerBonus: { type: Number, default: 0 },
		blockerThreshold: { type: Number, default: -120 },
		triggerThreshold: { type: Number, default: -120 },
		extenderThreshold: { type: Number, default: -120 },
		rssiThreshold: { type: Number, default: -120 },
		hystereseWidth: { type: Number, default: 8 },
		ticsToWin: { type: Number, default: 1 },
		isActive: { type: Boolean, default: false },
	},
	areaBeaconAlgParameters: {
		areaThreshold: { type: Number, default: -60 },
		areaHysterese: { type: Number, default: 10 },
		isActive: { type: Boolean, default: false },
	},
	activateBluetooth: { type: Boolean, default: false },
	mergeExhibitionBeacons: { type: Boolean, default: false },
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
