/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Read the documentation in 'socket.actions.ts'

import { merge as observableMerge, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { SocketConnectionService } from './socket-connection.service';
import * as socketActions from '../socket.actions';

/**
 * Service for emitting and receiving socket events using the SocketConnectionService
 */
@Injectable()
export class SocketEventsService {
	itemCreated$: Observable<any>;
	itemUpdated$: Observable<any>;
	listUpdated$: Observable<any>;
	listItemUpdated$: Observable<any>;
	fullListUpdated$: Observable<any>;
	fullListItemUpdated$: Observable<any>;
	itemDeleted$: Observable<any>;
	versionListUpdated$: Observable<any>;
	versionOfItemUpdated$: Observable<any>;
	revertToVersionOfItemDone$: Observable<any>;
	currentUserDataUpdated$: Observable<any>;
	dependencyMapsDataUpdated$: Observable<any>;
	message$: Observable<any>;
	notAuthenticated$: Observable<any>;
	mapDownloadSizePredicted$: Observable<any>;
	mapDownloadCompleted$: Observable<any>;
	analyticsDataUpdated$: Observable<any>;
	analyticsDataDownload$: Observable<any>;
	apiKeyUpdated$: Observable<any>;
	mapInformationUpdated$: Observable<any>;
	connected$: Observable<boolean>;
	loggedIn$: Observable<boolean>;

	constructor(private socket: SocketConnectionService) {
		// this.socket.join('notes');

		// Every socket event has it's own observable, will be used by ngrx effects

		Object.entries({
			itemCreated$: socketActions.COLLECTION_ITEM_CREATED,
			listUpdated$: socketActions.COLLECTION_LIST_UPDATED,
			listItemUpdated$: socketActions.COLLECTION_LIST_ITEM_UPDATED,
			itemUpdated$: socketActions.COLLECTION_ITEM_UPDATED,
			fullListUpdated$: socketActions.COLLECTION_FULL_LIST_UPDATED,
			fullListItemUpdated$: socketActions.COLLECTION_FULL_LIST_ITEM_UPDATED,
			itemDeleted$: socketActions.COLLECTION_ITEM_DELETED,
			versionListUpdated$: socketActions.VERSION_LIST_UPDATED,
			versionOfItemUpdated$: socketActions.VERSION_OF_ITEM_UPDATED,
			currentUserDataUpdated$: socketActions.CURRENT_USER_DATA_UPDATED,
			dependencyMapsDataUpdated$: socketActions.DEPENDENCY_MAPS_DATA_UPDATED,
			message$: socketActions.MESSAGE,
			notAuthenticated$: socketActions.NOT_AUTHENTICATED,
			mapDownloadSizePredicted$: socketActions.MAP_DOWNLOAD_SIZE_PREDICTED,
			mapDownloadCompleted$: socketActions.MAP_DOWNLOAD_COMPLETED,
			analyticsDataUpdated$: socketActions.ANALYTICS_DATA_UPDATED,
			analyticsDataDownload$: socketActions.ANALYTICS_DATA_DOWNLOAD,
			apiKeyUpdated$: socketActions.API_KEY_UPDATED,
			mapInformationUpdated$: socketActions.MAP_INFORMATION_UPDATED,
			revertToVersionOfItemDone$: socketActions.REVERT_TO_VERSION_OF_ITEM_DONE,
		}).forEach(([prop, eventName]) => {
			// listen to both secure and non secure
			this[prop] = observableMerge(this.socket.listenSecure(eventName), this.socket.listen(eventName));
		});
		this.connected$ = socket.connected$.asObservable();
		this.loggedIn$ = socket.loggedIn$.asObservable();
	}

	// These methods will be called by ngrx effects (do not use directly in the components)
	async getList(data) {
		return await this.socket.emitSecure(socketActions.GET_COLLECTION_LIST, data);
	}

	async leaveList(data) {
		return await this.socket.emitSecure(socketActions.LEAVE_COLLECTION_LIST, data);
	}

	async getFullList(data) {
		return await this.socket.emitSecure(socketActions.GET_COLLECTION_FULL_LIST, data);
	}

	async leaveFullList(data) {
		return await this.socket.emitSecure(socketActions.LEAVE_COLLECTION_FULL_LIST, data);
	}

	async getItem(data) {
		return await this.socket.emitSecure(socketActions.GET_COLLECTION_ITEM, data);
	}

	async leaveItem(data) {
		return await this.socket.emitSecure(socketActions.LEAVE_COLLECTION_ITEM, data);
	}

	async createItem(data) {
		return await this.socket.emitSecure(socketActions.CREATE_COLLECTION_ITEM, data);
	}

	async updateItem(data) {
		return await this.socket.emitSecure(socketActions.UPDATE_COLLECTION_ITEM, data);
	}

	async deleteItem(data) {
		return await this.socket.emitSecure(socketActions.DELETE_COLLECTION_ITEM, data);
	}

	async getVersionList(data) {
		return await this.socket.emitSecure(socketActions.GET_VERSION_LIST, data);
	}

	async getVersionOfItem(data: { collectionName: string, id: string, versionNumber: number }) {
		return await this.socket.emitSecure(socketActions.GET_VERSION_OF_ITEM, data);
	}

	async requestRevertToVersionOfItem(data: { collectionName: string, id: string, versionNumber: number }) {
		return await this.socket.emitSecure(socketActions.REQUEST_REVERT_TO_VERSION_OF_ITEM, data);
	}

	async getDependencyMapData() {
		return await this.socket.emitSecure(socketActions.GET_DEPENDENCY_MAPS_DATA);
	}

	async predictMapDownloadSize() {
		return await this.socket.emitSecure(socketActions.PREDICT_MAP_DOWNLOAD_SIZE);
	}

	async startMapDownload(data) {
		return await this.socket.emitSecure(socketActions.START_MAP_DOWNLOAD, data);
	}

	async getAnalyticsData(data) {
		return await this.socket.emitSecure(socketActions.GET_ANALYTICS_DATA, data);
	}

	async getApiKey() {
		return await this.socket.emitSecure(socketActions.GET_API_KEY);
	}

	async getMapInformation(data) {
		return await this.socket.emitSecure(socketActions.GET_MAP_INFORMATION, data);
	}

	async recreateThumbNail(data) {
		return await this.socket.emitSecure(socketActions.RECREATE_THUMB_NAIL, data);
	}

	async publishChanges(data) {
		return await this.socket.emitSecure(socketActions.PUBLISH_CHANGES, data);
	}
}
