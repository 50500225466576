/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as actions from '../../../socket.actions';

@Component({
	selector: 'location-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class LocationFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	public isForCustomMap = false;
	public distortableImageMapArticle = '';
	private currentArticleId;

	constructor(private store: Store<any>) {}

	getArticle(articleID) {
		if (articleID !== this.currentArticleId) {
			this.leaveArticle(this.currentArticleId)
			this.store.dispatch(new actions.GetCollectionItem({ collectionName: 'Article', id: articleID }));
			this.currentArticleId = articleID;
		}
	}
	leaveArticle(articleID) {
		if (articleID) {
			this.store.dispatch(new actions.LeaveCollectionItem({ collectionName: 'Article', id: articleID }));
		}
	}
	ngOnDestroy() {
		this.leaveArticle(this.currentArticleId)
	}

	ngAfterViewInit() {
		this.formGroup.valueChanges.subscribe(value => {
			this.isForCustomMap = this.formGroup.value.type === 'geoLocation' && !!value.customMapID;
		})
	}

	ngOnChanges(changes) {
		if (this.data.model.customMap.articleID) {
			this.getArticle(this.data.model.customMap.articleID)
		}
	}
}
