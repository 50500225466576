
		<div class="input-date-range">
			<my-date-range-picker
				[disabled]="disabled"
				[(ngModel)]="model"
				[options]="{ dateFormat: 'dd.mm.yyyy', showClearBtn: false }"
				(dateRangeChanged)="onPickDates($event)"
			>
			</my-date-range-picker>
		</div>
	