
<div
	[ngClass]="{invalid: missingValues}">
	<div *ngIf="this.missingValues" class="invalid-message" i18n>
		The organisation is missing or there are no languages set for your organisation.
	</div>
	<div
		[ngClass]="{'media-files-list--hide': this.missingValues}"
		[dragula]="bagName"
		[(dragulaModel)]="value"
		class="drag-container media-files-list">
		<div
			*ngFor="let languageItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					{{isoLang2name[languageItem.languageISO]}}
				</div>
				<div *ngIf="enableDrag && value && value.length > 1" class="drag-handle">
					<span class="make-default-hint" i18n>(drag left / right to change order in app)</span>
					<span class="make-default-hint" i18n>(disable option is not available for default language)</span>
				</div>
				<div *ngFor="let field of fields">
					<label>{{ field.label }}</label>
					<input-text
						*ngIf="field.multiline"
						cols="30"
						rows="4"
						[placeholder]="field.placeholder || field.label"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem[field.prop]"></input-text>
					<input
						*ngIf="!field.multiline"
						type="text"
						[attr.placeholder]="field.placeholder || field.label"
						(change)="propagateChange()"
						[attr.disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem[field.prop]">
				</div>
				<div class="spacer"></div>
				<div *ngIf="canDisableLanguage && (languageItem.languageISO !== defaultLanguage)" class="can-disable">
					<input
						class="sl-form__switch"
						[attr.id]="languageItem.languageISO+'disable'"
						[(ngModel)]="languageItem.disabled"
						(change)="propagateChange()"
						type="checkbox" />
					<label [attr.for]="languageItem.languageISO+'disable'" ></label>
					<span i18n>Disable</span>
				</div>
			</div>
		</div>
	</div>
</div>
	