/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routes } from './routes';

// import { DashboardComponent } from './components/dashboard';
import { SettingsComponent } from './components/settings';
import { AdminComponent } from './components/admin';
import { NotFoundComponent } from './components/notfound';
import { MaintenanceComponent } from './components/maintenance';


import { NavContextModule } from './_navcontext';
import { MessagesModule } from './_messages/';


@NgModule({
	imports: [
		CommonModule,
		routes,
	],
	exports: [
		NavContextModule,
		MessagesModule,
		MaintenanceComponent,
	],
	declarations: [
		// DashboardComponent,
		SettingsComponent,
		AdminComponent,
		NotFoundComponent,
		MaintenanceComponent,
	],
})
export class CoreModule { }
