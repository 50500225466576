/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Observable } from 'rxjs';

import { ModelConfig } from './ModelConfig';

import { mediaConfig } from '../_media/config';
import { locationConfig } from '../_locations/config';
import { articleConfig } from '../_articles/config';
import { exhibitConfig } from '../_exhibits/config';
import { exhibitionConfig } from '../_exhibitions/config';
import { articlelistConfig } from '../_articleLists/config';

import { boxxConfig } from '../_boxxs/config';
import { boxxEventConfig } from '../_boxxEvents/config';
import { boxxStateConfig } from '../_boxxState/config';
import { beaconConfig } from '../_beacons/config';
import { loanerdeviceConfig } from '../_loanerdevices/config';
import { datasourceConfig } from '../_datasources/config';
import { styletemplateConfig } from '../_styletemplates/config';
import { systempkConfig } from '../_systempks/config';
import { ticketConfig } from '../_tickets/config';
import { userConfig } from '../_users/config';
import { organisationConfig } from '../_organisations/config';
import { applogConfig } from '../_applogs/config';
import { cmssettingsConfig } from '../_cmssettings/config';
import { unifiedArticleConfig } from '../_unifiedarticles/config';
import { surveyConfig } from '../_survey/config';
import { glossaryTermConfig } from '../_glossaryTerm/config';
import { calendarEventConfig } from '../_calendarEvent/config';
import { tourConfig } from '../_tours/config';
import { quizConfig } from '../_quiz/config';


export const modelConfigs: { [index: string]: ModelConfig } = [
	unifiedArticleConfig,
	mediaConfig,
	locationConfig,
	articleConfig,
	exhibitConfig,
	exhibitionConfig,
	articlelistConfig,
	surveyConfig,
	glossaryTermConfig,
	calendarEventConfig,
	tourConfig,
	userConfig,
	organisationConfig,
	cmssettingsConfig,

	boxxConfig,
	boxxEventConfig,
	boxxStateConfig,
	beaconConfig,
	loanerdeviceConfig,
	datasourceConfig,
	styletemplateConfig,
	systempkConfig,
	ticketConfig,
	applogConfig,
	].reduce((acc, item) => ({...acc, ...{ [item.collectionName]: item }}), {});
modelConfigs.Quiz = quizConfig;

export type CollectionName = keyof typeof modelConfigs;

export const nameToCollectionName = Object.values(modelConfigs).reduce((acc, config) => {
	acc[config.name] = config.collectionName;
	return acc;
}, {} as { [index: string]: string });

export const nameToModel = Object.values(modelConfigs).reduce((acc, config) => {
	acc[config.name] = config;
	return acc;
}, {} as { [index: string]: ModelConfig });
