/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormInputsModule } from '../forminputs';

import { BoxxFormComponent } from './components/boxx-form.component';
import { BoxxDashboardContainer, BoxxDashboardComponent } from './components/boxx-dashboard.component';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule,
	],
	exports: [
		BoxxFormComponent,
		BoxxDashboardContainer,
	],
	declarations: [
		BoxxFormComponent,
		BoxxDashboardContainer,
		BoxxDashboardComponent,
	],
	entryComponents: [
		BoxxFormComponent,
	],
})
export class BoxxModule { }
