
		<div class="filter" (click)="$event.stopPropagation()">
			<input-filter
				#filterComponent
				[items]="articlesFiltered()"
				(filter)="filteredItems = $event.result"
				(keyup.enter)="selectFirst()">
			</input-filter>
			<div class="sort-menu">
				<div i18n> Sort </div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'changeLog.editedOn')}"
					(click)="sorting('changeLog.editedOn')">
					<div i18n>by date</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'articleID.name')}"
					(click)="sorting('articleID.name')">
					<div i18n>by name</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
			</div>
		</div>

		<div class="list">
			<div
				*ngFor="let article of filteredItems"
				(click)="selectarticle.emit(article._id)"
				[ngStyle]="{backgroundImage: getUrl(article)}">
					<span>{{article.articleID.name}}</span>
				</div>
		</div>

		<form-modal #newModal collectionName="Article" i18n>
			Create a new article
		</form-modal>
	