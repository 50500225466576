/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export const info = `<div>
	A user is a login to your organisation, protected by a password and with different capabilities:
	<ul>
		<li>An admin can add and remove new users.</li>
		<li>A curator is able to create new exhibitions.</li>
		<li>An editor can only create and edit articles.</li>
	</ul>
</div>`;
