/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {from as observableFrom, fromEvent as observableFromEvent,  Observable ,  Subject } from 'rxjs';

import {takeUntil, debounceTime} from 'rxjs/operators';
import {
	Component,
	ChangeDetectorRef,
	Input,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { VirtualScrollComponent } from 'angular2-virtual-scroll';

import { throttle } from '../../utils';
import { RouteInfo } from '../../services/route-info';
import { EditRouteSaveGuard } from '../../services/edit-route-save-guard';
import { actions } from '../../actions';
import { ModelConfig } from '../../modelConfigs/ModelConfig';
import * as CollectionActions from '../../socket.actions';

@Component({
	selector: 'model-list',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
	// OnPush caused boxxstate changes to not be picked up
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelListComponent {
	@Input() id: string;
	@Input() model: ModelConfig;
	@Input() data: any;
	@Input() access: any;

	@ViewChild(VirtualScrollComponent, { static: true })
	private vs: VirtualScrollComponent;

	public scrollItems: any[];
	public lastFilterEvent = { query: '', result: [] };
	public destroyed$ = new Subject();
	public showSort;

	private _defaultValues;

	constructor(
		private store: Store<any>,
		private routeInfo: RouteInfo,
		private editRouteSaveGuard: EditRouteSaveGuard,
		private cdr: ChangeDetectorRef,
		private componentElement: ElementRef
	) {
		this.store = store;
	}

	ngAfterViewInit() {
		observableFromEvent(window, 'resize')
			.pipe(
				debounceTime(250),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.vs.refresh();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	onFilter(event) {
		if (this.lastFilterEvent.query !== event.query) {
			this.store.dispatch(actions.filter(this.model.collectionName, event.query));
		}

		this.lastFilterEvent = event;
	}

	selectFirst() {
		const first = this.lastFilterEvent.result[0];

		if (!first) { return; }

		this.routeInfo.router.navigate([`${this.model.clientPathPrefix}/${this.model.name}/edit/${first._id}`]);
	}

	sort(objectPath) {
		this.store.dispatch(actions.sort(this.model.collectionName, objectPath));
	}

	hideSort() {
		setTimeout(() => {
			this.showSort = false;
		}, 1000);
	}

	onCreateClick() {
		const modelEdit = this.routeInfo.modelEditInstance;

		if (
			modelEdit &&
			this.routeInfo.router.url === `/${this.model.clientPathPrefix}${this.model.name}/create`
		) {
			const $canDeactivate = observableFrom(this.editRouteSaveGuard.canDeactivate(modelEdit));

			$canDeactivate.subscribe(canDeactivate => {
				if (canDeactivate) {
					modelEdit.dynamicModelForm.formGroup.reset();
					modelEdit.dynamicModelForm.formGroup.markAsPristine();
				}
			});
		}
	}
}
