/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {distinctUntilChanged, pluck} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
	template: `
	<div class="sl-listview__wrapper">

	<nav [ngStyle]="{ width: (leftColumnStyle$ | async), flex: (leftColumnStyle$ | async) ? 'none' : '' }">
		<a routerLink="/admin/boxx" class="box" i18n> Boxxes </a>
		<a routerLink="/admin/beacon" class="box" i18n> Beacons </a>
		<a routerLink="/admin/loanerdevice" class="box" i18n> Loaner devices </a>
		<a routerLink="/admin/systempk" class="box" i18n> System packages </a>
		<a routerLink="/admin/styletemplate" class="box" i18n> Style templates </a>
		<a routerLink="/datasource" class="box" i18n> Data sources (Apps) </a>
		<a routerLink="/admin/ticket" class="box" i18n> Tickets </a>
		<a routerLink="/admin/applog" class="box" i18n> Application log </a>
		<a routerLink="/admin/cmssettings" class="box" i18n> CMS settings </a>
	</nav>

	<main>
		<h1 i18n>Admin</h1>
		<p i18n>Please select a category on the left.</p>
	</main>

</div>
`,
	styleUrls: ['./admin.component.sass'],
})
export class AdminComponent {
	public leftColumnStyle$: Observable<string>;

	constructor(store: Store<any>) {
		this.leftColumnStyle$ = store.pipe(pluck('currentuser', 'leftColumnStyle'), distinctUntilChanged(), ) as Observable<string>;
	}
}

