/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Component, forwardRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Store } from '@ngrx/store';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import config from '../../../config';
import * as actions from '../../../socket.actions';

import { selectors } from '../../../selectors';

@Component({
	selector: 'input-articlelist',
	template: `
<div class="media-files-list">
	<div [dragula]='bagName' [(dragulaModel)]='value' class="drag-container">
		<div
			*ngFor="let listItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					<label class="drag-handle">
						<ng-container i18n>Article</ng-container>
						<span class="make-default-hint" *ngIf="i != 0;" i18n>(drag to move)</span>
					</label>
					{{articleFromID(listItem.articleID) && articleFromID(listItem.articleID).name}}
					<div
						class="cover"
						[ngStyle]="{'background-image': getUrl(articleFromID(listItem.articleID))}"></div>
				</div>
				<div class="input-number">
					<label i18n>Duration <small> in s</small></label>
					<div>
						<input type="number" (input)="setDuration(listItem, $event)" [value]="listItem.duration"/>
					</div>
				</div>
				<div class="input-number">
					<label i18n>Jump to<small> on end</small></label>
					<div>
						<input type="number" (input)="setJumpTo(listItem, $event)" [value]="listItem.jumpTo"/>
					</div>
				</div>
				<div class="input-number"  *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label i18n>Stop Duration <small> in s</small></label>
					<div>
						<input type="number" (input)="setStopDuration(listItem, $event)" [value]="listItem.stopDuration"/>
					</div>
				</div>
				<div class="input-number" *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label>DMX ID</label>
					<div>
						<input type="number" (input)="setDmxID(listItem, $event)" [value]="listItem.dmxID"/>
					</div>
				</div>
				<div class="input-number" *ngIf="articleEntities[listItem.articleID]?.type === 'audiosync-media'">
					<label>CAST ID</label>
					<div>
						<input type="number" (input)="setCastID(listItem, $event)" [value]="listItem.castID"/>
					</div>
				</div>
				<div class="" *ngIf="articleEntities[listItem.articleID]?.type != 'audiosync-media'">
					<label>TRIGGER</label>
					<div>
						<select (change)="setTrigger(listItem, $event)" [value]="listItem.trigger">
							<option [value]="triggerOption" *ngFor="let triggerOption of triggerOptions">{{triggerOption}}</option>
						</select>
					</div>
				</div>
			<div class="check-label-right"  *ngIf="articleEntities[listItem.articleID]?.type !== 'audiosync-media'">
				<input (change)="propagateChange()" class="sl-form__switch" [attr.id]="i + 'loop'" type="checkbox" [(ngModel)]="listItem.loop">
				<label [attr.for]="i + 'loop'"></label>
				Loop
			</div>
			<div class="check-label-right"  *ngIf="articleEntities[listItem.articleID]?.type !== 'audiosync-media'">
				<input (change)="propagateChange()" class="sl-form__switch" [attr.id]="'autoStartContent-' + i" type="checkbox" [(ngModel)]="listItem.autoStartContent">
				<label [attr.for]="'autoStartContent-' + i"></label>
				Auto start content
			</div>

			</div>
			<div class="footer">
				<div (click)="remove(i); $event.preventDefault()" i18n>
					<span class="icon-minus"></span>REMOVE
				</div>
			</div>
		</div>
	</div>
	<div class="media-files-item media-files-list__new-item body" (click)="selectArticleModal.open(); $event.preventDefault()" i18n>
		<span class="icon-add"></span> Add an article
	</div>
	<div class="spacer"> </div>
</div>

<modal-component #selectArticleModal>
<select-article
	#selectArticle
	*ngIf="selectArticleModal.isOpen()"
	(selectarticle)="add($event); selectArticleModal.close()">
</select-article>
</modal-component>
	`,
	styleUrls: ['./input-articlelist.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputArticleListComponent), multi: true },
	],
})
export class InputArticleListComponent extends CustomValueAccessor {
	private mediaObjectEntities;
	private articleEntities;
	private subscription;
	private triggerOptions;

	constructor(drake: DragulaService, changeDetectorRef: ChangeDetectorRef, private store: Store<any>, public i18n: I18n) {
		super(i18n);

		this.triggerOptions = [
			'default',
			'hardwareTrigger',
			'mqttTrigger',
			'background',
		]

		this.initOptions({
			drake,
			drakeOptions: {
				direction: 'horizontal',
			},
			bagName: `articlelist-${Math.random()}`,
			changeDetectorRef,
		});

		this.value = [];

		this.subscription = observableCombineLatest(
			store.pipe(
				pluck('models', 'Article'),
				map(selectors.getEntities)
			),
			store.pipe(
				pluck('models', 'MediaObject'),
				map(selectors.getEntities)
			),
			(articles, mediaobjects) => ({ articles, mediaobjects })
		).subscribe(({ articles, mediaobjects }) => {
			this.mediaObjectEntities = mediaobjects;
			this.articleEntities = articles;
			changeDetectorRef.markForCheck();
		});
	}
	ngOnInit() {
		this.store.dispatch(new actions.GetCollectionList({ collectionName: 'MediaObject' }));
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.store.dispatch(new actions.LeaveCollectionList({ collectionName: 'MediaObject' }));
	}

	add(id) {
		this.value = [
			...this.value,
			{
				articleID: id,
				duration: null,
			},
		];
	}
	setDuration(item, event) {
		item.duration = event.target.value;
		this.value = [...this.value];
	}
	setJumpTo(item, event) {
		item.jumpTo = event.target.value;
		this.value = [...this.value];
	}
	setStopDuration(item, event) {
		item.stopDuration = event.target.value;
		this.value = [...this.value];
	}
	setDmxID(item, event) {
		item.dmxID = event.target.value;
		this.value = [...this.value];
	}
	setCastID(item, event) {
		item.castID = event.target.value;
		this.value = [...this.value];
	}
	setTrigger(item, event) {
		item.trigger = event.target.value;
		this.value = [...this.value];
	}
	remove(targetIndex) {
		this.value = this.value.filter((item, index) => index !== targetIndex);
	}
	getUrl(article) {
		if (!article) return '';
		const mediaObject = this.mediaObjectEntities[article.coverID];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}/200)`;
	}
	articleFromID(id) {
		return this.articleEntities[id];
	}
}
