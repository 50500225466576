/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'ng-select';
import { FormInputsModule } from '../forminputs';

import { AnalyticsControlComponent } from './components/analytics-control.component';
import { AnalyticsContainer } from './components/analytics.container';
import { LoadingModule } from '../loading/module';
import { AnalyticsVisitsGraphComponent } from './components/analytics-visits-graph.component';
import { AnalyticsPagesComponent } from './components/analytics-pages.component';
import { AnalyticsDevicesComponent } from './components/analytics-devices.component';
import { AnalyticsTimesComponent } from './components/analytics-times.component'

@NgModule({
	imports: [CommonModule, LoadingModule, SelectModule, FormInputsModule],
	exports: [AnalyticsContainer],
	declarations: [
		AnalyticsControlComponent,
		AnalyticsContainer,
		AnalyticsVisitsGraphComponent,
		AnalyticsPagesComponent,
		AnalyticsDevicesComponent,
		AnalyticsTimesComponent,
	],
})
export class AnalyticsModule {}
