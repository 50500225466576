/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export const currentuserActions = {
	toggleExtendNav: (toggleState?) => ({ type: currentuserActions.TOGGLE_EXTENDNAV, toggleState }),
	resizeLeftColumn: width => ({ type: currentuserActions.RESIZE_LEFT_COLUMN, payload: width }),
	resizeRightColumn: width => ({ type: currentuserActions.RESIZE_RIGHT_COLUMN, payload: width }),
	recoverState: data => ({ type: currentuserActions.RECOVER_STATE, data }),
	login: data => ({ type: currentuserActions.LOGIN_REQUEST, data }),
	resetpassword: data => ({ type: currentuserActions.RESET_PASSWORD_REQUEST, data }),
	logout: () => ({ type: currentuserActions.LOGOUT_REQUEST }),

	patch: data => ({ type: currentuserActions.PATCH_CURRENTUSER_REQUEST, payload: data }),
	changePassword: data => ({ type: currentuserActions.CURRENTUSER_CHANGE_PASSWORD, payload: data }),
	setOrganisation: (data = {}) => ({ type: currentuserActions.CURRENTUSER_SET_ORG, payload: data }),
	validate2FactorAuthenticationCode: data => ({ type: currentuserActions.CURRENTUSER_VALIDATE_2FCODE, data }),
	resend2factorEmail: () => ({ type: currentuserActions.CURRENTUSER_RESEND_2FACTOREMAIL}),
	twoFactorSetAuthenticationStatus: (data = {}) => (
		{ type: currentuserActions.CURRENTUSER_SET_2FACTOR_AUTHENTICATION_STATUS, payload: data }
	),

	TOGGLE_EXTENDNAV: 'TOGGLE_EXTENDNAV',
	RESIZE_LEFT_COLUMN: 'RESIZE_LEFT_COLUMN',
	RESIZE_RIGHT_COLUMN: 'RESIZE_RIGHT_COLUMN',
	RECOVER_STATE: 'RECOVER_STATE',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	LOGOUT_REQUEST: 'LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

	LOAD_CURRENTUSER_SUCCESS: 'LOAD_CURRENTUSER_SUCCESS',
	LOAD_CURRENTUSER_FAIL: 'LOAD_CURRENTUSER_FAIL',
	PATCH_CURRENTUSER_REQUEST: 'PATCH_CURRENTUSER_REQUEST',
	PATCH_CURRENTUSER_SUCCESS: 'PATCH_CURRENTUSER_SUCCESS',
	PATCH_CURRENTUSER_FAIL: 'PATCH_CURRENTUSER_FAIL',
	CURRENTUSER_CHANGE_PASSWORD: 'CURRENTUSER_CHANGE_PASSWORD',
	CURRENTUSER_CHANGE_PASSWORD_SUCCESS: 'CURRENTUSER_CHANGE_PASSWORD_SUCCESS',

	CURRENTUSER_SET_ORG: 'CURRENTUSER_SET_ORG',
	CURRENTUSER_VALIDATE_2FCODE: 'CURRENTUSER_VALIDATE_2FCODE',
	CURRENTUSER_SET_2FACTOR_AUTHENTICATION_STATUS: 'CURRENTUSER_SET_2FACTOR_AUTHENTICATION_STATUS',
	CURRENTUSER_VALIDATE_2FCODE_SUCCESS: 'CURRENTUSER_VALIDATE_2FCODE_SUCCESS',
	CURRENTUSER_RESEND_2FACTOREMAIL: 'CURRENTUSER_RESEND_2FACTOREMAIL',
	CURRENTUSER_RESEND_2FACTOREMAIL_SUCCESS: 'CURRENTUSER_RESEND_2FACTOREMAIL_SUCCESS',
};
