
<button [disabled]="disabled" type="button" class="button button--red" (click)="showConfirmModal = true" i18n> DELETE </button>
<div class="modal" *ngIf="showConfirmModal" [ngClass]="{show: showConfirmModal}" (click)="showConfirmModal = false">
	<span class="modal__close icon-delete_sign"></span>
	<div class="modal__body" (click)="$event.stopPropagation()">
		<div>
			<div class="header">
				<ng-container i18n>Are you sure you want to delete?</ng-container>
				<div>
					<button class="button--red" (click)="confirm.emit(); close.emit(); showConfirmModal = false" i18n> yes </button>
					<button class="button--blue-ghost" (click)="showConfirmModal = false" i18n> no </button>
				</div>
			</div>
			<div *ngIf="hasRelationData">
				<ng-container i18n>The following dataset will be deleted too</ng-container>
				<div *ngFor="let name of keys(relationData)">
					<h3>{{name}}</h3>
					<div class="related-items">
						<a
							*ngFor="let relation of relationData[name]"
							[routerLink]="'/'+name.toLowerCase()+'/edit/'+relation._id"
							>{{relation.name}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
	