/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component } from '@angular/core';

@Component({
	template: `
<account-nav>
	<h1 i18n>Account</h1>
	<div class="sl-listview__form">
		<currentuser-form-container></currentuser-form-container>
	</div>
</account-nav>
`,
})
export class AccountProfileComponent {}

