/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

const getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	username: { type: String, index: { unique: false }, trim: true, softUnique: true },
	firstName: { type: String, required: true, index: { unique: false }, trim: true, label: 'First name' },
	lastName: { type: String, required: true, index: { unique: false }, trim: true, label: 'Last Name' },
	coverID: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image' }, // Has to be type Image
	email: {
		type: String,
		required: true,
		index: { unique: false },
		trim: true,
		softUnique: true,
		label: 'Email',
		lowercase: true,
	},
	apiKey: { type: String, readable: false, writable: false },
	roles: { type: [require('./roleSchema').definition], required: true, label: 'Roles' },
	password: { type: String, select: false, readable: false, writable: false },
	resetPasswordToken: { type: String, select: false, readable: false, writable: false },
	resetPasswordTokenCreatedAt: { type: Date, readable: false, select: false, writable: false },
	// Versioning meta data to keep track of all edit operations.
	lastLogin: { type: Date, readable: false, default: null, writable: false },
	address: { type: String },
	// these field are used for 2factor authentication
	enableTOTP: { type: Boolean, default: false, label: 'enable TOTP', isField: true },
	secret: { type: String, readable: false, writable: false, select: false },
	userNotAuthenticated: { type: Boolean, default: true },
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'User',
	schema: ModelSchema,
	labels: getLabels(schemaDefinition),
	definition: schemaDefinition,
};
