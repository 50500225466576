
<div class="list">
	<div *ngFor="let pkid of value">{{getName(pkid)}} <span class="icon-remove" (click)="remove(pkid)"></span></div>
</div>
<div class="input-widget">
	<select
		(change)="propagateChange()"
		[(ngModel)]="selectSystemPk"
		[ngClass]="{empty: !selectSystemPk}"
		(change)="add()">
		<option value="" disabled i18n>... select a system package</option>
		<option *ngFor="let spk of filterSelectable((systempks$ | async))" [value]="spk._id" >{{spk.name}}</option>
	</select>
</div>
	