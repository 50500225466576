<div class="search">
	<input-filter
		(filter)="onFilter($event)"
		(keyup.enter)="selectFirst()"
		[items]="data.list"
		[value]="data.filter"
	></input-filter>
	<div class="icon-sorting-ascending" (click)="showSort = !showSort"></div>
	<a
		class="search__add-new"
		*ngIf="
			model.name !== 'cmssettings' &&
			model.name !== 'survey' &&
			(model.name !== 'app' || access.isAdmin) &&
			(model.name !== 'organisation' || access.isAdmin)
		"
		title="Add new item"
		routerLink="{{ model.clientPathPrefix }}/{{ model.name }}/create"
		i18n
	>
		<span class="icon-add"></span>
		add
	</a>
</div>
<div [hidden]="!showSort" class="sort-menu">
	<div *ngFor="let sortMode of model.sortModes" (click)="sort(sortMode.objectPath); hideSort()">
		<div i18n="sort by">By {{ sortMode.name }}</div>
		<div
			class="icon-expand-arrow_S sort-direction"
			[hidden]="data.sort.objectPath != sortMode.objectPath"
			[ngClass]="{ 'sort-direction--reverse': data.sort.reverse }"
		></div>
	</div>
</div>

<virtual-scroll
	[items]="lastFilterEvent.result"
	(update)="scrollItems = $event"
	[bufferAmount]="20"
	class="list"
>
	<a
		*ngFor="let item of scrollItems"
		class="box"
		[ngClass]="{ 'box--active': item._id === id }"
		[attr.data-id]="item._id"
		routerLink="{{ model.clientPathPrefix }}/{{ model.name }}/edit/{{ item._id }}"
	>
	<list-item-background [mediaObjectId]="model.getItemCoverId(item, data)" [itemType]="model.getItemType(item)" *ngIf="model.name === 'media'"></list-item-background>
		<div class="flex-one">
			<div
				[ngClass]="{
					plain: !(
						model.schema.isLive ||
						model.schema.languageContent ||
						model.schema.codes ||
						model.schema.isPublishing
					)
				}"
				class="name"
			>
				{{ model.getItemTitle(item, data) }}
				<span *ngIf="item.macAddress">
					{{ item.macAddress }}
				</span>
			</div>
			<div class="info">
				<div
					*ngIf="item.isLive !== undefined"
					class="is-live"
					isLive
					[item]="item"
					[ngClass]="{
						'item--is-live': item.isLive,
						'item--unpublished-changes': !item.isUpToDate
					}"
				></div>

				<div
					*ngIf="item.publishing"
					class="is-live"
					[title]="item.publishing.state"
					[ngClass]="{
						'item--unpublished-changes': !item.isUpToDate,
						'item--is-live': item.publishing.state === 'published',
						'item--publish-in-progress': item.publishing.state === 'publishing',
						'item--publish-queued': item.publishing.state === 'queued'
					}"
				></div>

				<div *ngIf="data.boxxStates !== undefined && data.boxxStates[item._id]" class="boxx-state">
					<div [ngClass]="data.boxxStates[item._id].status" [title]="data.boxxStates[item._id].status"></div>
				</div>
				<div *ngIf="item.type" class="type">{{ item.type }}</div>

				<ul class="languages" *ngIf="model.name == 'exhibition'">
					<li
						*ngFor="let langItem of item.languageContent; let i = index"
						[ngClass]="{ 'default-language': i === 0 }"
					>
						{{ langItem.languageISO }}
					</li>
					<li class="comments" *ngIf="model.getItemAddress(item) as address">
						{{address}}
					</li>
				</ul>
				<div *ngFor="let code of item.codes" class="code">{{ code }}</div>

				<span
					class="comments"
					*ngIf="model.collectionName === 'Boxx' && model.getItemComments(item, data) as comments"
				>
					{{ comments }}
				</span>
			</div>
		</div>
		<div class="edit">
			<span class="icon-expand-arrow_S"></span>
		</div>
	</a>
</virtual-scroll>

<request-indicator
	#requestIndicator
	[ids]="[this.model.collectionName]"
	[requireStarted]="true"
></request-indicator>
