<style>
	:host(.ng-invalid) >>> .media-files-list {
		border: 1px solid #CD0026;
	}
		</style>
	<div
		[ngClass]="{invalid: missingLanguageOptions}"
		class="media-files-list-wrapper">
		<file-dropzone
			#dropzone
			*ngIf="!missingLanguageOptions && !value?.length"
			(fileDrop)="add($event.event)"
			[accept]="getAcceptMime()">
			<p i18n>Add {{ dropzone.droppableTypes.length }} new version(s)</p>
		</file-dropzone>

		<div
			class="media-files-list">
			<div class="relative-container">
				<div class="drag-container">
					<div *ngIf="missingLanguageOptions"  i18n>
						The organisation is missing or there are no languages set for your organisation.
					</div>
					<div *ngFor="let languageItem of value; let i = index;" class="media-files-item">
						<div class="item-body">
							<div>
								{{languageIso2name[languageItem.languageISO]}}
							</div>

							<div>
								<label i18n> File </label>
								<input-file
									(fileChange)="onFileChange($event, languageItem, i)"
									[(ngModel)]="languageItem.source"
									[file]="languageItem.file"
									[type]="type">

									<div slot="menu">
										<div *ngIf="value?.length > 1" (click)="openSyncLangsDialog(languageItem, i, 'sync')">
											<span class="icon-sync"></span>
											<ng-container i18n>Sync languages</ng-container>
										</div>
									</div>

								</input-file>
							</div>

							<ng-container *ngIf="canHaveThumb(type)">
								<br>
								<div class="file-row">
									<div>
										<label i18n>Preview Image</label>
									</div>
									<input-file
										[iconized]="true"
										[(ngModel)]="languageItem.thumbSource"
										type="image">
										<div *ngIf="type === 'image' || type === 'video'" slot="menu">
											<div (click)="recreateThumbnail(languageItem, i)" >
												<span class="icon-refresh"></span>
												<ng-container i18n>Recreate</ng-container>

											</div>
										</div>
									</input-file>
								</div>
							</ng-container>

							<div *ngIf="value && value.length">
								<label> File Size </label>
								<ng-template ngFor let-lc [ngForOf]="value">
									<span class="file-size-info" *ngIf="lc.languageISO === languageItem.languageISO">
										{{convertBytesToHumanReadableSize(lc.fileSize)}}
									</span>
								</ng-template>
							</div>
							<div>
								<label i18n>User-visible Title</label>
								<input (input)="propagateChange()" type="text" placeholder="... title" [(ngModel)]="languageItem.title">
							</div>
							<div *ngIf="type === 'image' || type === 'video'">
								<label i18n>Attribution</label>
								<input (input)="propagateChange()" type="text" placeholder="... author, source, license" [(ngModel)]="languageItem.attribution">
							</div>
							<div>
								<label i18n>Description</label>
								<input-text [allowModal]="true" cols="30" rows="4" placeholder="... description" (input)="propagateChange()" [(ngModel)]="languageItem.description"></input-text>
							</div>
							<div>
								<label i18n>ALT Text</label>
								<input-text [allowModal]="true" cols="30" rows="4" placeholder="... description" (input)="propagateChange()" [(ngModel)]="languageItem.altText"></input-text>
							</div>
							<div>
								<input-custom-fields [(ngModel)]="languageItem.customFields" [fields]="customFields"></input-custom-fields>
							</div>
						</div>
					</div>
				</div>
			</div>

			<label class="new-item item-body" *ngIf="!missingLanguageOptions && !value?.length">
				<span class="icon-add"></span><ng-container i18n>Add new file</ng-container>
				<input (change)="add($event)" [attr.accept]="isRoot ? null: getAcceptMime()" type="file">
			</label>

			<div class="spacer"> </div>

			<modal-component #syncLangsModal (close)="pendingSync.source = pendingSync.targets = null;">
				<p> {{ pendingSync.message }} </p>

				<div>
					<ng-container *ngFor="let target of pendingSync.targets">
						<ng-container *ngIf="!!target">
							<input-switch [disabled]="target.synced" [(ngModel)]="target.checked">
							{{ target.lang }}{{target.title ? ': ' + target.title : ''}} {{ target.synced ? '(already synced)' : '' }}
							</input-switch>
							<br>
						</ng-container>
					</ng-container>

					<p *ngIf="pendingSync.fieldPaths?.length">
						<ng-container i18n>Also sync these properties:</ng-container>

						<ng-container *ngFor="let field of pendingSync.fieldPaths">
							<ng-container *ngIf="!field.hidden">
								<input-switch [(ngModel)]="field.checked">
								{{ field.title }}
								</input-switch>
							</ng-container>
						</ng-container>
					</p>
				</div>

				<div class="modal__buttons">
					<div (click)="syncLangsModal.close()" class="button button--red-ghost" i18n>Leave unchanged</div>
					<div
						class="button"
						(click)="syncLangs(pendingSync); syncLangsModal.close();" i18n>
						Synchronize
					</div>
				</div>
			</modal-component>
	</div>
