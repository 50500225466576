/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { actions } from './actions';

const initialState = {
	collectionName: '',
	id: '',
	path: '',
	versionId: '',
	lastVisited: {},
};

export function routeInfo(state = initialState, action) {
	if (action.type === actions.ROUTE_CHANGED) {
		const newState = {
			...state,
			...action.data,
		}
		if (action.data.id) {
			return {
				...newState,
				lastVisited: {
					...state.lastVisited,
					[action.data.collectionName]: action.data.id,
				}
			};
		}
		return newState;
	}

	return state;
};
