/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var,vars-on-top */
var Schema = require('mongoose').Schema;

function nullAllDefaults(object) {
	const _obj = {};
	Object.keys(object).forEach((key) => {
		if (typeof object[key].default !== 'undefined') {
			_obj[key] = { ...object[key], default: null };
		} else if (typeof object[key] !== 'object') {
			_obj[key] = nullAllDefaults(object[key]);
		} else if (['duty', 'dnsWhiteList'].indexOf(key) !== -1) {
			_obj[key] = nullAllDefaults(object[key]);
		} else {
			_obj[key] = object[key];
		}
	});
	return _obj;
}

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	dataSourceID: { type: Schema.ObjectId, ref: 'DataSource', index: true, unique: false },
	name: { type: String, index: true, unique: false, required: true, softUnique: true },
	comments: { type: String },
	macAddress: { type: String, uppercase: true, required: true, unique: false, softUnique: true },
	specificConfig: require('./boxxSpecificConfigSchema').definition,
	overWriteExhibitionConfig: nullAllDefaults(require('./boxxConfigSchema').definition),
	systemStateID: { type: Schema.ObjectId, ref: 'BoxxState' },
	hardware: require('./hardwareSchema').definition,
	usbEncryptionKey: { type: Schema.ObjectId }, // FileID
};

var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Boxx',
	schema: ModelSchema,
	definition: def,
	relations: [{ op: 'add', rel: 'BoxxState', pathFrom: 'boxxID', pathTo: 'systemStateID' }],
};
