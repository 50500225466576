
<div class="exhibit-list">
	<div [dragula]='bagName' [(dragulaModel)]='value' class="drag-container">
		<div
			*ngFor="let id of value; let i = index;">
			<div>
				<h2>{{i + 1}}</h2>
				<span *ngIf="mediaObjects" [ngClass]="{'warning--message': mediaObjects[id]?.name === null}">
					{{(mediaObjects[id]?.name !== null) ? mediaObjects[id]?.name : 'Media could not be accessed'}}
				</span>
			</div>
			<div class="menu">
				<span class="icon-drag-reorder drag-handle"></span>
				<span class="icon-delete_sign" (click)="remove(i)"></span>
			</div>
		</div>
	</div>
	<a
		class="exhibit-list__add"
		(click)="selectMediaModal.open(); $event.preventDefault()">
		<span class="icon-add"></span>
		<div i18n>Add media</div>
	</a>
	<modal-component #selectMediaModal [clean]="true">
		<select-media
			*ngIf="selectMediaModal.isOpen()"
			[typeFilter]="type"
			[organisationID]="organisationID"
			(selectmedia)="add($event); selectMediaModal.close(); showMenu = false"></select-media>
	</modal-component>
</div>
	