
		<form class="login" [formGroup]="form" (ngSubmit)="onSubmit()">
			<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100" />
			<h1>shoutr labs</h1>
			<div class="form">
				<h2 i18n>Authenticate Your Account</h2>
				<div>
					<label i18n>Authentication Code</label>
					<input type="text" formControlName="authenticationCode" />
				</div>

				<button class="button" type="submit" [disabled]="!(form.valid && form.dirty)">Validate Code</button>
			<div class="forgot-password">
				<a href="javascript:void(0);" (click)="resend2factorEmail();" i18n *ngIf="!userNotAuthenticated">Resend a new code</a>
		    </div>
			</div>
			<div class="footer">
				<a href="https://www.shoutrlabs.com/datenschutz/?lang=en" i18n>Privacy</a>
				| <a href="https://www.shoutrlabs.com/impressum/?lang=en" i18n>Imprint</a>
			</div>
		</form>
	