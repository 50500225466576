
	<style>
:host(.ng-invalid) >>> .add-new {
	border: 1px solid #CD0026;
}
	</style>
<div *ngIf="value && articleFromID(value)" class="article">
	<label class="large" i18n>Article</label>
	<div class="first-row">
		<div class="title">{{articleFromID(value).name}}</div>
	</div>
	<div class="box-1-1">
		<div> <!-- first child 1/2 -->
			<label i18n>cover</label>
			<div
				class="cover"
				[ngStyle]="{'background-image': getUrl(articleFromID(value))}"></div>
		</div>
		<div> <!-- last child 1/2 -->
			<label i18n>languages</label>
			<div *ngFor="let lc of articleFromID(value).languageContent">{{isoLangsObj[lc.languageISO]}}</div>
			<div class="spacer"></div>
			<label i18n>comments</label>
			{{articleFromID(value).components}}
		</div>
	</div>
	<div class="input-article__footer">
		<div (click)="editArticleModal.open()">
			<span class="icon-edit"></span> edit
		</div>
		<div (click)="value = null">
			<span class="icon-remove"></span> remove
		</div>
	</div>

	<form-modal #editArticleModal collectionName="Article" [id]="value">
		<h2 i18n>Edit this exhibit's article</h2>
	</form-modal>
</div>
<div class="input-widget" *ngIf="!(value && articleFromID(value))">
	<div class="add-new" (click)="$event.preventDefault(); selectArticleModal.open()" i18n><span class="icon-collection"></span> select article</div>
</div>

<modal-component #selectArticleModal>
	<select-article
		*ngIf="selectArticleModal.isOpen()"
		(selectarticle)="value = $event; selectArticleModal.close()">
	</select-article>
</modal-component>
	