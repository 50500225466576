/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false, required: true },
	exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition' },
	dataSourceID: { type: Schema.ObjectId, ref: 'DataSource', required: true, index: true, unique: false },
	code: { type: String, index: true, unique: false, required: true },
	webClientUUID: { type: String },
	createdOn: { type: Date, default: Date.now },
	converted: { type: Boolean, default: false },
	convertedOn: { type: Date },
};
var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Ticket',
	schema: ModelSchema,
	definition: def,
};
