/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isoLang2name } from '../../../utils';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';

@Component({
	selector: 'input-glossary-language-content',
	template: `
<div [ngClass]="{invalid: missingValues()}">
	<div *ngIf="missingValues()" class="invalid-message" i18n>
		The organisation is missing or there are no languages set for your organisation.
	</div>
	<div class="drag-container media-files-list">
		<div
			*ngFor="let languageItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					{{isoLang2name[languageItem.languageISO]}}
				</div>
				<div>
					<label i18n>Term</label>
					<input
						type="text"
						(change)="propagateChange()"
						[(ngModel)]="languageItem.term"/>
				</div>
				<div>
					<label i18n>Additional terms</label>
					<input-tags
						i18n-placeholder
						placeholder="… keywords"
						(change)="propagateChange()"
						[(ngModel)]="languageItem.keywords"></input-tags>
				</div>
				<div>
					<label i18n>Definition</label>
					<input-text
						cols="30"
						rows="4"
						placeholder="… definition"
						i18n-placeholder
						(change)="propagateChange()"
						[allowModal]="true"
						[(ngModel)]="languageItem.definition"></input-text>
				</div>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputGlossaryLanguageContentComponent), multi: true }],
})
export class InputGlossaryLanguageContentComponent extends CustomValueAccessor {
	public isoLang2name = isoLang2name;

	missingValues() {
		return !(this.value && this.value.length);
	}
}
