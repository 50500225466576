/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var */
var Schema = require('mongoose').Schema;
var getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	name: { type: String, required: true },
	date: { type: Date, required: true },
	coverID: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image', required: true }, // Has to be type Image
	isShareable: { type: Boolean, default: false },
	shareTargetUrl: { type: String, default: null, required: false },
	languageContent: {
		type: [
			{
				languageISO: { type: String, required: true },
				title: { type: String, required: true },
				description: { type: String },
				content: { type: String },
			},
		],
		required: true,
	},
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'CalendarEvent',
	labels: getLabels(schemaDefinition),
	schema: ModelSchema,
	definition: schemaDefinition,
};
