/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const { Schema } = require('mongoose');

const schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation' },
	articleID: { type: Schema.ObjectId, ref: 'Article' },
	data: { type: Object },
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Survey',
	schema: ModelSchema,
	definition: schemaDefinition,
};
