/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	dataSourceID: { type: Schema.ObjectId, ref: 'DataSource', index: true, unique: false },
	platform: {
		deviceName: { type: String, default: 'unkown' },
		webClientUUID: { type: String, default: 'unkown' },
		isLoaner: { type: Boolean, default: 'unkown' },
		AppVers: { type: String, default: 'unkown' },
		deviceOS: { type: String, default: 'unkown' },
		deviceOsVers: { type: String, default: 'unkown' },
		webClientVersion: { type: String, default: 'unkown' },
	},
	metaInformation: {
		comment: { type: String },
	},
	data: { type: String },
	createdOn: { type: Date, default: Date.now },
};
var ModelSchema = new Schema(def);

module.exports = {
	name: 'AppLog',
	schema: ModelSchema,
	definition: def,
};
