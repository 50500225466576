
<div class="input-text">
	<textarea
		#inlineTextarea
		[cols]="cols"
		[rows]="rows"
		[placeholder]="placeholder"
		[disabled]="disabled"
		[minlength]="minLength"
		[maxlength]="maxLength"
		[(ngModel)]="value"></textarea>
		<ng-container *ngIf="allowModal">
		<modal-component
			#modal
			*ngIf="allowModal"
			(openEvent)="modalTextarea.focus()"
			(closeEvent)="inlineTextarea.focus()"
			[fullSize]="true">
			<textarea
				#modalTextarea
				[attr.placeholder]="placeholder"
				[attr.disabled]="disabled || null"
				[(ngModel)]="value"></textarea>
		</modal-component>
		<button role="button"
			class="modal-open icon-open-in-popup"
			(click)="modal.open();"></button>
		</ng-container>
</div>
	