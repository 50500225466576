/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Input,
	ViewChild,
	ViewContainerRef,
	ComponentFactoryResolver,
	ComponentFactory,
	ComponentRef,
	ChangeDetectorRef,
	SimpleChange,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as deepExtend from 'deep-extend';

import { ModelConfig } from '../modelConfigs/ModelConfig';
import { modelConfigs } from '../modelConfigs';
import { getValidators } from '../services/schemaTools';
import * as actions from '../socket.actions';
import { CollectionJoiner } from '../utils';

@Component({
	selector: 'dynamic-model-form',
	template: `
	<ng-content></ng-content>
	<div class="dynamic-model-form" [ngClass]="{ disabled: disabled, hidden: requestIndicator.loading }">
		<ng-template #templateRef></ng-template>
	</div>
	<request-indicator #requestIndicator [ids]="pendingIds" [requireStarted]="true"></request-indicator>
	`,
	styleUrls: ['./dynamic-model-form.component.sass'],
})
export class DynamicModelFormComponent {
	@Input() collectionName: string;
	@Input() id = '';
	@Input() versionId: string = null;
	@Input() extendDefaultData: any;
	@Input() data: any;
	@Input() disabled: boolean;

	@ViewChild('templateRef', {read: ViewContainerRef, static: true }) childViewContainer: ViewContainerRef;

	itemData = null;
	formGroup: FormGroup;
	formFilled = false;
	usingDataStreamInput = false;

	modelConfig: ModelConfig;
	model: ModelConfig;
	modelFormFactory: ComponentFactory<any>;
	modelFormRef: ComponentRef<any>;
	pendingIds: string[];

	private depManager: CollectionJoiner;
	private currentItem: { collectionName, id } = null;
	initialized = false;

	constructor(
		private store: Store<any>,
		private formBuilder: FormBuilder,
		private cfr: ComponentFactoryResolver,
		public vcr: ViewContainerRef,
		private cdr: ChangeDetectorRef,
	) {
		this.depManager = new CollectionJoiner(store);
	}

	ngOnInit() {
		this.initialized = true;

		if (this.modelConfig) {
			this.depManager.replace(this.modelConfig.dependencies);
		}
		this.cdr.detectChanges();
	}

	ngOnChanges(changes) {
		if (changes.collectionName) {
			this.modelConfig = modelConfigs[this.collectionName];
			this.model = this.modelConfig;
			this.recreateModelForm();
		}

		if (!this.collectionName) {
			return;
		}

		if (changes.collectionName || changes.id) {
			this.loadItem();
			this.reset();

			this.pendingIds = [
				this.collectionName,
				...this.modelConfig.dependencies.map(d => d.name),
				...this.id ? [`${this.collectionName}:${this.id}`] : [],
			];
		}

		if (this.modelFormRef && changes.data && this.data) {
			this.onDataChange();
			this.updateModelFormProps();
		}
	}

	onDataChange() {
		const itemData = deepExtend({}, (this.data.model || this.data), this.extendDefaultData || {});
		let makePristine = !!itemData.incomplete;

		// formGroup yet.
		if (
			// Don't patch if the form was already filled *and* it is dirty.
			(!this.formFilled || !this.formGroup.dirty) &&
			// If this form has an id and the data doesn't, that means that the
			// item data request hasn't yet succeeded, so don't patch the form
			(!this.id || !!itemData._id) &&
			// Don't patch if only the lightweight list data is loaded
			!itemData.incomplete
		) {
			this.formGroup.patchValue(itemData);
			this.itemData = itemData;
			this.formFilled = true;
			makePristine = true;
		} else {
			// the changelog was updated elsewhere, but we should revert this change
			// for the data we have.
			this.itemData = {
				...itemData,
				changeLog: (this.itemData || itemData).changeLog,
			};

			if (this.data.model) {
				this.data.model = this.itemData;
			} else {
				this.data = this.itemData;
			}
		}

		if (this.initialized) {
			this.cdr.detectChanges();
		}

		if (makePristine) {
			this.formGroup.markAsPristine();
		}
	}

	recreateModelForm() {
		this.childViewContainer.clear();

		if (this.collectionName) {
			this.formGroup = getValidators(
				this.formBuilder,
				this.modelConfig.schema,
				deepExtend(
					{},
					this.modelConfig.defaultData,
					this.extendDefaultData || {},
				)
			);

			this.modelFormFactory = this.cfr.resolveComponentFactory(this.modelConfig.formComponent);
			this.modelFormRef = this.childViewContainer.createComponent(this.modelFormFactory);

			this.updateModelFormProps();

			if (this.initialized) {
				this.depManager.replace(this.modelConfig.dependencies);
			}
		} else {
			this.formGroup = this.formBuilder.group({});
		}
	}

	loadItem() {
		if (this.currentItem && this.id === this.currentItem.id) {
			return;
		}

		this.leaveCurrentItem();

		const { collectionName, id } = this;

		if (!collectionName || !id) return;

		this.currentItem = { collectionName, id };
		this.store.dispatch(new actions.GetCollectionItem({ ...this.currentItem, join: true }));
	}

	leaveCurrentItem() {
		if (!this.currentItem) return;

		this.store.dispatch(new actions.LeaveCollectionItem(this.currentItem));
	}

	updateModelFormProps() {
		const instance = this.modelFormRef.instance;
		let changes;

		['formGroup', 'id', 'data', 'model'].forEach(prop => {
			const prev = instance[prop];
			const cur = this[prop];
			const first = !(prop in instance);

			instance[prop] = cur;

			if (instance.ngOnChanges && prev !== cur) {
				changes = changes || {};
				changes[prop] = new SimpleChange(prev, cur, first);
			}
		});

		if (changes) {
			instance.ngOnChanges(changes);
		}

		// this.modelFormRef.changeDetectorRef.markForCheck();
		// this.modelFormRef.changeDetectorRef.detectChanges();
	}

	reset() {
		this.formGroup.reset();

		if (this.extendDefaultData) {
			this.formGroup.patchValue(this.extendDefaultData);
		}

		this.formFilled = false;
		this.formGroup.markAsPristine();
	}

	ngOnDestroy() {
		this.depManager.leaveCurrent();
		this.leaveCurrentItem();
	}
}
