/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {of as observableOf, combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map, takeUntil, distinctUntilChanged, filter} from 'rxjs/operators';
import { sortListOfObjects } from './services/sortListOfObjects';
import { pluckDistinct, fillDefaults, shallowEqual } from './utils';
import * as actions from './socket.actions';

function findInObject(object, query) {
	if (object.minor && object.major) {
		object = {...object, majorMinor: `${object.major}:${object.minor}` };
	}

	if (object.firstName && object.lastName) {
		object = {...object, fullName: `${object.firstName} ${object.lastName}` };
	}

	return Object.keys(object).find(key => {
		// ignore version, IDs, changeLog and uuid
		if (/(^_)|(ID$)|(^changeLog$)|(^uuid$)/.test(key)) {
			return false;
		}

		const value = object[key];

		if (typeof value === 'string') return value.toLowerCase().trim().includes(query.toLowerCase().trim());
		if (typeof value === 'number') return value.toString().includes(query);

		if (Array.isArray(value)) {
			return findInObject(value, query);
		}

		return false;
	});
}

export const selectors = {
	getEntities(res) {
		return res.entities;
	},

	getAlphabeticList(modelState) {
		return modelState.alphabeticSortedItems;
	},

	getSortedListForOrg$(state$, store): Observable<{ list: any[], sort: string }> {
		return observableCombineLatest(
			state$.pipe(
				map(selectors.getListAndSort),
				distinctUntilChanged(shallowEqual)
			),
			pluckDistinct(store, 'currentuser', 'organisation'),
		).pipe(map(([{ list, sort }, organisation]: [{ [index: string]: any }, any]) => {
			return {
				list: organisation._id ? list.filter(item => item && item.organisationID === organisation._id) : list,
				sort,
			}
		}));
	},

	getList({ sortedItems }: { sortedItems: any[] }) {
		return sortedItems;
	},

	filter(items: any[], filter) {
		if (filter) {
			if (filter.includes('|')) {
				const [propertyName, queryValuesString] = filter.split('|');
				const queryValuesList = queryValuesString.split(' ');
				return items.filter(item => queryValuesList.includes(item[propertyName]));
			} else {
				return items.filter(
					item => findInObject(item, filter),
				);
			}
		}

		return items;
	},

	getListAndSort(modelState) {
		return {
			list: selectors.getList(modelState),
			sort: modelState.sort,
		};
	},

	getOne(store, collectionName: string, id: string, defaultData?, versionId?) {
		if (!id) return observableOf({...defaultData});

		const isWithVersion = versionId !== undefined;

		if (isWithVersion) {
			store.dispatch(new actions.GetVersionOfItem({ collectionName, id, versionNumber: parseInt(versionId, 10) }));
		}

		return pluckDistinct(store, 'models', collectionName, 'entities', id).pipe(
			map(item => {
				if (item) {
					if (isWithVersion) {
						if (item.versionEntities && (versionId in item.versionEntities)) {
							return item.versionEntities[versionId];
						}
						return fillDefaults({}, defaultData);
					}
					return Object.assign({}, item);
				}
				return {...defaultData};
			}),
			takeUntil((store as any).actionsObserver.pipe(filter(({ type, payload }) => {
				return type === actions.DELETE_COLLECTION_ITEM && payload.id === id;
			})))
		);
	},
}
