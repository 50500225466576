/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
var BOXX_STATUSES = require('../../helpers/consts').BOXX_STATUSES;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation' },
	boxxID: { type: Schema.ObjectId, ref: 'Boxx', index: true, unique: false },
	lastHeartbeat: { type: Date, default: new Date(0), writable: false },
	lastUpdate: { type: Date, default: new Date(0), writable: false },
	uptime: { type: String, writable: false, default: 'unkown' },
	status: {
		type: String,
		enum: Object.values(BOXX_STATUSES),
		default: 'unknown',
		writable: false,
	},
	installedPackages: { type: String, default: 'unkown' },
};
var ModelSchema = new Schema(def);

module.exports = {
	name: 'BoxxState',
	schema: ModelSchema,
	definition: def,
};
