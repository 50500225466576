
<div class="modal" [ngClass]="{'is-open': _isOpen, 'modal--clean': clean, 'modal--full-size': fullSize}">
	<div class="modal__bg" (click)="close()"></div>
	<span class="modal__close icon-delete_sign" (click)="close()"></span>

	<div class="modal__content">
		<div class="modal__header">
			<ng-content select="header"></ng-content>
		</div>

		<div #bodyElement class="modal__body" (click)="bodyClick($event, bodyElement)">
			<ng-content></ng-content>
		</div>

		<div class="modal__footer">
			<ng-content select="footer"></ng-content>
		</div>
	</div>
</div>
	