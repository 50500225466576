/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Actions } from '@ngrx/effects';

import { ModelConfig } from '../modelConfigs/ModelConfig';
import { articleConfig } from '../_articles/config';
import { exhibitConfig } from '../_exhibits/config';
import { UnifiedArticleFormComponent } from './unifiedarticle-form.component';
import { selectors } from '../selectors';
import { actions } from '../actions';
import * as isoLangs from '../../../models/languageObjects/isoLangs';
import { isoLang2name, fixLanguageContent, pluckDistinct, setItemFieldDefault } from '../utils';
import { articleTypes } from '../../../models/article/articleSchema';

export const unifiedArticleConfig = new ModelConfig({
	collectionName: 'UnifiedArticle',
	namePlural: 'articles',
	icon: 'icon-xlarge-icons',
	clientPathPrefix: '',
	apiEndpoint: 'unifiedarticles',
	publishable: true,
	defaultData: Object.assign({}, exhibitConfig.defaultData, {
		articleID: articleConfig.defaultData,
	}),

	dependencies: [
		{ name: 'MediaObject', inSidebar: true },
		{ name: 'Location', inSidebar: true },
		{ name: 'Beacon', inSidebar: false },
		{ name: 'Exhibit', inSidebar: false },
		{ name: 'Exhibition', inSidebar: true },
		{ name: 'DataSource', inSidebar: false },
	],

	schema: Object.assign({}, exhibitConfig.schema, { articleID: articleConfig.schema }),
	labels: {},

	formComponent: UnifiedArticleFormComponent,
});

Object.assign(unifiedArticleConfig, {
	getFormData$(store, id, versionId?) {
		const models$ = pluckDistinct(store, 'models');

		const { defaultData } = unifiedArticleConfig;
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', 'Article', 'entities'),
			pluckDistinct(models$, 'Article').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(models$, 'Exhibition').pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(models$, 'Organisation').pipe(map(selectors.getEntities)),
			selectors
				.getSortedListForOrg$(pluckDistinct(models$, 'Exhibit'), store)
				.pipe(map(({ list }) => list)) as any,
			pluckDistinct(models$, 'MediaObject', 'entities'),
			pluckDistinct(models$, 'DataSource', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'customMap'),
			(
				model: any,
				articleEntities,
				articles,
				exhibitions,
				currentUser,
				organisationEntities,
				exhibits,
				mediaObjects,
				dataSources,
				customMap,
			) => {
				const organisationID = model && id ? model.organisationID : currentUser.organisation._id;
				const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
				const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
				const languageOptions = (languages || []).map(value => ({
					value: `${value}`,
					label: `${isoLang2name[value]}`,
				}));
				const showShareOption = modelOrg
					? modelOrg.showShareOption
					: currentUser.organisation.showShareOption;
				const useNewEditor = modelOrg ? modelOrg.useNewEditor : false;
				const availableArticleTypes =
					modelOrg && modelOrg.articleTypes.length ? modelOrg.articleTypes : articleConfig.schema.type.enum;

				exhibitions = organisationID
					? exhibitions.filter(ex => ex.organisationID === organisationID)
					: exhibitions;

				if (!id) {
					// fix language content
					model.articleID.languageContent = fixLanguageContent({
						languageOptions,
						languageContent: defaultData.articleID.languageContent,
					});

					// set exhibitionID
					if (exhibitions.length === 1) {
						model.exhibitionID = exhibitions[0]._id;
					}

					model.articleID.type = availableArticleTypes[0].value;
				}

				if (!model.incomplete) {
					model.articleID.arContent.guidanceText = fixLanguageContent({
						languageOptions,
						languageContent: (model.articleID.arContent || {}).guidanceText,
					});

					setItemFieldDefault(model, 'exhibitionID', exhibitions);
				}
				const modelExhibition = model._id && exhibitions.find(({ _id }) => _id === model.exhibitionID);
				const isLandingExhibit = modelExhibition && modelExhibition.landingExhibitID === model._id;

				exhibits = modelExhibition
					? exhibits.filter(exhibit => exhibit.exhibitionID === modelExhibition._id)
					: exhibits;

				const allCodes = exhibits
					.filter(exhibit => exhibit.id !== id)
					.reduce((acc, exhibit) => {
						for (const code of exhibit.codes || []) acc.add(code);
						return acc;
					}, new Set());
				return {
					model,
					articles,
					exhibitions,
					isLandingExhibit,
					modelExhibition,
					currentUser,
					organisationID,
					mediaObjects,
					languageOptions,
					availableArticleTypes,
					showShareOption,
					showOpenInModalUi: modelOrg && modelOrg.showOpenInModalUi,
					allCodes,
					hasLocationNav: modelOrg && modelOrg.enableNavUi.includes('location'),
					dataSourceIDs: organisationID
						? dataSources.filter(ds => ds.organisationID === organisationID).map(({_id}) => _id)
						: dataSources.map(({_id}) => _id),
					customMap,
					quizArticle: model.articleID.isQuizArticle ? model.articleID.isQuizArticle: false,
					hasQuizNav: modelOrg && modelOrg.enableNavUi.includes('quiz'),
					useNewEditor,
				};
			}
		);
	},
	getItemCoverId(item, data) {
		return item.articleID ? item.articleID.coverID : undefined;
	},

	getPublishEnabled: exhibitConfig.getPublishEnabled,

	// interrupt the normal save procedure and save the article and exhibit separately
	beforeSave(formGroup, formData, footer) {
		if (!formGroup.controls.article && !formGroup.controls.exhibit) {
			// saving the sub items, continue the footer.save() method
			return true;
		}

		const articleData = formData.model.article;
		const exhibitData = formData.model.exhibit;

		const articleSaveAttempted = footer.save({
			formGroup: formGroup.controls.article as FormGroup,
			formData: articleData,
			collectionName: 'Article',
			id: articleData._id || null,
		});

		if (!articleSaveAttempted) {
			return false;
		}

		footer.events.take(1).subscribe(({ data: articleData, type }) => {
			if (/FAIL/.test(type)) {
				// console.error('unified-article failed at article');
				return;
			}

			// update the articleID in the form
			formGroup.controls.exhibit.patchValue({
				articleID: articleData._id,
			});

			footer.save({
				formGroup: formGroup.controls.exhibit as FormGroup,
				formData: exhibitData,
				collectionName: 'exhibit',
			});

			footer.events.take(1).subscribe(({ type, data }) => {
				if (/FAIL/.test(type)) {
					// console.error('unified-article failed at exhibit');
					return;
				}

				formGroup.markAsPristine();
			});
		});

		// don't try to save this <unifiedarticle-form>
		return false;
	},
});
