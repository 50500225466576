/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { schema, labels } from '../../../models/tour/tourSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { TourFormComponent } from './components/tour-form.component';
import { selectors } from '../selectors';
import { isoLang2name, pluckDistinct, fixLanguageContent } from '../utils';
import { map } from 'rxjs/operators';

export const tourConfig = new ModelConfig({
	name: 'tour',
	namePlural: 'tours',
	collectionName: 'Tour',
	icon: 'icon-signpost',
	clientPathPrefix: '',
	publishable: true,
	dependencies: [{ name: 'UnifiedArticle', inSidebar: false }, { name: 'Location', inSidebar: false }],
	schema,
	labels,
	formComponent: TourFormComponent,
});

export const getFormData$ = function(store, id, versionId?) {
	const models$ = pluckDistinct(store, 'models');
	return observableCombineLatest(
		this.getItem$(store, id, versionId),
		pluckDistinct(models$, 'Exhibition').pipe(map(selectors.getAlphabeticList)),
		pluckDistinct(models$, 'Organisation').pipe(map(selectors.getEntities)),
		pluckDistinct(models$, 'UnifiedArticle', 'entities'),
		pluckDistinct(models$, 'Location', 'entities').pipe(map(entities => Object.values(entities))),
		pluckDistinct(store, 'currentuser'),
		(
			model: any,
			exhibitions: any,
			organisationEntities,
			unifiedArticles: any,
			locationEntities: any,
			currentUser: any
		) => {
			const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
			const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
			const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
			const languageOptions = (languages || []).map(value => ({
				value: `${value}`,
				label: `${isoLang2name[value]}`,
			}));
			exhibitions = organisationID
				? exhibitions.filter(ex => ex.organisationID === organisationID)
				: exhibitions;
			if (!model.incomplete) {
				model.languageContent = fixLanguageContent({
					languageOptions,
					languageContent: model.languageContent,
				});
			}
			const isQuiz = this.name === 'quiz';
			const showShareOption = modelOrg ? modelOrg.showShareOption : currentUser.organisation.showShareOption;
			return {
				model,
				isQuiz,
				organisationID,
				languageOptions,
				exhibitions,
				unifiedArticles,
				showShareOption,
				polylineLocationEntities: locationEntities.filter(
					location =>
						(!organisationID || location.organisationID === organisationID) && ['geoLocation', 'customMap'].includes(location.type) &&
						location[location.type].shape === 'polyline'
				),
				markerLocationEntities: locationEntities.filter(
					location =>
						(!organisationID || location.organisationID === organisationID) && ['geoLocation', 'customMap'].includes(location.type) && location[location.type].shape === 'marker'
				),
				access: currentUser && currentUser.access,
				hasLocationNav: modelOrg && modelOrg.enableNavUi.includes('location'),
			};
		}
	);
};

export const getListData$ = function(store) {
	const modelState$: Observable<any> = pluckDistinct(store, 'models', this.collectionName);

	return observableCombineLatest(
		pluckDistinct(modelState$, 'filter'),
		selectors.getSortedListForOrg$(modelState$, store)
	).pipe(
		map(([filter, data]) => {
			return {
				...data,
				list: data.list.filter(model => (this.name === 'quiz' ? model.isQuiz : !model.isQuiz)),
				filter,
			};
		})
	);
};

export function beforeSave(formGroup, data) {
	formGroup.patchValue({ isQuiz: this.name === 'quiz' });
	return true;
}

Object.assign(tourConfig, { getFormData$, getListData$, beforeSave });
