/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';
import { RouterModule } from '@angular/router';

import { OrganisationFormComponent } from './components/organisation-form.component';
import { UserManagerComponent } from './components/user-manager.component/user-manager.component';
import { InputStyleTemplatesComponent } from './components/input-styletemplates.component/input-styletemplates.component';
import { InputArticleTypesComponent } from './components/input-article-types.component';
import { InputNavUiComponent } from './components/input-nav-ui.component';
import { InputCommandsComponent } from './components/input-commands.component';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule,
	],
	exports: [
		OrganisationFormComponent,
	],
	declarations: [
		OrganisationFormComponent,
		UserManagerComponent,
		InputStyleTemplatesComponent,
		InputArticleTypesComponent,
		InputNavUiComponent,
		InputCommandsComponent,
	],
	entryComponents: [
		OrganisationFormComponent,
	],
})
export class OrganisationModule { }
