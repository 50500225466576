/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {map, pluck} from 'rxjs/operators';
import { Component, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { styletemplateConfig } from '../../../_styletemplates/config';
import * as actions from '../../../socket.actions';
import { selectors } from '../../../selectors';
import { pluckDistinct } from '../../../utils';

@Component({
	selector: 'input-styletemplates',
	template: `
<div class="list">
	<div *ngFor="let pkid of value">{{getName(pkid)}} <span class="icon-remove" (click)="remove(pkid)"></span></div>
</div>
<div class="input-widget">
	<select
		(change)="propagateChange()"
		[(ngModel)]="selectStyleTemplate"
		[ngClass]="{empty: !selectStyleTemplate}"
		(change)="add()">
		<option value="" disabled placeholder>... select a style template</option>
		<option
			*ngFor="let spk of ((styletemplates$ | async) | filter:(routeInfoOrgaID | async))"
			[value]="spk._id" [hidden]="value.includes(spk._id)">{{spk.name}}
		/option>
	</select>
</div>
	`,
	styleUrls: ['./input-styletemplates.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputStyleTemplatesComponent), multi: true }],
})
export class InputStyleTemplatesComponent extends CustomValueAccessor {
	public styletemplates$;
	private styletemplatesEntities;
	public selectStyleTemplate;
	public routeInfoOrgaID;
	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		store.dispatch(new actions.GetCollectionList({ collectionName: styletemplateConfig.collectionName }));
		this.styletemplates$ = store.pipe(pluck('models', 'StyleTemplate'), map(selectors.getAlphabeticList), );
		this.styletemplatesEntities = {};
		this.routeInfoOrgaID = pluckDistinct(store, 'routeInfo', 'id');
		store.pipe(pluck('models', 'StyleTemplate'), map(selectors.getEntities), ).subscribe((data) => {
			this.styletemplatesEntities = data;
		});
		this.selectStyleTemplate = '';
		this.value = [];
	}
	add() {
		if (!this.value) this.value = [];
		if (this.selectStyleTemplate) this.value = [...this.value, this.selectStyleTemplate];
		this.selectStyleTemplate = '';
	}
	remove(rid) {
		this.value = this.value.filter(id => rid !== id);
	}
	getName(id) {
		return this.styletemplatesEntities[id] ? this.styletemplatesEntities[id].name : '';
	}
	ngOnDestroy() {
		this.store.dispatch(new actions.LeaveCollectionList({ collectionName: styletemplateConfig.collectionName }));
	}
}
