/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'glossary-term-form',
	templateUrl: './index.html',
})
export class GlossaryTermFormComponent {
	@Input() id = '';
	@Input() data: any;
	@Input() formGroup: FormGroup;
}
