/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';

@Component({
	selector: 'input-article-types',
	template: `
		<div class="list">
			<div *ngFor="let item of value">
				{{
					item === 'builder2'
						? 'editor v2'
						: item === 'editor'
						? 'editor v3'
						: item === 'custom-map'
						? 'indoor map'
						: item === 'distortable-image-map'
						? 'outdoor map'
						: item
				}}
				<span class="icon-remove" (click)="remove(item)"></span>
			</div>
		</div>
		<div class="input-widget">
			<select (change)="propagateChange()" (change)="add($event.target.value)">
				<option value="" disabled i18n>... select an article type</option>
				<option *ngFor="let type of articleTypeOptions" [value]="type.value">{{ type.label }}</option>
			</select>
		</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputArticleTypesComponent), multi: true },
	],
})
export class InputArticleTypesComponent extends CustomValueAccessor {
	public styletemplates$;
	private styletemplatesEntities;
	public selectStyleTemplate;
	public articleTypeOptions;

	constructor(public i18n: I18n) {
		super(i18n);
		this.articleTypeOptions = [
			// { value: 'builder', label: this.i18n('Editor (deprecated, do not use for new content)')},
			{ value: 'builder2', label: this.i18n('Editor') },
			{ value: 'editor', label: this.i18n('Editor Tapir') },
			{ value: 'custom', label: this.i18n('Hand-coded webpage in zip file') },
			{ value: 'ar-package', label: this.i18n('Augmented Reality package for native AR apps') },
			{ value: 'media', label: this.i18n('Video for webstation-type shoutr.Boxxes') },
			{ value: 'custom-map', label: this.i18n('Indoor map from image') },
			{ value: 'distortable-image-map', label: this.i18n('Outdoor map with image overlay') },
			{ value: 'audiosync-media', label: this.i18n('Video for mediaplayer-type shoutr.Boxxes') },
			{ value: 'external', label: this.i18n('External URL for webstation-type shoutr.Boxxes') },
			{ value: 'memoryGameKids', label: this.i18n('Memory Game for Kids') },
			{ value: 'memoryGameAdult', label: this.i18n('Memory game for Adults') },
			{ value: 'hangmanGame', label: this.i18n('Hangman Game') },
			{ value: 'puzzle', label: this.i18n('Puzzle Game') },
			{ value: 'file', label: this.i18n('File') },
			{ value: 'media-files', label: this.i18n('Media Files') },
			// { value: 'unity-scene', label: this.i18n('Unity3D WebGL scene')},
		];

	}
	add(typeName) {
		if (!this.value) this.value = [];
		this.value.push(typeName);
	}
	remove(typeName) {
		this.value = this.value.filter((id) => typeName !== id);
	}
}
