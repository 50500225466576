/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Observable } from 'rxjs';

import { definition as schema } from '../../../models/survey/surveySchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { SurveyFormComponent } from './components/survey';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';

export const surveyConfig = new ModelConfig({
	name: 'surveys',
	namePlural: 'surveys',
	collectionName: 'Survey',
	clientPathPrefix: '',
	icon: 'icon-survey',
	dependencies: [
		{ name: 'Article', inList: true },
	],
	schema,
	formComponent: SurveyFormComponent,
});
