<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<div class="first-row">
		<label i18n>name</label>
		<input type="text" formControlName="name" i18n-placeholder placeholder="… location name" />
	</div>
	<div>
		<label i18n>Tags</label>
		<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
	</div>
	<div class="spacer"></div>

	<div class="box-1-3">
		<div>
			<label i18n>type</label>
		</div>
		<div>
			<label i18n>value</label>
		</div>
	</div>

	<div class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<input type="radio" value="beacon" id="beacon" name="type" formControlName="type" /><label for="beacon"
				 i18n>Beacon</label
			>
		</div>
		<div>
			<!-- last child 2/3 -->
			<form-input
				i18n-placeholder
				placeholder="… select a beacon"
				formControlName="beaconID"
				collectionName="Beacon"
				[disabled]="formGroup.value.type !== 'beacon'"
				[modelItems]="data?.beacons"
			></form-input>
		</div>
	</div>
	<div class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<input type="radio" value="geoLocation" id="geoLocation" name="type" formControlName="type" /><label
				for="geoLocation"
				i18n
				>Geo location</label
			>
		</div>
		<div class="custom-map-group"> <!-- last child 2/3 -->
			<ng-select
				(selected)="getArticle($event.value); distortableImageMapArticle = $event.value"
				placeholder="... select a distortable image map"
				[options]="data.distortableImageMaps"
				[disabled]="formGroup.value.type !== 'geoLocation'"></ng-select>
			<input-map
				crs="EPSG3857"
				[mapMetaData]="data.articlesMapMetaData[distortableImageMapArticle] ? data.articlesMapMetaData[distortableImageMapArticle].mapData : { center: [52.220836, 13.16902], maxZoom: 19, minZoom: 0, bounds: [] }"
				[imageMediaObjectID]="data.articlesMapMetaData[distortableImageMapArticle] ? data.articlesMapMetaData[distortableImageMapArticle].coverID : ''"
				[hasMarker]="true"
				[hasPolygon]="true"
				[hasPolyline]="true"
				[otherMapPoints]="data.otherMapPoints"
				[formGroup]="formGroup.controls.geoLocation"
				[disabled]="formGroup.value.type !== 'geoLocation'"></input-map>
		</div>
	</div>
	<div class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<input type="radio" value="dmxLocation" id="dmxLocation" name="type" formControlName="type" /><label
				for="dmxLocation"
				i18n
				>dmx location</label
			>
		</div>
		<div formGroupName="dmxLocation">
			<div>
				<label i18n>Universe</label>
				<input type="number" step="1" formControlName="universe" />
			</div>
			<div>
				<label i18n>Channel</label>
				<input type="number" step="1" formControlName="channel" />
			</div>
		</div>
	</div>
	<div class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<input type="radio" value="customMap" id="customMapLocation" name="type" formControlName="type" />
			<label for="customMapLocation" i18n>Custom map location</label>
		</div>
		<div class="custom-map-group" formGroupName="customMap">
			<!-- last child 2/3 -->
			<ng-select
				i18n-placeholder
				placeholder="… select a map"
				formControlName="articleID"
				(selected)="getArticle($event.value)"
				[options]="data.customMaps"
				[disabled]="formGroup.value.type !== 'customMap'"></ng-select>
			<input-map
				crs="Simple"
				[hasMarker]="true"
				[hasPolygon]="true"
				[hasPolyline]="true"
				[formGroup]="formGroup.controls.customMap"
				[articleID]="formGroup.value.customMap.articleID"
				[mapMetaData]="data.articlesMapMetaData[formGroup.value.customMap.articleID] ? data.articlesMapMetaData[formGroup.value.customMap.articleID].mapData : null"
				[disabled]="!(formGroup.value.type === 'customMap' && formGroup.value.customMap.articleID)"></input-map>
		</div>
	</div>
	<div
		*ngIf="
			(formGroup.value.type === 'customMap' && formGroup.value.customMap.shape === 'polygon') ||
			(formGroup.value.type === 'geoLocation' && formGroup.value.geoLocation.shape === 'polygon')
		"
	>
		<form-input [formGroup]="formGroup" type="boolean" formControlName="isFence" i18n>is geo fence</form-input>
	</div>
	<div class="spacer"></div>

	<div>
		<label i18n>comments</label>
		<textarea cols="30" rows="4" formControlName="comments"></textarea>
	</div>

	<div class="spacer"></div>

	<div>
		<input-language-content formControlName="languageContent" [formGroup]="formGroup">
		</input-language-content>
	</div>

	<div class="spacer"></div>

	<div>
		<label>Classes</label>
		<input type="text" formControlName="classes" placeholder="… class1 class2" />
	</div>
</ng-container>
