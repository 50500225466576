/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
const PACKAGE_SYNCER_MODE = require('../../helpers/consts').PACKAGE_SYNCER_MODE;
const PACKAGE_SYNCER_IMAGE_SIZES = require('../../helpers/consts').PACKAGE_SYNCER_IMAGE_SIZES;
const GALLERY_UIMODE = require('../../helpers/consts').GALLERY_UIMODE;

var def = {
	hasExhibitOverView: { type: Boolean, default: true },
	webClientStartsGallerySlideShow: { type: Boolean, default: true },
	gallery: {
		openGalleryOnImages: { type: Boolean, default: false },
		startsSlideShow: { type: Boolean, default: false },
		slideShowSpeed: { type: Number, default: 5000 }, // in milliseconds
		uiMode: { type: String, enum: Object.values(GALLERY_UIMODE), default: 'tapToggle' },
		zoomEnabled: { type: Boolean, default: true },
	},
	zipStyle: {
		openSingle: { type: Boolean, default: false },
		openFirst: { type: Boolean, default: false },
		closeable: { type: Boolean, default: true },
		openAll: { type: Boolean, default: false },
	},
	audioPlayer: {
		inLine: { type: Boolean, default: false },
		autoPlay: { type: Boolean, default: false },
	},
	locations: {
		preventForceTrigger: { type: Boolean, default: false },
		combineLocations: { type: Boolean, default: true },
	},
	maintenance: {
		showDeviceInfo: { type: Boolean, default: false },
		vorlon: { type: Boolean, default: false },
	},
	notificationSounds: {
		articleLoading: { type: Boolean, default: false },
		articleLoaded: { type: Boolean, default: false },
		audioPlayerLoading: { type: Boolean, default: false },
	},
	keyPad: {
		combineCodeMaps: { type: Boolean, default: true },
	},
	navigationBar: {
		hideOnScroll: { type: Boolean, default: false },
	},
	navigation: {
		alwaysRestoreScroll: { type: Boolean, default: false },
	},
	packageSyncer: {
		autoDownload: { type: Boolean, default: false }, // deprechated
		mode: {
			type: String,
			enum: Object.values(PACKAGE_SYNCER_MODE),
			default: PACKAGE_SYNCER_MODE.all,
		},
		pkgSyncImgSizes: {
			type: String,
			enum: Object.values(PACKAGE_SYNCER_IMAGE_SIZES),
			default: PACKAGE_SYNCER_IMAGE_SIZES[2048].toString(),
		},
	},
	swipeNavigation: {
		enableHistorySwipe: { type: Boolean, default: true },
		disableSwipeInBrowser: { type: Boolean, default: true },
	},
	cookiePopup: {
		enableOverylayScreen: { type: Boolean, default: false },
	},
	tutorial: {
		showArTutorial: { type: Boolean, default: false },
	},
};
var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
