/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export const statusActions = {
	connect: () => ({
		type: statusActions.CONNECT,
	}),
	disconnect: () => ({
		type: statusActions.DISCONNECT,
	}),

	addPending: (id: string) => ({
		type: statusActions.ADD_PENDING,
		payload: id,
	}),

	removePending: (id: string) => ({
		type: statusActions.REMOVE_PENDING,
		payload: id,
	}),

	addUpload: () => ({
		type: statusActions.ADD_UPLOAD,
	}),

	removeUpload: () => ({
		type: statusActions.REMOVE_UPLOAD,
	}),

	CONNECT: 'CONNECT',
	DISCONNECT: 'DISCONNECT',
	PING: 'PING',
	ADD_PENDING: 'ADD_PENDING_REQUEST',
	REMOVE_PENDING: 'REMOVE_PENDING_REQUEST',
	ADD_UPLOAD: 'ADD_UPLOAD',
	REMOVE_UPLOAD: 'REMOVE_UPLOAD',
};
