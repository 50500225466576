/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import {startWith} from 'rxjs/operators';
import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'input-layer-exhibition',
	template: `
<div >
	<div *ngIf="layers && layers.length">
			<div class="columns is-mobile">
				<div class="column is-2"><label>layer</label></div>
				<div class="column"><label>Exhibition</label></div>
			</div>
			<div *ngFor="let layer of layers; let i = index" class="columns is-mobile">
				<div class="column is-2">{{ i }}:</div>
				<div class="column">
					<ng-select
						placeholder="... select an exhibition"
						[ngModelOptions]="{standalone: true}"
						[(ngModel)]="value[i]"
						(selected)="onLayerExhibitionChange()"
						[options]="exhibitionOptions"
						></ng-select>
				</div>
			</div>
		</div>
</div>
	`,
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLayerExhibitionComponent), multi: true }],
})
export class InputLayerExhibitionComponent extends CustomValueAccessor {
	@Input() layers = [];
	@Input() set exhibitions (exhibitions) {
		this.exhibitionOptions = [{label: '', value: null}, ...(exhibitions || []).map(({ _id, name }) => ({label: name, value: _id}))]
	}
	public exhibitionOptions = [];

	onLayerExhibitionChange() {
		this.value = [...this.value]
	}
	// get value() {
	// 	return this._value;
	// }

	// set value(value) {
	// 	this.set_value(value);
	// }
}
