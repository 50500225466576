
<div class="boxx-dashboard">
	<h1>Boxx Dashboard</h1>
	<div *ngFor="let org of boxxesDataGrouped">
		<h3>{{org.name}}</h3>
		<div class="boxx-dashboard__list">
			<div *ngFor="let boxx of org.boxxes">
				<div
					class="boxx-status"
					[ngClass]="boxx.state.status"
					[title]="boxx.state.status">
				</div>
				<div class="dashboard-box first-box">
					<div>
						<a [routerLink]="'/admin/boxx/edit/'+boxx._id" class="boxx-name">{{boxx.name}}</a>
						<div class="mac-address">{{boxx.macAddress}}</div>
					</div>
					<div>
						<div class="boxx-type">{{boxx.specificConfig.boxxType}}</div>
						<div class="hardware-model">{{boxx.hardware.model}}</div>
					</div>
					<div class="dashboard-comments" [attr.title]="boxx.comments">
						{{ boxx.comments }}
					</div>
				</div>
				<div class="dashboard-box">
					<label>Tbp</label>
					<div>{{boxx.specificConfig.talkBackPort}}</div>
				</div>
				<div class="dashboard-box">
					<label i18n>Load</label>
					<div>
						<span
							class="boxx-load"
							[ngClass]="{'boxx--on': boxx.state.load <= boxx.specificConfig.workloadThreshold}">
								{{boxx.state.load}}
						</span>
						/ {{boxx.specificConfig.workloadThreshold}}
					</div>
				</div>
				<div class="dashboard-box">
					<label>Stor</label>
					<div>…</div>
				</div>
				<div class="dashboard-box">
					<label>❤ Beat</label>
					<div class="duty">{{boxx.state.heartbeatDay}}<br />{{boxx.state.heartbeatTime}}</div>
				</div>
				<div class="dashboard-box">
					<label>Duty</label>
					<div class="duty">{{dateToTime(boxx?.config?.duty?.from)}}<br />{{dateToTime(boxx?.config?.duty?.to)}}</div>
				</div>
				<div class="dashboard-box">
					<label>QOS</label>
					<div class="qos">
						<span [ngClass]="{'boxx--on': boxx.config.enableQoS === true}">2</span>
						<span [ngClass]="{'boxx--on': boxx.config.enableQoS5 === true}">5</span>
						<span [ngClass]="{'boxx--on': boxx.config.enableQoSMesh === true}">M</span>
					</div>
				</div>
				<div class="dashboard-box">
					<label>Chan</label>
					<div>{{boxx.config.channel || '…'}} {{boxx.config.channel5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>Pwr</label>
					<div>{{boxx.config.tXDbm || '…'}} {{boxx.config.tXDbm5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>HTmode</label>
					<div class="htmode">{{boxx.config.htmode || '…'}} {{boxx.config.htmode5 || '…'}}</div>
				</div>
				<div class="dashboard-box">
					<label>SSID</label>
					<div class="duty">{{boxx.config.ssid}}<br />{{boxx.config.ssid5}}</div>
				</div>
			</div>
		</div>
	</div>
</div>
	