/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'loanerdevice-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class LoanerdeviceFormComponent {
	@Input() id = '';
	@Input() model: any;
	@Input() formGroup: FormGroup;

	public data: any;
}
