/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { schema } from '../../../models/logging/boxxEventSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';

export const boxxEventConfig = new ModelConfig({
	collectionName: 'BoxxEvent',
	apiEndpoint: 'boxxEvents',
	dependencies: [],
	sortModes: [
		{ name: 'timeStamp', objectPath: 'timeStamp' },
	],
	initialState: {
		byBoxx: {},
	},
	schema,
	clientPathPrefix: null,
});
