/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Directive,
	Input,
	ViewChild,
	TemplateRef,
	ViewContainerRef,
	Host,
	HostBinding,
	ChangeDetectorRef,
	Optional,
	SkipSelf,
	Self,
	forwardRef,
	Injector,
	ChangeDetectionStrategy,
} from '@angular/core';
import {
	FormGroup,
	FormControl,
	ControlContainer,
	FormControlName,
	NG_VALUE_ACCESSOR,
	NgControl,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import { uid } from '../../utils';
import { modelConfigs } from '../../modelConfigs';

@Component({
	selector: 'form-input',
	template: `
	<ng-container *ngIf="formGroup && formControlName" [formGroup]="formGroup">

		<input-disable
			#checkbox
			*ngIf="control && hasDisable"
			[disabled]="disabled"
			[control]="control"
			[defaultValue]="dataSourceSettings"></input-disable>

		<ng-content select="[slot=before]"></ng-content>

		<input-switch
			*ngIf="type === 'boolean'"
			[formControlName]="formControlName"
			[disabled]="control.disabled || disabled"
			[value]="value"
			[placeholder]="dataSourceSettings"
			(change)="value = $event.target.checked"></input-switch>

		<ng-content></ng-content>
		<ng-container *ngIf="type !== 'boolean'" [ngSwitch]="type">

			<input *ngSwitchCase="'number'"
				type="number"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[min]="min"
				[max]="max"
				[placeholder]="dataSourceSettings || placeholder"
				[(ngModel)]="value">

			<input *ngSwitchCase="'text'"
				type="text"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[placeholder]="placeholder"
				[(ngModel)]="value">

			<ng-select *ngSwitchCase="'enum'"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[(ngModel)]="value"
				[placeholder]="dataSourceSettings || placeholder"
				[options]="options"></ng-select>

			<input-text *ngSwitchCase="'textarea'"
				[disabled]="control.disabled || disabled"
				[minLength]="minLength"
				[maxLength]="maxLength"
				[formControlName]="formControlName"
				[placeholder]="placeholder"
				[(ngModel)]="value"></input-text>

			<input-time *ngSwitchCase="'date'"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[(ngModel)]="value"></input-time>

			<input type="text" *ngSwitchDefault
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[minlength]="minLength"
				[maxlength]="maxLength"
				[placeholder]="dataSourceSettings || placeholder"
				[(ngModel)]="value">

		</ng-container>

		<ng-content select="[slot=after]"></ng-content>

	</ng-container>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormInputComponent), multi: true }],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent extends CustomValueAccessor {
	@Input() hasDisable = false;
	@Input() disabled = false;
	@Input() placeholder: string;
	@Input() defaultValue = null;
	@Input() options = null;
	@Input() min: number;
	@Input() max: number;
	@Input() minLength: number;
	@Input() maxLength: number;
	@Input() blankEntry = false;
	@Input() dataSourceSettings: string;

	@Input() set type (t: any) {
		if (typeof t === 'function') {
			t = t.name;
		}

		this._type = `${t}`.toLowerCase();
	}

	get type() {
		return this._type;
	}

	@Input() set definition (def: any) {
		def = def || {};

		if (def.enum) {
			this.type = 'enum';

			this.options = this.options || def.enum.map(value => ({
				label: `${value}`,
				value: `${value}`,
			}));
		} else {
			this.type = def.type;
		}

		this.min = def.min;
		this.max = def.max;

		this.defaultValue = def.default === undefined ? null : def.default;

		this.changeDetectorRef.markForCheck();
	}

	private _modelItems: any[];

	@Input() set modelItems(items: any[]) {
		this._modelItems = items;
		this.type = 'enum';

		this.options = (items || []).map(item => {
			const label = this.collectionName
				? modelConfigs[this.collectionName].getItemTitle(item)
				: item.name;

			return { label, value: item._id };
		});
		if (this.blankEntry) this.options.unshift({label: '', value: null})
	}

	private _collectionName: string;
	@Input() set collectionName(value) {
		this._collectionName = value;

		this.modelItems = this._modelItems;
	}
	get collectionName() {
		return this._collectionName;
	}

	private fcn: FormControlName;

	@HostBinding('attr.type') private _type: string;

	private destroyed = false;
	public inputUid = uid();
	public get formGroup() {
		return this.fcn && this.fcn.control.parent;
	}
	public get formControlName() {
		return this.fcn && this.fcn.name;
	}
	public get control () {
		return this.fcn
			? this.fcn.control
			: this.formGroup && this.formGroup.get(this.formControlName) as FormControl;
	}

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private inj: Injector,
		public i18n: I18n,
	) {
		super(i18n);
		this.initOptions({ changeDetectorRef });

		(window as any).fg = this.formGroup;
	}

	ngAfterViewInit() {
		this.fcn = this.inj.get(NgControl);
		this.changeDetectorRef.detectChanges();
	}

	set_value(value) {
		value = this.fixValue(value);

		if (value !== this._value || (typeof value === 'object' && !!value)) {
			this._value = value;
			this.onChange(value);
			this.onTouched();
		}

		if (!this.destroyed) {
			this.changeDetectorRef.markForCheck();
		}
	}

	fixValue(value) {
		// convert value to string for ng-select
		if (this.type === 'enum' && value !== null) {
			value = `${value}`;
		}

		return value;
	}

	ngOnDestroy() {
		this.destroyed = true;
	}
}
