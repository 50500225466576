<header *ngIf="showHeader">
	<div
		class="extend-nav"
		[ngClass]="{
			active: showExtenderNav || lockExtenderNav,
			analytics: topPanelActive,
			staging: isStaging
		}"
		(mouseenter)="onExtenderNavEnter()"
		(mouseleave)="onExtenderNavLeave()">
		<ul class="admin-menu">
			<li *ngIf="(access$ | async).access.isAdmin">
				<a
					(click)="topPanelActive = false"
					routerLink="/admin">
					<div class="icon-settings_L"></div>
					<ng-container i18n>Admin</ng-container>
				</a>
			</li>
			<li *ngIf="hasAnalytics">
				<a
					(click)="topPanelActive = true; analytics = true; dashboard = false;">
					<div class="icon-statistics"></div>
					<ng-container i18n>Analytics</ng-container>
				</a>
			</li>
			<li *ngIf="(access$ | async).access.isAdmin">
				<a
					(click)="topPanelActive = true; dashboard = true; analytics = false;">
					<div class="icon-details-pane"></div>
					<ng-container i18n>Dashboard</ng-container>
				</a>
			</li>
		</ul>

		<div class="user-menu-container">
			<div class="login-user" (click)="toggleAccountMenu($event)">
				{{loggedInUserInitials}}
			</div>
			<ul [ngClass]="{
				show: toggleAccountSettings,
				hide: !toggleAccountSettings
			}" id="accountSettings">
				<li> <a href="https://docs.google.com/document/d/1uKf7zNd06uyK3pPQyyhWxjLIWwj-RvDwzvq7exEl2wo" target="_blank"><div class="icon-Info-2"></div> <span i18n>Help</span></a></li>
				<li> <a routerLink="/account/profile" (click)="topPanelActive = false"> <div class="icon-id-card_L"></div> <span i18n>Account</span></a></li>
				<li> <a (click)="logout()"> <div class="icon-logout-rounded_L"></div> <span i18n>Logout</span></a></li>
			</ul>
		</div>
		<span (click)="onExtenderNavClick()" class="icon-expand-arrow_S"></span>
	</div>

	<div class="extend-btns">
		<div class="extend-btns__left"></div>
		<div
			class="extend-btns__center"
			[ngClass]="{
				active: lockExtenderNav,
				staging: isStaging
			}"
			(click)="onExtenderNavClick()"><span class="icon-expand-arrow_S"></span></div>
		<div class="extend-btns__right">
			<nav-context></nav-context>
		</div>
	</div>

	<div class="header__nav">
		<ul #leftMenu>
			<li
			*ngFor="let navItem of navList.left; let i = index;"
			(mousedown)="clearFilterAndNavigateToCollection(navItem)"
			[ngClass]="{
				active: isActive(navItem.model.name),
				hidden: isHidden(i)
			}"
			[attr.title]="navItem.title">
					<div class="{{navItem.model.icon}}" ></div>
					<span>{{navItem.name || navItem.model.namePlural}}</span>
			</li>
			<li
				*ngIf="maxVisibleMenuItems < navList.left.length - 1"
				(click)="showHiddenItems = !showHiddenItems">
				<div class="icon-more-filled"></div>
				<span i18n>more</span>
				<ul class="hiddenMenuItems" [ngClass]="{ active: showHiddenItems }">
					<li
						*ngFor="let navItem of navList.left; let i = index;"
						(mousedown)="clearFilterAndNavigateToCollection(navItem); showHiddenItems = false;"
						[ngClass]="{
							active: isActive(navItem.model.name),
							hidden: !isHidden(i)
						}"
						[attr.title]="navItem.title">
						<div class="{{navItem.model.icon}}" ></div>
						<span>{{navItem.name || navItem.model.namePlural}}</span>
					</li>
				</ul>
			</li>
		</ul>
		<ul *ngIf="(access$ | async).access.isAdmin || (access$ | async).access.isOrgAdmin">
			<li
			*ngFor="let navItem of navList.right"
			(mousedown)="clearFilterAndNavigateToCollection(navItem)"
			[ngClass]="{active: isActive(navItem.model.name)}">
				<div class="{{navItem.model.icon}}" ></div> <span>{{navItem.name || navItem.model.namePlural}}</span>
			</li>
		</ul>
	</div>
<div class="offline-notice" [attr.aria-disabled]="!(status$ | async).online" *ngIf="!(status$ | async).online">
	<span class="warn" i18n>Your connection to the server was lost. You may not make changes while you are offline.</span>
</div>
</header>
<messages-component [messages]="messages$ | async"></messages-component>
<maintenance-component
	*ngIf="(cmssettings$|async)?.maintenanceMode && !(access$ | async).access.isAdmin"
	[message]="(cmssettings$|async)?.maintenanceModeMessage"></maintenance-component>

<div
	class="main-top"
	[ngClass]="{active: topPanelActive}">
	<div class="top-extend" (click)="topPanelActive = false">
		<span class="icon-expand-arrow_S"></span>
	</div>
	<messages-component [messages]="messages$ | async"></messages-component>
	<visitor-analytics *ngIf="analytics"></visitor-analytics>
	<admin-dashboard *ngIf="dashboard"></admin-dashboard>
</div>

<div class="content">
	<router-outlet></router-outlet>
</div>
<footer class="footer">
	<a href="https://www.shoutrlabs.com/datenschutz/?lang=en" i18n>Privacy</a>
	| <a href="https://www.shoutrlabs.com/impressum/?lang=en" i18n>Imprint</a>
</footer>

<div modal-root></div>
