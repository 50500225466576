/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'memory-game-form',
	template: `
		<div class="memory-game-form">
			<div [formGroup]="formGroup">
				<input-media-list
					formControlName="mediaObjectsForGame"
					[mediaObjects]="mediaObjects"
					[organisationID]="organisationID"
					type="audio,video,image"
				></input-media-list>
		</div>
	`,
})
export class MemoryGameFormComponent {
	@Input() formGroup: FormGroup;
	@Input() organisationID;
	@Input() mediaObjects = {};


	constructor() {}

}
