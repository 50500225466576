/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
const getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition' },
	// user input
	name: { type: String, required: true, label: 'Name' },
	type: {
		type: String,
		enum: ['audio', 'video', 'image', 'misc', '3DModel'],
		isField: true,
		required: true,
		label: 'Type (automatically set)',
	},
	tags: [{ type: String }],
	customFields: { type: Object },
	languageContent: {
		type: [require('./languageMediaObjectSchema').definition],
		required: true,
		label: 'Language content',
	},
	triggers: [{ locationID: { type: Schema.ObjectId, ref: 'Location' } }],
	usedIn: [{ type: Schema.ObjectId, ref: 'Article' }],
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'MediaObject',
	schema: ModelSchema,
	labels: getLabels(schemaDefinition),
	definition: schemaDefinition,
};
