/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';

import { LocationFormComponent } from './components/location-form.component';


@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		LocationFormComponent,
	],
	declarations: [
		LocationFormComponent,
	],
	entryComponents: [
		LocationFormComponent,
	],
})
export class LocationModule { }
