/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { messageActions } from '../../core/_messages/actions';
import { CustomValueAccessor } from '../CustomValueAccessor';


@Component({
	selector: 'input-tags',
	template: `
<div class="input-widget">
	<div class="tags">
		<div *ngFor="let ltag of value">{{ltag}} <span class="icon-clear-field_S" (click)="remove(ltag)"></span></div>
		<input
			[type]="type"
			[attr.min]="min"
			[attr.max]="max"
			[attr.step]="step"
			[attr.placeholder]="placeholder"
			[(ngModel)]="tag"
			(change)="propagateChange()"
			(input)="onInput()"
			(keydown)="onKeyDown($event)"
			(blur)="add()"/>
	</div>
	<span
		(click)="add(); $event.preventDefault()"
		class="icon-add"
		[title]="addButtonTitle"
		[ngClass]="{disable: invalid}"></span>
</div>
	`,
	styleUrls: ['./input-tags.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTagsComponent), multi: true }],
})
export class InputTagsComponent extends CustomValueAccessor {
	@Input() type;
	@Input() min;
	@Input() max;
	@Input() step;
	@Input() placeholder;
	@Input() exclude = { values: [], message: null };
	@Output() change = new EventEmitter(false);

	private _tag: number | string = null;
	public set tag(value: string | number) {
		value = typeof value === 'string' ? value.trim() : value;

		this._tag = this.type === 'number' && typeof value !== 'number'
			? parseFloat(value)
			: value;
	}
	public get tag() {
		return this._tag;
	}
	public invalid = true;
	public addButtonTitle = this.i18n('The input is empty');
	private lastDeleteTimestamp;
	private deleteCoolDown = 250;

	constructor (private store: Store<any>, public i18n: I18n) {
		super(i18n);
		this.initOptions();
	}

	ngOnInit() {
		this.type = this.type || 'text';
		this.placeholder = this.placeholder || this.i18n('… your tag');

		if (isNaN(parseFloat(this.min))) { this.min = ''; }
		if (isNaN(parseFloat(this.max))) { this.max = ''; }
		if (isNaN(parseFloat(this.step))) { this.step = 1; }

		this.value = this.value || [];
	}
	onInput() {
		const invalidReason = this.getInvalidReason();

		this.invalid = !!invalidReason;
		this.addButtonTitle = invalidReason || this.i18n('Click here, press enter or insert a space to add this tag');
	}
	onKeyDown($event) {
		// If it's a number input prevent the following keys as input: "e", "=", ",", "-", "."
		if (this.type === 'number' && [69, 187, 188, 189, 190].includes($event.keyCode)) {
			$event.preventDefault();
		}
		if (($event.key === 'Enter') || ($event.keyCode === 188)) {
			this.add();
		} else if ($event.keyCode === 8 && !$event.target.value.length) {
			if (!this.lastDeleteTimestamp || Date.now() > this.lastDeleteTimestamp + this.deleteCoolDown) {
				this.value.pop();
				this.lastDeleteTimestamp = Date.now();
			}
		}
	}
	getInvalidReason() {
		if (!this.tag && this.tag !== 0) {
			return 'The input is empty';
		}

		if (this.value.includes(this.tag)) {
			return `"${this.tag}" ${this.i18n('is already in the list')}`;
		}

		if (Array.from(this.exclude.values).includes(this.tag)) {
			return this.exclude.message;
		}

		return null;
	}
	add() {
		if (!this.value) this.value = [];

		if (!this.tag && this.tag !== 0) {
			return;
		}

		const invalidReason = this.getInvalidReason();

		if (invalidReason) {
			this.store.dispatch(messageActions.error(invalidReason));
		} else {
			this.value = [...this.value, this.tag];
			this.tag = null;
		}
		this.change.emit(true);
	}
	remove(tag) {
		this.value = this.value.filter(item => item !== tag);
		this.change.emit(true);
	}
}
