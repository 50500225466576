<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [preventChange]=true [form]="formGroup" [id]="id"></overwrite-context-container>

	<div class="box-3-1 background-wrapper">
		<div> <!-- first child 2/3 -->
			<div>
				<label i18n>Exhibition</label>
				<div *ngIf="data.isLandingExhibit">
					{{data.modelExhibition.name}} <i i18n>[Start article]</i>
				</div>
				<form-input
					type="enum"
					*ngIf="data.exhibitions.length && !data.isLandingExhibit"
					formControlName="exhibitionID"
					i18n-placeholder
					placeholder="… select exhibition"
					[modelItems]="data.exhibitions">
				</form-input>
				<div *ngIf="!data.exhibitions.length">
					<ng-container i18n>… missing exhibitions</ng-container>
					<button (click)="createExhibitionModal.open()" type="button" i18n> <div class="icon-add"></div> add exhibition </button>
				</div>
			</div>
			<div>
				<input-article formControlName="articleID"></input-article>
			</div>
		</div>

		<div> <!-- last child 1/3 -->
			<div class="switches">
				<div>
					<label i18n>public</label>
					<input
						[attr.disabled]="formGroup.value.isLive && data.isLandingExhibit || null"
						class="sl-form__switch is-live"
						id="isLiveExhibit"
						type="checkbox"
						[ngClass]="{'is-live--unpublished-changes': !data.model?.isUpToDate}"
						formControlName="isLive">
					<label
						isLive [item]="data.model"
						for="isLiveExhibit"></label>
				</div>
			</div>
			<div>
				<label i18n>Exhibit Codes</label>
				<input-tags
					type="number"
					min="1"
					i18n-placeholder
					placeholder="… enter a number"
					[exclude]="{ values: data.allCodes, message: 'This code is already being used for another exhibit' }"
					formControlName="codes"></input-tags>
			</div>
		</div>
	</div>

	<label class="large">Locations</label>
	<input-locations
		[organisationID]="data.organisationID"
		[access]="data.access"
		[hasTrigger]="true"
		formControlName="triggers"></input-locations>
	<div class="add-new-location" (click)="createLocationModal.open()">
		<span class="icon-add"></span>
		<ng-container i18n>Create new location</ng-container>
	</div>

	<div class="spacer"></div>

	<div>
		<label i18n>Tags</label>
		<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
	</div>

	<form-modal
		#createExhibitionModal
		collectionName="Exhibition"
		(saveEvent)="onCreateExhibition($event)">
		<h2 i18n>Create a new exhibition</h2>
	</form-modal>
	<form-modal #createLocationModal collectionName="Location">
		<h2 i18n>Create a new location</h2>
	</form-modal>
</ng-container>
