/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Action } from '@ngrx/store';

// Define all actions of what can be send to the websocket and
// what can be recieved from the websocket
// `CREATE_COLLECTION_ITEM` is the request action
// `COLLECTION_ITEM_CREATED` is the recieve action;

export const MESSAGE = 'message';
export const NOT_AUTHENTICATED = 'not authenticated';

export const CREATE_COLLECTION_ITEM = 'create collection item';
export const COLLECTION_ITEM_CREATED = 'collection item created';

export const GET_COLLECTION_LIST = 'get collection list';
export const COLLECTION_LIST_ITEM_UPDATED = 'collection list item updated';
export const COLLECTION_LIST_UPDATED = 'collection list updated';
export const LEAVE_COLLECTION_LIST = 'leave collection list';

export const GET_COLLECTION_FULL_LIST = 'get collection full list';
export const COLLECTION_FULL_LIST_ITEM_UPDATED = 'collection full list item updated';
export const COLLECTION_FULL_LIST_UPDATED = 'collection full list updated';
export const LEAVE_COLLECTION_FULL_LIST = 'leave collection full list';

export const GET_COLLECTION_ITEM = 'get collection item';
export const COLLECTION_ITEM_UPDATED = 'collection item updated';
export const LEAVE_COLLECTION_ITEM = 'leave collection item';

export const UPDATE_COLLECTION_ITEM = 'update collection item';

export const DELETE_COLLECTION_ITEM = 'delete collection item';
export const COLLECTION_ITEM_DELETED = 'collection item deleted';

export const GET_VERSION_LIST = 'get version list';
export const VERSION_LIST_UPDATED = 'version list updated';
export const GET_VERSION_OF_ITEM = 'get version of item';
export const VERSION_OF_ITEM_UPDATED = 'version of item updated';
export const REQUEST_REVERT_TO_VERSION_OF_ITEM = 'request revert to version of item';
export const REVERT_TO_VERSION_OF_ITEM_DONE = 'revert to version of item done';

export const CURRENT_USER_DATA_UPDATED = 'current user data updated';
export const GET_DEPENDENCY_MAPS_DATA = 'get dependency maps data';
export const DEPENDENCY_MAPS_DATA_UPDATED = 'dependency maps data updated';

export const PREDICT_MAP_DOWNLOAD_SIZE = 'predict map download size'
export const MAP_DOWNLOAD_SIZE_PREDICTED = 'map download size predicted';
export const START_MAP_DOWNLOAD = 'start map download';
export const MAP_DOWNLOAD_COMPLETED = 'map download completed';

export const GET_MAP_INFORMATION = 'get map information';
export const MAP_INFORMATION_UPDATED = 'map information updated';

export const GET_ANALYTICS_DATA = 'get analytics data';
export const ANALYTICS_DATA_UPDATED = 'update analytics data';
export const ANALYTICS_DATA_DOWNLOAD = 'download analytics data';

export const GET_API_KEY = 'get api key';
export const API_KEY_UPDATED = 'api key updated';

export const PUBLISH_CHANGES = 'publish changes';

export const RECREATE_THUMB_NAIL = 'recreate thumbnail';
export class CreateCollectionItem implements Action {
	readonly type = CREATE_COLLECTION_ITEM;
	constructor(public payload: {collectionName: string, data: any}) {}
}

export class CollectionItemCreated implements Action {
	readonly type = COLLECTION_ITEM_CREATED;
	constructor(public payload: {collectionName: string, data: any}) {}
}

export class GetCollectionList implements Action {
	readonly type = GET_COLLECTION_LIST;
	constructor(public payload: { collectionName: string }) {}
}
export class CollectionListUpdated implements Action {
	readonly type = COLLECTION_LIST_UPDATED;
	constructor(public payload: { collectionName: string, data: any[] }) {}
}
export class CollectionListItemUpdated implements Action {
	readonly type = COLLECTION_LIST_ITEM_UPDATED;
	constructor(public payload: { collectionName: string, data: any }) {}
}
export class LeaveCollectionList implements Action {
	readonly type = LEAVE_COLLECTION_LIST;
	constructor(public payload: { collectionName: string }) {}
}

export class GetCollectionFullList implements Action {
	readonly type = GET_COLLECTION_FULL_LIST;
	constructor(public payload: { collectionName: string, options?: any }) {}
}
export class CollectionFullListUpdated implements Action {
	readonly type = COLLECTION_FULL_LIST_UPDATED;
	constructor(public payload: { collectionName: string, data: any[] }) {}
}
export class CollectionFullListItemUpdated implements Action {
	readonly type = COLLECTION_FULL_LIST_ITEM_UPDATED;
	constructor(public payload: { collectionName: string, data: any }) {}
}
export class LeaveCollectionFullList implements Action {
	readonly type = LEAVE_COLLECTION_FULL_LIST;
	constructor(public payload: { collectionName: string }) {}
}

export class GetCollectionItem implements Action {
	readonly type = GET_COLLECTION_ITEM;
	constructor(public payload: { collectionName: string, id: string, join?: boolean}) {}
}

export class LeaveCollectionItem implements Action {
	readonly type = LEAVE_COLLECTION_ITEM;
	constructor(public payload: { collectionName: string, id: string }) {}
}

export class UpdateCollectionItem implements Action {
	readonly type = UPDATE_COLLECTION_ITEM;
	constructor(public payload: { collectionName: string, id: string, data: any }) {}
}

export class CollectionItemUpdated implements Action {
	readonly type = COLLECTION_ITEM_UPDATED;
	constructor(public payload: { collectionName: string, data: any }) {}
}

export class DeleteCollectionItem implements Action {
	readonly type = DELETE_COLLECTION_ITEM;
	constructor(public payload: { collectionName: string, id: string }) {}
}

export class CollectionItemDeleted implements Action {
	readonly type = COLLECTION_ITEM_DELETED;
	constructor(public payload: { collectionName: string, data: string }) {}
}

export class GetVersionList implements Action {
	readonly type = GET_VERSION_LIST;
	constructor(public payload: { collectionName: string, id: string }) {}
}

export class VersionListUpdated implements Action {
	readonly type = VERSION_LIST_UPDATED;
	constructor(public payload: { collectionName: string, data: any }) {}
}

export class GetVersionOfItem implements Action {
	readonly type = GET_VERSION_OF_ITEM;
	constructor(public payload: { collectionName: string, id: string, versionNumber: number }) {}
}

export class VersionOfItemUpdated implements Action {
	readonly type = VERSION_OF_ITEM_UPDATED;
	constructor(public payload: { collectionName: string, versionNumber: number, data: any }) {}
}

export class RequestRevertToVersionOfItem implements Action {
	readonly type = REQUEST_REVERT_TO_VERSION_OF_ITEM;
	constructor(public payload: { collectionName: string, id: string, versionNumber: number }) {}
}

export class CurrentUserDataUpdated implements Action {
	readonly type = CURRENT_USER_DATA_UPDATED;
	constructor(public payload: any) {}
}

export class GetDependencyMapsData implements Action {
	readonly type = GET_DEPENDENCY_MAPS_DATA;
	constructor() {}
}

export class DependencyMapsDataUpdated implements Action {
	readonly type = DEPENDENCY_MAPS_DATA_UPDATED;
	constructor(public payload: any) {}
}

export class PredictMapDownloadSize implements Action {
	readonly type = PREDICT_MAP_DOWNLOAD_SIZE;
	constructor(public payload: { coords: number[][], maxZoom: number }) {}
}

export class MapDownloadSizePredicted implements Action {
	readonly type = MAP_DOWNLOAD_SIZE_PREDICTED;
	constructor(public payload: { downloadSize: { minimalTiles, allTiles }, queuePosition: number }) {}
}

export class StartMapDownload implements Action {
	readonly type = START_MAP_DOWNLOAD;
	constructor(public payload: { coordinates: object[], maxZoom: number, articleId: string }) {}
}

export class MapDownloadCompleted implements Action {
	readonly type = MAP_DOWNLOAD_COMPLETED;
	constructor(public payload: any) {}
}


export class GetAnalyticsData implements Action {
	readonly type = GET_ANALYTICS_DATA;
	constructor(public payload: { organisationID: string, exhibitionID: string, dateFrom: string, dateTo: string, analyticsType: string, format?: string }) {}
}

export class AnalyticsDataUpdated implements Action {
	readonly type = ANALYTICS_DATA_UPDATED;
	constructor(public payload: any) {} // FIXME set proper types here
}

export class AnalyticsDataDownload implements Action {
	readonly type = ANALYTICS_DATA_DOWNLOAD;
	constructor(public payload: any) {} // FIXME set proper types here
}

export class GetApiKey implements Action {
	readonly type = GET_API_KEY;
	constructor() {}
}

export class ApiKeyUpdated implements Action {
	readonly type = API_KEY_UPDATED;
	constructor(public payload: {apiKey: string}) {}
}
export class GetMapInformation implements Action {
	readonly type = GET_MAP_INFORMATION;
	constructor(public payload: {coordinates: object[], maxZoom: number}) {}
}

export class MapInformationUpdated implements Action {
	readonly type = MAP_INFORMATION_UPDATED;
	constructor(public payload: {queueSize: number, tileCount: number}) {}
}

export class RecreateThumbNail implements Action {
	readonly type = RECREATE_THUMB_NAIL;
	constructor(public payload: {mediaObjectID: string, languageItem: object}) {}
}

export class PublishChanges implements Action {
	readonly type = PUBLISH_CHANGES;
	constructor(public payload: { collectionName: string, id: string }) {}
}
