
	<style>
:host(.ng-invalid) >>> .media-files-list {
	border: 1px solid #CD0026;
}
	</style>

	<div class="media-files-list-wrapper">
		<div class="media-files-list">
			<div *ngIf="languageItem?.threeD" [ngClass]="{invalid: missingLanguageOptions}" class="media-files-item">
				<div class="item-body">

					<media-viewer
						[type]="languageItem.thumbSource ? 'image' : null"
						[source]="getThumbUrl(languageItem)"
						[fullSize]="true"
						title="Click to view the 3D model"
						(click)="openThreeDViewer(languageItem)"
						class="three-d-thumb">
						<p>Preview image is missing</p>
					</media-viewer>

					<br>
					<div class="file-row">
						<div>
							<label> 3D Model File </label>
							<div>{{ languageItem.threeD.resourceMap[languageItem.source] }}</div>
						</div>
						<input-file
							(fileChange)="onFileChange($event)"
							[iconized]="true"
							[(ngModel)]="languageItem.source"
							[file]="languageItem.file"
							[type]="type">

						</input-file>
					</div>

					<div class="file-row">
						<div>
							<label>Preview Image</label>
						</div>
						<input-file
							[iconized]="true"
							(fileChange)="onPreviewChange($event)"
							[(ngModel)]="languageItem.thumbSource"
							type="image">
						</input-file>
					</div>

					<!-- 3D inputs -->
					<div class="file-row" *ngIf="languageItem.threeD.format === 'obj'">
						<div>
							<label>Material File</label>
							<div>{{ languageItem.threeD.resourceMap[languageItem.threeD.material] }}</div>
						</div>
						<input-file [iconized]="true" (fileChange)="onMtlChange($event)" accept=".mtl,.mat">
							<div slot="menu">
								<div (click)="onMtlRemove()">
									<span class="icon-delete_M"></span>
									Remove
								</div>
							</div>
						</input-file>
					</div>

					<div class="texture-management">
						<file-dropzone
							accept="image/*"
							multiple
							(fileDrop)="addTexture($event.event)">
							Upload new texture files
						</file-dropzone>
						<label>Textures</label>
						<ng-container *ngFor="let texture of languageItem.threeD?.textures; let textureIndex = index;">
							<div class="file-row">

								<div>{{ texture.name || languageItem.threeD.resourceMap[texture] }}</div>
								<input-file
									[iconized]="true"
									[canUpdate]="false"
									(fileChange)="onTextureUpload($event)"
									[(ngModel)]="languageItem.threeD.textures[textureIndex]"
									[file]="texture"
									type="image">
									<div slot="menu">
										<div (click)="onTextureRemove(texture)">
											<span class="icon-delete_M"></span>
											Remove
										</div>
									</div>
								</input-file>
							</div>
						</ng-container>

						<label class="add-texture-btn">
							<div class="button button--blue-ghost">Add Texture</div>
							<input (change)="addTexture($event)" accept="image/*" type="file" multiple>
						</label>
					</div>

					<div class="spacer"> </div>

					<label>Viewer options</label>
					<input-json [(ngModel)]="languageItem.threeD.viewerOptions" (input)="propagateChange()"></input-json>

				</div>
			</div>

			<div *ngFor="let languageItem of value" class="media-files-item">
				<div class="item-body">
					<div>
						{{languageIso2name[languageItem.languageISO]}}
					</div>

					<div>
						<label>User-visible Title</label>
						<input (input)="propagateChange()" type="text" placeholder="... title" [(ngModel)]="languageItem.title">
					</div>
					<div>
						<label>Description</label>
						<input-text [allowModal]="true" cols="30" rows="4" placeholder="... description" (input)="propagateChange()" [(ngModel)]="languageItem.description"></input-text>
					</div>
					<div>
						<label>ALT Text</label>
						<input-text [allowModal]="true" cols="30" rows="4" placeholder="... description" (input)="propagateChange()" [(ngModel)]="languageItem.altText"></input-text>
					</div>
					<div>
						<input-custom-fields [(ngModel)]="languageItem.customFields" [fields]="customFields"></input-custom-fields>
					</div>
				</div>
			</div>
		</div>
	</div>

	<modal-component #threeDModal (close)="viewThreeD = null" [fullSize]="true" [clean]="true">
		<media-viewer
			*ngIf="!!viewThreeD && threeDModal.isOpen()"
			type="3DModel"
			(screenshot)="onScreenshot($event)"
			(viewer3dParams)="onViewer3dParams($event)"
			[source]="viewThreeD"
			[fullSize]="true"></media-viewer>
	</modal-component>
