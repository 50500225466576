/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component } from '@angular/core';

@Component({
	template: `
	<div class="sl-listview__wrapper">

	<nav>
		<a routerLink="/settings/organisation" class="box"> Organisations </a>
		<a routerLink="/settings/user" class="box"> User </a>
	</nav>

	<main>
		<h1>Settings</h1>
		Please select a category on the left.
	</main>

</div>
`,
})
export class SettingsComponent {}

