<div class="sl-form__footer" *ngIf="model && model.collectionName !== 'Survey'">
	<div>
		<button-confirm-delete
			*ngIf="id && !versionIdHuman"
			[id]="id"
			[collectionName]="model.collectionName"
			[disabled]="data?.isLandingExhibit || formGroup?.value.immortal"
			(confirm)="formGroup.markAsPristine(); deleteItem(model.collectionName, id)"
			(close)="close()"
		></button-confirm-delete>
		<button class="button button--red" *ngIf="versionIdHuman" (click)="save({ revertVersion: true, versionNumber: data.model.changeLog.version })" i18n>
			Revert to version {{ versionIdHuman }}
		</button>

		<ng-content></ng-content>
	</div>

	<div *ngIf="id && data.model?.changeLog">
		<div
			(click)="changeLogModal.open(); changeLogContainer.loadVersions(model.collectionName, id)"
			class="change-log"
		>
			<span *ngIf="versionIdHuman" i18n>other Versions</span>
			<span *ngIf="!versionIdHuman" i18n> Version {{ data.model.changeLog.version }} </span>
		</div>
		<modal-component #changeLogModal>
			<change-log
				#changeLogContainer
				(close)="changeLogModal.close()"
				[id]="id"
				[collectionName]="model.collectionName"
				[changeLog]="data.model.changeLog"
			></change-log>
		</modal-component>
	</div>

	<ng-content select="footer"></ng-content>
	<div>
		<a
			*ngIf="id &&
				(model.name !== 'organisation' || data.access.isAdmin)"
			[routerLink]="
				formGroup?.dirty
					? null
					: model.clientPathPrefix +
					  '/' +
					  model.name +
					  '/create/' +
					  id +
					  (versionIdHuman ? '/version/' + versionId : '')
			"
		>
			<button
				type="button"
				[attr.title]="
					formGroup?.dirty ? 'Save your changes first' : 'Copy these values into a new ' + model?.name
				"
				[attr.disabled]="data?.quizArticle || (formGroup?.dirty || null)"
				class="button--white-ghost"
				i18n
			>
				Clone
			</button>
		</a>

		<button
			*ngIf="model.publishable && !versionIdHuman"
			type="button"
			[attr.disabled]="!model.getPublishEnabled(data.model, data) || null"
			(click)="onPublishClick($event)"
			class="publish-button button--white-ghost"
			i18n
		>
			Publish changes
		</button>
		<button
			*ngIf="!versionIdHuman"
			class="button--white-ghost"
			[ngClass]="{ 'button--disabled': !(formGroup?.valid && formGroup.dirty) || uploading }"
			[attr.title]="invalidInputsText() || 'Shortcut: Ctrl+S'"
			(click)="popupInvalidInpusText(); save()"
		>
			<ng-container *ngIf="formGroup?.dirty; else elseBlock" i18n>Save changes</ng-container>
			<ng-template #elseBlock i18n>Save</ng-template>
		</button>
	</div>
</div>
