/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { currentuserActions } from '../../_currentuser/actions';
import * as CollectionActions from '../../socket.actions';
import { exhibitionConfig } from '../../_exhibitions/config';
import { organisationConfig } from '../../_organisations/config';

@Injectable()
export class NavContextEffects {
	constructor(private actions$: Actions) {}
}

