
<div class="visitor-analytics">
	<analytics-control
		[organisationOptions]="organisationOptions$ | async"
		[exhibitionOptions]="exhibitionOptions$ | async"
		[datasourceOptions]="datasourceOptions$ | async"
		[siteId]="currentSiteId"
		[isRoot]="isRoot$ | async"
		(change)="onChange($event)"></analytics-control>
	<div class="visitor-analytics__body">
		<analytics-visits-graph
			*ngIf="isGraphType()"
			[timeSeriesData]="(analytics$ | async)[analyticsType]"
			[yAxisLabelText]="graphTypesLabels[analyticsType]"></analytics-visits-graph>
		<analytics-visits-graph
			*ngIf="analyticsType == analyticsTypes.googlePlayStoreInstalls"
			[timeSeriesData]="(analytics$ | async)[analyticsType]"
			yAxisLabelText="Active Device Installs"></analytics-visits-graph>
		<analytics-devices
			*ngIf="analyticsType == analyticsTypes.deviceModel"
			[data]="(analytics$ | async).deviceModel">deviceModel</analytics-devices>
		<analytics-times
			*ngIf="analyticsType == analyticsTypes.visitsPerTime"
			[data]="(analytics$ | async).visitsPerTime">visitsPerTime</analytics-times>
		<analytics-pages
			*ngIf="analyticsType == analyticsTypes.pageTitles"
			[data]="(analytics$ | async).pageTitles">pageTitles</analytics-pages>
	</div>
</div>
	