/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';

import { schema } from '../../../models/boxx/boxxSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { BoxxFormComponent } from './components/boxx-form.component';
import { datasourceConfig } from '../_datasources/config';
import { boxxStateConfig } from '../_boxxState/config';
import { boxxEventConfig } from '../_boxxEvents/config';

import { actions } from '../actions';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';

const loadAverageRegEx = /load average: \d+[\.,]\d+, (\d+[\.,]\d+), \d+[\.,]\d+/

export const boxxConfig = new ModelConfig({
	collectionName: 'Boxx',
	namePlural: 'boxxes',
	apiEndpoint: `boxxes`,
	clientPathPrefix: '/admin',
	icon: '',

	dependencies: [
		{ name: 'DataSource', inList: true, full: true },
		{ name: 'BoxxState', inList: true, full: true, options: { select: 'organisationID boxxID lastHeartbeat lastUpdate uptime status' }  },
		{ name: 'Exhibit', inList: false},
	],

	schema,

	formComponent: BoxxFormComponent,
});

Object.assign(boxxConfig, {
	getFormData$(store, id, versionId?) {
		if (id) store.dispatch(actions.filterList('BoxxEvent', 50, 0, { boxxID: id }));
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(selectors.getSortedListForOrg$(pluckDistinct(store, 'models', datasourceConfig.collectionName), store), 'list'),
			pluckDistinct(store, 'models', boxxStateConfig.collectionName, 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'models', boxxEventConfig.collectionName).pipe(map(be => be.byBoxx[id])),
			pluckDistinct(store, 'models', boxxConfig.collectionName, 'entities').pipe(
				map(entities => Object.values(entities)
					.reduce((acc: any, ent: any) => [...acc, ent.name], [])
				)
			),
			(model: any, datasources, boxxStateList: any, boxxEvents: any, boxxNames: Array<string>) => {
				if (!model.name && boxxNames.length) {
					const boxxNameRegEx = /boxx_(\d+)/
					const names = boxxNames
						.filter(name => boxxNameRegEx.test(name))
						.map(name => parseInt(name.match(boxxNameRegEx)[1], 10));
					names.sort((a, b) => b - a);
					model.name = `boxx_${names[0] + 1}`;
				}
				const boxxState = boxxStateList.find(_boxxState => _boxxState.boxxID === model._id);

				// FIXME edit ng-select so it accepts number as an input value
				if (typeof model.overWriteExhibitionConfig.channel === 'number') {
					model.overWriteExhibitionConfig.channel = `${model.overWriteExhibitionConfig.channel}`
				}
				if (typeof model.overWriteExhibitionConfig.channel5 === 'number') {
					model.overWriteExhibitionConfig.channel5 = `${model.overWriteExhibitionConfig.channel5}`
				}

				return {
					model,
					datasources,
					boxxStatus: boxxState ? boxxState.status : 'undefined',
					boxxState,
					boxxEvents,
				};
			},
		);
	},

	getListData$(store) {
		return observableCombineLatest(
			selectors.getSortedListForOrg$(pluckDistinct(store, 'models', this.collectionName), store).pipe(map(data => {
				data.list = data.list.map(item => Object.assign(
					{ type: item.specificConfig.boxxType },
					item,
				));

				return data;
			})),
			pluckDistinct(store, 'models', boxxStateConfig.collectionName, 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'models', datasourceConfig.collectionName).pipe(map(selectors.getEntities)),
			(data: any, boxxStates: any, datasources: any) => {
				// set SSID to display
				data.list.forEach(item => {
					const datasourceConfig = (datasources[item.dataSourceID] ? datasources[item.dataSourceID].boxxesConfig : {});

					item.ssid = item.overWriteExhibitionConfig.ssid || datasourceConfig.ssid || '-';
					item.ssid5 = item.overWriteExhibitionConfig.ssid5 || datasourceConfig.ssid5 || '-';
					item.channel = item.overWriteExhibitionConfig.channel || datasourceConfig.channel || '-';
					item.channel5 = item.overWriteExhibitionConfig.channel5 || datasourceConfig.channel5 || '-';
				});


				return Object.assign(
					data,
					{ boxxStates: boxxStates.reduce(
						(acc, {boxxID, status, uptime, lastUpdate}) => {
							const match = uptime ? loadAverageRegEx.exec(uptime) : undefined;

							lastUpdate = lastUpdate ? new Date(lastUpdate) : new Date('1900-01-01');
							const date = new Date();
							let update;

							if (date.getTime() - lastUpdate.getTime() < 24 * 60 * 60 * 1000) {
								update = lastUpdate.toTimeString().substring(0, 8);
							} else {
								update = lastUpdate.toISOString().substring(0, 10);
							}

							return {
								...acc,
								[boxxID]: {
									status,
									loadAverage: match ? match[1] : '',
									lastUpdate: update,
								}
							};
						}, {}),
					}
				)
			},
		);
	},
});
