/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../CustomValueAccessor';
import * as isoLangs from '../../../../models/languageObjects/isoLangs';


@Component({
	selector: 'input-languages',
	template: `
<div [dragula]='bagName' [(dragulaModel)]='value' class="list">
	<div *ngFor="let iso of value; let i = index" class="drag-handle">
		<span>
			{{languages[iso].name}}
			<span *ngIf="i === 0" i18n>(fallback language)</span>
			<span *ngIf="i !== 0" i18n>(drag up to make fallback language)</span>
		</span>
		<span class="icon-remove" (click)="deleteIso = iso;confirmDeleteModal.open()"></span>
	</div>
</div>
<modal-component #confirmDeleteModal>
	<ng-container i18n>Deleting a language also deletes all language content in exhibitions, exhibits, and media files.</ng-container>
	<br />
	<button type="button" class="button button--red" (click)="remove(deleteIso);confirmDeleteModal.close()" i18n>Delete</button>
</modal-component>
<div class="input-widget">
	<input-language
		(change)="setSelectedLang($event); propagateChange()"
		[excludeLanguages]="value || []"
		[(ngModel)]="langInput"></input-language>
</div>
	`,
	styleUrls: ['./input-languages.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLanguagesComponent), multi: true }],
})
export class InputLanguagesComponent extends CustomValueAccessor {

	protected languages;
	protected selectedLang;
	public langInput;
	public confirmDeleteModal;
	public deleteIso;

	constructor(drake: DragulaService, changeDetectorRef: ChangeDetectorRef, public i18n: I18n) {
		super(i18n);

		this.initOptions({
			drake,
			drakeOptions: {
				direction: 'vertical',
			},
			bagName: `langcontent-${Math.random()}`,
			changeDetectorRef,
		});

		this.languages = {};
		isoLangs.forEach((lang) => { this.languages[lang.iso] = lang; });
		this.value = [];
	}

	setSelectedLang(lang) {
		this.selectedLang = lang;
		if (!this.value) this.value = [];
		if (this.langInput) this.value = [...this.value, this.langInput];
		this.langInput = '';
	}
	remove(iso) {
		this.value = this.value.filter(i => i !== iso);
	}
}
