
<div class="input-widget">
	<div *ngIf="hasDisable">
		<input
			type="checkbox"
			class="btn-checkbox"
			attr.id="{{inputUid}}-checkbox"
			(change)="handleCheckbox($event.target.checked)"
			[checked]="value != null">
		<label attr.for="{{inputUid}}-checkbox"></label>
	</div>

	<div class="btn-switch">
	  <input
	  	class="sl-form__switch"
	  	type="checkbox"
		attr.id="{{inputUid}}-switch"
		[checked]="placeholder || value == true"
	  	(change)="handleSwitch($event.target.checked)"
		[disabled]="disabled || (value == null && hasDisable)">
	  <label attr.for="{{inputUid}}-switch"></label>
		<ng-content></ng-content>
	</div>
</div>
	