/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Directive, ElementRef, Input } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Directive({ selector: '[isLive]' })
export class IsLiveDirective {
	@Input() item: any;
	constructor(private el: ElementRef, public i18n: I18n) {}
	ngOnChanges() {
		if (this.item.isUpToDate) this.el.nativeElement.title = this.i18n('live and published');
		else if (this.item.isLive) this.el.nativeElement.title = this.i18n('live with pending changes');
		else this.el.nativeElement.title = this.i18n('not live');
	}
}
