/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
const getLabels = require('../getLabels').getLabels;

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	name: { type: String, required: true, label: 'Name' },
	type: {
		type: String,
		enum: ['beacon', 'geoLocation', 'customMap', 'dmxLocation'],
		isField: true,
		required: true,
		label: 'Location type',
	},
	beaconID: { type: Schema.ObjectId, ref: 'Beacon' },
	languageContent: { type: [require('./languageLocationSchema').definition], required: true },
	dmxLocation: {
		universe: { type: Number, default: 0 },
		channel: { type: Number, default: 0 },
	},
	isFence: { type: Boolean, default: false },
	geoLocation: {
		shape: {
			type: String,
			enum: ['marker', 'polygon', 'polyline', null],
			default: 'marker',
		},
		coordinates: {
			// type: [[Number]], // [[<longitude>, <latitude>], ...]
			// old schema, dropped for new schema below
			type: Object, // [{lat: ..., lng: ...}, ...]
			// index: '2d',     // create the geospatial index
		},
		radius: { type: Number, default: 0 },
	},
	customMap: {
		articleID: { type: Schema.ObjectId },
		shape: { type: String, enum: ['marker', 'polygon', 'polyline', null], default: 'marker' },
		coordinates: {
			type: Object, // [{lat: ..., lng: ...}, ...]
		},
	},
	comments: { type: String },
	tags: [{ type: String }],
	classes: { type: String, default: 'default' },
};

var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Location',
	schema: ModelSchema,
	definition: schemaDefinition,
	labels: getLabels(schemaDefinition),
	relations: [{ op: 'del', rel: 'Exhibit', path: 'locationID' }],
};
