/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { pluckDistinct } from '../../../utils';
import * as collectionActions from '../../../socket.actions';
import { map } from 'rxjs/operators';

const fullDeps = ['BoxxState', 'Boxx'];

@Component({
	selector: 'boxx-dashboard',
	template: `
		<boxx-dashboard-component [data]="data$ | async"></boxx-dashboard-component>
	`,
})
export class BoxxDashboardContainer {
	public data$;

	constructor(protected store: Store<AppState>) {
		// this.data$ = pluckDistinct(store, 'models', 'Boxx', 'entities').map(entities => Object.values(entities));
		this.data$ = observableCombineLatest(
			pluckDistinct(store, 'models', 'Boxx', 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'models', 'BoxxState', 'entities').pipe(
				map(entities => Object.values(entities).reduce((acc, bs: any) => ({ ...acc, [bs.boxxID]: bs }), {}))
			),
			pluckDistinct(store, 'models', 'DataSource', 'entities'),
			pluckDistinct(store, 'models', 'Organisation', 'entities').pipe(
				map(entities => Object.values(entities))
			),
			pluckDistinct(store, 'currentuser', 'organisation'),
			(boxxes: any, boxxStates: any, datasources: any, organisations: any, currentOrganisation: any) => ({
				boxxes,
				boxxStates,
				organisations: currentOrganisation._id
					? organisations.filter(({ _id }) => currentOrganisation._id === _id)
					: organisations,
				datasources: boxxes.reduce((acc, boxx) => {
					if (datasources[boxx.dataSourceID]) acc[boxx._id] = datasources[boxx.dataSourceID].boxxesConfig;
					return acc;
				}, {}),
			})
		);

		this.store.dispatch(new collectionActions.GetCollectionFullList({ collectionName: 'Boxx' }));
				console.log('BoxxState with filter');
		this.store.dispatch(
			new collectionActions.GetCollectionFullList({
				collectionName: 'BoxxState',
				options: { select: 'organisationID boxxID lastHeartbeat lastUpdate uptime status' },
			})
		);
	}

	ngOnDestroy() {
		fullDeps.forEach(collectionName => {
			const action = new collectionActions.LeaveCollectionFullList({ collectionName });
			this.store.dispatch(action);
		});
	}
}
