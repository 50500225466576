/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';
import { RouterModule } from '@angular/router';

import { DatasourceFormComponent } from './components/datasource-form.component';
import { InputSystemPksComponent } from './components/input-systempks.component/input-systempks.component';
import { InputVariableStorageComponent } from './components/input-variable-storage.component';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule,
	],
	exports: [
		DatasourceFormComponent,
	],
	declarations: [
		DatasourceFormComponent,
		InputSystemPksComponent,
		InputVariableStorageComponent,
	],
	entryComponents: [
		DatasourceFormComponent,
	],
})
export class DatasourceModule { }
