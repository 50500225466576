/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ModelConfig } from '../../../modelConfigs/ModelConfig';
import config from '../../../config'


@Component({
	selector: 'applog-form',
	templateUrl: './index.html',
	// styleUrls: ['./index.sass'],
})
export class ApplogFormComponent {
	@Input() id = '';
	@Input() data: any;
	// @Input() model: ModelConfig;
	@Input() formGroup: FormGroup;
	public config = config;
}
