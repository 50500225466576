/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	mediaObjectsForGame: { type: [Schema.ObjectId], ref: 'MediaObject' },
};

var ModelSchema = new Schema(def);

module.exports = {
	schema: ModelSchema,
	definition: def,
};
