/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormInputsModule } from '../forminputs';
import { LocationModule } from '../_locations/module';
import { ExhibitModule } from '../_exhibits/module';
import { ArticleModule } from '../_articles/module';


import { UnifiedArticleFormComponent } from './unifiedarticle-form.component';

@NgModule({
	imports: [
		FormInputsModule,
		RouterModule,
		ExhibitModule,
		ArticleModule,
	],
	declarations: [
		UnifiedArticleFormComponent,
	],
	exports: [
		UnifiedArticleFormComponent,
	],
	entryComponents: [
		UnifiedArticleFormComponent,
	],
})
export class UnifiedArticleModule {}
