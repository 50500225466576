/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { map, pluck } from 'rxjs/operators';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, forwardRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomValueAccessor } from '../../forminputs/CustomValueAccessor';
import { selectors } from '../../selectors';

@Component({
	selector: 'input-locations',
	template: `
		<div class="list">
			<div *ngIf="hasTrigger">
				<div> <label i18n>Location</label> </div>
				<div> <label i18n>Trigger</label> </div>
				<span></span>
			</div>
			<div *ngFor="let entry of value">
				<div> {{ itemFromID(entry.locationID).name }} </div>
				<div *ngIf="hasTrigger"> {{ entry.triggerType }} </div>
				<span class="icon-remove" (click)="remove(entry)"></span>
			</div>
		</div>
		<div>
			<select-array
				*ngIf="optionSets[0].options.length"
				[optionSets]="optionSets"
				[clearOnComplete]="true"
				(complete)="add($event)"
			>
			</select-array>
			<div *ngIf="!optionSets[0].options.length">
				<p i18n>There are no locations available.</p>
			</div>
		</div>
	`,
	styleUrls: ['./input-locations.component.sass'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLocationsComponent), multi: true },
	],
})
export class InputLocationsComponent extends CustomValueAccessor {
	@Input() organisationID: string;
	@Input() access: any;
	@Input() hasTrigger = false;

	public optionSets: any[];
	protected locationEntities: any;
	protected triggerTypeOptions: any[];
	protected locationSub;
	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);

		this.optionSets = [
			{
				label: this.i18n('Location'),
				placeholder: this.i18n('Select a location...'),
				options: [],
				type: 'select',
			},
		];
	}

	ngOnInit() {
		if (this.hasTrigger) {
			this.optionSets.push({
				label: this.i18n('Trigger Type'),
				placeholder: this.i18n('Select a trigger type...'),
				options: [],
				type: 'select',
			});
		}
		this.locationSub = this.store
			.pipe(
				pluck('models', 'Location'),
				map(selectors.getEntities)
			)
			.subscribe(ent => {
				this.locationEntities = ent;
				this.updateOptionSets();
			});
	}

	ngOnChanges(changes) {
		this.triggerTypeOptions = [
			{ label: this.i18n('Notify user about available content'), value: 'trigger' },
			{ label: this.i18n('Show available content without user intervention'), value: 'forceTrigger' },
			{ label: this.i18n('Show available content without user intervention only once'), value: 'forceOneShotTrigger' },
		];

		if (!this.access || !this.access.isAdmin) {
			this.triggerTypeOptions.pop();
		}

		this.updateOptionSets();
	}

	// ngOnDestroy() {
	// 	this.locationSub.unsubscribe();
	// }

	itemFromID(id) {
		return this.locationEntities[id] || { name: 'UNDEFINED' };
	}

	add([locationID, triggerType]) {
		this.value = [...(this.value || []), { locationID, triggerType }];

		this.updateOptionSets();
	}

	remove(target) {
		this.value = this.value.filter(entry => entry._id !== target._id);
		this.updateOptionSets();
	}

	updateOptionSets() {
		if (!this.locationEntities || !Object.keys(this.locationEntities).length) {
			return;
		}

		this.optionSets[0].options = this.getLocationOptions();
		if (this.hasTrigger) this.optionSets[1].options = this.triggerTypeOptions;
	}

	getValidIds() {
		const value = this.value || [];

		const usedLocations = value.reduce((used, { locationID }) => {
			used[locationID] = true;
			return used;
		}, {});

		return Object.keys(this.locationEntities).filter(
			id =>
				!usedLocations[id] &&
				(!this.organisationID || this.locationEntities[id].organisationID === this.organisationID)
		);
	}

	getLocationOptions() {
		return this.getValidIds().map(id => ({
			value: id,
			label: this.locationEntities[id].name,
		}));
	}
}
