/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { schema, labels } from '../../../models/location/locationSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { beaconConfig } from '../_beacons/config';
import { articleConfig } from '../_articles/config';
import { selectors } from '../selectors';
import { LocationFormComponent } from './components/location-form.component';
import { info } from './info';
import { isoLang2name, fixLanguageContent, pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const locationConfig = new ModelConfig({
	collectionName: 'Location',
	icon: 'icon-geo-fence',
	clientPathPrefix: '',

	dependencies: [
		{ name: 'Exhibit', inSidebar: true },
		{ name: 'Exhibition', inSidebar: true },
		{ name: beaconConfig.collectionName, inSidebar: false },
		{ name: articleConfig.collectionName, inSidebar: false },
	],

	schema,
	labels,

	formComponent: LocationFormComponent,
	infoHtml: info,
});

Object.assign(locationConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', locationConfig.collectionName).pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'models', beaconConfig.collectionName, 'entities').pipe(
				map(entities => Object.values(entities))
			),
			pluckDistinct(store, 'models', articleConfig.collectionName).pipe(map(selectors.getAlphabeticList)),
			pluckDistinct(store, 'currentuser'),
			pluckDistinct(store, 'models', 'Organisation').pipe(map(selectors.getEntities)),
			(
				model: any,
				locations: any[],
				beacons: any[],
				articles: any,
				currentUser: any,
				organisationEntities,
			) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
				const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
				const languageOptions = (languages || []).map(value => ({value: `${value}`, label: `${isoLang2name[value]}`}));
				const articlesFiltered = articles.filter(m => ['custom-map', 'distortable-image-map'].includes(m.type) && (!organisationID || m.organisationID === organisationID))
				const customMaps = articlesFiltered.filter(({type}) => type === 'custom-map').map(({ name, _id }) => {
					return { label: name, value: _id };
				});
				const distortableImageMaps = articlesFiltered.filter(({type}) => type === 'distortable-image-map').map(({ name, _id }) => {
					return { label: name, value: _id };
				});

				if (!model.incomplete) {
					model.languageContent = fixLanguageContent({
						languageOptions,
						languageContent: model.languageContent,
					});
				}

				let otherMapPoints = [];

				if (model.type === 'geoLocation' && !!organisationID) {
					otherMapPoints = locations.filter(({ type, geoLocation, _id }) => {
						return (
							_id !== model._id &&
							type === 'geoLocation' &&
							geoLocation.coordinates &&
							geoLocation.coordinates[0] &&
							geoLocation.coordinates[0].length >= 2 &&
							/circle|marker/.test(geoLocation.shape)
						);
					});
				}
				// fix fucked up code that inserted wrong format
				// console.log('model.type', model.type);
				if (
					model.type &&
					['geoLocation', 'customMap'].includes(model.type) &&
					['polygon', 'polyline'].includes(model[model.type].shape) &&
					model[model.type].coordinates
				) {
					if (model[model.type].shape === 'polygon' && model[model.type].coordinates[0].lat) {
						console.warn('fix coordinates format, please update this model!');
						model[model.type].coordinates = [model[model.type].coordinates];
					}
					if (model[model.type].coordinates[0] && typeof model[model.type].coordinates[0][0] === 'number') {
						console.warn('fix coordinates format, please update this model!');
						model[model.type].coordinates = [model[model.type].coordinates];
					}
					if (
						model[model.type].shape === 'polyline' &&
						model[model.type].coordinates[0] &&
						model[model.type].coordinates[0][0] &&
						model[model.type].coordinates[0][0].lat
					) {
						console.warn('fix coordinates format, please update this model!');
						model[model.type].coordinates = model[model.type].coordinates[0]
					}
				}

				return {
					model,
					access: currentUser.access,
					customMaps,
					distortableImageMaps,
					articlesMapMetaData: articlesFiltered.reduce(
						(acc, article) =>
							Object.assign(acc, {
								[article._id]: {
									mapData: article.customMapContent ? article.customMapContent.mapMetaData : {},
									coverID: article.coverID,
								}
							}),
						{}
					),
					beacons: organisationID ? beacons.filter(ex => ex.organisationID === organisationID) : beacons,
					organisationID,
					languageOptions,
					otherMapPoints,
				};
			}
		);
	},
});
