/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';

import { TourFormComponent } from './components/tour-form.component';
import { InputExhibitListComponent } from './components/input-exhibit-list.component';
import { SelectUnifiedArticleComponent } from './components/select-unified-article.component';



@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		TourFormComponent,
	],
	declarations: [
		TourFormComponent,
		InputExhibitListComponent,
		SelectUnifiedArticleComponent,
	],
	entryComponents: [
		TourFormComponent,
	],
})
export class TourModule { }
