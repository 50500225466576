/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { schema, labels } from '../../../models/glossaryTerm/glossaryTermSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { GlossaryTermFormComponent } from './components/glossary-term-form.component';
import { selectors } from '../selectors';
import { isoLang2name, pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const glossaryTermConfig = new ModelConfig({
	name: 'glossary-term',
	namePlural: 'glossary',
	collectionName: 'GlossaryTerm',
	icon: 'icon-hint',
	clientPathPrefix: '',
	dependencies: [],
	schema,
	labels,
	formComponent: GlossaryTermFormComponent,
});

Object.assign(glossaryTermConfig, {
	getFormData$(store, id, versionId?) {
		const models$ = pluckDistinct(store, 'models');
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(models$, 'Organisation').pipe(map(selectors.getEntities)),
			pluckDistinct(store, 'currentuser'),
			(model: any, organisationEntities, currentUser: any) => {
				const organisationID = id && model ? model.organisationID : currentUser.organisation._id;
				const modelOrg = organisationID ? organisationEntities[organisationID] : undefined;
				const languages = modelOrg ? modelOrg.languages : currentUser.organisation.languages;
				model.languageContent = model.languageContent || [];
				model.languageContent = (languages || []).map(orgLanguageISO => {
					const currentValue = model.languageContent.find(
						({ languageISO }) => orgLanguageISO === languageISO
					);
					if (currentValue) return currentValue;
					return { languageISO: orgLanguageISO, keywords: [], definition: '' };
				});
				return { model };
			}
		);
	},
});
