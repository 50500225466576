/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';

import { FormInputsModule } from '../forminputs';
import { PlaylistFormComponent } from './components/playlist-form';
import { InputArticleListComponent } from './components/input-articlelist.component/input-articlelist.component';

@NgModule({
	imports: [
		FormInputsModule,
	],
	exports: [
		PlaylistFormComponent,
	],
	declarations: [
		PlaylistFormComponent,
		InputArticleListComponent,
	],
	entryComponents: [
		PlaylistFormComponent,
	],
})
export class ArticlelistModule { }
