
		<div class="sl-form" [ngSwitch]="(model$ | async)?.name">
			<dynamic-model-form
				#dynamicModelForm
				[disabled]="(cloneId$ | async) ? false : !!getHumanReadableVersion(versionId$ | async)"
				[collectionName]="(model$ | async)?.collectionName"
				[id]="id$ | async"
				[versionId]="versionId$ | async"
				[data]="data$ | async"
				class="sl-form__content"
			>
				<!-- <span
				*ngIf="(model$ | async)?.infoHtml"
				(click)="infoModal.open()"
				i18n-title
				title="Click here to get additional information about this form."
				class="icon-Info-2 model-edit__info"></span> -->
				<h1 *ngIf="!dynamicModelForm.id">
					Create
					<span *ngIf="(model$ | async)?.name == 'unifiedarticle'" i18n>article</span>
					<span *ngIf="(model$ | async)?.name != 'unifiedarticle'">{{ (model$ | async)?.name }}</span>
				</h1>
				<h1 *ngIf="!(cloneId$ | async) && !!getHumanReadableVersion(versionId$ | async)" i18n>
					Version {{ getHumanReadableVersion(versionId$ | async) }}
				</h1>
			</dynamic-model-form>

			<model-edit-footer
				#footer
				(saveEvent)="onSave($event)"
				[formGroup]="dynamicModelForm.formGroup"
				[id]="dynamicModelForm.id"
				[versionId]="versionId$ | async"
				[versionIdHuman]="(cloneId$ | async) ? undefined : getHumanReadableVersion(versionId$ | async)"
				[data]="dynamicModelForm.data"
				[model]="model$ | async"
				[uploading]="uploading"
				[cloneId]="cloneId$ | async"
			>
			</model-edit-footer>
		</div>

		<modal-component #askSaveModal (closeEvent)="closeAskSave(false)">
			<div style="text-align: center">
				<span *ngIf="uploading" i18n
					>You're currently uploading a file for this {{ (model$ | async)?.name }} item.</span
				>
				<span *ngIf="!uploading" i18n
					>You have unsaved changes in this {{ (model$ | async)?.name }} item.</span
				>
			</div>
			<br />
			<button type="button" class="button--red" (click)="closeAskSave(true)">
				<ng-container *ngIf="uploading" i18n>Cancel and discard changes</ng-container>
				<ng-container *ngIf="!uploading" i18n>Discard changes</ng-container>
			</button>
			<button type="button" (click)="closeAskSave(false)">
				<ng-container *ngIf="uploading; else elseBlock" i18n> Wait for upload </ng-container>
				<ng-template #elseBlock i18n> Continue editing </ng-template>
			</button>
			<button
				type="button"
				*ngIf="!uploading"
				[disabled]="!dynamicModelForm.formGroup.valid"
				[attr.title]="dynamicModelForm.formGroup.valid ? '' : buttonTextInvalid"
				(click)="footer.save(); closeAskSave(true)"
				i18n
			>
				Save and leave
			</button>
		</modal-component>
		<modal-component #infoModal>
			<div [innerHTML]="(model$ | async)?.infoHtml"></div>
		</modal-component>

		<div *ngIf="!(appStatus$ | async).online" class="offline-overlay"></div>
	