/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;
var landingModes = require('../../helpers/consts').LANDING_MODES;
var dayOfWeek = require('../../helpers/consts').DAYOFWEEK;
var month = require('../../helpers/consts').MONTH;
var dayOfMonth = require('../../helpers/consts').DAYOFMONTH;
var hour = require('../../helpers/consts').HOUR;

var platforms = ['debian', 'openwrt'];
var progressiveWebAppDisplayModes = ['fullscreen', 'standalone', 'minimal-ui', 'browser'];

var def = {
	organisationID: {
		type: Schema.ObjectId,
		ref: 'Organisation',
		index: true,
		unique: false,
		required: true,
	},
	name: { type: String, required: true },
	type: {
		type: String,
		enum: ['boxxes', 'cloud', 'ondevice', 'boxxes&cloud', 'derivative', 'sub'],
		isField: true,
		required: true,
	},
	platform: { type: String, enum: platforms, default: 'openwrt' },
	mainDataSourceID: { type: Schema.ObjectId, ref: 'DataSourceID' },
	relatedDataSourceID: { type: Schema.ObjectId, ref: 'DataSourceID', default: null }, // Only meta info will be connected no media files
	styleTemplateID: { type: Schema.ObjectId, ref: 'StyleTemplate' },
	styleTemplateFileSize: { type: Number, default: 0 },
	urlToken: { type: String, writable: false },
	systemPks: [{ type: Schema.ObjectId, ref: 'SystemPk' }],
	systemPksFileSize: { type: Number, default: 0 },
	customJsModules: [{ type: Schema.ObjectId, ref: 'SystemPk' }],
	blocks: [{ type: Schema.ObjectId, ref: 'SystemPk' }],
	appStoreIds: {
		ios: { type: String },
		android: { type: String },
	},
	facebookAppId: { type: String },
	progressiveWebApp: {
		short_name: { type: String },
		name: { type: String },
		description: { type: String },
		background_color: { type: String },
		theme_color: { type: String },
		display: { type: String, enum: progressiveWebAppDisplayModes, default: 'standalone' },
		icon: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image' }, // Has to be type Image
	},
	boxxesConfig: require('../boxx/boxxConfigSchema').definition,
	offlineThresholdMinutes: { type: Number, default: 10 },
	navigationJson: { type: Object, default: {} },
	landingMode: { type: String, enum: Object.keys(landingModes), default: landingModes.exhibit },
	landingExhibitID: { type: Schema.ObjectId, ref: 'Exhibit' },
	customMapArticleID: { type: Schema.ObjectId, ref: 'Article' },
	hasOnlineNavigationMap: { type: Boolean, default: false },
	onlineMapBoundsLocationID: { type: Schema.ObjectId, ref: 'Location' },
	captivePortalExhibitID: { type: Schema.ObjectId, ref: 'Exhibit' },
	triggers: [
		{
			locationID: { type: Schema.ObjectId, ref: 'Location' },
			triggerType: {
				type: String,
				enum: ['trigger', 'forceTrigger', 'forceOneShotTrigger'],
				default: 'trigger',
			},
		},
	],
	configAntiTheftGate: require('./antiTheftGateConfigSchema').definition,
	configLoanerDevice: require('./loanerDeviceConfigSchema').definition,
	configWebClient: require('./webClientConfigSchema').definition,
	beaconSettings: require('./beaconSettingsSchema').definition,
	compressMedia: { type: Boolean, default: true },
	removeMissingLanguagePosts: { type: Boolean, default: false },
	isUpToDate: { type: Boolean, default: false, writable: false },
	publishing: {
		pendingActionTypes: { type: [String], default: [] },
		pendingSources: { type: [String], default: [] },
		state: {
			type: String,
			enum: ['queued', 'publishing', 'published', null],
			default: 'published',
		},
		publishingRuns: [
			{
				publishingErrors: [{ type: String }],
				sources: [{ type: String }],
				actions: [{ type: String }],
				duration: { type: Number },
				date: { type: Date },
			},
		],
	},
	defaultLanguage: { type: String },
	meshTopologyDotString: { type: String },
	storeOnS3: { type: Boolean, default: false },
	MQTTSupport: {
		MQTTUserCredentials: {
			MQTTUsername: { type: String },
			MQTTPassword: { type: String },
		},
		mobile: {
			host: { type: String, default: 'boxx.shoutrlabs.com' },
			port: { type: Number, default: 8443 },
			active: { type: Boolean, default: false },
		},
		mediaPlayers: {
			host: { type: String, default: 'boxx.shoutrlabs.com' },
			port: { type: Number, default: 8443 },
			active: { type: Boolean, default: false },
		},
		customTopic: {
			baseTopic: { type: String, default: '' },
			topic: { type: String, default: '' },
			active: { type: Boolean, default: false },
		},
	},
	payment: require('./paymentSubSchema').definition,
	variableStorage: [
		{
			name: { type: String },
			scope: { type: String, enum: ['global', 'local', 'session'] },
			type: { type: String, enum: ['boolean', 'string', 'number', 'object'] },
			default: { type: String },
			comments: { type: String },
			storeURL: { type: String },
		},
	],
	repository: {
		autoContent: {
			exhibitionList: {
				version: { type: Number },
				relatedMediaFiles: [{ type: String }],
			},
			exhibitLists: [
				{
					exhibitionID: { type: Schema.ObjectId, ref: 'Exhibition' },
					version: { type: Number },
					relatedMediaFiles: [{ type: String }],
				},
			],
		},

		userContent: [{ type: String }], // Key PackageName, relatedPackages
		systemPackages: [
			[
				{ type: String },
				{
					id: { type: Schema.ObjectId, ref: 'SystemPackage' },
					fileID: { type: Schema.ObjectId },
					version: { type: String },
				},
			],
		],
		// Key StyleTemplateID, version numer
		styleTemplates: [{ id: { type: Schema.ObjectId }, version: Number }],
		glossary: { type: Boolean },
	},
	preventCloudUpdates: { type: Boolean, default: false },
	publishedVersions: [
		{
			version: { type: Number },
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
			packages: [{ type: String }],
		},
	],
	dataSourceTotalFileSize: { type: Number, default: 0 },
	autoPublishing: {
		dayOfWeek: { type: String, enum: dayOfWeek },
		month: { type: String, enum: month },
		dayOfMonth: { type: Number, enum: dayOfMonth },
		hour: { type: Number, enum: hour },
	},
};
var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'DataSource',
	schema: ModelSchema,
	landingModes,
	progressiveWebAppDisplayModes,
	dayOfWeek,
	month,
	dayOfMonth,
	hour,
	definition: def,
	relations: [
		{ op: 'del', rel: 'Boxx', path: 'dataSourceID' },
		{ op: 'del', rel: 'DataSource', path: 'mainDataSourceID' },
		{ op: 'del', rel: 'Exhibition', path: 'dataSourceID' },
		{ op: 'del', rel: 'LoanerDevice', path: 'dataSourceID' },
	],
};
