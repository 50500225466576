/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { messageActions } from '../core/_messages/actions';
import { currentuserActions } from '../_currentuser/actions';
import { actions as newActions } from '../actions';
import * as collectionActions from '../socket.actions';

export function getUpdateActions(updates) {
	const updateActions = [];

	Object.entries(updates).forEach(([collectionName, entityList]: [string, any]) => {
		const data = entityList.filter(entity => !entity.deleted);

		if (data.length) {
			updateActions.push(new collectionActions.CollectionFullListUpdated({
				collectionName,
				data,
			}));
		}
	});

	const deleteActions = [];

	Object.entries(updates).forEach(([collectionName, entityList]: [string, any]) => {
		const data = entityList.filter(entity => !entity.deleted);

		for (const { _id } of data) {
			deleteActions.push(new collectionActions.CollectionItemDeleted({ collectionName, data: _id }));
		}
	});

	return [...updateActions, ...deleteActions];
}

export function getErrorMessage(payloadJson) {
	let message = '';

	if (payloadJson.errors) {
		message += `${payloadJson.errors.message}<br>`;
		if (payloadJson.errors.errors) {
			Object.keys(payloadJson.errors.errors).forEach(key => {
				message += `${key}: ${payloadJson.errors.errors[key].message}<br>`;
			});
		}
	}
	return message;
}

export function generateResponse(payload, successOptions: any, errorAction?) {
	let payloadJson;
	if (payload.status === 0) {
		return [
			{ type: currentuserActions.LOAD_CURRENTUSER_FAIL },
			messageActions.error(
				'The main server can not be reached. <br> Please check your internet connection.'
			),
		];
	} else if (payload.status === 401) {
		payloadJson = payload.body || {};
		if (payloadJson.error) return [ messageActions.error(payloadJson.error.message) ];
		else return [messageActions.error(`HTTP Error ${payload.status} <br> You do not have enough access rights to do this.`)]
	} else if (payload.status === 403) {
		return [{ type: currentuserActions.LOAD_CURRENTUSER_FAIL }];
	} else if (payload.status === 502) {
		if (errorAction) return [errorAction];
		else
			return [
				messageActions.error(`Error ${payload.status} <br> Please try again in a few minutes.`),
			];
	} else if (payload.status >= 302 && payload.status < 401) {
		// FIXME https://trello.com/c/kBGTj5Jl/146-wrong-http-code-when-session-dropped-302-found-401-unauthorized
		console.log('STATUS ', payload.status);
	} else if (payload.status < 400 || !payload.status) {
		payloadJson = payload.body || {};
		let actionList = [];
		if (payloadJson.update) {
			actionList = getUpdateActions(payloadJson.update);
		} else if (successOptions.update) {
			actionList = actionList.concat(
				getUpdateActions({ [successOptions.collectionName]: [payloadJson] })
			);
		}
		if (successOptions.type)
			actionList.push({
				...successOptions,
				payload: payloadJson,
			});
		return actionList;
	} else {
		try {
			payloadJson = payload.body || payload.error;
			if (payload.status === 500) {
				if (payloadJson.errors && payloadJson.update) {
					return [
						messageActions.error(`HTTP Error ${payload.status} <br> ${payloadJson.errors.message}`),
						...getUpdateActions(payloadJson.update),
					];
				}
				payloadJson = { status: payload.status, message: getErrorMessage(payloadJson) };
			}
			if (payloadJson.error) return [ messageActions.error(payloadJson.error.message) ];

		} catch (e) {
			payloadJson = { status: payload.status, message: 'could not parse error object' };
		}
		if (payload.body || payload.error) {
			const out = [messageActions.error(`HTTP Error ${payload.status} <br> ${payloadJson.message}`)]
			if (errorAction) out.push(errorAction);
			return out;
		}
	}
}
