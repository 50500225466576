/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';

@Component({
	selector: 'input-text',
	template: `
<div class="input-text">
	<textarea
		#inlineTextarea
		[cols]="cols"
		[rows]="rows"
		[placeholder]="placeholder"
		[disabled]="disabled"
		[minlength]="minLength"
		[maxlength]="maxLength"
		[(ngModel)]="value"></textarea>
		<ng-container *ngIf="allowModal">
		<modal-component
			#modal
			*ngIf="allowModal"
			(openEvent)="modalTextarea.focus()"
			(closeEvent)="inlineTextarea.focus()"
			[fullSize]="true">
			<textarea
				#modalTextarea
				[attr.placeholder]="placeholder"
				[attr.disabled]="disabled || null"
				[(ngModel)]="value"></textarea>
		</modal-component>
		<button role="button"
			class="modal-open icon-open-in-popup"
			(click)="modal.open();"></button>
		</ng-container>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextComponent), multi: true }],
})
export class InputTextComponent extends CustomValueAccessor {
	@Input() allowModal = false;
	@Input() disabled = false;
	@Input() cols = null;
	@Input() rows = null;
	@Input() placeholder = null;
	@Input() minLength: number;
	@Input() maxLength: number;

	@ViewChild('modal', { static: false }) modal;
	@ViewChild('modalTextarea', { static: true }) modalTextarea;
	@ViewChild('inlinTextarea', { static: true }) inlineTextarea;

	value;
	modalIsOpen = false;
}
