
		<header class="filter" (click)="$event.stopPropagation()">
			<input-filter
				#filterComponent
				[items]="list"
				(filter)="filteredItems = $event.result"
				(keyup.enter)="selectFirst()">
			</input-filter>
			<div class="sort-menu">
				<div i18n>Sort</div>
				<div
					[ngClass]="{'sort-direction--active': (data.sort.objectPath == 'changeLog.editedOn')}"
					(click)="sorting('changeLog.editedOn')">
					<div i18n>by date</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.sort.reverse}"></div>
				</div>
				<div
					[ngClass]="{'sort-direction--active': (data.sort.objectPath == 'name')}"
					(click)="sorting('name')">
					<div i18n>by name</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.sort.reverse}"></div>
				</div>
			</div>
		</header>

		<div class="list">
			<div
				*ngFor="let item of filteredItems"
				(click)="selectmedia.emit(item._id);"
				[ngStyle]="{backgroundImage: getThumb(item)}">
					<span>{{item.name}}</span>
				</div>
			<a class="list--add-new" (click)="$event.preventDefault(); newModal.open()">
				<span class="icon-add"></span>
				<div i18n>new media</div>
			</a>
		</div>

		<form-modal #newModal collectionName="MediaObject" i18n>
			Create a new media item
		</form-modal>
	