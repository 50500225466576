/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	fromEvent as observableFromEvent,
	merge as observableMerge,
	combineLatest as observableCombineLatest,
	Observable,
	Subject,
} from 'rxjs';

import { startWith, takeUntil, filter } from 'rxjs/operators';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	EventEmitter,
	ViewChild,
	ViewChildren,
	ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';

import { AppState } from '../../reducers';
import appConfig from '../../config';
import { pluckDistinct } from '../../utils';

const size2px = obj => ({ width: `${obj.width}px`, height: `${obj.height}px` });

@Component({
	selector: 'exhibition-preview',
	template: `
		<div class="exhibition-preview" *ngIf="id && !!getPreviewUrl()">
			<div class="modes">
				<button
					type="button"
					(click)="showVersion = 'latest'"
					[ngClass]="{ 'button--blue-ghost': showVersion != 'latest' }"
					i18n
				>
					latest
				</button>
				<button
					type="button"
					(click)="showVersion = 'published'"
					[ngClass]="{ 'button--blue-ghost': showVersion != 'published' }"
					i18n
				>
					published
				</button>
			</div>
			<div class="exhibition-preview__controls">
				<span class="icon-left-arrow_L" (click)="increaseDeviceIndex(-1)"></span>
				<div>{{ devices[currentDevice].name }}</div>
				<span class="icon-right-arrow_L" (click)="increaseDeviceIndex(1)"></span>
			</div>
			<div class="phone">
				<div #previewFrame class="preview-frame">
					<iframe
						class="exhibition-preview__iframe"
						[src]="sanitizer.bypassSecurityTrustResourceUrl(getPreviewUrl())"
						allowfullscreen="false"
						frameborder="0"
					></iframe>
				</div>
				<img [src]="'/assets/img/' + devices[currentDevice].name + '.svg'" alt="phone frame" />
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./component.sass'],
})
export class ExhibitionPreviewComponent {
	@Input() id: string;
	@Input() collectionName: string;
	@Input() updateCounter: number;
	@Input() exhibitEntities: any;

	@ViewChildren('previewFrame') previewFrameQuery;

	public showVersion = 'latest'; // either 'latest' or 'published'
	public currentDevice = 0;
	public devices = [
		{
			name: 'Google-Nexus-5',
			iframe: { width: 412, height: 732 },
			previewFrame: { top: '8.6%', left: '5.5%', width: '89.2%' },
		},
		{
			name: 'iPhone-5',
			iframe: { width: 320, height: 568 },
			previewFrame: { top: '14.8%', left: '8.7%', width: '83.7%' },
		},
		{
			name: 'iPod',
			iframe: { width: 320, height: 568 },
			previewFrame: { top: '14.8%', left: '8.3%', width: '83.8%' },
		},
		{
			name: 'iPad-mini',
			iframe: { width: 768, height: 1024 },
			previewFrame: { top: '9.8%', left: '5.3%', width: '89.4%' },
		},
		{
			name: 'MacBook-Pro',
			iframe: { width: 1440, height: 900 },
			previewFrame: { top: '6.1%', left: '11.8%', width: '76.4%' },
		},
	];

	private destroyed$ = new Subject();

	constructor(public sanitizer: DomSanitizer, private store: Store<AppState>) {}

	getPreviewUrl() {
		const versionPrefix = this.showVersion === 'published' ? '/publishedVersions' : '';
		let url = `${appConfig.baseURL}/preview/exhibitions/`;
		let params = 'preview=true';

		if (this.collectionName === 'Exhibition') {
			url += `${this.id}${versionPrefix}`;
		} else if (this.exhibitEntities[this.id]) {
			url += `${this.exhibitEntities[this.id].exhibitionID}${versionPrefix}`;

			params += `&ex=${this.exhibitEntities[this.id].exhibitionID}&id=${
				this.exhibitEntities[this.id].articleID
			}`;
		} else {
			return null;
		}

		return `${url}?${params}`;
	}

	ngAfterViewInit() {
		observableCombineLatest(
			// when the ViewChildren emits a change (the component is created or the *ngIf in the template changes)
			(this.previewFrameQuery.changes as Observable<any>).pipe(startWith(this.previewFrameQuery)),
			// when either the user settings changes or the window is resized
			observableMerge(
				pluckDistinct(this.store, 'currentuser', 'rightColumnStyle'),
				observableFromEvent(window, 'resize')
			).pipe(startWith(null))
		)
			.pipe(
				// only act if the iframe exists
				filter(([{ first: previewFrameRef }]) => {
					return !!(previewFrameRef && previewFrameRef.nativeElement);
				}),
				takeUntil(this.destroyed$)
			)
			// scale the iframe
			.subscribe(() => {
				this.scaleIframe();
			});
	}

	scaleIframe() {
		const previewFrame = this.previewFrameQuery.first.nativeElement;
		const iframe = previewFrame.querySelector('iframe');

		const device = this.devices[this.currentDevice];
		Object.assign(previewFrame.style, device.previewFrame);
		Object.assign(iframe.style, size2px(device.iframe));
		const pfBr = previewFrame.getBoundingClientRect();
		const scale = pfBr.width / device.iframe.width;
		iframe.style.transform = `scale(${scale})`;
	}

	increaseDeviceIndex(value) {
		const newIndex = this.currentDevice + value;
		if (newIndex >= this.devices.length) this.currentDevice = 0;
		else if (newIndex < 0) this.currentDevice = this.devices.length - 1;
		else this.currentDevice = newIndex;
		this.scaleIframe();
	}

	ngOnDestroy() {
		this.destroyed$.next(null);
		this.destroyed$.complete();
	}
}
