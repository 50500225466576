/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { map, first, filter, distinctUntilChanged } from 'rxjs/operators';
import deepExtend from 'deep-extend';
import { Actions } from '@ngrx/effects';

import * as collectionActions from '../socket.actions';
import { actions } from '../actions';
import { messageActions } from '../core/_messages/actions';
import { currentuserActions } from '../_currentuser/actions';
import dispatchUpdates from '../services/dispatchUpdates';
import * as isoLangs from '../../../models/languageObjects/isoLangs';

import { updateFieldMut, getFieldValue } from './objectTraversal';
import { Observable } from 'rxjs';
export * from './objectTraversal';
export { previewDomain } from './previewDomain';

export const isoLang2name = isoLangs.reduce((acc, lang) => ({ ...acc, [lang.iso]: lang.name }), {});

export function generateResponse(payload, successType, store, onError?) {
	let payloadJson;
	if (payload.status === 0) {
		console.log('STATUS ', payload.status);
		store.dispatch({ type: currentuserActions.LOAD_CURRENTUSER_FAIL });
		store.dispatch(
			messageActions.error('The main server can not be reached. <br> Please check your internet connection.')
		);
		// window.location = '/login';
	} else if (payload.status >= 302 && payload.status < 402) {
		// FIXME https://trello.com/c/kBGTj5Jl/146-wrong-http-code-when-session-dropped-302-found-401-unauthorized
		console.log('STATUS ', payload.status);
		// window.location = '/login';
	} else if (payload.status < 400 || !payload.status) {
		payloadJson = payload.json();
		if (payloadJson.update) dispatchUpdates(payloadJson.update, store);
		else store.dispatch({ type: successType, payload: payload.json() });
	} else if (payload.status === 403) {
		store.dispatch({ type: currentuserActions.LOAD_CURRENTUSER_FAIL });
	} else if (payload.status === 502) {
		store.dispatch(messageActions.error(`Error ${payload.status} <br> Please try again in a few minutes.`));

		if (onError) {
			onError(payload);
		}
	} else {
		try {
			payloadJson = payload.json();
			if (payload.status === 500) {
				let message = '';
				if (payloadJson.errors) {
					message += `${payloadJson.errors.message}: <br>`;
					if (payloadJson.errors.errors) {
						Object.keys(payloadJson.errors.errors).forEach(key => {
							message += `${key}: ${payloadJson.errors.errors[key].message}<br>`;
						});
					}
					if (payloadJson.errors.update) dispatchUpdates(payloadJson.errors.update, store);
				}
				payloadJson = { status: payload.status, message };
			}
		} catch (e) {
			payloadJson = { status: payload.status, message: 'could not parse error object' };
		}

		if (payload.text()) {
			store.dispatch(messageActions.error(`Error ${payload.status} <br> ${payloadJson.message}`));
			if (onError && payload.text()) {
				onError(payload);
			}
		}
	}
}

export function mimeToMediaType(mime) {
	const match = mime.match(/^(audio|image|video)[/]/);

	if (match) {
		return match[1];
	}

	if (mime === 'application/object' || mime === 'application/x-tgif' || mime === 'application/octet-stream') {
		return '3DModel';
	}

	return 'misc';
}

export const allowedMimes = {
	audio: 'audio/mp3,audio/mpeg,audio/ogg',
	image: 'image/jpeg,image/png',
	video: 'video/mp4,video/ogg,video/webm',
	misc: 'application/pdf',
	// not a MIME type, but this works best
	'3DModel': '.obj,.fbx',
	null: '',
};

allowedMimes['null'] = Object.values(allowedMimes)
	.filter(Boolean)
	.join(',');

export function fileIsAccepted(file, acceptableTypes) {
	if (acceptableTypes === '*') return true;

	if (acceptableTypes[0] === '.') {
		// '.ext' should check the filename but in dragenter events, the
		// filename isn't available so assume that it is acceptable
		if (!file.name) {
			return true;
		}

		return file.name.indexOf(acceptableTypes) === file.name.length - acceptableTypes.length;
	}

	const [acceptableType, acceptableSubtype] = acceptableTypes.split('/');
	const [fileType, fileSubtype] = file.type.split('/');

	return acceptableType === fileType && (acceptableSubtype === '*' || acceptableSubtype === fileSubtype);
}

export function getFileMediaType(file) {
	for (const type in allowedMimes) {
		for (const acceptableType of allowedMimes[type].split(',')) {
			if (fileIsAccepted(file, acceptableType)) {
				return type;
			}
		}
	}

	return 'misc';
}

export function getFileAcceptAttr(type) {
	return allowedMimes[type];
}

export const blankGif = document.createElement('img');
blankGif.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export function throttle(func, wait, options?) {
	let context, args, result;
	let timeout = null;
	let previous = 0;
	if (!options) options = {};
	const later = function() {
		previous = options.leading === false ? 0 : Date.now();
		timeout = null;
		result = func.apply(context, args);
		if (!timeout) context = args = null;
	};
	return function() {
		const now = Date.now();
		if (!previous && options.leading === false) previous = now;
		const remaining = wait - (now - previous);
		context = this;
		args = arguments;
		if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
			previous = now;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};
}

export function fixLanguageContent({ languageContent, languageOptions }) {
	languageContent = languageContent || [];

	const languageIso2name = languageOptions.reduce((acc, lang) => ({ ...acc, [lang.value]: lang.label }), {});
	const currentLanguages = new Set(languageContent.map(({ languageISO }) => languageISO));
	const fixed = [
		...languageContent.filter(({ languageISO }) => languageISO in languageIso2name),
		...languageOptions
			.filter(lang => !currentLanguages.has(lang.value))
			.map(lang => ({
				languageISO: lang.value,
				title: '',
				description: '',
				text: '',
			})),
	];

	return fixed;
}

export function pluckDistinct(observable$: Observable<any>, ...fields: string[]) {
	return observable$.pipe(
		map(o => getFieldValue(o, fields)),
		distinctUntilChanged()
	);
}

export function fillDefaults(item, defaultData) {
	for (const field in defaultData) {
		if (item[field] === null || item[field] === undefined) {
			const defaultValue = defaultData[field];

			item[field] = Array.isArray(defaultValue)
				? deepExtend([], defaultValue)
				: !!defaultValue && typeof defaultValue === 'object'
				? deepExtend({}, defaultValue)
				: defaultValue;
		}
	}

	return item;
}

export function uid() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
}

export function getSaveResult$(
	actions$: Actions,
	collectionName: string,
	id?: string,
	revertVersion?: boolean
) {
	return actions$.pipe(
		filter((action: any) => {
			if (revertVersion) {
				return action.type === collectionActions.REVERT_TO_VERSION_OF_ITEM_DONE;
			}

			if (id) {
				return action.type === collectionActions.COLLECTION_ITEM_UPDATED && action.payload.data._id === id;
			}

			// check for *both* SUCCESS and FAIL types so that the `take(1)` unsubscribes
			return (
				action.type === collectionActions.COLLECTION_ITEM_CREATED
				&&
				action.payload.collectionName === collectionName
			);
		}),
		first(),
		map(action => {
			return {
				collectionName,
				type: action.type,
				data: action.payload.data || action.payload.update || action.payload.error,
			};
		})
	);
}

export function timer(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export { default as CollectionJoiner } from './CollectionJoiner';

export function setItemFieldDefault(item, path, list) {
	if (list.length === 1 && !getFieldValue(item, path)) {
		updateFieldMut(item, path, list[0]._id);
	}
}

export function shallowEqual(a, b) {
	for (const key in a) {
		if (!(key in b) || a[key] !== b[key]) {
			return false;
		}
	}
	for (const key in b) {
		if (!(key in a)) {
			return false;
		}
	}
	return true;
}

export const setCookie = (name, value, days = 7, path = '/') => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

export const getCookie = name => {
	return document.cookie.split('; ').reduce((r, v) => {
		const parts = v.split('=');
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, '');
};

export const deleteCookie = (name, path) => {
	setCookie(name, '', -1, path);
	document.location.reload();
};

export const bytesToHumanReadableSize = (bytes, decimals = 2) => {
	if (bytes === undefined) return 'File size not available';
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
// https://www.codeproject.com/Articles/813480/HTTP-Partial-Content-In-Node-js
