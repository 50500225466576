
	<style>
	.viewer-3d__container {
		display: inline-block;
		width: 100%;
		height: 100%;
	}
	.viewer-3d__host,
	.viewer-3d__container canvas {
		width: 100%;
		height: 100%;
	}
	</style>
	<div class="viewer-3d__host">
		<div #viewerHost class="viewer-3d__container"></div>
		<div class="viewer-3d__controls">
			<span
				class="icon-visible"
				(click)="makeScreenshot()"
				title="upload preview screenshot"></span>
			<span
				(click)="emitParameters()"
				class="icon-download-from-cloud"
				title="export the viewer parameters"></span>
		</div>
	</div>
	