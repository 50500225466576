
	<div class="sl-listview__wrapper">

	<nav>
		<a routerLink="/settings/organisation" class="box"> Organisations </a>
		<a routerLink="/settings/user" class="box"> User </a>
	</nav>

	<main>
		<h1>Settings</h1>
		Please select a category on the left.
	</main>

</div>
