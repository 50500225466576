/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {map} from 'rxjs/operators';
import {
	Component,
	ChangeDetectionStrategy,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { relations } from '../../collection/relations';
import { modelConfigs } from '../../modelConfigs';

/**
 * usage:
 * `<button-confirm-delete *ngIf="id" (confirm)="del.emit(id);"></button-confirm-delete>`
 * or
 * ```<button-confirm-delete
				*ngIf="id"
				[id]="id"
				[collectionName]="collectionName"
				(confirm)="del.emit(id);"></button-confirm-delete>```
 */
@Component({
	selector: 'button-confirm-delete',
	template: `
<button [disabled]="disabled" type="button" class="button button--red" (click)="showConfirmModal = true" i18n> DELETE </button>
<div class="modal" *ngIf="showConfirmModal" [ngClass]="{show: showConfirmModal}" (click)="showConfirmModal = false">
	<span class="modal__close icon-delete_sign"></span>
	<div class="modal__body" (click)="$event.stopPropagation()">
		<div>
			<div class="header">
				<ng-container i18n>Are you sure you want to delete?</ng-container>
				<div>
					<button class="button--red" (click)="confirm.emit(); close.emit(); showConfirmModal = false" i18n> yes </button>
					<button class="button--blue-ghost" (click)="showConfirmModal = false" i18n> no </button>
				</div>
			</div>
			<div *ngIf="hasRelationData">
				<ng-container i18n>The following dataset will be deleted too</ng-container>
				<div *ngFor="let name of keys(relationData)">
					<h3>{{name}}</h3>
					<div class="related-items">
						<a
							*ngFor="let relation of relationData[name]"
							[routerLink]="'/'+name.toLowerCase()+'/edit/'+relation._id"
							>{{relation.name}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./button-confirm-delete.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonConfirmDeleteComponent {

	@Input() disabled: boolean;
	@Input() collectionName: string;
	@Input() id: string;

	@Output() confirm = new EventEmitter(false);
	@Output() close = new EventEmitter(false);

	private subscriptions = [];
	public relationData: any;
	public hasRelationData: boolean;
	public showConfirmModal;

	constructor(private store: Store<any>) {}

	ngOnChanges() {
		this.relationData = {};
		this.hasRelationData = false;
		this.subscriptions = (relations[this.collectionName] || [])
			.filter(relation => relation.op === 'del')
			.map(
				relation => this.store
					.select(s => {
						const collectionName = relation.rel.toLowerCase();

						const modelConfig = modelConfigs[collectionName];

						return modelConfig ? s.models[collectionName] : s[collectionName];
					}).pipe(
					map((state: any) => Object.values(state.entities)
						.filter(entity => entity[relation.path] === this.id)
						.map((entity: any) => ({ _id: entity._id, name: entity.name || entity._id }))
					))
					.subscribe(realtions => {
						if (realtions.length) this.hasRelationData = true;
						this.relationData[relation.rel] = realtions
					})
			)
	}
	ngOnDestroy() {
		if (this.subscriptions) this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
	}
	keys(obj) {
		return Object.keys(obj);
	}
}

