import {
	Component,
	Input,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
} from '@angular/core';
import * as d3 from 'd3';

const parseDate = d3.timeParse('%Y-%m-%d');

const transition = d3.transition().duration(500);

@Component({
	selector: 'analytics-times',
	template: `
		<div #graphElement class="times-graph"></div>
	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./analytics-times.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsTimesComponent {
	@Input() data: [any];

	@ViewChild('graphElement', { static: true }) graphElement: ElementRef;

	private svg;
	private x;
	private y;
	private height;
	private xAxis;
	private yAxis;
	private xAxisElement;
	private yAxisElement;
	private bars;

	ngAfterViewInit() {
		const { clientHeight, clientWidth } = this.graphElement.nativeElement;
		const margin = { top: 20, right: 20, bottom: 30, left: 40 };
		const width = clientWidth - margin.left - margin.right;
		this.height = clientHeight - margin.top - margin.bottom;

		this.svg = d3
			.select(this.graphElement.nativeElement)
			.append('svg')
			.attr('width', clientWidth)
			.attr('height', clientHeight)
			.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		this.x = d3
			.scaleBand()
			.rangeRound([0, width], 0.1)
			.paddingInner(0.1);

		this.y = d3.scaleLinear().range([this.height, 0]);

		this.xAxis = d3.axisBottom().scale(this.x);

		this.yAxis = d3
			.axisLeft(this.y);

		this.xAxisElement = this.svg
			.append('g')
			.attr('class', 'x axis')
			.attr('transform', 'translate(0,' + this.height + ')');
		this.yAxisElement = this.svg
			.append('g')
			.attr('class', 'y axis')
			.call(this.yAxis)
		this.yAxisElement
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('y', 6)
			.attr('dy', '.71em')
			.style('text-anchor', 'end')
			.text('sum_daily_nb_uniq_visitors');
		this.bars = this.svg
			.selectAll('.bar')
			.data(this.data)
	}

	ngOnChanges() {
		if (!this.svg || !this.data.length) return;

		this.x.domain(this.data.map(d => d.label));
		this.y.domain([0, d3.max(this.data, d => d.sum_daily_nb_uniq_visitors || 0)]);

		this.xAxisElement.call(this.xAxis);
		this.yAxisElement.call(this.yAxis);

		const bars = this.svg.selectAll('.bar')
			.remove()
			.exit()
			.data(this.data)

		bars
			.enter()
			.append('rect')
			.attr('class', 'bar')
			.attr('x', (d) => this.x(d.label))
			.attr('width', this.x.bandwidth())
			.attr('y', (d) =>  this.y(d.sum_daily_nb_uniq_visitors))
			.attr('height', (d) => this.height - this.y(d.sum_daily_nb_uniq_visitors));
		this.yAxisElement
			.transition(transition)
			.call(this.yAxis);
		this.xAxisElement
			// .transition(transition)
			.call(this.xAxis);
	}
}
