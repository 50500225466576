<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<div class="box-3-1">
		<div>
			<h3 i18n>General-Settings</h3>
		</div>
		<div class="boxx-state">
			<label>boxx state</label>
			<div class="boxx-state__status">
				<div [ngClass]="data.boxxStatus"></div>
				<div>{{data.boxxStatus}}</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div>
			<label i18n>Name</label>
			<input formControlName="name" type="text">
		</div>
		<div *ngIf="!showForBoxxtypes(['externalmediaplayer'])">
			<label i18n>data Source</label>
			<select formControlName="dataSourceID" (change)="getDataSourceSettings(data.datasources, $event.target.value)">
				<option [ngValue]="null" selected></option>
				<option [value]="datasource._id" *ngFor="let datasource of data.datasources">{{datasource.name}}</option>
			</select>
		</div>
	</div>
	<div class="row">
		<div>
			<label i18n>MAC-Adress</label>
			<input
				formControlName="macAddress"
				type="text"
				placeholder="… 12:AB:34:CD:45:EF"
				mask="##:##:##:##:##:##"
				>
		</div>
		<div>
			<label i18n>comments</label>
			<textarea cols="30" rows="4" formControlName="comments" ></textarea>
		</div>
		<div formGroupName="specificConfig">
			<label>Boxx Type</label>
			<select formControlName="boxxType" (change)="boxxConfigForm.selectedBoxxtype = $event.target.value">
				<option *ngFor="let boxxType of model.schema.specificConfig.boxxType.enum" value="{{boxxType}}">{{boxxType}}</option>
			</select>
		</div>
	</div>

	<h3 *ngIf="!showForBoxxtypes(['externalmediaplayer'])" i18n>
		Network-Settings specific Config
	</h3>
	<div class="columns" formGroupName="specificConfig" *ngIf="!showForBoxxtypes(['externalmediaplayer'])">
		<div class="column">
			<div class="input-number">
				Talk back port
				<input type="number" formControlName="talkBackPort">
			</div>
			<div class="input-text">
				Private static LAN IP
				<input type="text" formControlName="privateLANStaticIP">
			</div>
			<div class="input-text" *ngIf="!showForBoxxtypes(['gate'])">
				Webstation URL
				<input (input)="onWebstationURLInput($event)" type="text" formControlName="webstationURL">
			</div>
			<div *ngIf="!showForBoxxtypes(['mediaplayer', 'gate'])">
				<label>Triggerhappy-DMX Mode</label>
				<select formControlName="triggerhappydmxMode">
					<option *ngFor="let triggerhappydmxMode of model.schema.specificConfig.triggerhappydmxMode.enum" value="{{triggerhappydmxMode}}">{{triggerhappydmxMode}}</option>
				</select>
			</div>
		</div>
		<div class="column">
			<input-switch formControlName="disableMesh" *ngIf="!showForBoxxtypes(['mediaplayer', 'gate'])">
				Disable wireless mesh
			</input-switch>
			<div>
				Workload threshold: {{formGroup.value.specificConfig.workloadThreshold}}
				<input type="hidden" formControlName="workloadThreshold">
			</div>
		</div>
	</div>

	<div class="spacer" *ngIf="!showForBoxxtypes(['externalmediaplayer', 'gate'])"></div>

	<h3 *ngIf="!showForBoxxtypes(['externalmediaplayer', 'gate'])">
		Overwrite-Settings
	</h3>
	<div formGroupName="overWriteExhibitionConfig">
		<boxx-config-form
			[hasDisable]="true"
			[group]="formGroup.controls.overWriteExhibitionConfig"
			[boxxType]="formGroup.value.specificConfig.boxxType"
			[dataSourceSettings]="dataSourceSettings"></boxx-config-form>
	</div>

	<div class="spacer" *ngIf="!showForBoxxtypes(['externalmediaplayer', 'gate'])"></div>

	<h3 *ngIf="!showForBoxxtypes(['externalmediaplayer'])">
		Hardware
	</h3>

	<div class="row" formGroupName="hardware" *ngIf="!showForBoxxtypes(['externalmediaplayer'])">
		<div>
			<label>Boxx Model</label>
			<select formControlName="model" (change)="setThreshold($event)">
				<option *ngFor="let boxxModel of model.schema.hardware.model.enum">{{boxxModel}}</option>
			</select>
		</div>
		<div formGroupName="usbStick" *ngIf="showForBoxxtypes(['mother', 'child', 'hub'])">
			<label>USB-Stick model</label>
			<select formControlName="model">
				<option *ngFor="let stickyTpe of model.schema.hardware.usbStick.model.enum">{{stickyTpe}}</option>
			</select>
		</div>
		<div formGroupName="usbStick" *ngIf="showForBoxxtypes(['mother', 'child', 'hub'])">
			<label>USB-Stick size</label>
			<input type="number" formControlName="size">
		</div>
	</div>

	<div *ngIf="showForBoxxtypes(['mother', 'child', 'hub'])">
		<label>usb Encryption Key</label>
		<input-file
			#inputFile
			accept="*"
			[uploadPath]="'/api/boxxes/files'"
			formControlName="usbEncryptionKey">
			<p *ngIf="inputFile.value === data.model.usbEncryptionKey">Has key file</p>
			<p *ngIf="inputFile.value !== data.model.usbEncryptionKey && inputFile.fileInfo">{{ inputFile.fileInfo.originalName }}</p>
		</input-file>
	</div>

	<div class="spacer" *ngIf="!showForBoxxtypes(['externalmediaplayer', 'gate'])"></div>

	<div *ngIf="!showForBoxxtypes(['gate', 'externalmediaplayer'])">
		<h3>
			Boxx State
		</h3>
		<pre>
			{{data.boxxState | json}}
		</pre>

		<h3>
			Events
		</h3>
		<ul class="events">
			<li
				*ngFor="let event of last100"
				[ngClass]="event.escalation">
					{{prettyDate(event.timeStamp)}} {{event.escalation}} {{event.eventID}} {{event.data | json}}
			</li>
		</ul>
	</div>
</ng-container>
