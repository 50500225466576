<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>
	<div class="first-row">
		<label i18n>Name</label>
		<input type="text" formControlName="name" i18n-placeholder placeholder="… glossary term">
	</div>

	<input-glossary-language-content formControlName="languageContent"></input-glossary-language-content>

</ng-container>
