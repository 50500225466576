/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Output,
	EventEmitter,
	forwardRef,
	ViewChildren,
	Input,
} from '@angular/core';
import { SelectModule, SelectComponent } from 'ng-select';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomValueAccessor } from '../CustomValueAccessor';


@Component({
	selector: 'select-array',
	template: `
<div class="input-widget select-array" *ngIf="value !== null">
	<div *ngFor="let optionSet of optionSets; let optionSetIndex = index;">
		<div [ngSwitch]="optionSet.type">
			<div *ngSwitchCase="'text'">
				<label>{{optionSet.label}}</label>
				<input
					[(ngModel)]="value[optionSetIndex]"
					(input)="onSelect($event, optionSetIndex)"
					[placeholder]="optionSet.placeholder"
					type="text" />
			</div>
			<ng-select
				*ngSwitchDefault
				(selected)="onSelect($event, optionSetIndex)"
				[(ngModel)]="value[optionSetIndex]"
				[options]="optionSet.options"
				[label]="optionSet.label"
				[placeholder]="optionSet.placeholder">
			</ng-select>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./select-array.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectArrayComponent), multi: true }],
})
export class SelectArrayComponent extends CustomValueAccessor {
	@Output() change = new EventEmitter();
	@Output() complete = new EventEmitter();

	@Input() optionSets: Array<any> = [];
	@Input() clearOnComplete: Boolean;

	@ViewChildren(SelectComponent) selectComponents;

	ngOnInit() {
		this.optionSets = this.optionSets || [];
		this.clearOnComplete = this.clearOnComplete || false;
		this.value = this.value || [];
	}

	onSelect() {
		const _value = this.value;
		this.change.emit(this);

		// if there are no holes
		if (!this.optionSets.find((set, i) => _value[i] === undefined)) {
			this.complete.emit(_value);

			if (this.clearOnComplete) {
				this.clear();
			}
		}

		this.propagateChange();
	}

	clear() {
		this.selectComponents.forEach(selectComponent =>
			selectComponent.clear()
		);
	}
}
