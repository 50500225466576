/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppHttp } from '../../../services/AppHttp';
import config from '../../../config';
import { datasourceConfig } from '../../../_datasources/config';

@Component({
	selector: 'organisation-form',
	templateUrl: './index.html',
})
export class OrganisationFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	private datasourceDefaultData = {};
	public hostname = config.hostname === 'localhost' ? 'staging.shoutrlabs.com' : config.hostname;

	constructor(private store: Store<any>, private appHttp: AppHttp) {}

	ngOnChanges(changes) {
		if (changes.id) {
			Object.assign(this.datasourceDefaultData, datasourceConfig.defaultData, { organisationID: this.id });
		}
	}
	rebuildAllArticles() {
		this.appHttp.post(`/organisations/${this.id}/actions/setdirty`).subscribe();
	}
	addMediaFilenames() {
		this.appHttp.post(`/organisations/${this.id}/actions/add-media-file-names`).subscribe();
	}
}
