/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { DefaultValueAccessor } from '@angular/forms';
import { Input, ChangeDetectorRef, ElementRef, Renderer } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

const drakeDefaults = {
	invalid: (element, handle) => !handle.closest('.drag-handle'),
};

interface CustomValueAccessorOptions {
	drake?: any;
	drakeOptions?: any;
	bagName?: string;
	changeDetectorRef?: ChangeDetectorRef;
};

/**
 * https://stackoverflow.com/a/37786142/1436151
 */
export class CustomValueAccessor {

	protected changeDetectorRef;
	private drake;
	public bagName;
	protected _value;
	protected onChange;
	protected onTouched;

	constructor(public i18n: I18n) {
		this.onChange = () => { };
		this.onTouched = () => { };
	}
	initOptions(options: CustomValueAccessorOptions = { changeDetectorRef: null }) {
		// if using Dragula for drag and drop of values,
		// `drake`, `bagName` and `changeDetectorRef` *must* be in options.
		// `options.drakeOptions` may also be supplied
		if (options.drake) {
			options.drake.createGroup(
				options.bagName,
				Object.assign({}, drakeDefaults, options.drakeOptions)
			);
			options.drake.dropModel(options.bagName).subscribe(() => {
				this.forceChangeDetection();
			});

			this.drake = options.drake;
			this.bagName = options.bagName;
		}

		if (options.changeDetectorRef) {
			this.changeDetectorRef = options.changeDetectorRef;
		}
	}

	ngOnDestroy() {
		if (this.drake) this.drake.destroy(this.bagName);
	}

	get value() {
		return this._value;
	}

	@Input() set value(value) {
		this.set_value(value);
	}

	set_value(value) {
		if (value !== this._value || (typeof value === 'object')) {
			this._value = value;
			this.onChange(value);
			this.onTouched();
		}
	}

	writeValue(value) {
		this.value = value;
	}

	propagateChange() {
		this.onChange(this._value);
	}

	// Force angular to update views and mark forms as dirty, changed etc.  In
	// some cases calling `this.onChange` isn't enough to propagate existing
	// changes so inheriting classes which have problems should pass a
	// options.changeDetectorRef: ChangeDetectorRef to the super constructor so
	// that it may be reattached when this method is called
	// https://angular.io/docs/ts/latest/api/core/index/ChangeDetectorRef-class.html#!#markForCheck-anchor
	forceChangeDetection() {
		this.propagateChange();
		this.changeDetectorRef.markForCheck();
	}
	registerOnChange(fn) {
		this.onChange = fn;
	}
	registerOnTouched(fn) {
		this.onTouched = fn;
	}
}
