/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Directive, Input, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { CustomValueAccessor } from './CustomValueAccessor';
import { uid, pluckDistinct } from '../utils';
import { filter, delay, takeUntil } from 'rxjs/operators';

// ## Mask Input
// Format input according to specified mask while the user is typing
// usage:
// ```
// <input type="text" mask="##:##:##:##:##:##">
// ```
// the `mask` property triggers this directive. Masks only use `#` as wildcard
// character. Is should be escabable, this is however untested.
@Component({
	selector: 'input-disable',
	template: `
<div class="input-widget">
	<input
		type="checkbox"
		class="btn-checkbox"
		attr.id="{{inputUid}}-checkbox"
		[disabled]="selfDisabled"
		(change)="handleCheckbox($event.target.checked)"
		[checked]="checked"
		[(ngModel)]="checked">
		<label attr.for="{{inputUid}}-checkbox"></label>
	<ng-content></ng-content>
</div>
	`,
	styleUrls: ['./input-switch.component/input-switch.component.sass'],
})
export class InputDisableComponent {
	@Input() control: FormControl;
	@Input() disabled = false;
	@Input() selfDisabled = false;
	@Input() defaultValue = null;

	private destroyed$ = new Subject();
	private
	public checked = false;
	public inputUid = uid();

	constructor(store: Store<any>, private cdr: ChangeDetectorRef) {
		pluckDistinct(store, 'routeInfo', 'id').pipe(
				filter(() => !!this.control),
				delay(0),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				if (this.control && this.control.value === null) {
					this.disable();
				}
			});
	}

	ngOnChanges(changes) {
		if (changes.control && this.control) {
			if (this.control.value === null) {
				this.disable();
			}

			this.control.registerOnChange(() => {
				if (this.control.value !== null) {
					this.enable();
				}
			});

			this.control.registerOnDisabledChange(() => {
				if (this.control.disabled && !this.disabled) {
					this.control.enable();
				}
			});
		}
	}

	handleCheckbox(checked) {
		if (checked) {
			this.enable();
			this.control.setValue(this.defaultValue);
		} else {
			this.disable();
		}

		this.control.markAsDirty();
	}

	enable() {
		this.disabled = false;
		this.checked = !this.disabled;
		this.control.enable();
	}

	disable() {
		this.disabled = true;
		this.checked = !this.disabled;

		if (this.control.value !== null) {
			this.control.setValue(null);
		}

		this.control.disable();
		this.cdr.markForCheck();
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
