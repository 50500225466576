/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map, pluck} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { userConfig } from '../../../_users/config';
import * as actions from '../../../socket.actions';
import { selectors } from '../../../selectors';

@Component({
	selector: 'user-manager',
	template: `
<div class="no-users" *ngIf="!userList.length">
	<i i18n>... the organisation does not have users</i>
	<a
		class="button"
		routerLink="/user">
		<div class="icon-add"></div>
		<ng-container i18n>add user</ng-container>
	</a>
</div>
<ul>
	<li *ngFor="let user of userList">
		<a routerLink="/user/edit/{{user._id}}">
			{{user.firstName}} {{user.lastName}} ({{getRole(user)}})
		</a>
	</li>
</ul>
	`,
	styleUrls: ['./user-manager.component.sass'],
})
export class UserManagerComponent {
	@Input() organisationID;

	@Output() changed = new EventEmitter(false);

	private organisationID$;
	public userList;

	constructor(private store: Store<any>) {
		this.store = store;
		this.store.dispatch(new actions.GetCollectionList({ collectionName: userConfig.collectionName }));
		this.organisationID$ = new EventEmitter(false);
		this.userList = [];
		observableCombineLatest(
			this.store.pipe(pluck('models', 'User', 'entities'), map(entities => Object.values(entities))),
			this.organisationID$,
			(users: any, organisationID) => users.filter(user => user.roles.find(role => role.organisationID === organisationID)),
		).subscribe((users) => {
			this.userList = users;
		});
	}
	ngOnChanges(changed) {
		if (changed.organisationID) this.organisationID$.emit(this.organisationID);
	}
	getRole(user) {
		if (!user) return '';
		const roles = user.roles.filter(role => role.organisationID === this.organisationID);
		return roles.length ? roles[0].name : 'unknown';
	}
	ngOnDestroy() {
		this.store.dispatch(new actions.LeaveCollectionList({ collectionName: userConfig.collectionName }));
	}
}
