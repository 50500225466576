/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'exhibit-form',
	templateUrl: './index.html',
	styleUrls: ['./index.sass'],
})
export class ExhibitFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	onCreateExhibition({ data }) {
		this.formGroup.patchValue({ exhibitionID: data._id });
	}
}
