/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { environment } from '../environments/environment';

const config = {
	baseURL: 'http://localhost:3000',
	hostname: 'localhost',
	port: '3000',
};

if (environment.production || (<any>window).serverEnvironment === 'staging') {
	config.hostname = window.location.hostname;
	config.port = window.location.port;
	config.baseURL = `${location.protocol}//${config.hostname}`;
	if (window.location.port) config.baseURL += `:${config.port}`;
}

export default config;
