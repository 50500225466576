/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-var, vars-on-top */
var deepExtend = require('deep-extend');
var Schema = require('../schemaWrapper');
var getLabels = require('../getLabels').getLabels;

function nullAllDefaults(object) {
	Object.keys(object).forEach((key) => {
		// logger.debug("Field with Key " + key + ": "+ JSON.stringify(object[key]));
		if (typeof object[key].default !== 'undefined') {
			object[key].default = null;
		} else {
			nullAllDefaults({ ...object[key] });
		}
	});
}

var overWriteWebClientConfigSchema = deepExtend(
	{},
	require('../dataSource/webClientConfigSchema').definition
);

nullAllDefaults(overWriteWebClientConfigSchema);

var schemaDefinition = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	dataSourceID: { type: Schema.ObjectId, ref: 'DataSource', label: 'Data source' },
	name: { type: String, required: true, label: 'Name' },
	address: { type: String },
	website: { type: String },
	comments: { type: String },
	immortal: { type: Boolean, default: false },
	urlToken: { type: String, writable: false },
	isLive: { type: Boolean, default: false },
	isUpToDate: { type: Boolean, default: false, writable: false },
	isInOfflineAppProviderInit: { type: Boolean, default: false },
	isInExhibitionList: { type: Boolean, default: true },
	defaultLanguage: { type: String, required: true },
	languageContent: { type: [require('./languageExhibitionSchema').definition], required: true },
	coverID: { type: Schema.ObjectId, ref: 'MediaObject', mediaType: 'image' }, // Has to be type Image
	coverIDVersion: { type: Number },
	styleTemplateID: { type: Schema.ObjectId, ref: 'StyleTemplate', label: 'Style Template' },
	configWebClient: require('../dataSource/webClientConfigSchema').definition,
	landingExhibitID: { type: Schema.ObjectId, ref: 'Exhibit' },
	customMapArticleID: { type: Schema.ObjectId, ref: 'Article' },
	hasOnlineNavigationMap: { type: Boolean, default: false },
	onlineMapBoundsLocationID: { type: Schema.ObjectId, ref: 'Location' },
	tutorialTourID: { type: Schema.ObjectId, ref: 'Tour' },
	triggers: [
		{
			locationID: { type: Schema.ObjectId, ref: 'Location' },
			triggerType: {
				type: String,
				enum: ['trigger', 'forceTrigger', 'forceOneShotTrigger'],
				default: 'trigger',
			},
		},
	],
	navigationJson: { type: Object, default: {} },
	analyticsSiteId: { type: Number },
	shareTargetUrl: { type: String, required: false },
	webClientConfig: overWriteWebClientConfigSchema,
	publishedVersions: [
		{
			version: { type: Number },
			publishDate: { type: Date },
			publisherID: { type: Schema.ObjectId, ref: 'User' },
			locations: [
				// TODO this is not use yet
				{
					id: { type: Schema.ObjectId, ref: 'Location' },
					version: { type: Number },
				},
			],
		},
	], // Sorted List of Version which have been published
};
var ModelSchema = new Schema(schemaDefinition);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Exhibition',
	schema: ModelSchema,
	definition: schemaDefinition,
	labels: Object.assign(getLabels(schemaDefinition), {
		languageContent: 'Language content',
	}),
	relations: [{ op: 'del', rel: 'Exhibit', path: 'exhibitionID', deleteRelatives: true }],
};
