/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { definition as schema } from '../../../models/styleTemplate/styleTemplateSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { StyletemplateFormComponent } from './components/styletemplate-form';
import { selectors } from '../selectors';
import { actions } from '../actions';
import { datasourceConfig } from '../_datasources/config';
import { exhibitionConfig } from '../_exhibitions/config';
import { pluckDistinct } from '../utils';
import * as collectionActions from '../socket.actions';

export const styletemplateConfig = new ModelConfig({
	collectionName: 'StyleTemplate',
	clientPathPrefix: '/admin',
	namePlural: 'Styles',
	icon: 'icon-template',
	schema,
	formComponent: StyletemplateFormComponent,
});

Object.assign(styletemplateConfig, {
	getFormData$(store, id, versionId?) {
		if (id) {
			store.dispatch(new collectionActions.GetCollectionFullList({ collectionName: datasourceConfig.collectionName }));
			store.dispatch(new collectionActions.GetCollectionFullList({ collectionName: exhibitionConfig.collectionName }));
		}

		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			pluckDistinct(store, 'models', datasourceConfig.collectionName, 'entities').pipe(map(entities => Object.values(entities))),
			pluckDistinct(store, 'models', exhibitionConfig.collectionName, 'entities').pipe(map(entities => Object.values(entities))),
			(model: any, dataSources, exhibitions) => {
				return {
					model,
					dataSources: !dataSources ? [] : dataSources.filter(({styleTemplateID}) => styleTemplateID === id),
					exhibitions: !exhibitions ? [] : exhibitions.filter(({styleTemplateID}) => styleTemplateID === id),
				};
			},
		);
	},

	ngOnDestroy() {
		this.store.dispatch(new collectionActions.LeaveCollectionFullList({ collectionName: datasourceConfig.collectionName }));
		this.store.dispatch(new collectionActions.LeaveCollectionFullList({ collectionName: exhibitionConfig.collectionName }));
	},
});
