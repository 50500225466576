/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { definition as schema } from '../../../models/cmsSetting/cmsSettingSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { CmsSettingsFormComponent } from './components/cmssettings-form';
import { selectors } from '../selectors';
import { pluckDistinct } from '../utils';
import { map } from 'rxjs/operators';

export const cmssettingsConfig = new ModelConfig({
	name: 'cmssettings',
	namePlural: 'cmssettings',
	collectionName: 'CmsSetting',
	clientPathPrefix: '/admin',

	dependencies: [],

	schema,

	formComponent: CmsSettingsFormComponent,
});

Object.assign(cmssettingsConfig, {
	getFormData$(store, id, versionId?) {
		return observableCombineLatest(
			this.getItem$(store, id, versionId),
			(model: any) => {
				return {
					model,
				}
			},
		);
	},
	getListData$(store) {
		return pluckDistinct(store, 'models', this.collectionName).pipe(map(selectors.getListAndSort));
	},
});
