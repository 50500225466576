/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { currentuserActions as Actions } from './actions';
import * as socketActions from '../socket.actions';
import { actions as newActions } from '../actions'

export const initialState = {
	currentuser: {
		roles: [],
		_id: null,
		enableTOTP: false,
	},
	organisation: {
		id: null,
	},
	exhibition: {
		id: null,
	},
	access: {
		isAdmin: false,
		isDesigner: false,
		orgAdmin: false,
	},
	collectionAction: '',
	extendNav: false,
	leftColumnStyle: '',
	rightColumnStyle: '',
	dependencyMaps: {},
	apiKey: '',
	userNotAuthenticated: true,
};

interface Role { name: string, organisationID: string };

function hasRoleInOrg(roles: Role[], organisation: any, targetRole: string) {
	return roles.some(
		role => (role.organisationID === organisation._id) && (role.name === targetRole)
	);
}

function getAccess(roles: Role[], organisation: any) {
	return {
		isAdmin: roles.some(role => role.name === 'root'),
		isDesigner: hasRoleInOrg(roles, organisation, 'designer'),
		isOrgAdmin: hasRoleInOrg(roles, organisation, 'admin'),
	};
}

export function reducer(state = initialState, action) {
	switch (action.type) {
	// case newActions.LOAD_MULTI_SUCCESS:
	// 	if (action.payload.user) {
	// 		const currentuser = action.payload.user.find(({ _id }) => _id === state.currentuser._id);
	// 		if (currentuser) {
	// 			return Object.assign(
	// 				{},
	// 				state,
	// 				{ currentuser }
	// 			);
	// 		}
	// 	}
	// 	return state;
	case Actions.RECOVER_STATE:
		return Object.assign({}, state, action.data);
	case Actions.TOGGLE_EXTENDNAV:
		return Object.assign({}, state, {
			extendNav: action.toggleState || !state.extendNav,
		});
	case Actions.RESIZE_LEFT_COLUMN:
		return Object.assign({}, state, {
			leftColumnStyle: action.payload ? action.payload + 'px' : '',
		});
	case Actions.RESIZE_RIGHT_COLUMN:
		return Object.assign({}, state, {
			rightColumnStyle: action.payload ? action.payload + 'px' : '',
		});
	case socketActions.CURRENT_USER_DATA_UPDATED:
	case Actions.PATCH_CURRENTUSER_SUCCESS: {
		return {
			...state,
			currentuser: action.payload,
			access: getAccess(action.payload.roles, state.organisation),
		};
	}

	case socketActions.DEPENDENCY_MAPS_DATA_UPDATED:
		return Object.assign({}, state, {
			dependencyMaps: {
				...state.dependencyMaps,
				...action.payload
			}
		});

	case Actions.CURRENTUSER_SET_ORG:
		return {
			...state,
			organisation: action.payload,
			access: getAccess(state.currentuser.roles, action.payload),
		};

	case Actions.LOGOUT_SUCCESS:
		return Object.assign({}, state, {
			currentuser: {
				roles: [],
			},
			organisation: {
				id: null,
			},
		})
	case socketActions.API_KEY_UPDATED:
		return {
			...state,
			apiKey: action.payload.apiKey
		}
	case Actions.CURRENTUSER_SET_2FACTOR_AUTHENTICATION_STATUS:
		return {
			...state,
			userNotAuthenticated: action.payload.userNotAuthenticated,
		}
	default:
		return state;
	}
};
