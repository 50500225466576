/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {distinctUntilChanged, pluck} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'account-nav',
	template: `
<div class="sl-listview__wrapper">
	<nav [ngStyle]="{ width: (leftColumnStyle$ | async), flex: (leftColumnStyle$ | async) ? 'none' : '' }">
		<a routerLink="/account/profile" class="box" i18n>
			Profile
		</a>
		<a routerLink="/account/password" class="box" i18n>
			Password
		</a>
	</nav>

	<main>
		<ng-content></ng-content>
	</main>
</div>
`,
	styleUrls: ['./account-nav.component.sass'],
})
export class AccountNavComponent {
	public leftColumnStyle$: Observable<string>;

	constructor(store: Store<any>) {
		this.leftColumnStyle$ = store.pipe(pluck('currentuser', 'leftColumnStyle'), distinctUntilChanged(), ) as Observable<string>;
	}
}

