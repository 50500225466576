/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { currentuserActions } from '../actions';

@Component({
	selector: 'reset-password',
	template: `
	<form
		class="login"
		[formGroup]="form"
		(ngSubmit)="onSubmit()">
	<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100">
	<h1>shoutr labs</h1>
	<div class="form">
		<h2 i18n>Reset your CMS password</h2>
		<div>
			<label i18n>email</label>
			<input
				required
				pattern="^.+@.+\\.\\w+$"
				type="email"
				formControlName="email">
		</div>
		<button class="button" type="submit" i18n>Reset password</button>
		<div class="forgot-password">
			<a routerLink="/account/login" i18n>Login</a>
		</div>
	</div>
</form>
	`,
	styleUrls: ['./login.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent {

 	public form;

  constructor(private fb: FormBuilder, private store: Store<any>) {}

	ngOnInit() {
		this.form = this.fb.group({
			email: ['', Validators.required],
		});
	}

	onSubmit() {
		if (this.form.valid) {
			this.store.dispatch(currentuserActions.resetpassword(this.form.value));
		}
	}
}
