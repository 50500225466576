
	<div class="input-date-time">
		<my-date-picker
			[disabled]="disabled"
			[ngModel]="_value === null ? null : { jsdate: _value }"
			[options]="{ dateFormat: 'dd.mm.yyyy', showClearDateBtn: false }"
			(dateChanged)="onPickDate($event)">
		</my-date-picker>
		<input-time [disabled]="disabled" [ngModel]="_value" (inputTime)="this.propagateChange();">
		</input-time>
	</div>
	