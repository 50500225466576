/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {fromEvent as observableFromEvent,  Observable ,  Subject } from 'rxjs';

import {takeUntil, debounceTime} from 'rxjs/operators';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	ViewChild,
} from '@angular/core';
import { selectors } from '../../selectors';

@Component({
	selector: 'input-filter',
	template: `
<div class="input-widget">
	<div class="icon-filter"></div>
	<div
		class="filters"
		*ngIf="this.filterBoxes.length">
		<div *ngFor="let name of filterBoxes">
			{{name}} <span class="icon-clear-field_S" (click)="clear()"></span>
		</div>
	</div>
	<input
		#textBox
		[style.display]="this.filterBoxes.length ? 'none' : ''"
		type="text"
		[value]="value"
		(keyup.enter)="this.value = $event.target.value"
		(keyup.escape)="clear()"
		(keyup)="keyup.emit($event)"
		i18n-placeholder
		placeholder="… filter"/>
	<span (click)="clear()" class="clear icon-clear-field_S"></span>
</div>
	`,
	styleUrls: ['./input-filter.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFilterComponent {
	@Input() set value(value) {
		value = value || '';

		if (value === this._query) { return; }

		this._query = value;
		if (this.textBox) {
			this.textBox.nativeElement.value = value;
		}

		if (value.includes('|')) {
			const [, queryValuesString] = value.split('|');
			const queryValuesList = queryValuesString.split(' ');
			this.filterBoxes = [`ids (${queryValuesList.length})`];
		} else {
			this.filterBoxes = [];
		}

		this.updateResult();
	};
	get value () { return this._query; };

	@Input() items: any[];

	@Output() filter = new EventEmitter(false);
	@Output() keyup = new EventEmitter(false);

	@ViewChild('textBox', { static: true }) textBox;

	private _query = '';
	public filterBoxes = [];
	public result = [];
	public destroyed$ = new Subject();

	ngAfterViewInit() {
		const inputElement = this.textBox.nativeElement;
		observableFromEvent(inputElement, 'input').pipe(
			debounceTime(200),
			takeUntil(this.destroyed$), )
			.subscribe((event: Event) => {
				this.value = (event.target as any).value;
			});

		requestAnimationFrame(() => inputElement.focus());
	}

	ngOnChanges(changes) {
		if (changes.items) {
			this.updateResult();
		}
	}

	updateResult() {
		this.result = selectors.filter(this.items || [], this.value);

		this.filter.emit({ query: this.value, result: this.result });
	}

	clear() {
		this.value = '';
		this.filterBoxes = [];
		this.updateResult();
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

}
