/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Observable } from 'rxjs';

import { ApplogFormComponent } from './components/applog-form';
import { schema } from '../../../models/logging/appLogSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';

export const applogConfig = new ModelConfig({
	collectionName: 'AppLog',
	apiEndpoint: 'applogs',
	clientPathPrefix: '/admin',
	schema,
	formComponent: ApplogFormComponent,
});


