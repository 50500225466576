/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = new Schema({
	languageISO: { type: String, required: true },
	title: { type: String },
	attribution: { type: String, label: 'Attribution' },
	description: { type: String },
	source: { type: Schema.ObjectId },
	fileSize: { type: Number },
	mediaInfo: {
		width: { type: Number },
		height: { type: Number },
		format: { type: String },
		hasAlpha: { type: Boolean },
		duration: { type: Number },
		filename: { type: String },
	},

	threeD: {
		type: {
			format: {
				type: String,
				enum: ['obj', 'fbx'],
				required: true,
			},
			material: { type: Schema.ObjectId, required: false },
			textures: [Schema.ObjectId],
			resourceMap: {
				/* Schema.ObjId: resourceFilename */
			},
			viewerOptions: {
				/* TODO: camera, lighting, etc. options for Viewer3D */
			},
		},
		required: false,
	},

	thumbSource: { type: Schema.ObjectId },
	thumbCoords: {
		x: { type: Number, writable: false },
		y: { type: Number, writable: false },
		width: { type: Number, writable: false },
		height: { type: Number, writable: false },
	},
	thumbTime: { type: Number, writable: false },
	altText: { type: String },
	customFields: { type: Object },
});
var ModelSchema = new Schema({ def });

module.exports = {
	schema: ModelSchema,
	definition: def,
};
