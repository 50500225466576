
<div class="list">
	<div *ngFor="let item of value; let idx=index">
		{{item.name}}
		<span class="icon-remove" (click)="remove(idx)"></span>
	</div>
</div>
<div class="input-widget">
	<input-file [type]="type" [accept]="accept" [uploadPath]="uploadPath" (fileChange)="onFileChange($event)"></input-file>
</div>
	