/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Validators } from '@angular/forms';

function isSchema(field) {
	if (field.isField) return false;
	if (field.type) {
		if ((typeof field.type === 'object') && field.type.isField) {
			return true;
		}
		return false;
	}
	return typeof field === 'object';
}

const EXCLUDE_FIELDS = ['plugin', 'add', 'definition', 'isSchema', 'methods'];

/**
 * ##getDefaults
 * Recurse the mongoose Schema and set the form validators as well as the default values and input types.
 * https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
 * @param {Object} schemaTree a mongoose schema tree
 */
export function getDefaults(schema) {
	if (!schema) debugger;
	const defaultValues = {};

	Object.keys(schema)
		.filter(key => !EXCLUDE_FIELDS.includes(key))
		.forEach((key) => {
			const currentItem = schema[key];

			if (!currentItem || typeof currentItem !== 'object' || currentItem.writable === false) {
				return;
			}

			if (isArrayType(currentItem)) {
				const entry = currentItem[0] || currentItem.type[0];

				if (entry.writable !== false) {
					defaultValues[key] = currentItem.default || [];
				}
			} else if (isSchema(currentItem)) {
				defaultValues[key] = getDefaults(currentItem);
			} else {
				if (('default' in currentItem) && currentItem.default != null) {
					defaultValues[key] = schema[key].default;
				} else {
					defaultValues[key] = null;
				}
			}
		});
	return defaultValues;
}

function isArrayType(item) {
	return !!item && (
		// item: [thing]
		Array.isArray(item)
		// item: { type: [thing] }
		|| Array.isArray(item.type)
		|| item === Array
		|| item.type === Array);
}

export function getValidators(formBuilder, schema, modelDefaults) {
	const validators = {};
	const arrayFields = [];

	Object.keys(schema)
		.filter(key => !EXCLUDE_FIELDS.includes(key))
		.forEach((key) => {
			const currentItem = schema[key];

			if (typeof currentItem !== 'object' || currentItem.writable === false) {
				return;
			}

			if (isArrayType(currentItem)) {
				const entry = currentItem[0] || currentItem.type[0];

				if (entry.writable !== false) {
					arrayFields.push(key);

					validators[key] = currentItem.required || entry.required
						? [modelDefaults[key], Validators.required]
						: [modelDefaults[key]];
				}
			} else if (isSchema(currentItem)) {
				validators[key] = getValidators(formBuilder, schema[key], modelDefaults[key]);
			} else {

					validators[key] = currentItem.required
						? [modelDefaults[key], Validators.required]
						: [modelDefaults[key]];
			}
		});

	const formGroup = formBuilder.group(validators);

	arrayFields.forEach(key => {
		const control = formGroup.controls[key];

		// subscribe to changes in array control values
		control.valueChanges.subscribe(value => {
			// ensure that the value is always an array
			if (!Array.isArray(value)) {
				control.setValue([]);
			}
		});
	})

	return formGroup;
}

export function getInputTypes(schema) {
	const inputTypeMap = { String: 'text', Number: 'number', Date: 'datetime' };
	const inputTypes = {};

	Object.keys(schema)
		.filter(key => !EXCLUDE_FIELDS.includes(key))
		.forEach((key) => {
			const currentItem = Array.isArray(schema[key]) ? schema[key][0] : schema[key];
			if (currentItem.writable === false) {
				// skip this item
			} else if (isSchema(currentItem)) {
				inputTypes[key] = getInputTypes(schema[key]);
			} else if (typeof currentItem === 'object') {
				if (('default' in currentItem) && currentItem.default) {
					inputTypes[key] = schema[key].type in inputTypeMap ? inputTypeMap[schema[key].type] : 'text';
				} else {
					inputTypes[key] = 'text';
				}
			}
		});
	return inputTypes;
}

/**
 * Try to remove non writable fields from the form data. This doesn't remove
 * fields of array entries.
 */
export function removeNonWritableFields(data, schema) {
	const schemaType = schema.type && !('type' in schema.type)
		? schema.type
		: schema;

	for (const field in schemaType) {
		if (!schemaType[field] || Array.isArray(schemaType[field])) {
			continue;
		}

		if (schemaType[field].writable === false) {
			delete data[field];
		} else if (!!data[field] && isSchema(schemaType[field])) {
			removeNonWritableFields(data[field], schemaType[field]);
		}
	}
}
