
<div *ngIf="fields?.length" class="input-widget">
	<label i18n>Custom Fields</label>
	<div *ngFor="let field of fields">
		<div> {{field.label}} </div>
		<div [ngSwitch]="field.type">
			<input
				*ngSwitchCase="'String'"
				type="text"
				(input)="onChangeField(field, $event.target.value)"
				[value]="getItemValue(field.name)"
				placeholder="…">
			<input
				*ngSwitchCase="'Number'"
				(input)="onChangeField(field, $event.target.value)"
				[value]="getItemValue(field.name)"
				type="number"
				placeholder="…">
			<input-json
				*ngSwitchCase="'Object'"
				(input)="onChangeField(field, $event)"
				[value]="getItemValue(field.name)"
				type="number"
				placeholder="…"></input-json>
		</div>
	</div>
</div>
	