/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable ,  Subject } from 'rxjs';

import {map, pluck, takeUntil} from 'rxjs/operators';
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { unifiedArticleConfig } from '../../../_unifiedarticles/config';
import { actions } from '../../../actions';
import * as CollectionActions from '../../../socket.actions';
import config from '../../../config';

import { selectors } from '../../../selectors';
import { CollectionJoiner } from '../../../utils';

@Component({
	selector: 'select-unified-article',
	template: `
		<div class="filter" (click)="$event.stopPropagation()">
			<input-filter
				#filterComponent
				[items]="articlesFiltered()"
				(filter)="filteredItems = $event.result"
				(keyup.enter)="selectFirst()">
			</input-filter>
			<div class="sort-menu">
				<div i18n> Sort </div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'changeLog.editedOn')}"
					(click)="sorting('changeLog.editedOn')">
					<div i18n>by date</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
				<div
					[ngClass]="{'sort-direction--active': (data.articles.sort.objectPath == 'articleID.name')}"
					(click)="sorting('articleID.name')">
					<div i18n>by name</div>
					<div
						class="icon-expand-arrow_S sort-direction"
						[ngClass]="{'sort-direction--reverse': data.articles.sort.reverse}"></div>
				</div>
			</div>
		</div>

		<div class="list">
			<div
				*ngFor="let article of filteredItems"
				(click)="selectarticle.emit(article._id)"
				[ngStyle]="{backgroundImage: getUrl(article)}">
					<span>{{article.articleID.name}}</span>
				</div>
		</div>

		<form-modal #newModal collectionName="Article" i18n>
			Create a new article
		</form-modal>
	`,
	styleUrls: ['./index.sass'],
})
export class SelectUnifiedArticleComponent {
	public data;
	public filteredItems;
	private destroyed$ = new Subject();
	public createArticleUrl = `${unifiedArticleConfig.clientPathPrefix}/${unifiedArticleConfig.name}/create`;
	private sort;

	@Input()
	exhibitionID;
	@Input()
	quiz;

	@Output()
	selectarticle = new EventEmitter(false);

	constructor(private store: Store<any>) {
		observableCombineLatest(
			selectors.getSortedListForOrg$(store.pipe(pluck('models', 'UnifiedArticle')), store),
			this.store.pipe(pluck('models', 'MediaObject'), map(selectors.getEntities), ),
			(articles, mediaobjects) => ({ articles, mediaobjects })
		).pipe(
			takeUntil(this.destroyed$))
			.subscribe(data => {
				this.data = data;
			});
	}

	articlesFiltered() {
		let listOfArticles = null;
		if (!this.exhibitionID) return this.data.articles.list;
		if (this.quiz) {
			listOfArticles = this.data.articles.list
				.filter(({ exhibitionID, articleID }) => exhibitionID === this.exhibitionID && articleID.isQuizArticle);
		} else {
			listOfArticles = this.data.articles.list.filter(({ exhibitionID }) => exhibitionID === this.exhibitionID);
		}
		return listOfArticles;
	}

	ngOnInit() {
		CollectionJoiner.getUntil(this.store, ['UnifiedArticle', 'MediaObject'], this.destroyed$);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	getUrl(article) {
		const mediaObject = this.data.mediaobjects[article.articleID.coverID];
		if (!mediaObject) return '';
		if (!(mediaObject.languageContent && mediaObject.languageContent.length)) return '';
		return `url(${config.baseURL}/api/mediafiles/${mediaObject.languageContent[0].source}/200)`;
	}

	selectFirst() {
		const first = this.filteredItems[0];

		if (first) {
			this.selectarticle.emit(first._id);
		}
	}

	sorting(objectPath) {
		this.store.dispatch(actions.sort('UnifiedArticle', objectPath));
	}
}
