/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as collectionActions from '../socket.actions';
import { getDefaults } from './../services/schemaTools';
import { selectors } from '../selectors';
import { ModelEditFooterComponent } from '../components/model-edit-footer.component';
import { pluckDistinct } from '../utils';

export class ModelConfig {
	public collectionName;
	public name;
	public namePlural;
	public apiEndpoint;
	public clientPathPrefix;
	public icon;
	public dependencies;
	public context;
	public schema;
	public labels;
	public defaultData;
	public initialState;
	public publishable;
	public sortModes;

	public formComponent: Type<any>;
	public infoHtml;

	constructor({
		collectionName,
		name = collectionName.toLowerCase(),
		namePlural = `${name}s`,
		apiEndpoint = `${collectionName}s`,
		clientPathPrefix = null as string,
		icon = null as string,
		dependencies = [] as { name: string, full?: boolean, inSidebar?: boolean, inList?: boolean, options?: any }[],
		context = dependencies.filter(dep => dep.inSidebar).map((dep => dep.name)),
		schema,
		labels = {},
		defaultData = getDefaults(schema),
		initialState = {},
		publishable = false,
		sortModes = [
			{ name: 'name', objectPath: 'name' },
			{ name: 'date', objectPath: 'changeLog.editedOn' },
		],

		formComponent = null as Type<any>,
		infoHtml = null as string,
	}) {
		this.name = name;
		this.namePlural = namePlural;
		this.collectionName = collectionName;
		this.apiEndpoint = apiEndpoint;
		this.clientPathPrefix = clientPathPrefix;
		this.icon = icon;
		this.dependencies = dependencies;
		this.context = context;
		this.schema = schema;
		this.labels = labels;
		this.defaultData = defaultData;
		this.initialState = Object.assign({
			entities: {},
			deps: {},
			sort: {
				objectPath: 'name',
				reverse: false,
			},
			sortedItems: [],
			alphabeticSortedItems: [],
			initialized: false,
			joinedRoom: false,
			filter: '',
		}, initialState);
		this.publishable = publishable;
		this.sortModes = sortModes;

		this.formComponent = formComponent;
		this.infoHtml = infoHtml;
	}

	getItem$(store: Store<any>, id?: string, versionId?) {
		return selectors
			.getOne(store, this.collectionName, id, this.defaultData, versionId);
	}

	getFormData$(store, id, versionId?) {
		return this.getItem$(store, id, versionId).pipe(map(model => ({ model })));
	}

	getListData$(store) {
		const modelState$: Observable<any> = pluckDistinct(store, 'models', this.collectionName);

		return observableCombineLatest(
			pluckDistinct(modelState$, 'filter'),
			selectors.getSortedListForOrg$(modelState$, store),
		).pipe(map(([filter, data]) => {
			return {
				...data,
				filter,
			};
		}));
	}

	getItemCoverId(item: any, data?: any) {
		return item.coverID || (item.type === 'image' && item._id);
	}

	getItemTitle(item) {
		return item.name;
	}

	getItemComments(item) {
		return item.comments;
	}

	getItemType(item) {
		return item.type;
	}

	getPublishEnabled(item: any, data: any) {
		return !!item && item.isLive && !item.isUpToDate;
	}

	beforeSave(formGroup: FormGroup, itemData: any, footer: ModelEditFooterComponent) {
		return true;
	}

	getItemAddress(item) {
		const [, address] = (item.address) ? item.address.split(',') : '';
		return address;
	}
}
