/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { RouterModule } from '@angular/router';

import { LoginComponent } from './components/login.component';
import { ResetPasswordComponent } from './components/reset-password.component';
import {
	AccountProfileComponent,
	AccountPasswordComponent,
} from './components/account';
import { Validate2FCodeComponent } from './components/validate2FCode.component';


export const routes = RouterModule.forChild([
	// ### Account
	{
		path: 'account/profile',
		component: AccountProfileComponent,
		data: {
			breadcrumb: [{ name: 'Account' }, { name: 'Profile', url: '/account/profile' }],
		},
	},
	{
		path: 'account/password',
		component: AccountPasswordComponent,
		data: {
			breadcrumb: [{ name: 'Account' }, { name: 'Password', url: '/account/password' }],
		},
	},
	{
		path: 'account/login',
		component: LoginComponent,
	},
	{
		path: 'account/resetpassword',
		component: ResetPasswordComponent,
	},
	{
		path: 'account/validateCode',
		component: Validate2FCodeComponent,
	},
]);

