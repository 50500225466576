
<div class="no-users" *ngIf="!userList.length">
	<i i18n>... the organisation does not have users</i>
	<a
		class="button"
		routerLink="/user">
		<div class="icon-add"></div>
		<ng-container i18n>add user</ng-container>
	</a>
</div>
<ul>
	<li *ngFor="let user of userList">
		<a routerLink="/user/edit/{{user._id}}">
			{{user.firstName}} {{user.lastName}} ({{getRole(user)}})
		</a>
	</li>
</ul>
	