<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container
		[preventChange]="true"
		[form]="formGroup"
		[id]="id"
	></overwrite-context-container>

	<div class="box-3-1">
		<div class="first-row">
			<input type="text" formControlName="name" placeholder="… exhibition name" />
		</div>
		<div class="publishing">
			<div class="row">
				<input
					class="sl-form__switch is-live-switch"
					id="isLiveExhibition"
					type="checkbox"
					[ngClass]="{ 'is-live--unpublished-changes': !data.model.isUpToDate }"
					formControlName="isLive"
				/>
				<label isLive [item]="data.model" for="isLiveExhibition"></label>
				<label i18n>public</label>
			</div>
			<div *ngIf="formGroup.value.isLive">
				<i>
					<ng-container *ngIf="data.model.isUpToDate; else elseBlock" i18n>
						All changes are published.
					</ng-container>
					<ng-template #elseBlock i18n>
					 There are unpublished changes.
					</ng-template>
				</i>
			</div>
		</div>
	</div>

	<div class="box-3-3">
		<div>
			<!-- first child 1/3 -->
			<div *ngIf="!data.currentUser.organisation.hideLanguageUiExhibition">
				<label i18n>Cover</label>
				<input-media-file
					type="image"
					[organisationID]="data.organisationID"
					formControlName="coverID"
				></input-media-file>
			</div>
		
			<div>
				<label i18n>Fallback language</label>
				<form-input
					type="enum"
					formControlName="defaultLanguage"
					placeholder="… select a fallback language"
					[modelItems]="data.languageOptions2"
				>
				</form-input>
			</div>
		</div>
		<div>
			<!-- last child 2/3 -->
			<div>
				<label i18n>Address</label>
				<textarea rows="2" formControlName="address"></textarea>
			</div>
			<div>
				<label i18n>Website</label>
				<input type="text" formControlName="website" />
			</div>
			<div *ngIf="data.showShareOption || data.currentUser.access.isAdmin">
				<label i18n>Social media target url</label>
				<input type="text" formControlName="shareTargetUrl" />
			</div>
			<div>
				<label i18n>Comments</label>
				<textarea rows="2" formControlName="comments"></textarea>
			</div>
		</div>
		<div>
			<!-- last child 3/3 -->
			<div *ngIf="formGroup.value.isLive && data.modelDataSource.type.includes('cloud')">
				<div class="row">
					<a
						target="_blank"
						[attr.disabled]="!data.model.isLive"
						[attr.href]="previewDomain() + '/' + formGroup.value.dataSourceID + '/apps/webapp-navigation-bar?ex=' + id"
						i18n
					>
						Visit published
					</a>
					<span
						class="icon-copy"
						title="Copy URL"
						(click)="
							copyToClipboard(
								previewDomain() + '/' + formGroup.value.dataSourceID + '/apps/webapp-navigation-bar?ex=' + id,
								'published'
							)
						"
						[ngClass]="{ 'icon--active': copyActive.published }"
					></span>
				</div>
			</div>
			<div *ngIf="id">
				<div class="row" *ngIf="!data.model.urlToken">
					<a
						target="_blank"
						[attr.href]="canPreview() ? '/preview/exhibitions/' + id : null"
						[attr.disabled]="!canPreview()"
						[attr.title]="
							canPreview()
								? null
								: 'The exhibition must be live, saved, and must have a landing exhibit, styletemplate and language content'
						"
						i18n
					>
						Visit preview
					</a>
					<span
						class="icon-copy icon--active"
						title="Copy URL"
						(click)="copyToClipboard(canPreview() ? 'preview/exhibitions/' + id : null, 'unpublished')"
						[ngClass]="{ 'icon--active': copyActive.unpublished }"
					></span>
				</div>
				<div class="row" *ngIf="data.model.urlToken">
					<a target="_blank" [attr.href]="data.model.urlToken" i18n>
						Visit preview (short URL)
					</a>
					<span
						class="icon-copy"
						title="Copy URL"
						(click)="copyToClipboard(data.model.urlToken, 'short')"
						[ngClass]="{ 'icon--active': copyActive.short }"
					></span>
				</div>
				<div *ngIf="data.currentUser.access.isAdmin || data.currentUser.access.isOrgAdmin">
					<div class="row">
						<input
							class="sl-form__switch"
							id="urlToken"
							(click)="toggleUrlToken($event)"
							[checked]="!!data.model.urlToken"
							type="checkbox"
						/>
						<label for="urlToken"></label>
						<label i18n>Share preview</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<input-language-content
		*ngIf="!data.currentUser.organisation.hideLanguageUiExhibition"
		formControlName="languageContent"
		[enableDrag]="true"
		[formGroup]="formGroup"
		[canDisableLanguage]="true"
		[defaultLanguage]="data.model.defaultLanguage"
	>
	</input-language-content>

	<div class="spacer"></div>
	<div>
		<label i18n>Start article</label>
		<form-input
			type="enum"
			formControlName="landingExhibitID"
			placeholder="… select a landing exhibit"
			[modelItems]="data.liveArticles"
			[blankEntry]="true"
		>
		</form-input>
	</div>

	<label i18n> Articles </label>
	<div
		class="exhibits"
		[attr.title]="id ? '' : 'Please create the exhibition first'"
		[ngClass]="{ 'exhibits--disabled': !id }"
	>
		<div *ngFor="let exhibit of data.unifiedArticles">
			<div class="exhibits__main">
				<span class="exhibits__name">
					<div
						class="exhibits__is-live"
						isLive
						[item]="exhibit"
						[ngClass]="{ 'exhibits--is-live': exhibit.isLive, 'exhibits--unpublished-changes': !exhibit.isUpToDate }"
					></div>
					{{ exhibit.name }}
				</span>
				<span class="exhibits__codes" *ngIf="exhibit.codes?.length">
					<label i18n>Codes</label>
					<div>
						<div *ngFor="let code of exhibit.codes">{{ code }}</div>
					</div>
				</span>
				<span class="exhibits__locations" *ngIf="exhibit.locationIDs?.length"
					><label i18n>Locations</label>
					<div *ngFor="let locationId of exhibit.locationIDs">
						{{ data.locationEntities[locationId]?.name }}
						<span>{{ data.locationEntities[locationId]?.type }}</span>
					</div></span
				>
			</div>
			<div class="exhibits__footer">
				<div (click)="editExhibitId = exhibit._id; unifiedArticleModal.open()" i18n>
					<span class="icon-edit"></span> edit
				</div>
			</div>
		</div>
		<a
			class="exhibits--add-new"
			(click)="$event.preventDefault()"
			(click)="editExhibitId = null; !id || unifiedArticleModal.open()"
		>
			<span class="icon-add"></span>
			<div i18n>Add article</div>
		</a>
	</div>

	<div>
		<label i18n>navigation map</label>
		<div class="row">
			<form-input class="check-label-right" formControlName="hasOnlineNavigationMap" [formGroup]="formGroup" type="boolean" i18n>
				Online map
			</form-input>
			<form-input
				type="enum"
				[disabled]="!formGroup.value.hasOnlineNavigationMap"
				formControlName="onlineMapBoundsLocationID"
				placeholder="… select a polygon location as bounds"
				i18n-placeholder
				[blankEntry]="true"
				[modelItems]="data.polygonLocations"
			>
			</form-input>
			<div i18n> Map bounds </div>
		</div>
		<form-input
			type="enum"
			[disabled]="formGroup.value.hasOnlineNavigationMap"
			formControlName="customMapArticleID"
			placeholder="… select a custom map article"
			i18n-placeholder
			[blankEntry]="true"
			[modelItems]="data.customMapAricles"
		>
		</form-input>
	</div>

	<div *ngIf="data.hasLocationNav || data.access.isAdmin">
		<label class="large" i18n>Locations</label>
		<input-locations
			[organisationID]="data.organisationID"
			[access]="data.access"
			formControlName="triggers"
		></input-locations>
		<div class="add-new-location" (click)="createLocationModal.open()" i18n>
			<span class="icon-add"></span>
			Create new location
		</div>
	</div>

	<form-modal
		#unifiedArticleModal
		(saveEvent)="onCreateArticle($event)"
		collectionName="UnifiedArticle"
		[id]="editExhibitId"
		[extendDefaultData]="!!editExhibitId ? null : { exhibitionID: id }"
	>
		<h2 i18n>Edit article</h2>
	</form-modal>
	<form-modal #createLocationModal collectionName="Location">
		<h2 i18n>Create a new location</h2>
	</form-modal>

	<div class="spacer" *ngIf="data.currentUser.access.isAdmin"></div>
	<div class="admin-area" *ngIf="data.currentUser.access.isAdmin">
		<h2 i18n>shoutr labs admin features</h2>
		<div class="box-1-1">
			<div class="columns">
				<input-switch formControlName="immortal" class="column" i18n>
					prevent deleting
				</input-switch>
				<input-switch formControlName="isInOfflineAppProviderInit" class="column" i18n>
					add to the list of initially synced packages
				</input-switch>
				<input-switch formControlName="isInExhibitionList" class="column" i18n>
					list in exhibition list
				</input-switch>
			</div>
		</div>
		<div class="box-1-1">
			<div>
				<label i18n>style template </label>
				<form-input
					type="enum"
					formControlName="styleTemplateID"
					placeholder="… select a style template"
					[modelItems]="data.styleTemplates"
				>
				</form-input>
				<br />
				<a
					*ngIf="formGroup.value.styleTemplateID"
					[routerLink]="'/admin/styletemplate/edit/' + formGroup.value.styleTemplateID"
					i18n
				>
					Visit styletemplate
				</a>
			</div>
			<div>
				<div>
					<label i18n>Data source</label>
					<form-input
						type="enum"
						formControlName="dataSourceID"
						placeholder="… select a data source"
						[modelItems]="data.dataSources"
					>
					</form-input>
					<br />
					<a
						*ngIf="formGroup.value.dataSourceID"
						[routerLink]="'/admin/datasource/edit/' + formGroup.value.dataSourceID"
						i18n
					>
						Visit datasource
					</a>
				</div>
				<div class="spacer"></div>
				<div #createDatasource *ngIf="data.currentUser.access.isAdmin">
					<form-modal
						#datasourceFormModal
						(saveEvent)="onCreateDatasource($event)"
						[extendDefaultData]="{ organisationID: data.model.organisationID }"
						collectionName="DataSource"
					></form-modal>
					<button (click)="datasourceFormModal.open()" class="button" type="button">
						<div class="icon-add"></div>
						<ng-container i18n>Create a Datasource</ng-container>
					</button>
				</div>
			</div>
		</div>
		<div>
			<label i18n>Tutorial</label>
			<form-input
				type="enum"
				[blankEntry]="true"
				formControlName="tutorialTourID"
				placeholder="… select a tutorial tour"
				i18n-placeholder
				[modelItems]="data.tourEntities"
			>
			</form-input>
		</div>
		<div *ngIf="data && data.hasSiteId && formGroup.value.analyticsSiteId">
			<label i18n>Analytics</label>
			<br />
			<a
				[href]="
					'https://athena.' +
					hostname +
					'/index.php?module=CoreHome&action=index&idSite=' +
					formGroup.value.analyticsSiteId +
					'&period=day&date=yesterday&updated=1#?idSite=' +
					formGroup.value.analyticsSiteId +
					'&period=day&date=yesterday&category=Dashboard_Dashboard&subcategory=1'
				"
				target="_blank"
				i18n
				>Visit the Matomo analytics</a
			>
		</div>

		<div>
			<label>Navigation Menu JSON</label>
			<input-json formControlName="navigationJson"></input-json>
		</div>

		<div>
			<h3>Web Client</h3>
			<web-client-config [formGroup]="formGroup.get('configWebClient')" formGroupName="configWebClient">
			</web-client-config>
		</div>
	</div>
</ng-container>
