/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export function fieldPathToArray(path) {
	return typeof path === 'string'
		? path.split('.')
		: path;
}

export function applyToField(obj, path, func, stopAtFalsey = false) {
	path = fieldPathToArray(path);

	return path.length === 0
		? func(obj)
		: walkFieldPath(
			obj,
			path,
			(obj, path, pathIndex, next) => {
				const field = path[pathIndex];

				if (!obj[field] && stopAtFalsey) {
					return obj;
				}

				const newFieldValue = pathIndex === path.length - 1
					? func(obj[field], path)
					: next(obj[field]);

				return newFieldValue === obj[field] && field in obj
					? obj
					: { ...obj, [field]: newFieldValue };
			},
		);
}

/**
 * Traverse obj using the fields of the path (array or dot-separated string)
 * and update the final field with the given value.  If the target value is
 * changed, the traversed objects are copied and a distinct object is returned.
 * If the value equals teh existing value, the original object is returned.
 *
 * ```js
 * const o = { a: { b: 'z' } };
 *
 * updateField(o, 'a.b', 'z') === o;      // true
 * JSON.stringify(o)                      // '{"a":{"b":"x"}}'
 *
 * const oo = updateField(o, 'a.b', 'y');
 * oo === o;                              // false
 * JSON.stringify(oo)                     // '{"a":{"b":"x"}}'
 *
 * const ooo = updateField(o, 'a.c', {d: 'x'})
 * JSON.stringify(ooo)                     // '{"a":{"b":"z"},"c":{"d":"x"}}'
 * ```
 */
export function updateField(obj: any, path: any[] | string, value: any) {
	return applyToField(
		obj,
		path,
		prevValue => value,
	);
}

export function updateFieldMut(obj: any, path_: any[] | string, value: any) {
	return walkFieldPath(
		obj,
		path_,
		(subObj, path, pathIndex, next) => {
			const field = path[pathIndex];
			if (pathIndex < path.length - 1) {
				return next(subObj[field]);
			}

			subObj[field] = value;
			return obj;
		},
	);
}

/**
 * @param obj - the object to traverse
 * @param path {any[] | string} - the path to follow (array of strings/symbols or a dot-separated string)
 * @param cb {(obj, path, pathIndex, next?) => Object} callback that recieves
 * the walk location and a next callback to walk to the next field.
 */
export function walkFieldPath(obj, path, cb, pathIndex = 0) {
	path = fieldPathToArray(path);

	if (pathIndex < path.length) {
		const nextPathIndex = pathIndex + 1;

		return cb(
			obj,
			path,
			pathIndex,
			(newFieldValue = obj[path[nextPathIndex]]) => walkFieldPath(newFieldValue, path, cb, nextPathIndex),
		);
	}

	return obj;
}

/**
 * @param obj {Object | null | undefined}
 * @param path {string[] | sting} - the path of fields to walk and set to {} if falsey
 */
export function initFieldPath(obj = {}, path) {
	return walkFieldPath(
		obj,
		path,
		(obj, path, pathIndex, next) => {
			const field = path[pathIndex];

			if (!obj[field]) {
				const newObj = { ...obj };

				path.slice(pathIndex).reduce((subObj, field) => (subObj[field] = {}), newObj);
				return newObj;
			}

			const newFieldValue = next(obj[field]);

			return newFieldValue === obj[field]
				? obj
				: { ...obj, [field]: newFieldValue };
		},
	);
}

/**
 * @param obj {Object | null | undefined}
 * @param path {string[] | string} - the path of fields to walk and set to {} if falsey
 */
export function initFieldPathMut(obj = {}, path) {
	path = fieldPathToArray(path);

	let subObj = obj;
	let i = 0;
	let reachedEmpty = false;

	for (i = 0; i < path.length; i++) {
		const field = path[i];

		reachedEmpty = reachedEmpty || !subObj[field];

		if (reachedEmpty) {
			subObj[field] = {};
		}

		subObj = subObj[field];
	}

	return obj;
}

export function getFieldValue(obj, path) {
	return walkFieldPath(obj, path, (subObj, path_, pathIndex, next) => {
		const nextSubObj = subObj[path_[pathIndex]];

		return pathIndex === path.length - 1
			? nextSubObj
			: next(nextSubObj);
	})
}

export function deleteField(obj, path) {
	const last = path.length - 1;
	const targetField = path[last];

	return applyToField(
		obj,
		path.slice(0, last),
		subObj => {
			let withoutField = subObj;

			if (targetField in subObj) {
				withoutField = {};

				for (const field in subObj) {
					if (field !== targetField) { withoutField[field] = subObj[field]; }
				}
			}

			return withoutField;
		},
		true,
	);
}

export function traverseObject(obj, func: (subValue: any, path: string[], next: (nextValue: any) => void) => any, pre?) {
	if (!obj || typeof obj !== 'object') {
		return [];
	}

	pre = pre || [];

	for (const [key, subValue] of Object.entries(obj)) {
		const currentPath = [...pre, key];

		func(subValue, currentPath, (nextValue) => {
			traverseObject(nextValue, func, currentPath);
		});
	}
}

export function getRecursivePaths(obj: any) {
	const paths: Array<string[]> = [];

	traverseObject(obj, (subObj, path, next) => {
		paths.push(path);
		next(subObj);
	});

	return paths;
}

export function flattenObject(obj: any, separator = '') {
	const flat: any = {};

	traverseObject(obj, (subObj, path, next) => {
		flat[path.join(separator)] = subObj;
		next(subObj);
	});

	return flat;
}
