/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'calendar-event-form',
	templateUrl: './index.html',
})
export class CalendarEventFormComponent {
	@Input() id = '';
	@Input() data: any;
	@Input() formGroup: FormGroup;

	ngOnInit() {
		this.formGroup.valueChanges.subscribe(value => {
			// always set a date when creating events
			if (!this.id && !value.date) {
				this.formGroup.patchValue({ date: Date.now() });
			}
		});
	}
}
