<ng-container [formGroup]="formGroup">
	<div class="columns">
		<input-switch formControlName="onlyExhibitionBeacons"> Only Exhibition Beacons </input-switch>
		<input-switch formControlName="activateBluetooth"> Activate Bluetooth (only VUE client) </input-switch>
		<input-switch formControlName="mergeExhibitionBeacons">
			Merge Exhibition Beacons (only VUE client)
		</input-switch>
	</div>
	<div class="box-1-1">
		<div>Beacon timeout (ms)<input type="number" formControlName="beaconTimeout" /></div>
		<div>Scan interval (ms)<input type="number" formControlName="scanInterval" /></div>
	</div>
	<div class="box-1-1">
		<div>Android Bias(RSSI)<input type="number" formControlName="androidBias" /></div>
		<div>iOs Bias (RSSI)<input type="number" formControlName="iosBias" /></div>
	</div>
	<div class="box-1-1">
		<div>RSSI Filter Alpha<input type="number" formControlName="rssiFilterAlpha" /></div>
		<div>Not seen for One Scan Malus<input type="number" formControlName="notSeenForOneScanMalus" /></div>
		<div>Median Window Width<input type="number" formControlName="medianWindowWidth" /></div>
	</div>

	<label>Nearest Beacon Algorithm Parameters</label>

	<div formGroupName="nearestBeaconAlgParameters">
		<div class="box-1-1">
			<div>Blocker Threshold<input type="number" formControlName="blockerThreshold" /></div>
			<div>Trigger Threshold<input type="number" formControlName="triggerThreshold" /></div>
			<div>extender Threshold<input type="number" formControlName="extenderThreshold" /></div>
			<div>Tics to win<input type="number" formControlName="ticsToWin" /></div>
		</div>

		<div class="box-1-1">
			<div>RSSI Threshold<input type="number" formControlName="rssiThreshold" /></div>
			<div>Hysterese Width<input type="number" formControlName="hystereseWidth" /></div>
		</div>

		<input-switch formControlName="isActive">
			<span>Activate Nearest Algorithm</span>
		</input-switch>
	</div>

	<label>Area Beacon Algorithm Parameters</label>

	<div formGroupName="areaBeaconAlgParameters">
		<div class="box-1-1">
			<div>Area Threshold<input type="number" formControlName="areaThreshold"/></div>
			<div>Area Hysterese<input type="number" formControlName="areaHysterese"/></div>
		</div>

		<div>
			<input-switch formControlName="isActive">
				<span>Activate Area Algorithm</span>
			</input-switch>
		</div>
	</div>
	<ng-content></ng-content>
</ng-container>
