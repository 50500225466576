
	<form
		class="login"
		[formGroup]="form"
		(ngSubmit)="onSubmit()">
	<img src="/assets/img/logo.shoutrlabs.svg" alt="shoutr labs logo" width="100">
	<h1>shoutr labs</h1>
	<div class="form">
		<h2 i18n>Reset your CMS password</h2>
		<div>
			<label i18n>email</label>
			<input
				required
				pattern="^.+@.+\.\w+$"
				type="email"
				formControlName="email">
		</div>
		<button class="button" type="submit" i18n>Reset password</button>
		<div class="forgot-password">
			<a routerLink="/account/login" i18n>Login</a>
		</div>
	</div>
</form>
	