<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container
		[preventChange]="true"
		[form]="formGroup"
		[id]="id"
	></overwrite-context-container>

	<div class="first-row">
		<div formGroupName="articleID">
			<input type="text" name="name" formControlName="name" i18n-placeholder placeholder="… article name" />
		</div>
		<div class="is-live">
			<label i18n>public</label>
			<input
				[attr.disabled]="(formGroup.value.isLive && data.isLandingExhibit) || null"
				class="sl-form__switch"
				id="isLiveUA"
				type="checkbox"
				[ngClass]="{ 'is-live--unpublished-changes': !data.model?.isUpToDate }"
				formControlName="isLive"
			/>
			<label isLive [item]="data.model" for="isLiveUA"></label>
		</div>
		<a
			*ngIf="formGroup.value.articleID.type === 'builder'"
			[href]="data.model.articleID ? baseURL + '/builder/' + data.model.articleID._id : ''"
			[ngClass]="{ 'button--disabled': hasUnsavedChanges() }"
			[attr.title]="editAppBtnTitleText()"
			(click)="!hasUnsavedChanges() || $event.preventDefault()"
			target="_blank"
			class="edit-builder button"
		>
			<span class="icon-edit"></span>
			<ng-container i18n
				>{{data.model.articleID.builderContent ? 'Edit Content' : 'Create Content'}}
				{{formGroup.value.articleID.type === 'builder2' ? 'editor v2': 'editor v3'}}
			</ng-container>
		</a>
		<a
			*ngIf="formGroup.value.articleID.type === 'builder2'"
			[href]="data.model.articleID ? baseURL + '/builder/2/' + data.model.articleID._id + '?exhibitionId=' + data.model.exhibitionID : ''"
			[ngClass]="{ 'button--disabled': hasUnsavedChanges() }"
			[attr.title]="editAppBtnTitleText()"
			(click)="!hasUnsavedChanges() || $event.preventDefault()"
			target="_blank"
			class="edit-builder button"
		>
			<span class="icon-edit"></span>
			<ng-container i18n
				>{{data.model.articleID.builderContent ? 'Edit Content in' : 'Create Content in'}}
				{{formGroup.value.articleID.type === 'builder2' ? 'editor v2': 'editor v3'}}
			</ng-container>
		</a>
		<a
			*ngIf="formGroup.value.articleID.type === 'editor' && data.useNewEditor"
			[href]="data.model.articleID ? baseURL + '/editor-next/' + data.model._id : ''"
			[ngClass]="{ 'button--disabled': hasUnsavedChanges() }"
			[attr.title]="editAppBtnTitleText()"
			(click)="!hasUnsavedChanges() || $event.preventDefault()"
			target="_blank"
			class="edit-builder button"
		>
			<span class="icon-edit"></span>
			<ng-container i18n
				>{{data.model.articleID.builderContent ? 'Edit Content' : 'Create Content'}}
				{{formGroup.value.articleID.type === 'builder2' ? 'editor v2': 'editor v3'}}
			</ng-container>
		</a>
		<a
			*ngIf="formGroup.value.articleID.type === 'editor' && !data.useNewEditor"
			[href]="data.model.articleID ? baseURL + '/editor/' + data.model._id : ''"
			[ngClass]="{ 'button--disabled': hasUnsavedChanges() }"
			[attr.title]="editAppBtnTitleText()"
			(click)="!hasUnsavedChanges() || $event.preventDefault()"
			target="_blank"
			class="edit-builder button"
		>
			<span class="icon-edit"></span>
			<ng-container i18n
				>{{data.model.articleID.builderContent ? 'Edit Content' : 'Create Content'}}
				{{formGroup.value.articleID.type === 'builder2' ? 'editor v2': 'editor v3'}}
			</ng-container>
		</a>
	</div>

	<div class="spacer"></div>
	<div class="box-1-1">
		<div>
			<label i18n>Exhibition</label>
			<div *ngIf="data.isLandingExhibit">{{ data.modelExhibition.name }} <i i18n>[Start article]</i></div>
			<form-input
				type="enum"
				*ngIf="data.exhibitions.length && !data.isLandingExhibit"
				formControlName="exhibitionID"
				placeholder="… select exhibition"
				[modelItems]="data.exhibitions"
			>
			</form-input>
			<div *ngIf="!data.exhibitions.length">
				<button (click)="createExhibitionModal.open()" type="button">
					<div class="icon-add"></div>
					<ng-container i18n>add exhibition</ng-container>
				</button>
			</div>
			<form-modal #createExhibitionModal collectionName="Exhibition" (saveEvent)="onCreateExhibition($event)">
				<h2 i18n>Create a new exhibition</h2>
			</form-modal>
		</div>
		<div>
			<label i18n>Tags</label>
			<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
		</div>
	</div>

	<div *ngIf="!data.currentUser.organisation.hideLanguageUiArticle" class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<div formGroupName="articleID">
				<label i18n>Cover</label>
				<input-media-file
					[organisationID]="data.organisationID"
					type="image"
					formControlName="coverID"
				></input-media-file>
			</div>
			<div *ngIf="data.showOpenInModalUi">
				<label i18n>Open article in modal</label> <br />
				<input class="sl-form__switch" id="openInModal" type="checkbox" formControlName="openInModal" />
				<label for="openInModal"></label>
			</div>
			<div *ngIf="data.showShareOption || data.currentUser.access.isAdmin" formGroupName="articleID">
				<label i18n>share on social media</label>
				<form-input [formGroup]="formGroup" type="boolean" formControlName="isShareable"></form-input>
			</div>
			<div formGroupName="articleID" *ngIf="data.hasQuizNav">
				<label i18n>isQuizArticle</label>
				<form-input [formGroup]="formGroup" type="boolean" formControlName="isQuizArticle"></form-input>
			</div>
			<div *ngIf="data.showShareOption || data.currentUser.access.isAdmin" formGroupName="articleID">
				<label i18n>Social media target url</label>
				<input
					type="text"
					name="shareTargetUrl"
					formControlName="shareTargetUrl"
					placeholder="… https://www.your-website.com/subpage"
				/>
			</div>
		</div>
		<div>
			<!-- last child 2/3 -->
			<div>
				<label i18n>Exhibit Codes</label>
				<input-tags
					type="number"
					min="1"
					i18n-placeholder
					placeholder="… enter a number"
					[exclude]="{
						values: data.allCodes,
						message: 'This code is already being used for another article'
					}"
					formControlName="codes"
				></input-tags>
			</div>
			<div
				formGroupName="articleID"
				*ngIf="availableArticleTypeOptions.length > 0 || data.currentUser.access.isAdmin"
			>
				<label i18n>type</label>
				<select
					formControlName="type"
					[attr.disabled]="id ? 'disabled' : null"
					(change)="onTypeSelect($event)"
				>
					<option
						*ngFor="let availableArticleOptions of availableArticleTypeOptions"
						[value]="availableArticleOptions.value"
					>
						{{ availableArticleOptions.value === 'builder2' ? 'editor v2' : availableArticleOptions.value ===
						'editor' ? 'editor v3' : availableArticleOptions.value === 'custom-map' ? 'indoor map' :
						availableArticleOptions.value === 'distortable-image-map' ? 'outdoor map' :
						availableArticleOptions.value }}
					</option>
				</select>
			</div>
			<div
				formGroupName="articleID"
				*ngIf="
					formGroup.value.articleID.type === 'media' || formGroup.value.articleID.type === 'audiosync-media'
				"
			>
				<label i18n>Video</label>
				<input-media-file
					menuDir="left"
					[organisationID]="data.organisationID"
					type="video"
					formControlName="mediaObjectID"
				></input-media-file>
			</div>
			<div formGroupName="articleID" *ngIf="formGroup.value.articleID.type === 'ar-package'">
				<label i18n>Prefab Types</label>
				<select formControlName="prefabType" value="">
					<option [value]="pfbs" *ngFor="let pfbs of prefabTypes">{{pfbs}}</option>
				</select>
			</div>

			<div formGroupName="articleID">
				<label i18n>comments</label> <textarea cols="30" rows="4" formControlName="comments"></textarea>
			</div>

			<br />
			<div
				formGroupName="articleID"
				*ngIf="
					formGroup.value.articleID.type === 'custom' ||
					formGroup.value.articleID.type === 'unity-scene' ||
					formGroup.value.articleID.type === 'hangmanGame' ||
					formGroup.value.articleID.type === 'file'
				"
			>
				<input-file
					accept=".zip"
					uploadPath="/api/articleContent"
					formControlName="customFileID"
				></input-file>
			</div>
		</div>
	</div>

	<div class="spacer"></div>
	<div>
		<label i18n>QR Code for this Article</label>
		<br />
		<small>
			command://setExhibition?id={{formGroup.value.exhibitionID}}&articleid={{data.model?.articleID._id}}
		</small>
	</div>

	<div *ngIf="data.currentUser.organisation.hideLanguageUiArticle" class="box-1-1">
		<div>
			<!-- first child 1/3 -->
			<div *ngIf="articleTypeOptions.length > 1" formGroupName="articleID">
				<label i18n>type</label>
				<select formControlName="type" [attr.disabled]="id ? 'disabled' : null">
					<option *ngFor="let articleType of articleTypeOptions" [value]="articleType.value">
						{{ articleType.label }}
					</option>
				</select>
			</div>
			<div>
				<label i18n>Exhibit Codes</label>
				<input-tags
					type="number"
					min="1"
					i18n-placeholder
					placeholder="… enter a number"
					[exclude]="{
						values: data.allCodes,
						message: 'This code is already being used for another article'
					}"
					formControlName="codes"
				></input-tags>
			</div>
			<div *ngIf="data.showOpenInModalUi">
				<label i18n>Open article in modal</label> <br />
				<input class="sl-form__switch" id="openInModal" type="checkbox" formControlName="openInModal" />
				<label for="openInModal"></label>
			</div>
		</div>
		<div>
			<!-- last child 2/3 -->
			<div *ngIf="articleTypeOptions.length <= 1" formGroupName="articleID">
				<input type="hidden" formControlName="type" [value]="articleTypeOptions[0].value" />
			</div>
			<div
				formGroupName="articleID"
				*ngIf="
					formGroup.value.articleID.type === 'media' || formGroup.value.articleID.type === 'audiosync-media'
				"
			>
				<label i18n>Video</label>
				<input-media-file
					menuDir="left"
					[organisationID]="data.organisationID"
					type="video"
					formControlName="mediaObjectID"
				></input-media-file>
			</div>

			<div formGroupName="articleID">
				<label i18n>comments</label> <textarea cols="30" rows="4" formControlName="comments"></textarea>
			</div>

			<br />
			<div
				formGroupName="articleID"
				*ngIf="formGroup.value.articleID.type === 'custom' || formGroup.value.articleID.type === 'hangmanGame'"
			>
				<input-file
					accept=".zip"
					uploadPath="/api/articleContent"
					formControlName="customFileID"
				></input-file>
			</div>
		</div>
	</div>

	<div *ngIf="formGroup.value.articleID.type == 'memoryGameKids' ||
		 formGroup.value.articleID.type == 'memoryGameAdult' ||
		 formGroup.value.articleID.type == 'puzzle' || formGroup.value.articleID.type == 'media-files'">
		<h3 i18n>Media Objects </h3>
		<memory-game-form
			[mediaObjects]="data.mediaObjects"
			[organisationID]="organisationID"
			[formGroup]="formGroup.get('articleID.game')"
		></memory-game-form>
	</div>

	<div *ngIf="formGroup.value.articleID.type == 'ar-package'">
		<h3 i18n>Augmented Reality Content</h3>
		<ar-content-form
			[mediaObjects]="data.mediaObjects"
			[organisationID]="organisationID"
			[formGroup]="formGroup.get('articleID.arContent')"
		></ar-content-form>
	</div>

	<div *ngIf="formGroup.value.articleID.type == 'custom-map'">
		<h3>Custom Map</h3>
		<div
			class="button"
			[ngClass]="{
				'button--blue-ghost': mapType != 'simple'}"
			(click)="mapType = 'simple';"
		>
			Simple Map
		</div>
		<div
			class="button"
			[ngClass]="{
				'button--blue-ghost': mapType != 'offlineGps'}"
			(click)="mapType = 'offlineGps';"
		>
			Offline GPS Map
		</div>
		<div *ngIf="mapType == 'simple'">
			<custom-map-content-form
				mapId="data.model.articleID._id"
				[formGroup]="formGroup.get('articleID.customMapContent')"
			></custom-map-content-form>
			<div formGroupName="articleID">
				<div formGroupName="customMapContent">
					<div fromGroupName="mapMetaData">
						<input-layer-exhibition
							[layers]="formGroup.value.articleID.customMapContent.mapMetaData.layers"
							[exhibitions]="data.exhibitions"
							formControlName="mapLayerToExhibitionId"
						>
						</input-layer-exhibition>
					</div>
					<input-file
						#mapZipInput
						accept=".zip"
						formControlName="customMapZip"
						uploadPath="/api/customMapContent"
					></input-file>
				</div>
			</div>
		</div>
		<div *ngIf="mapType == 'offlineGps' && !id">
			<label id="warning-label" i18n>Please save the Article before creating a new map</label>
		</div>
		<div *ngIf="mapType == 'offlineGps' && id">
			<input-map
				crs="EPSG3857"
				[mapMetaData]="{ center: [52.220836, 13.16902], maxZoom: 19, minZoom: 0, bounds: [] }"
				[hasPolygon]="true"
				[isGeoOffline]="true"
				[formGroup]="formGroup.get('articleID.customMapContent.mapMetaData.distortableImageCorners')"
				(polygonChanged)="onPolygonChanged($event)"
			></input-map>
			<label
				id="warning-label"
				*ngIf="!data.model.articleID?.customMapContent?.mapMetaData?.distortableImageCorners?.coordinates"
				i18n
				>Please save some Coordinates</label
			>
			<div *ngIf="data.model.articleID?.customMapContent?.mapMetaData?.distortableImageCorners?.coordinates">
				<label>max. Zoom(1-19)</label>
				<input
					type="number"
					name="offlineGpsMaxZoom"
					[(ngModel)]="maxZoom"
					[ngModelOptions]="{standalone: true}"
					[min]="1"
					[max]="19"
					placeholder="5"
				/>
				<div class="button" (click)="sendMapRequest()" i18n>Check selected Map</div>
				<div *ngIf="this.mapCreated == true">
					<label>TileCount(max.{{maxNrOfMapTiles}})</label>
					<div>{{data.customMap.tileCount}}</div>
					<label>QueuePosition</label>
					<div>{{data.customMap.queueSize}}</div>
					<div
						*ngIf="data.model.articleID?.customMapContent && (data.model.articleID.customMapContent.files.length == 0) && downloadingNewMap"
					>
						updating Map... Please Wait
					</div>
					<div
						*ngIf="data.model.articleID?.customMapContent && (data.model.articleID.customMapContent.files.length > 0) && downloadingNewMap"
					>
						Map updated
					</div>
					<div
						class="button"
						[ngClass]="{ 'button--disabled': (downloadingNewMap || (data.customMap.tileCount > maxNrOfMapTiles) || (data.customMap.tileCount === 0) || !data.model.articleID?.customMapContent?.mapMetaData?.distortableImageCorners?.coordinates)}"
						(click)="sendMapDownloadRequest()"
					>
						download and save Map
					</div>
				</div>
				<div formGroupName="articleID">
					<div formGroupName="customMapContent">
						<input-file
							*ngIf="formGroup.value.articleID?.customMapContent?.customMapZip"
							accept=".zip"
							formControlName="customMapZip"
							uploadPath="/api/customMapContent"
						></input-file>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="formGroup.value.articleID.type == 'distortable-image-map'" formGroupName="articleID">
		<h3 i18n>Distortable Image Map</h3>
		<input-map
			crs="EPSG3857"
			[mapMetaData]="{ center: [52.220836, 13.16902], maxZoom: 19, minZoom: 0, bounds: [] }"
			[hasDistortableImage]="true"
			[imageMediaObjectID]="formGroup.value.articleID.coverID"
			[formGroup]="formGroup.get('articleID.customMapContent.mapMetaData.distortableImageCorners')"
		></input-map>
	</div>

	<div *ngIf="!data.currentUser.organisation.hideLanguageUiArticle" formGroupName="articleID">
		<input-language-content
			formControlName="languageContent"
			[formGroup]="formGroup"
			(contentFixed)="onLanguageContentFixed()"
		>
		</input-language-content>
	</div>

	<div *ngIf="data.hasLocationNav || data.currentUser.access.isAdmin">
		<label class="large" i18n>Locations</label>
		<input-locations
			[organisationID]="data.organisationID"
			[access]="data.currentUser.access"
			[hasTrigger]="true"
			formControlName="triggers"
		></input-locations>
		<br />
		<div class="button add-new-location" (click)="createLocationModal.open()">
			<span class="icon-add"></span> <ng-container i18n>Create new location</ng-container>
		</div>
	</div>

	<form-modal #createLocationModal collectionName="Location">
		<h2 i18n>Create a new location</h2>
	</form-modal>
	<div class="spacer" *ngIf="data.currentUser.access.isAdmin"></div>
	<div class="admin-area" *ngIf="data.currentUser.access.isAdmin">
		<h2 i18n>shoutr labs admin features</h2>
		<div class="box-1-1">
			<div class="columns">
				<input-switch formControlName="immortal" class="column" i18n> prevent deleting</input-switch>
				<input-switch formControlName="isInExhibitList" class="column" i18n
					>list in autogenerated overview page</input-switch
				>
			</div>
		</div>
		<div class="spacer"></div>
		<div>
			<label>Webclient Navigation Link</label>
			<br />
			<small>
				setExhibition?id={{formGroup.value.exhibitionID}}&articleid={{data.model?.articleID._id}}
			</small>
			<br />

			<label>Production</label>
			<ul>
				<li *ngFor="let datasourceId of data.dataSourceIDs">
					<small>
						<a
							target="_blank"
							[attr.href]="previewDomain() + '/' + datasourceId + '/apps/webapp-navigation-bar/#/' + formGroup.value.exhibitionID + '/' + data.model?.articleID._id"
						>
							{{previewDomain()}}/{{datasourceId}}/apps/webapp-navigation-bar/#/{{formGroup.value.exhibitionID}}/{{data.model?.articleID._id}}
						</a>
					</small>
				</li>
			</ul>
			<label>Preview</label>
			<ul>
				<li *ngFor="let datasourceId of data.dataSourceIDs">
					<small>
						<a
							target="_blank"
							[attr.href]="'https://cms.shoutrlabs.com/preview/datasources/' + datasourceId + '/apps/webapp-navigation-bar/?ex='+ formGroup.value.exhibitionID + '#/' + formGroup.value.exhibitionID + '/' + data.model?.articleID._id + '/'"
						>
							https://cms.shoutrlabs.com/preview/datasources/{{datasourceId}}/apps/webapp-navigation-bar/?ex={{formGroup.value.exhibitionID}}#/{{formGroup.value.exhibitionID}}/{{data.model?.articleID._id}}/
						</a>
					</small>
				</li>
			</ul>

			<ng-template [ngIf]="data.model?.articleID.arContent?.arZip !== null">
				<label>AR Article</label>
				<ul>
					<li *ngFor="let datasourceId of data.dataSourceIDs">
						<small>
							<a
								target="_blank"
								[attr.href]="previewDomain() + '/' + datasourceId + '/apps/article-'+ data.model?.articleID._id + '-' + data.model?.articleIDVersion + '/' + 'index.json'"
							>
								{{previewDomain()}}/{{datasourceId}}/apps/article-{{data.model?.articleID._id}}-{{data.model?.articleIDVersion}}/index.json
							</a>
						</small>
					</li>
				</ul>
			</ng-template>
		</div>
	</div>
</ng-container>
