
		<div>
			<h1 *ngIf="(model$ | async)?.name !== 'boxx'">{{ (model$ | async)?.namePlural }}</h1>
			<p
				*ngIf="
					!['organisation', 'cmssettings', 'boxx', 'survey', 'app', 'unifiedarticle', 'tour', 'exhibition', 'media', 'glossary-term'].includes(
						(model$ | async)?.name
					)
				"
				i18n
			>
				Select an item on the left or
				<a
					class="button"
					routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create"
				>
					<span class="icon-add"></span> add
				</a>
				a new one
			</p>
			<div *ngIf="(model$ | async)?.name === 'exhibition'">
				<p i18n>
					New exhibitions are created here and the already existing ones are managed.
				</p>
				<p i18n>
				The basic settings of the exhibitions, such as titles and introductions in all available languages, title images and articles about exhibition stations can be edited here.
				</p>
				<p i18n>To edit an <b>existing exhibition</b> → select an item from the list on the left</p>
				<p i18n>
					To create a <b>new exhibition </b> click the
					<a routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create">
						<b>HINZUFÜGEN</b>
					</a>
					button on the upper left
				</p>
			</div>

			<div *ngIf="(model$ | async)?.name === 'unifiedarticle'">
				<p i18n>
					Articles are info pages about exhibits, places and services for your visitors.They can be arranged
					in a tour or quiz - or linked where ever you need them.
				</p>
				<p i18n>
					If beside texts you’d like to include images, audio files or short films in your article, they need
					to be uploaded into the
					<b> Media Library </b> before.
				</p>
				<p i18n>To edit an <b>existing article</b> → select an item from the list on the left</p>
				<p i18n>
					To create a <b>new article </b> click the
					<a routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create">
						<b>HINZUFÜGEN</b>
					</a>
					button on the upper left
				</p>
			</div>

			<div *ngIf="(model$ | async)?.name === 'tour'">
				<p i18n>
					Here you can arrange the articles on exhibits or sites into a tour and also manage the tour’s
					general settings.
				</p>

				<p i18n>To edit an <b>existing tour</b> → select an item from the list on the left</p>
				<p i18n>
					To create a <b>new tour </b> click the
					<a routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create">
						<b>HINZUFÜGEN</b>
					</a>
					button on the upper left
				</p>
			</div>

			<div *ngIf="(model$ | async)?.name === 'media'">
				<p i18n>
					Here you can upload and manage files that you want to use in your app.
				</p>
				<p i18n>
					Supported formats and recommended / maximum sizes:
				</p>
				<p i18n>
					Photo and graphic: png, jpg
				</p>
				<p i18n>
					Audio: mp3 → Maximum Size: 1 GB
				</p>
				<p i18n>
					Video: mp4 → Maximum Size: 1 GB
				</p>
				<p i18n>To edit a <b>existing media file</b> → select an item from the list on the left</p>
				<p i18n>
					To upload a <b>new media file </b> click the
					<a routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create">
						<b>HINZUFÜGEN</b>
					</a>
					button on the upper left
				</p>
			</div>

			<div *ngIf="(model$ | async)?.name === 'glossary-term'">
				<p i18n>
					With the help of the glossary you can explain specialized vocabulary to your visitors and provide additional information.
				</p>
				<p i18n>
					The terms appearing in information texts, as well as their synonyms and other grammatical forms, are linked to the corresponding definitions.
				</p>
				<p i18n>To edit an <b>existing entry</b> → select an item from the list on the left</p>
				<p i18n>
					To create a <b>new entry </b> click the
					<a routerLink="{{ (model$ | async).clientPathPrefix }}/{{ (model$ | async).name }}/create">
						<b>HINZUFÜGEN</b>
					</a>
					button on the upper left
				</p>
			</div>

			<p *ngIf="(model$ | async)?.name === 'organisation'" i18n>Select your organisation on the left</p>
			<p *ngIf="(model$ | async)?.name === 'cmssettings'" i18n>Select the settings on the left</p>
			<p *ngIf="(model$ | async)?.name === 'boxx'" class="boxx-dashboard-wrapper">
				<boxx-dashboard></boxx-dashboard>
			</p>
			<p *ngIf="(model$ | async)?.name === 'survey'" i18n>
				Creat an article and add survey elements in the editor. View the survey result by selecting an article
				on the left.
			</p>

			<div *ngIf="(model$ | async)?.name === 'app'">
				<p i18n>
				Here you get the overview of the exhibitions published in your app, articles and tours.
				</p>
				<p i18n>To see the details, please select an item from the list on the left</p>
			</div>


			<ng-container *ngIf="(model$ | async)?.name === 'media' && (canUpload$ | async)">
				<p i18n>You can also drag and drop files into the space below</p>

				<div class="box-1-1">
					<div class="dropzone-wrapper">
						<div [hidden]="dropzone?.droppableTypes.length" class="dropzone-overlay">
							<p i18n>Drag media files from your device and drop them here to create multiple media items</p>
						</div>
						<file-dropzone
							#dropzone
							(fileDrop)="onDropItems($event.files)"
							(dragEnd)="cdr.detectChanges()"
							(dragStart)="cdr.detectChanges()"
							[accept]="accept"
						>
							<p>
								Create {{ dropzone.droppableTypes.length }} media item{{
									dropzone.droppableTypes.length > 1 ? 's' : ''
								}}
							</p>
						</file-dropzone>
					</div>
				</div>

				<modal-component #uploadItemsModal class="items-modal">
					<div class="new-media-items">
						<div class="new-media-item" *ngFor="let item of droppedItems; let i = index">
							<ng-container *ngIf="!item.uploadFailed">
								<div class="first-row">
									<input
										type="text"
										[(ngModel)]="droppedItems[i].name"
										i18n-placeholder
										placeholder="… media name"
									/>
								</div>

								<input-file
									(fileChange)="onFileUpload($event, item, i)"
									(error)="onUploadError(item)"
									[(ngModel)]="droppedItems[i].id"
									[file]="item.file"
								>
								</input-file>
							</ng-container>
						</div>
					</div>

					<footer>
						<button
							type="button"
							(click)="createItems()"
							[title]="this.allUploaded ? '' : 'Please wait while the files are uploaded'"
							[disabled]="!this.allUploaded"
						>
							Create {{ (droppedItems || []).length }} Media Item{{
								(droppedItems || []).length > 1 ? 's' : ''
							}}
						</button>
					</footer>
				</modal-component>
			</ng-container>
		</div>
	