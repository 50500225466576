/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	name: { type: String, required: true, unique: false, sparse: true, trim: true },
	comments: { type: String },
	content: { type: String, default: '' },
	images: [
		{
			fileID: { type: Schema.ObjectId },
			name: { type: String },
			mimeType: { type: String },
			md5: { type: String },
		},
	],
	fonts: [
		{
			fileID: { type: Schema.ObjectId },
			name: { type: String },
			mimeType: { type: String },
			md5: { type: String },
		},
	],
	fileSize: { type: Number, default: 0 },
	blockModifiers: [
		{
			name: { type: String },
			active: { type: Boolean, default: false },
			modifiers: [{ name: String, label: String }],
		},
	],
};
var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'StyleTemplate',
	schema: ModelSchema,
	definition: def,
};
