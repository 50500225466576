/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { I18n } from '@ngx-translate/i18n-polyfill';

import * as CollectionActions from '../../socket.actions';
import { getValidators } from '../../services/schemaTools';
import appConfig from '../../config';
import { articleConfig } from '../../_articles/config';
import { InputFileComponent } from '../../forminputs/input-file.component/input-file.component';
import { previewDomain } from '../../utils';
import { prefabTypes } from '../../../../models/article/articleSchema';

@Component({
	selector: 'unifiedarticle-form',
	templateUrl: 'index.html',
	styleUrls: ['./index.sass'],
})
export class UnifiedArticleFormComponent {
	@Input() id = '';
	@Input() formGroup: FormGroup;
	@Input() data: any;

	public baseURL = appConfig.baseURL;
	public mapType = 'offlineGps';
	private coordinates = [];
	private maxNrOfMapTiles = 100; // keep this number in line with the max in osmTools.js
	public maxZoom = 19;
	public mapCreated = false;
	public downloadingNewMap = false;
	public articleTypeOptions;
	public availableArticleTypeOptions = [];
	public prefabTypes = prefabTypes;

	constructor(private store: Store<any>, public i18n: I18n) {
		this.articleTypeOptions = [
			{ value: 'builder', label: this.i18n('Editor (deprecated)') },
			{ value: 'builder2', label: this.i18n('Editor') },
			{ value: 'editor', label: this.i18n('Editor Tapir') },
			{ value: 'custom', label: this.i18n('Hand-coded webpage in zip file') },
			{ value: 'ar-package', label: this.i18n('Augmented Reality package for native AR apps') },
			{ value: 'media', label: this.i18n('Video for webstation-type shoutr.Boxxes') },
			{ value: 'custom-map', label: this.i18n('Indoor map from image') },
			{ value: 'distortable-image-map', label: this.i18n('Outdoor map with image overlay') },
			{ value: 'audiosync-media', label: this.i18n('Video for mediaplayer-type shoutr.Boxxes') },
			{ value: 'external', label: this.i18n('External URL for webstation-type shoutr.Boxxes') },
			{ value: 'unity-scene', label: this.i18n('Unity3D WebGL scene') },
			{ value: 'memoryGameKids', label: this.i18n('Memory Game for Kids') },
			{ value: 'memoryGameAdult', label: this.i18n('Memory game for Adults') },
			{ value: 'hangmanGame', label: this.i18n('Hangman Game') },
			{ value: 'puzzle', label: this.i18n('Puzzle Game') },
			{ value: 'file', label: this.i18n('File') },
			{ value: 'media-files', label: this.i18n('Media Files') },
		];
	}
	ngOnChanges(changes) {
		if (changes.data) {
			this.availableArticleTypeOptions = this.articleTypeOptions.filter(({ value }) =>
				this.data.availableArticleTypes.includes(value)
			);

			// set the mapcoords in case they already exist so the user doesn't need to repress the button on the input-map
			try {
				this.coordinates = changes.data.currentValue.model.articleID.customMapContent.mapMetaData.distortableImageCorners.coordinates;
			} catch (error) {
				/* NOTE/TODO: optional chaining is not a thing in this node version it seems, instead of properly checking for existence I
				** opted for a silent fail instead of a conditional monster. This try/catch should be replaced with an if statement for form
				** once our node version gets bumped to 14, but this works for now since it only matters if the data actually exists
				*/
			}
		}
	}

	hasUnsavedChanges() {
		return !(this.id && this.formGroup.pristine);
	}

	editAppBtnTitleText() {
		return this.hasUnsavedChanges() ? 'Please save your changes first' : 'Edit the article in the editor';
	}

	preventClick(event) {
		return this.hasUnsavedChanges() ? event.preventDefault : event;
	}

	onLanguageContentFixed() {
		const articleGroup = this.formGroup && (this.formGroup.controls.article as FormGroup);

		if (articleGroup && !this.data.model.article._id) {
			articleGroup.controls.languageContent.markAsPristine();
		}
	}

	onCreateExhibition({ data }) {
		this.formGroup.patchValue({ exhibitionID: data._id });
	}

	onPolygonChanged(value) {
		this.coordinates = value.coordinates;
		this.downloadingNewMap = false;
	}

	sendMapRequest() {
		this.store.dispatch(
			new CollectionActions.GetMapInformation({ coordinates: this.coordinates[0], maxZoom: this.maxZoom })
		); // coordinates are delivered as [[coordinates...]], unwrap them here for a simpler structured [coordinates...]
		this.mapCreated = true;
	}

	sendMapDownloadRequest() {
		// TODO: check if localcoordinates == modelcoordinates and disable button if so to
		// avoid dirty article after automatic update which could result in saving over the new map
		if (!this.downloadingNewMap) {
			this.downloadingNewMap = true;
			this.store.dispatch(
				new CollectionActions.StartMapDownload({
					coordinates: this.coordinates[0],
					maxZoom: this.maxZoom,
					articleId: this.data.model.articleID._id,
				})
			);
		}
	}

	previewDomain() {
		return previewDomain({});
	}

	onTypeSelect() {
		if (this.formGroup.value.articleID.type === 'ar-package') {
			this.formGroup.controls.articleID['controls']['arContent'].controls['arZip'].setValidators([Validators.required]);
		} else {
			this.formGroup.controls.articleID['controls']['arContent'].controls['arZip'].setErrors(null)
		}
	}
}
