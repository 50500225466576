/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { CollectionName } from './modelConfigs';
export { currentuserActions as currentuser } from './_currentuser/actions';

export const actions = {
	filterList: (collectionName: CollectionName, limit: number, skip: number, filter: object) => {
		return ({
		type: actions.FILTER_REQUEST,
		collectionName,
		limit,
		skip,
		filter
	}) },
	filter: (collectionName: CollectionName, query) => ({
		type: actions.FILTER_MULTI,
		payload: query,
		collectionName,
	}),
	sort: (collectionName: CollectionName, query) => ({
		type: actions.SORT_MULTI,
		payload: query,
		collectionName,
	}),

	ADD_FAIL: 'ADD_FAIL',
	FILTER_MULTI: 'FILTER_MULTI',
	SORT_MULTI: 'SORT_MULTI',
	FILTER_REQUEST: 'FILTER_REQUEST',
};
