/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
	selector: 'nav-context-component',
	templateUrl: './nav-context.component.html',
	styleUrls: ['./nav-context.component.sass'],
	encapsulation: ViewEncapsulation.None,
})
export class NavContextComponent {
	@Input() data;

	@Output() orgchange = new EventEmitter(false);
	@Output() exhchange = new EventEmitter(false);

	public showOrgList;
	public showExhList;
	public options;
	public noOrgTitle = this.i18n('ALL ORGANISATIONS');

	constructor(public i18n: I18n) {}

	ngOnChanges() {
		this.options = this.data
			? this.data.organisations.map(orgItem => ({
				value: orgItem,
				label: orgItem.name,
			}))
			: [];

		this.options.unshift({ value: {}, label: this.noOrgTitle });
	}
}
