/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { NgModule } from '@angular/core';
import { FormInputsModule } from '../forminputs';

import { ExhibitFormComponent } from '../_exhibits/components/exhibit-form.component';
import { InputArticleComponent } from './components/input-article.component/input-article.component';

import { ModalsModule } from '../modals/module';
import { LocationModule } from '../_locations/module';

@NgModule({
	imports: [
		FormInputsModule,
		ModalsModule,
		LocationModule,
	],
	exports: [
		ExhibitFormComponent,
		InputArticleComponent,
	],
	declarations: [
		ExhibitFormComponent,
		InputArticleComponent,
	],
	entryComponents: [
		ExhibitFormComponent,
	],
})
export class ExhibitModule { }
