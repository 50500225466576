/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { startWith } from 'rxjs/operators';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { bytesToHumanReadableSize } from '../../../utils';

@Component({
	selector: 'ar-content-form',
	template: `
		<div class="ar-content-form">
			<div [formGroup]="formGroup">
				<div class="ar__content">
					<!--<input-ar-models formControlName="arModels"></input-ar-models>-->
					<div class="ar__input-wrapper">
						<label i18n>AR package (ZIP)</label>
						<input-file
							class="ar__input"
							#arZipInput
							accept=".zip"
							formControlName="arZip"
							uploadPath="/api/arContent"
							[downloadName]="downloadName"
						>
							<ng-container *ngIf="!!arZipInput.value">
								{{ (formGroup.get('arZip')?.dirty ? '(Updated ' : '(') + 'zip file)' }}
							</ng-container>
						</input-file>
					</div>

					<span class="ar__right-arrow icon-right-arrow_L"></span>

					<div class="ar__models-wrapper" *ngIf="formGroup.value.indexJson">
						<label>Prefab Markes</label>
						<div *ngFor="let prefab of formGroup.value.indexJson.Prefab">
							<label>{{ prefab.name }}</label>
							<div *ngIf="!prefab.markerIsExternalMediaObject">not a marker prefab</div>
							<div *ngIf="prefab.markerIsExternalMediaObject">
								<input-media-file
									[organisationID]="organisationID"
									type="image"
									[value]="getMediaObjectID(formGroup.value.externalMediaMarkers, prefab.name)"
									(change)="onExternalMediaMarkerChange(prefab.name, $event)"
								></input-media-file>
							</div>
						</div>
					</div>

					<div *ngIf="!formGroup.value.indexJson">
						<div class="ar__models-wrapper">
							<label i18n>Models</label>
							<div *ngFor="let model of formGroup.value.models; let index = index" class="ar__model">
								<h4>{{ index }}. {{ model.name }}</h4>
								<span> version {{ model.version }} </span>
							</div>
						</div>

						<div class="ar__markers-wrapper">
							<label i18n="Augmentened reality markers">Markers</label>
							<div *ngIf="!formGroup.get('arZip')?.dirty" class="ar__markers">
								<div *ngFor="let marker of formGroup.value.markers" class="ar__marker">
									<input-file
										type="image"
										[value]="marker"
										uploadPath="/api/arContent"
										[canUpdate]="false"
									></input-file>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="spacer"></div>
				<div *ngIf="formGroup.value.fileSize">
					<label> AR package (ZIP) Size </label>
					<span class="file-size-info"> {{ convertBytesToHumanReadableSize(formGroup.value.fileSize) }}</span>
				</div>
				<div class="spacer"></div>
				<label i18n>External Media Objects</label>
				<input-media-list
					formControlName="externalMediaObjects"
					[mediaObjects]="mediaObjects"
					[organisationID]="organisationID"
					type="audio,video,image"
				></input-media-list>
			</div>
		</div>
	`,
	styleUrls: ['./index.sass'],
})
export class ArContentFormComponent {
	@Input() formGroup: FormGroup;
	@Input() organisationID;
	@Input() mediaObjects = {};
	public downloadName = '';

	private sub;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngAfterViewInit() {
		this.sub = this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)).subscribe(value => {
			this.downloadName = (value.models || []).map(m => m.name).join('__');
		});
	}

	convertBytesToHumanReadableSize(size) {
		return bytesToHumanReadableSize(size);
	}

	onExternalMediaMarkerChange(prefabName, mediaObjectID) {
		const externalMediaMarkers = [
			...this.formGroup.value.externalMediaMarkers.filter(item => item.prefabName !== prefabName),
			{ prefabName, mediaObjectID },
		];
		this.formGroup.patchValue({ externalMediaMarkers });
		this.changeDetectorRef.detectChanges();
	}

	getMediaObjectID(externalMediaMarkers, prefabName) {
		if (!externalMediaMarkers) return null;
		const item = externalMediaMarkers.find(_item => _item.prefabName === prefabName)
		if (item) return item.mediaObjectID;
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
