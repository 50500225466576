/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

let counter = 0;

export const messageActions = {
	error: content => ({ type: messageActions.MESSAGE_ERROR, payload: { content, id: counter++ } }),
	success: content => ({ type: messageActions.MESSAGE_INFO, payload: { content, id: counter++ } }),
	warn: content => ({ type: messageActions.MESSAGE_WARN, payload: { content, id: counter++ } }),
	info: content => ({ type: messageActions.MESSAGE_INFO, payload: { content, id: counter++ } }),
	close: id => ({ type: messageActions.MESSAGE_CLOSE, payload: id }),

	MESSAGE_ERROR: 'MESSAGE_ERROR',
	MESSAGE_WARN: 'MESSAGE_WARN',
	MESSAGE_INFO: 'MESSAGE_INFO',
	MESSAGE_CLOSE: 'MESSAGE_CLOSE',
};
