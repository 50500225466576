<ng-container [formGroup]="formGroup">

	<ng-content></ng-content>

	<overwrite-context-container [form]="formGroup" [id]="id"></overwrite-context-container>

	<div>
		<label i18n>name</label>
		<input type="text" formControlName="name">
	</div>
	<div>
		<label i18n>comments</label>
		<textarea rows="4" formControlName="comments"></textarea>
	</div>
	<div>
		<label i18n>content</label>
		<textarea cols="30" rows="4" formControlName="content"></textarea>
	</div>
	<div class="box-1-1" *ngIf="id">
		<div>
			<label i18n>images</label>
			<input-files-extended accept=".PNG,.JPG,.JPEG,.png,.jpg,.jpeg,.gif,.svg" formControlName="images" [uploadPath]="'/api/styletemplates/'+id"></input-files-extended>
		</div>
		<div>
			<label i18n>fonts</label>
			<input-files-extended accept=".ttf,.otf,.woff" formControlName="fonts" [uploadPath]="'/api/styletemplates/'+id"></input-files-extended>
		</div>
	</div>
	<label>Used in</label>
	<div *ngIf="data.dataSources.length">
		<label i18n>DataSources</label>
		<ul>
			<li *ngFor="let item of data.dataSources"><a [routerLink]="'/admin/datasource/edit/'+item._id">{{item.name}}</a></li>
		</ul>
	</div>
	<div *ngIf="data.exhibitions.length">
		<label i18n>Exhibitions</label>
		<ul>
			<li *ngFor="let item of data.exhibitions"><a [routerLink]="'/exhibition/edit/'+item._id">{{item.name}}</a></li>
		</ul>
	</div>

</ng-container>
