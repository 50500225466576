/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export const info = `<div>
	A location is:
	<ul>
		<li>a point on earth characterised by latitude and longitude or</li>
		<li>a beacon, a miniature low-power radio device that transmits its own unique identification number to the local area.</li>
	</ul>
</div>`;
