
<div class="input-widget">
	<div class="time" [ngClass]="{ disabled: disabled }">
		<div class="hour">
			<div (click)="hour = hour + 1; setTime($event)">+</div>
			<input
				type="number"
				min="0"
				max="23"
				step="1"
				(input)="setTime($event)"
				[(ngModel)]="hour">
			<div (click)="hour = hour - 1; setTime($event)">-</div>
		</div>
		<div class="colon">
			<div>:</div>
		</div>
		<div class="minute">
			<div (click)="minute = minute + 15; setTime($event)">+</div>
			<input
				type="number"
				min="0"
				max="59"
				step="1"
				(input)="setTime($event)"
				[(ngModel)]="minute"
				>
			<div (click)="minute = minute - 15; setTime($event)">-</div>
		</div>
	</div>
	<div i18n>
		o'clock
	</div>
</div>
