
	<div
		[hidden]="droppableTypes.length <= 0"
		class="file-dropzone"
		(dragenter)="onDragChange($event)"
		(dragleave)="onDragChange($event)"
		(drop)="onDrop($event)">
		<div class="file-dropzone-border">
			<div class="file-dropzone-content" #contentRef>
				<ng-content>
				</ng-content>
			</div>
		</div>

		<span *ngIf="!contentRef.childNodes.length">Drop {{ droppableTypes.length }} {{ droppableTypes.length === 1 ? 'file' : 'files' }}</span>
	</div>
	