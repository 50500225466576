/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

var Schema = require('mongoose').Schema;

var def = {
	organisationID: { type: Schema.ObjectId, ref: 'Organisation', index: true, unique: false },
	uuid: { type: String, required: true, default: '13:37:18:FE:42:AA:88:13:AA:4F:EA:49:03:00:00:00' },
	major: { type: Number, required: true },
	minor: { type: Number, required: true },
	bias: { type: Number, default: 0 },
	type: {
		type: String,
		enum: ['trigger', 'blocker', 'extender', 'delayedTrigger', 'gate', 'area'],
		isField: true,
		required: true,
	},
	extendedBeaconID: { type: Schema.ObjectId, ref: 'Beacon' },
	delay: { type: Number }, // delay for type `delayedTrigger` in seconds
	triggerThreshold: { type: Number },
	comments: { type: String },
};
var ModelSchema = new Schema(def);
ModelSchema.plugin(require('../plugin/changeLog')); // Add soft Deleted and changelog

module.exports = {
	name: 'Beacon',
	schema: ModelSchema,
	definition: def,
	relations: [{ op: 'del', rel: 'Location', path: 'beaconID' }],
};
