/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, Input, Output, forwardRef, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { isoLang2name } from '../../../utils';
import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';

@Component({
	selector: 'input-language-content-calendar',
	template: `
<div
	[ngClass]="{invalid: missingValues}">
	<div *ngIf="this.missingValues" class="invalid-message">
		The organisation is missing or there are no languages set for your organisation.
	</div>
	<div
		[ngClass]="{'media-files-list--hide': this.missingValues}"
		[dragula]="bagName"
		[(dragulaModel)]="value"
		class="drag-container media-files-list">
		<div
			*ngFor="let languageItem of value; let i = index;"
			class="media-files-item">
			<div class="body">
				<div>
					{{isoLang2name[languageItem.languageISO]}}
				</div>
				<div *ngIf="enableDrag" class="drag-handle">
					<span class="make-default-hint" i18n>(drag left / right to change order in app)</span>
				</div>
				<div>
					<label i18n>User-visible Title</label>
					<input
						type="text"
						i18n-placeholder
						required="true"
						placeholder="… title"
						(change)="propagateChange()"
						[attr.disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.title">
				</div>
				<div>
					<label i18n>Description</label>
					<input-text
						cols="30"
						rows="4"
						i18n-placeholder
						placeholder="… description"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.description"></input-text>
				</div>
				<div >
					<label i18n>content</label>
					<input-text
						cols="30"
						rows="4"
						i18n-placeholder
						placeholder="… text"
						(change)="propagateChange()"
						[allowModal]="true"
						[disabled]="languageItem.disabled || null"
						[(ngModel)]="languageItem.content"></input-text>
				</div>
				<div class="spacer"></div>
				<div *ngIf="canDisableLanguage" class="can-disable">
					<input
						class="sl-form__switch"
						[attr.id]="languageItem.languageISO+'disable'"
						[(ngModel)]="languageItem.disabled"
						(change)="propagateChange()"
						type="checkbox" />
					<label [attr.for]="languageItem.languageISO+'disable'"></label>
					Disable
				</div>
			</div>
		</div>
	</div>
</div>
	`,
	styleUrls: ['./index.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputLanguageContentCalendarComponent), multi: true }],
})
export class InputLanguageContentCalendarComponent extends CustomValueAccessor {
	@Input() enableDrag: boolean;
	@Input() canDisableLanguage = false;
	@Input() formGroup: any;
	@Input() fields = {
		title: true,
		description: true,
		text: false,
	};

	@Output() contentFixed = new EventEmitter<any[]>();

	public isoLang2name = isoLang2name;
	public missingValues = true;

	constructor(drake: DragulaService, changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, public i18n: I18n) {
		super(i18n);

		this.initOptions({
			drake,
			drakeOptions: {
				direction: 'horizontal',
			},
			bagName: `langcontent-${Math.random()}`,
			changeDetectorRef,
		});

		this._value = [];
	}

	get value() {
		return this._value;
	}

	set value(value) {
		this.set_value(value);
		this.missingValues = !this.value || !this.value.length;
	}
}
