<ng-container [formGroup]="formGroup">
	<ng-content></ng-content>

	<overwrite-context-container
		[preventChange]="true"
		[form]="formGroup"
		[id]="id"
	></overwrite-context-container>

	<div class="first-row">
		<label i18n>Name</label> <input type="text" formControlName="name" i18n-placeholder placeholder="… article name" />
	</div>
	<div>
		<label i18n>Tags</label>
		<input-tags i18n-placeholder placeholder="… tags" formControlName="tags"></input-tags>
	</div>
	<div class="box-1-3">
		<div>
			<!-- first child 1/3 -->
			<label i18n>Cover</label>
			<input-media-file
				[organisationID]="data.organisationID"
				type="image"
				formControlName="coverID"
			></input-media-file>
			<a
				*ngIf="formGroup.value.type === 'builder'"
				[href]="baseURL + '/builder/' + id"
				[ngClass]="{ 'button--disabled': hasUnsavedChanges() }"
				[attr.title]="editAppBtnTitleText()"
				(click)="!hasUnsavedChanges() || $event.preventDefault()"
				target="_blank"
				class="edit-builder button"
				i18n
			>
				<span class="icon-edit"></span> Edit Article Content
			</a>
		</div>
		<div>
			<!-- last child 2/3 -->
			<div *ngIf="data.articleTypeOptions?.length > 1 || data.currentUser.access.isAdmin">
				<label i18n>type</label>
				<ng-select [disabled]="!!id" formControlName="type" [options]="data.articleTypeOptions"> </ng-select>
			</div>
			<div><label i18n>comments</label> <textarea cols="30" rows="4" formControlName="comments"></textarea></div>
			<div *ngIf="data.showShareOption || data.currentUser.access.isAdmin">
				<label i18n>share on social media</label>
				<form-input [formGroup]="formGroup" type="boolean" formControlName="isShareable"></form-input>
			</div>
			<div *ngIf="data.showShareOption || data.currentUser.access.isAdmin">
				<label i18n>Social media target url</label>
				<input
					type="text"
					name="shareTargetUrl"
					formControlName="shareTargetUrl"
					placeholder="… https://www.your-website.com/subpage"
				/>
			</div>
			<div *ngIf="formGroup.value.type === 'media' || formGroup.value.type === 'audiosync-media'">
				<label i18n>Video</label>
				<input-media-file
					menuDir="left"
					[organisationID]="data.organisationID"
					type="video"
					formControlName="mediaObjectID"
				></input-media-file>
			</div>
			<div *ngIf="formGroup.value.type === 'external'">
				<label i18n>External URL</label>
				<input type="text" formControlName="externalURL" i18n-placeholder placeholder="… URL to external website" />
			</div>

			<br />
			<div *ngIf="formGroup.value.type === 'custom' || formGroup.value.type === 'unity-scene' ||
						formGroup.value.type === 'hangmanGame' || formGroup.value.type === 'file'">
				<input-file
					accept=".zip"
					uploadPath="/api/articleContent"
					formControlName="customFileID"
				></input-file>
			</div>
		</div>
	</div>

	<div *ngIf="formGroup.value.type == 'ar-package'">
		<h3 i18n>Augmented Reality Content</h3>
		<ar-content-form
			[mediaObjects]="data.mediaObjects"
			[organisationID]="organisationID"
			[formGroup]="formGroup.get('arContent')"
		></ar-content-form>
	</div>
	<div *ngIf="formGroup.value.type == 'custom-map'" formGroupName="customMapContent">
		<h3>Custom Map</h3>
		<input-file
			#mapZipInput
			accept=".zip"
			formControlName="customMapZip"
			uploadPath="/api/customMapContent"></input-file>
		<custom-map-content-form
			mapId="id"
			[formGroup]="formGroup.get('customMapContent')"
		></custom-map-content-form>
	</div>
	<div *ngIf="formGroup.value.type == 'distortable-image-map'">
		<h3 i18n>Distortable Image Map</h3>
		<input-map
			crs="EPSG3857"
			[mapMetaData]="{ center: [52.220836, 13.16902], maxZoom: 19, minZoom: 0, bounds: [] }"
			[hasDistortableImage]="true"
			[imageMediaObjectID]="formGroup.value.coverID"
			[formGroup]="formGroup.get('customMapContent.mapMetaData.distortableImageCorners')"></input-map>
	</div>

	<div class="spacer"></div>
	<div>
		<input-language-content formControlName="languageContent" [formGroup]="formGroup">
		</input-language-content>
	</div>
</ng-container>
