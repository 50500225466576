/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { schema } from '../../../models/loanerDevice/loanerDeviceSchema';
import { ModelConfig } from '../modelConfigs/ModelConfig';
import { LoanerdeviceFormComponent } from './components/loanerdevice-form';

export const loanerdeviceConfig = new ModelConfig({
	collectionName: 'LoanerDevice',
	clientPathPrefix: '/admin',
	icon: '',

	schema,

	formComponent: LoanerdeviceFormComponent,
});
