
	<div class="sl-listview__wrapper">
		<nav
			#leftColumn
			[ngStyle]="{ width: (this.columns.left.styleWidth$ | async), flex: (columns.left.styleWidth$ | async) ? 'none' : '' }">

			<model-list
				[data]="listData$ | async"
				[id]="id"
				[access]="access$ |async"
				[model]="model$ | async"></model-list>

		</nav>

		<div
			class="resize-bar"
			draggable="true"
			(mousedown)="resizeStart($event, 'left')">
		</div>

		<main class="sl-listview__form">
			<router-outlet></router-outlet>
		</main>

		<ng-container *ngIf="hasSidebarDeps(model$ | async)">
			<div
				class="resize-bar"
				draggable="true"
				(mousedown)="resizeStart($event, 'right')">
			</div>

			<aside
				#rightColumn
				[ngStyle]="{
					width: (this.columns.right.styleWidth$ | async),
					flex: (columns.right.styleWidth$ | async) ? 'none' : ''
				}">
				<!-- we wil convert this in to bread crumbs feature
				that's the reason we are hiding this for now -->
				<!-- <model-context></model-context> -->
				<exhibition-preview-container *ngIf="!isLocalhost"></exhibition-preview-container>
				<div *ngIf="isLocalhost">Preview is hidden on localhost</div>
			</aside>
		</ng-container>
	</div>
	