
		<div class="analytics">
			<h1 i18n>Analytics</h1>
			<div class="box-1-1">
				<div>
					<label i18n>Organisation</label>
					<div class="organisations">
						<div *ngIf="organisationOptions.length === 1">
							{{ organisationOptions[0].label }}
						</div>
						<ng-select
							*ngIf="organisationOptions.length > 1"
							(selected)="selectOrganisation($event)"
							[options]="organisationOptions"
							[placeholder]="'… select organisation'"
						></ng-select>
					</div>
				</div>
				<div>
					<div *ngIf="!analyticsForOrganisation && exhibitionOptions?.length">
						<label i18n>Exhibition</label>
						<div class="exhibitions">
							<div *ngIf="exhibitionOptions.length === 1">
								{{ exhibitionOptions[0].label }}
							</div>
						</div>
					</div>
					<div *ngIf="isRoot && siteId">
						<a [href]="visitUrl" target="_blank">Visit the Matomo analytics</a>
					</div>
					<input-date-range
						[beginDate]="dateFrom.toDate()"
						[endDate]="dateTo.toDate()"
						(dateRange)="dateRangeChanged($event)"
					></input-date-range>
					<div class="tabs">
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.uniqueVisitorsOverTime }"
							(click)="setAnalyticsType(analyticsTypes.uniqueVisitorsOverTime)"
							i18n
						>
							Unique visitors over time
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageviewsOverTime }"
							(click)="setAnalyticsType(analyticsTypes.pageviewsOverTime)"
							i18n
						>
							Pageviews over time
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageviewsPerUniqueVisitorOverTime }"
							(click)="setAnalyticsType(analyticsTypes.pageviewsPerUniqueVisitorOverTime)"
							i18n
						>
							Pageviews per visitor
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.pageTitles }"
							(click)="setAnalyticsType(analyticsTypes.pageTitles)"
							i18n
						>
							Pages
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.deviceModel }"
							(click)="setAnalyticsType(analyticsTypes.deviceModel)"
							i18n
						>
							Device models
						</div>
						<div
							class="button"
							[ngClass]="{ active: analyticsType == analyticsTypes.visitsPerTime }"
							(click)="setAnalyticsType(analyticsTypes.visitsPerTime)"
							i18n
						>
							Visits per daytime
						</div>
					</div>
					<div *ngIf="datasourceOptions?.android?.length && isRoot">
						<label>Google Play Store</label>
						<div class="tabs">
							<div
								*ngFor="let app of datasourceOptions.android"
								class="button"
								[ngClass]="{ active: analyticsType == analyticsTypes.googlePlayStoreInstalls }"
								(click)="setAnalyticsType(analyticsTypes.googlePlayStoreInstalls, app.value)"
							>
								{{ app.label }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<button type="button" (click)="onExport()" i18n>Export TSV (Excel)</button>
			</div>
		</div>
	