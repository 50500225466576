
	<ng-container *ngIf="formGroup && formControlName" [formGroup]="formGroup">

		<input-disable
			#checkbox
			*ngIf="control && hasDisable"
			[disabled]="disabled"
			[control]="control"
			[defaultValue]="dataSourceSettings"></input-disable>

		<ng-content select="[slot=before]"></ng-content>

		<input-switch
			*ngIf="type === 'boolean'"
			[formControlName]="formControlName"
			[disabled]="control.disabled || disabled"
			[value]="value"
			[placeholder]="dataSourceSettings"
			(change)="value = $event.target.checked"></input-switch>

		<ng-content></ng-content>
		<ng-container *ngIf="type !== 'boolean'" [ngSwitch]="type">

			<input *ngSwitchCase="'number'"
				type="number"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[min]="min"
				[max]="max"
				[placeholder]="dataSourceSettings || placeholder"
				[(ngModel)]="value">

			<input *ngSwitchCase="'text'"
				type="text"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[placeholder]="placeholder"
				[(ngModel)]="value">

			<ng-select *ngSwitchCase="'enum'"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[(ngModel)]="value"
				[placeholder]="dataSourceSettings || placeholder"
				[options]="options"></ng-select>

			<input-text *ngSwitchCase="'textarea'"
				[disabled]="control.disabled || disabled"
				[minLength]="minLength"
				[maxLength]="maxLength"
				[formControlName]="formControlName"
				[placeholder]="placeholder"
				[(ngModel)]="value"></input-text>

			<input-time *ngSwitchCase="'date'"
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[(ngModel)]="value"></input-time>

			<input type="text" *ngSwitchDefault
				[disabled]="control.disabled || disabled"
				[formControlName]="formControlName"
				[minlength]="minLength"
				[maxlength]="maxLength"
				[placeholder]="dataSourceSettings || placeholder"
				[(ngModel)]="value">

		</ng-container>

		<ng-content select="[slot=after]"></ng-content>

	</ng-container>
	