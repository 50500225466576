/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {combineLatest as observableCombineLatest,  Observable, of as observaleOf } from 'rxjs';

import {filter, map, pluck, switchMap} from 'rxjs/operators';
import { Component, ViewEncapsulation, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { I18n } from '@ngx-translate/i18n-polyfill';

import * as CollectionActions from './socket.actions';
import { RouteInfo } from './services/route-info';
import { currentuserActions } from './_currentuser/actions';
import { actions } from './actions';
import { modelConfigs } from './modelConfigs';

import { AppState } from './reducers';
import { pluckDistinct, getCookie, setCookie } from './utils';
import { Title } from '@angular/platform-browser';

/**
 * Here we initialize the main HTML,routes from root, and inlude the global CSS styles.
 * To make the CSS global we used `ViewEncapsulation.None`. Read more about it
 * [here](http://blog.thoughtram.io/angular/2015/06/29/shadow-dom-strategies-in-angular2.html).
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'./app.component.sass',
		'../../node_modules/dragula/dist/dragula.min.css',
		'../../node_modules/viewer-3d/css/3d.css',
		'../../node_modules/leaflet/dist/leaflet.css',
		'../../node_modules/leaflet-draw/dist/leaflet.draw.css',
		'../../node_modules/leaflet-toolbar/dist/leaflet.toolbar.css',
		'../../node_modules/leaflet-distortableimage/dist/leaflet.distortableimage.css',
		'./sass/rainbow.sass',
	],
})
export class AppComponent {
	public access$;
	public status$;
	public navList;
	public showExtenderNav = false;
	public lockExtenderNav = false;
	public isStaging;
	public modelVisits = {};
	private currentModelName;
	public messages$;
	public cmssettings$;
	public analytics = false;
	public dashboard = false;
	public topPanelActive = false;
	public hasAnalytics = false;
	@ViewChild('leftMenu', { static: true }) leftMenuRef: ElementRef;
	public maxVisibleMenuItems = 10;
	public loggedInUserInitials = null;
	public toggleAccountSettings = false;
	public showHeader = false;
	private navElementWidth: number;
	private maxMenuWidth: number;
	private leaveTriggered = false;
	private leaveTimout: any;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions<any>,
		private routeInfo: RouteInfo,
		private i18n: I18n,
		private title: Title,
	) {
		this.isStaging = (<any>window).serverEnvironment === 'staging';
		store.dispatch(
			currentuserActions.recoverState(localStorage.currentuser ? JSON.parse(localStorage.currentuser) : {})
		);
		this.cmssettings$ = store.pipe(
			pluck('models', 'CmsSetting', 'entities'),
			map((state: any) => Object.values(state)[0]));
		this.status$ = pluckDistinct(store, 'status');
		this.messages$ = pluckDistinct(store, 'messages');
		this.navList = {
			left: [],
			right: [{ model: modelConfigs.User, name: this.i18n('User') }, { model: modelConfigs.Organisation, name: this.i18n('Organisation') }],
		};
		this.access$ = pluckDistinct(store, 'currentuser');
		this.access$.subscribe((state: any) => {
			this.hasAnalytics = state.access.isAdmin || state.organisation.showAnalytics
			this.loggedInUserInitials = this.getLoggedInUserInitials(state);
			const fullNavList = [
				{ model: modelConfigs.DataSource, name: this.i18n('App'), title: this.i18n('Apps or websites') },
				{ model: modelConfigs.Exhibition, name: this.i18n('Exhibition'), title: this.i18n('Groups of exhibits') },
				{ model: modelConfigs.UnifiedArticle, name: this.i18n('Article'), title: this.i18n('Exhibit + article + location') },
				{ model: modelConfigs.MediaObject, name: this.i18n('Media'), title: this.i18n('Pictures, videos, ...') },
				// { model: modelConfigs.Article, name: this.i18n('Web app'), title: this.i18n('Text with media') }, // Hidden because we use unifiedArticle for everything
				{ model: modelConfigs.Location, name: this.i18n('Location'), title: this.i18n('A physical place') },
				// { model: modelConfigs.Exhibit, name: this.i18n('Exhibit'), title: this.i18n('Articles with a location') }, // Hidden because we use unifiedArticle for everything
				{ model: modelConfigs.Tour, name: this.i18n('Tour'), title: this.i18n('Visitor tours') },
				{ model: modelConfigs.Quiz, name: this.i18n('Quiz'), title: this.i18n('Quiz') },
				{ model: modelConfigs.ArticleList, name: this.i18n('Playlist'), title: this.i18n('Article lists for media players') },
				{ model: modelConfigs.Survey, name: this.i18n('Survey'), title: this.i18n('Results of user surveys') },
				{
					model: { icon: 'icon-tickets', namePlural: this.i18n('Tickets'), path: '/tickets', name: 'tickets' },
					title: 'View the tickets issued by the current organisation',
				},
			];
			if (state.access.isAdmin || state.organisation.advancedUi) {
				this.navList.left = fullNavList;
			} else {
				this.navList.left = [
					{ model: modelConfigs.DataSource, name: this.i18n('App'), title: this.i18n('Apps or websites') },
					{ model: modelConfigs.Exhibition, name: this.i18n('Exhibition'), title: this.i18n('Groups of Exhibits') },
					{ model: modelConfigs.UnifiedArticle, name: this.i18n('Article'), title: this.i18n('Text with Media') },
					{ model: modelConfigs.MediaObject, name: this.i18n('Media'), title: this.i18n('Pictures, Videos, ...') },
				];
			}
			if (state.access.isAdmin || state.organisation.showGlossary) {
				this.navList.left.push({ model: modelConfigs.GlossaryTerm, name: this.i18n('Glossary'), title: this.i18n('Glossary') });
			}
			if (state.access.isAdmin || state.organisation.showCalendar) {
				this.navList.left.push({ model: modelConfigs.CalendarEvent, name: this.i18n('Calendar'), title: this.i18n('Calendar') });
			}
			if (state.access.isDesigner) {
				this.navList.left.push({ model: modelConfigs.StyleTemplate, name: this.i18n('Style template'), title: this.i18n('Style Templates') });
			}
			if (!state.access.isAdmin) {
				const enabledNavUi = (state.organisation.enableNavUi || []).filter(name => {
					if (name === 'surveys') {
						return state.access.isOrgAdmin;
					}
					return true;
				});

				this.navList.left.push(...fullNavList.filter(item => enabledNavUi.includes(item.model.name)));
			}
		});
		this.store.pipe(pluck('currentuser', 'extendNav')).subscribe((toggleState: boolean) => {
			this.lockExtenderNav = toggleState;
		});

		observableCombineLatest(
			routeInfo.navigationEnd$,
			routeInfo.params$.pipe(filter(params => {
				return !!params.modelName;
			})),
			(navigation, params) => ({ navigation, params })
		).subscribe(({ navigation, params }) => {
			if (navigation.url === "/account/validateCode") {
				this.showHeader = false;
			}else {
				this.showHeader = true;
			}
			const atCreateRoute = /\/create\b/.test(navigation.urlAfterRedirects);

			this.currentModelName = params.modelName;
			this.modelVisits[params.modelName] = atCreateRoute ? null : params.id || null;
		});

		// set document title
		routeInfo.params$.pipe(switchMap((params) => {

			if (params.modelConfig && params.id) {
				return params.modelConfig.getItem$(this.store, params.id, params.versionId)
					.pipe(map(item => ({ params, item })))
			}

			return observaleOf({ params, item: null })
		})).subscribe(({ params, item }) => {

			const titleParts = [
				'shoutr labs - CMS',
				params.modelConfig && params.modelConfig.namePlural,
				item && params.modelConfig.getItemTitle(item),
			];

			this.title.setTitle(titleParts.filter(Boolean).join(' | '))
		})

		this.actions$.pipe(
			filter(
				action =>
					action.type === currentuserActions.LOGOUT_SUCCESS ||
					action.type === currentuserActions.CURRENTUSER_SET_ORG
			))
			.subscribe(() => {
				this.modelVisits = {};
			});
	}
	ngAfterViewInit() {
		this.navElementWidth = 80;
		this.setMaxMenuItems();
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setMaxMenuItems();
	}
	setMaxMenuItems() {
		const maxMenuWidth = document.body.offsetWidth * 0.45;
		this.maxVisibleMenuItems = Math.floor(maxMenuWidth / this.navElementWidth) - 1;
	}
	clearFilterAndNavigateToCollection(navItem) {
		const modelConfig = navItem.model;
		if (!modelConfig.collectionName) {
			this.routeInfo.router.navigateByUrl(modelConfig.path);
			return;
		}
		this.store.dispatch(actions.filter(modelConfig.collectionName, ''));

		const lastVisited = this.modelVisits[modelConfig.name];
		const target =
			!lastVisited || navItem.model.name === this.currentModelName
				? `${modelConfig.clientPathPrefix}/${modelConfig.name}`
				: `${modelConfig.clientPathPrefix}/${modelConfig.name}/edit/${lastVisited}`;

		this.routeInfo.router.navigateByUrl(target);
	}
	isActive(modelName) {
		return RegExp(`[/]${modelName}\\b`).test(this.routeInfo.router.url);
	}
	isHidden(index) {
		return index > this.maxVisibleMenuItems;
	}
	onExtenderNavEnter() {
		if (this.lockExtenderNav) {
			return;
		}

		this.showExtenderNav = true;
	}

	onExtenderNavLeave() {
		if (this.lockExtenderNav) {
			return;
		}
		if (!this.leaveTriggered) this.leaveTriggered = true
		else clearTimeout(this.leaveTimout)
		this.leaveTimout = setTimeout(() => {
			this.showExtenderNav = false;
			this.leaveTriggered = false
		}, 8 * 1000)
	}

	onExtenderNavClick() {
		if (this.showExtenderNav) {
			this.showExtenderNav = false;
		}
		this.store.dispatch(currentuserActions.toggleExtendNav());
		// this.lockExtenderNav = !this.lockExtenderNav; // now done by the line above
	}

	logout() {
		this.store.dispatch(currentuserActions.logout());
	}
	// Prevent file drops anywhere to load the file as current page
	@HostListener('drop', ['$event'])
	@HostListener('dragover', ['$event'])
	onDrop(event) {
		event.preventDefault();
	}

	getLoggedInUserInitials(state) {
		let firstName;
		let lastName;
		if (!state.currentuser.firstName) return;
		if(state.currentuser) {
			firstName = state.currentuser.firstName ? (state.currentuser.firstName).substring(0,1) : null;
			lastName = state.currentuser.lastName ? (state.currentuser.lastName).substring(0,1): null;
			return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
		}
	}

	toggleAccountMenu(event) {
		if (!this.toggleAccountSettings) {
			this.toggleAccountSettings = true;
		}else {
			this.toggleAccountSettings = false;
		}
		let accountMenuElem = document.getElementById('accountSettings');
		if (accountMenuElem) {
			accountMenuElem.style.left = (event.clientX - 90) + 'px';
		}
	}

	@HostListener('document:click', ['$event'])
	onClick(event) {
		if(!event.target.classList.contains('login-user')) {
			this.toggleAccountSettings = false;
		}
	}

}
