/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {map, pluck} from 'rxjs/operators';
import { Component, forwardRef, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { CustomValueAccessor } from '../../../forminputs/CustomValueAccessor';
import { selectors } from '../../../selectors';
import * as actions from '../../../socket.actions';
import { systempkConfig } from '../../../_systempks/config';


@Component({
	selector: 'input-systempks',
	template: `
<div class="list">
	<div *ngFor="let pkid of value">{{getName(pkid)}} <span class="icon-remove" (click)="remove(pkid)"></span></div>
</div>
<div class="input-widget">
	<select
		(change)="propagateChange()"
		[(ngModel)]="selectSystemPk"
		[ngClass]="{empty: !selectSystemPk}"
		(change)="add()">
		<option value="" disabled i18n>... select a system package</option>
		<option *ngFor="let spk of filterSelectable((systempks$ | async))" [value]="spk._id" >{{spk.name}}</option>
	</select>
</div>
	`,
	styleUrls: ['./input-systempks.component.sass'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSystemPksComponent), multi: true }],
})
export class InputSystemPksComponent extends CustomValueAccessor {
	public systempks$;
	private systempksEntities;
	public selectSystemPk;
	constructor(private store: Store<any>, public i18n: I18n) {
		super(i18n);
		const systempks$ = store.pipe(pluck('models', 'SystemPK'));

		store.dispatch(new actions.GetCollectionList({ collectionName: systempkConfig.collectionName }));
		this.systempks$ = systempks$.pipe(map(selectors.getAlphabeticList));
		this.systempksEntities = {};
		systempks$.pipe(map(selectors.getEntities)).subscribe((data) => {
			this.systempksEntities = data;
		});
		this.selectSystemPk = '';
		this.value = [];
	}
	add() {
		if (this.selectSystemPk) this.value = [...(this.value || []), this.selectSystemPk];
		this.selectSystemPk = '';
	}
	writeValue(value) {
		this.selectSystemPk = 'x'; // FIXME I insert a bogus value here to reset the select input, an empty value does not seem to reset the select.
		this.value = value;
	}
	filterSelectable(list) {
		return this.value
			? list.filter(spk => !this.value.includes(spk._id))
			: list;
	}
	remove(rid) {
		this.value = this.value.filter(id => rid !== id);
	}
	getName(id) {
		return this.systempksEntities[id] ? this.systempksEntities[id].name : id;
	}
	ngOnDestroy() {
		this.store.dispatch(new actions.LeaveCollectionList({ collectionName: systempkConfig.collectionName }));
	}
}
