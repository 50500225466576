<ng-container [formGroup]="formGroup">
	<div class="columns">
		<input-switch formControlName="hasExhibitOverView" class="column">
			Has Exhibit Overview
		</input-switch>
		<input-switch formControlName="webClientStartsGallerySlideShow" class="column">
			Web Client Start Gallery Slideshow
		</input-switch>
	</div>

	<label>Gallery</label>

	<div formGroupName="gallery">
		<div class="columns">
			<input-switch formControlName="startsSlideShow" class="column">
				Start slideshow
			</input-switch>
			<input-switch formControlName="openGalleryOnImages" class="column">
				Open gallery on images
			</input-switch>
		</div>
		<div class="columns">
			<input-switch formControlName="zoomEnabled" class="column">
				Allow zoom
			</input-switch>
		</div>
		<div class="columns">
			<div class="column">
				UI display mode
				<select formControlName="uiMode">
					<option *ngFor="let mode of schema.gallery.uiMode.enum" value="{{ mode }}">{{ mode }}</option>
				</select>
			</div>
			<div class="column">
				Slideshow speed (ms)
				<input type="number" formControlName="slideShowSpeed" />
			</div>
		</div>
	</div>

	<label>Zip Style</label>

	<div formGroupName="zipStyle">
		<div class="columns">
			<input-switch formControlName="openSingle" class="column">
				Open Single
			</input-switch>
			<input-switch formControlName="openFirst" class="column">
				Open First
			</input-switch>
		</div>
		<div class="columns">
			<input-switch formControlName="closeable" class="column">
				Closable
			</input-switch>
			<input-switch formControlName="openAll" class="column">
				Open all
			</input-switch>
		</div>
	</div>

	<label>Audio Player</label>

	<div formGroupName="audioPlayer" class="columns">
		<input-switch formControlName="inLine" class="column">
			Inline
		</input-switch>

		<input-switch formControlName="autoPlay" class="column">
			Auto Play
		</input-switch>
	</div>
	<label>Navigation Settings</label>

	<div formGroupName="navigationBar" class="columns">
		<input-switch formControlName="hideOnScroll" class="column">
			Hide navigation bar on scroll
		</input-switch>
	</div>

	<div formGroupName="navigation" class="columns">
		<input-switch formControlName="alwaysRestoreScroll" class="column">
			always save and restore article view scroll positions
		</input-switch>
	</div>

	<label>Location Settings</label>
	<div formGroupName="locations" class="columns">
		<input-switch formControlName="preventForceTrigger" class="column">
			Prevent Force Trigger
		</input-switch>
		<input-switch formControlName="combineLocations" class="column">
			Combine locations of all exhibitions
		</input-switch>
	</div>

	<label>Maintenance Settings</label>

	<div formGroupName="maintenance" class="columns">
		<input-switch formControlName="showDeviceInfo" class="column">
			Show device info
		</input-switch>
	</div>

	<label>Notification Settings</label>

	<div formGroupName="notificationSounds">
		<div class="columns">
			<input-switch formControlName="articleLoading" class="column">
				Article is loading
			</input-switch>
			<input-switch formControlName="articleLoaded" class="column">
				Article is loaded
			</input-switch>
		</div>
		<div class="columns">
			<input-switch formControlName="audioPlayerLoading" class="column">
				Audioplayer is loading
			</input-switch>
		</div>
	</div>

	<label>Keypad settings</label>
	<div formGroupName="keyPad">
		<div class="columns">
			<input-switch formControlName="combineCodeMaps" class="column">
				Combine code maps of all exhibitions
			</input-switch>
		</div>
	</div>
	<label>Package Syncer Settings</label>
	<div formGroupName="packageSyncer">
		<div class="columns">
			<input-switch formControlName="autoDownload" class="column">
				Auto download all content (deprecated)
			</input-switch>
		</div>
		<div class="columns">
			Mode of which batches of packages are downloaded
			<select formControlName="mode">
				<option *ngFor="let mode of schema.packageSyncer.mode.enum" value="{{ mode }}">{{ mode }}</option>
			</select>
		</div>
		<div class="columns">
			Package Syncer Image Size
			<select formControlName="pkgSyncImgSizes">
				<option *ngFor="let imgSize of schema.packageSyncer.pkgSyncImgSizes.enum" value="{{ imgSize }}">{{ imgSize }}</option>
			</select>
		</div>
	</div>
	<div formGroupName="swipeNavigation">
		<div class="columns">
			<input-switch formControlName="enableHistorySwipe" class="column">
				Enable swipe left and right through history if there is no Tour or special pages actice
			</input-switch>
		</div>
		<div class="columns">
			<input-switch formControlName="disableSwipeInBrowser" class="column">
				Disable swipe between articles in browser
			</input-switch>
		</div>
	</div>

	<label>Cookie Popup Settings</label>

	<div formGroupName="cookiePopup" class="columns">
		<input-switch formControlName="enableOverylayScreen" class="column">
			Show Overlay Screen
		</input-switch>
	</div>

	<label>Tutorial</label>

	<div formGroupName="tutorial" class="columns">
		<input-switch formControlName="showArTutorial" class="column">
			Show AR Tutorial
		</input-switch>
	</div>

	<ng-content></ng-content>
</ng-container>
