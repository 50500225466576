
<div *ngIf="hasDisable">
	<input
		type="checkbox"
		class="btn-checkbox"
		attr.id="{{inputUid}}-checkbox"
		(change)="handleCheckbox($event.target.checked)"
		[checked]="value != null">
	<label attr.for="{{inputUid}}-checkbox"></label>
</div>
<div class="list">
	<div *ngFor="let item of valueAsList">{{item}}<span class="icon-remove" (click)="remove(item)"></span></div>
</div>
<div class="input-widget">
	<input type="text" [(ngModel)]="url" [disabled]="disabled || (value == null && hasDisable)"/>
	<button class="button" (click)="add($event)" [disabled]="disabled || (value == null && hasDisable)" i18n>add</button>
</div>
	