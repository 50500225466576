/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {of as observableOf, combineLatest as observableCombineLatest,  Observable } from 'rxjs';
import {delay, first, takeUntil, filter, map, pluck} from 'rxjs/operators';
import { Component, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import * as moment from 'moment';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { AppState } from '../../reducers';
import { ticketConfig } from '../../_tickets/config';
import { exhibitionConfig } from '../../_exhibitions/config';
import * as collectionActions from '../../socket.actions';
import config from '../../config';
import { pluckDistinct, CollectionJoiner } from '../../utils';

const dateFormat = 'YYYY-MM-DD HH:mm';

@Component({
	selector: 'tickets-list',
	template: `
	<div class="sl-listview__wrapper">

		<nav>
			<h1 i18n>Tickets</h1>

			<div class="ticket-counts">
				<div class="ticket-count" (click)="selectAll()">
					<div class="ticket-count-number">
						{{ filter.result.length }}
					</div>
					<div class="ticket-count-title" i18n>
						Created
					</div>
				</div>

				<div class="ticket-count" (click)="selectConverted()">
					<div class="ticket-count-number">
						{{ filter.convertedCount }}
					</div>
					<div class="ticket-count-title" i18n>
						Redeemed
					</div>
				</div>

				<div class="ticket-count" (click)="selected.size ? selectNone() : selectAll()">
					<div class="ticket-count-number">
						{{ selected.size }}
					</div>
					<div class="ticket-count-title" i18n>
						Selected
					</div>
				</div>

				<button [disabled]="deleting" (click)="deleteModal.open()" [class.hidden]="!selected.size" type="button" i18n>Delete Selection</button>
			</div>

			<a target="download" class="ticket-csv-download" [attr.href]="getCsvUrl((org$ | async)._id, filter.year, filter.quarter)">
				<button type="button" i18n>Download CSV</button>
			</a>

			<tickets-filter
				#filter
				(filterEvent)="onFilter($event)"
				[tickets]="(tickets$ | async).items">
				<h3 i18n>Filters</h3>
			</tickets-filter>

		</nav>

		<main class="table-container flex">
			<div class="table-head">
				<div class="table-row">
					<span class="table-heading {{ column.className }}" *ngFor="let column of columns">
						{{ column.title }}
					</span>
				</div>
			</div>

			<div
				class="table is-striped is-narrow is-hoverable is-fullwidth">
				<div class="table-body">
					<div
						class="table-row ticket-row"
						[ngClass]="{ 'is-selected': selected.has(ticket._id) }"
						(click)="toggleSelected(ticket)"
						*ngFor="let ticket of formattedTickets">
						<code class="table-data {{ column.className }}" *ngFor="let column of columns">{{ ticket[column.name] }}</code>
					</div>
				</div>
			</div>
		</main>

		<modal-component #deleteModal>

			<div *ngIf="!deleting">
				<p i18n> Are you sure you'd like to delete these tickets?  </p>
				<div class="modal__buttons">
					<button type="button" (click)="deleteSelected(deleteModal)" class="button--red" i18n>Delete</button>
					<button
						type="button"
						(click)="deleteModal.close()">
						Don't delete
					</button>
				</div>
			</div>
			<div *ngIf="deleting">
				<p i18n> Deleting Tickets </p>
				<p i18n>{{ selected.size }} remaining</p>
			</div>
		</modal-component>
	</div>
	`,
	styleUrls: ['./tickets.component.sass'],
})
export class TicketsComponent {
	org$: Observable<any> = this.store.pipe(pluck('currentuser', 'organisation'));
	tickets$: Observable<any[]> = observableCombineLatest(
		ticketConfig.getListData$(this.store).pipe(pluck('list')),
		pluckDistinct(this.store, 'models', 'Exhibition', 'entities')).pipe(
		map(([tickets, exhibitions]) => {
			return (tickets as any[]).reduce((acc, ticket) => {
				const exhibition = exhibitions[ticket.exhibitionID];

				acc.items.push({
					...ticket,
					convertedOn: ticket.convertedOn ? moment(ticket.convertedOn).format(dateFormat) : '',
					createdOn: moment(ticket.createdOn).format(dateFormat),
					exhibitionName: exhibition ? exhibition.name : '',
				});

				if (ticket.convertedOn) {
					acc.convertedCount++;
				}

				return acc;
			}, {
				convertedCount: 0,
				items: [],
			})
		}));
	fromDate: Date = new Date();
	toDate: Date = new Date();

	columns = [
		{ title: this.i18n('Code'), name: 'code', className: 'is-1' },
		{ title: this.i18n('Creation Date'), name: 'createdOn', className: 'is-4' },
		{ title: this.i18n('Conversion Date'), name: 'convertedOn', className: 'is-4' },
		{ title: this.i18n('Exhibition'), name: 'exhibitionName' },
	];

	formattedTickets: any[] = [];
	selected = new Set<string>();
	deleting = false;

	private depManager: CollectionJoiner;

	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
		private cdr: ChangeDetectorRef,
		public i18n: I18n
	) {
		this.depManager = new CollectionJoiner(store, [
			{ name: ticketConfig.collectionName, full: true },
			{ name: exhibitionConfig.collectionName },
		]);
	}

	ngOnDestroy() {
		this.depManager.leaveCurrent();
	}

	ngOnChanges(changes) {
		console.log(changes);
	}

	getCsvUrl(orgId, year, quarter) {
		if (!parseInt(quarter, 10)) {
			quarter = '1-4';
		}

		return `${config.baseURL}/api/tickets/${orgId ? `${orgId}/` : ''}${year}-q${quarter}-tickets.csv`;
	}

	toggleSelected({ _id: id }) {
		this.selected = new Set(this.selected);

		if (this.selected.has(id)) {
			this.selected.delete(id);
		} else {
			this.selected.add(id);
		}
	}

	onFilter(tickets) {
		this.updateSelected(tickets);

		this.formattedTickets = tickets.map(ticket => ({
			...ticket,
			convertedOn: ticket.convertedOn ? moment(ticket.convertedOn).format(dateFormat) : '',
			createdOn: moment(ticket.createdOn).format(dateFormat),
		}));
	}

	updateSelected(tickets) {
		const prevSelected = this.selected;
		this.selected = new Set<string>();

		for (const { _id: id } of tickets) {
			if (prevSelected.has(id)) {
				this.selected.add(id);
			}
		}
	}

	selectConverted() {
		this.selected = new Set(this.formattedTickets
			.filter(({ convertedOn }) => !!convertedOn)
			.map(({ _id }) => _id));
	}

	selectAll() {
		this.selected = new Set(this.formattedTickets
			.map(({ _id }) => _id));
	}

	selectNone() {
		this.selected = new Set();
	}

	async deleteSelected(modal) {
		this.deleting = true;

		for (const id of Array.from(this.selected)) {
			await this.deleteTicket(id);
		}

		this.deleting = false;
		modal.close();
	}

	async deleteTicket(id) {
		this.store.dispatch(new collectionActions.DeleteCollectionItem({ collectionName: ticketConfig.collectionName, id }));

		return this.actions$.pipe(
			filter(({ type, payload }: collectionActions.CollectionItemDeleted) => {
				return type === collectionActions.COLLECTION_ITEM_DELETED && payload.data === id;
			}),
			// timeout after 20 seconds
			takeUntil(observableOf(null).pipe(delay(20000))),
			first(),)
			.toPromise();
	}
}
