/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Store } from '@ngrx/store';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { currentuserActions } from '../../../_currentuser/actions';
import * as socketActions from '../../../socket.actions';
import { setCookie } from '../../../utils';

function requiredList(fromControl) {
	return fromControl.value && fromControl.value.length ? null : {
		requiredList: {
			valid: false,
		},
	};
}

@Component({
	selector: 'user-form',
	templateUrl: './index.html',
	styleUrls: ['index.sass'],
})
export class UserFormComponent {
	@Input() id = '';
	@Input() data;
	@Input() formGroup: FormGroup;
	public copyActive = false;
	public enableTOTP = false;
	constructor(private store: Store<any>) {}

	resetPassword() {
		this.store.dispatch(
			currentuserActions.resetpassword({ email: this.data.model.email }));
	}

	getApiKey() {
		this.store.dispatch(
			new socketActions.GetApiKey()
		);
	}

	copyToClipboard(path) {
		if (!path) return;

		const input = document.createElement('input');
		document.body.appendChild(input);
		input.value = path;
		input.select();

		try {
			document.execCommand('copy');
			this.copyActive = true;
			setTimeout(() => {
				this.copyActive = null;
			}, 300);
		} catch (error) {
			console.warn(`Could not copy to clipboard. ${error}`);
		}
		document.body.removeChild(input);
	}

	switchLanguage(language) {
		setCookie('language', language, 365);
		document.location.reload();
	}

	handleCheckBoxChange(event) {
		alert(event);
		this.enableTOTP = true;
	}
}
