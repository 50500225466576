
	<ng-content></ng-content>
	<div class="columns">
	<span class="column is-5" i18n>Year</span>
		<span class="column">
			<select type="date" [(ngModel)]="year">
				<option [value]="0" i18n>All years</option>
				<option
					*ngFor="let year of years; let i = index"
					[value]="year">{{ year }}</option>
			</select>
		</span>
	</div>
	<div class="columns" [ngStyle]="{ visibility: this.year ? '' : 'hidden' }">
		<span class="column is-5" i18n="Quater as in quater of the year">Quarter</span>
		<span class="column">
			<select type="date" [(ngModel)]="quarter">
				<option [value]="0" i18n>All quarters</option>
				<option
					*ngFor="let q of [1, 2, 3, 4]"
					[value]="q" i18n="Q for quater of the year">Q{{ q }}</option>
			</select>
		</span>
	</div>
	