/*
 * Copyright (C) shoutr labs UG (haftungsbeschränkt) - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { pluckDistinct } from '../../utils';

@Component({
	selector: 'overwrite-context-container',
	template: `
<overwrite-context-component
	[preventChange]="preventChange"
	[form]="form"
	[id]="id"
	[data]="data$ | async">
</overwrite-context-component>
`,
})
export class OverwriteContextContainer {
	@Input() form;
	@Input() id;
	@Input() preventChange = false;

	public data$;

	constructor(private store: Store<any>) {
		this.data$ = observableCombineLatest(
			store.select('currentuser'),
			pluckDistinct(store, 'models', 'Organisation', 'entities'),
		).pipe(map(([currentuser, organisations]) => {
			return {
				...currentuser,
				organisations,
			};
		}));
	}
}
